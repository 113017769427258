import React, { useContext, useEffect } from 'react';
import { SttTranslateHook } from '@stt-componentes/core';
import { SttBarChartHorizontal } from '@stt-componentes/charts';
import { useSignal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';
import axios from 'axios';
import { getHeaders } from '../../request';
import { useMoment } from '../../hooks';
import upperFirst from 'lodash.upperfirst';

const cores = (value) => {
    if (value.dataPointIndex === 0) return '#28689e';
    if (value.dataPointIndex === 1) return '#5f9fd2';
    if (value.dataPointIndex === 2) return '#95c1e6';
    return '#cbe3f9';
}

const Respondidas = ({ idRede }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    useSignals();

    const moment = useMoment();
    const datasetRespondidas = useSignal([]);
    const totalRespondidas = useSignal(0);
    const semDados = useSignal(undefined);

    useEffect(() => {
        buscarIndicadorRespondidas();
    }, []);

    const buscarIndicadorRespondidas = () => {
        axios.get(`${global.gConfig.url_base_teleconsultorias}/solicitacao/indicadores/respondidas`, { params: { idRede }, headers: getHeaders() })
            .then((response) => {
                const { data } = response.data;

                if (data.length === 0) {
                    semDados.value = strings.semTeleconsultoriasRespondidas;
                    datasetRespondidas.value = [
                        [],
                        [{ name: "Respondidas", data: [] }]
                    ]
                    return;
                }

                const nomeMes = [];
                const valorMes = [{
                    name: "Respondidas",
                    data: []
                }];
                let total = 0;
                data.forEach(indicador => {
                    nomeMes.push(upperFirst(moment(indicador.mes, 'M').format('MMMM')));
                    total += parseInt(indicador.total);
                    valorMes[0].data.push(parseInt(indicador.total))
                })
                datasetRespondidas.value = [
                    nomeMes,
                    valorMes,
                    [cores]
                ];

                totalRespondidas.value = total;
            });
    };

    return (
        <SttBarChartHorizontal
            dataset={datasetRespondidas.value}
            uiConfig={{
                height: 400,
                dataLabels: {
                    style: {
                        fontSize: '15px',
                        colors: ['#000']
                    },
                    textAnchor: 'end',
                    offsetX: 40,
                    position: 'top'
                }
            }}
            option={{ title: strings.respondidasNumeroTotal(totalRespondidas.value), description: strings.totalRespondidas4Meses }}
            showLoading={true}
            noData={semDados.value}
        />
    )
}

export default Respondidas;