import React, { useEffect, useContext } from 'react';
import StickyBox from "react-sticky-box";
import axios from 'axios';
import { getHeaders } from '../../request';
import {
    SttGrid,
    SttHidden,
    SttTranslateHook
} from '@stt-componentes/core';
import FormRegulacao from './form';
import Detalhes from '../visualizacao/detalhes';
import Functions from '../../common/Functions';
import { useSignal, useSignals } from '@preact/signals-react/runtime';
import usuario from '../../signals/usuario';
import alerta from '../../signals/alerta';
import carregando from '../../signals/carregando';
import { buscarRegulacao } from './pesquisa';

const ConteinerRegulacao = ({ id, idAba, removerAbaId, adicionarAba }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);

    useSignals();

    const teleconsultoriaRegulacao = useSignal(null);

    const removerAba = () => {
        buscarRegulacao.value = true;
        removerAbaId(idAba);
    }

    useEffect(() => {
        if (id) {
            carregando.value = {
                open: true,
                text: strings.carregando
            };
            axios.get(`${global.gConfig.url_base_teleconsultorias}/solicitacao/${id}`, { headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        let { data } = response.data;

                        data.acoes = Functions.acoes(usuario, data);
                        data = Functions.formatarParaVisualizacao(data);

                        teleconsultoriaRegulacao.value = data;
                    }
                })
                .catch(err => {
                    console.log(err);
                    const { response } = err;
                    let msg = strings.erroDesconhecido;
                    let arrMensagem = [];
                    if (response) {
                        const { data } = response;
                        data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        msg = arrMensagem.join('\n');
                    }

                    alerta.value = {
                        ...alerta.value,
                        type: 'error',
                        title: strings.erro,
                        message: msg,
                        open: true,
                        options: [{
                            title: strings.ok,
                            onClick: () => {
                                alerta.value = {
                                    ...alerta.value,
                                    open: false
                                }
                            }
                        }],
                        onClose: () => {
                            alerta.value = {
                                ...alerta.value,
                                open: false
                            };
                        }
                    }
                })
                .finally(() => {
                    carregando.value = {
                        ...carregando.value,
                        open: false,
                    };
                });
        }
    }, [id]);

    return (
        teleconsultoriaRegulacao.value ?
            <SttGrid container spacing={1}>
                <SttHidden mdUp>
                    <SttGrid item xs={12}>
                        <Detalhes teleconsultoriaInicial={teleconsultoriaRegulacao.value} esconderAcoes={true} adicionarAba={adicionarAba} />
                    </SttGrid>
                    <SttGrid item xs={12}>
                        <FormRegulacao teleconsultoria={teleconsultoriaRegulacao} removerAba={removerAba} />
                    </SttGrid>
                </SttHidden>
                <SttHidden only={['xs', 'sm']}>
                    <SttGrid item xs={6}>
                        <StickyBox>
                            <Detalhes teleconsultoriaInicial={teleconsultoriaRegulacao.value} esconderAcoes={true} adicionarAba={adicionarAba} />
                        </StickyBox>
                    </SttGrid>
                    <SttGrid item xs={6}>
                        <FormRegulacao teleconsultoria={teleconsultoriaRegulacao} removerAba={removerAba} />
                    </SttGrid>
                </SttHidden>
            </SttGrid>
            :
            <></>
    );
};

export default ConteinerRegulacao;