export const CABECALHO = 'cabecalho';
export const RESPOSTA_DIRETA = 'respostaDireta';
export const ATRIBUTOS_APS = 'atributoAps';
export const ORIENTACAO_ESPECIFICA = 'orientacaoEspecifica';
export const EXIBIR_ORIENTACAO_ESPECIFICA = 'exibirOrientacaoEspecifica';
export const MANEJO_APS = 'manejoAps';
export const EXIBIR_MANEJO_PREVIO = 'exibirManejoPrevio';
export const MANEJO_PREVIO_APS = 'manejoPrevioAps';
export const CLASSIFICACAO_RISCO = 'classificacaoRisco';
export const EXIBIR_EXAMES_COMPLEMENTARES = 'exibirExamesComplementares';
export const EXAMES_COMPLEMENTARES = 'examesComplementares';
export const MAIS_INFORMACOES = 'maisInformacoes';
export const SUGIRO = 'sugiro';
export const PSOF = 'psof';
export const TIPO_RESPOSTA = 'tipoResposta';
export const MANIFESTACAO = 'manifestacao';