import { SITUACAO_TELECONSULTORIA, CLASSIFICACOES, ORIENTACOES_ESPECIFICAS } from './Constants';
import { ANEXOS } from "../componentes/anexos/form/fieldNames";
import { CID_10 } from '@stt-componentes/cid10/dist/lib/fieldNames';
import { CABECALHO, MANIFESTACAO } from "../componentes/resposta/cabecalho/form/fieldNames";
import { REFERENCIAS } from "../componentes/resposta/referencias/form/fieldNames";
import { OPCOES_TIPO_TECNOLOGIA } from './ConstantsRespostaJudicial';
import { COMPLEMENTO_INDICACAO, COMPLEMENTO_ONCOLOGICO, COMPLEMENTO_TEMPO_TRATAMENTO, DISPONIVEL_SUS, EXISTE_GENERICO, EXISTE_SIMILAR, INDICACAO_CONFORMIDADE_REGISTRO, JUDICIAL, ONCOLOGICO, ORIGEM_PRESCRICAO, POSOLOGIA, PREVISTO_PROTOCOLO_MS, REGISTRO_ANVISA, REGISTRO_ATIVO, TECNOLOGIAS_SOLICITADAS, TEMPO_TRATAMENTO, USO_CONTINUO, VIA_ADMINISTRACAO } from '../componentes/resposta/tecnologias-solicitadas/form/fieldNames';
import { OUTRAS_TECNOLOGIAS } from '../componentes/resposta/outras-tecnologias/form/fieldNames';
import { APRESENTACAO, COMPLEMENTO_DURACAO_TRATAMENTO, CUSTOS_TECNOLOGIAS, DURACAO_TRATAMENTO, LABORATORIO, MARCA_COMERCIAL, OBSERVACAO, PRECO_FABRICA, PRECO_MAXIMO_CONSUMIDOR, PRECO_MAXIMO_GOVERNO, PRECO_TRATAMENTO, PRECO_TRATAMENTO_ANUAL } from '../componentes/resposta/custos-tecnologias/form/fieldNames';
import { DIAGNOSTICO, MEIOS_CONFIRMATORIOS } from '../componentes/resposta/diagnostico-principal/form/fieldNames';
import { RESUMO } from '../componentes/resposta/resumo-caso-concreto/form/fieldNames';
import { APOIO_TUTORIA, CONCLUSAO, EVIDENCIAS_CIENTIFICAS } from '../componentes/resposta/conclusao/form/fieldNames';
import { COMPLEMENTACAO_RECOMENDACAO, EVIDENCIAS, RECOMENDACAO_CONITEC, RESULTADO_ESPERADO } from '../componentes/resposta/evidencia/form/fieldNames';
import { COMPLEMENTO_RESPOSTA } from '../componentes/resposta/secundario/form/fieldNames';
import { SIGTAP } from '@stt-componentes/sigtap/dist/lib/fieldNames';

const inicializarValoresResposta = (dados) => {
    const { segundaOpiniao } = dados;

    const dadosResposta = {
        id: dados.id,
        idSegundaOpiniao: dados.id_segunda_opiniao,
        idResposta: _getValueOrNull(segundaOpiniao.resposta?.id),
        idInstituicaoSolicitante: dados.id_instituicao_solicitante,
        classificacao: dados.identificador_cadastro_classificacao_assunto,
        // situacao: segundaOpiniao.resposta?.situacao ?? SITUACAO_RESPOSTA.RASCUNHO
    };

    dadosResposta.cabecalho = {
        tipoResposta: _getValueOrEmptyString(segundaOpiniao.resposta?.judicializacao?.tipo_resposta),
        situacao: dados.situacao || SITUACAO_TELECONSULTORIA.SITUACAO.ENCAMINHADA,
        psof: dados.potencial_segunda_opiniao_formativa ?? false,
        exibirOrientacaoEspecifica: false,
        respostaDireta: _getValueOrEmptyString(segundaOpiniao.resposta?.resposta),
        atributoAps: _getValueOrEmptyString(segundaOpiniao.resposta?.atributos_aps),
        idPaciente: dados.id_paciente
    };

    if (dados.identificador_cadastro_classificacao_assunto !== CLASSIFICACOES.JUDICIALIZACAO && dados.id_paciente) {
        dadosResposta.cabecalho.exibirOrientacaoEspecifica = true;
        dadosResposta.cabecalho.orientacaoEspecifica = _getValueOrEmptyString(segundaOpiniao.resposta?.orientacao_especifica).trim();
        dadosResposta.cabecalho.exibirManejoPrevio = segundaOpiniao.resposta?.conduta_terapeutica ?? false;
        dadosResposta.cabecalho.manejoPrevioAps = _getValueOrEmptyString(segundaOpiniao.resposta?.complemento_conduta_terapeutica);
        dadosResposta.cabecalho.classificacaoRisco = _getValueOrNull(segundaOpiniao.resposta?.sigla_segunda_opiniao_resposta_cadastro_classificacao_risco);
        dadosResposta.cabecalho.exibirExamesComplementares = segundaOpiniao.resposta?.solicitacao_exames ?? false;
        dadosResposta.cabecalho.examesComplementares = _getValueOrEmptyString(segundaOpiniao.resposta?.complemento_solicitacao_exames);
        dadosResposta.cabecalho.manejoAps = '';
        dadosResposta.cabecalho.maisInformacoes = '';
        dadosResposta.cabecalho.sugiro = '';
        if (segundaOpiniao.resposta?.orientacao_especifica === ORIENTACOES_ESPECIFICAS.TIPO.MANEJO) {
            dadosResposta.cabecalho.manejoAps = _getValueOrEmptyString(segundaOpiniao.resposta?.complemento_orientacao_especifica);
        } else if (segundaOpiniao.resposta?.orientacao_especifica === ORIENTACOES_ESPECIFICAS.TIPO.INFORMACOES) {
            dadosResposta.cabecalho.maisInformacoes = _getValueOrEmptyString(segundaOpiniao.resposta?.complemento_orientacao_especifica);
        } else if (segundaOpiniao.resposta?.orientacao_especifica === ORIENTACOES_ESPECIFICAS.TIPO.SUGESTAO) {
            dadosResposta.cabecalho.sugiro = _getValueOrEmptyString(segundaOpiniao.resposta?.complemento_orientacao_especifica);
        }
    } else if (dados.identificador_cadastro_classificacao_assunto === CLASSIFICACOES.JUDICIALIZACAO) {
        dadosResposta[CABECALHO][MANIFESTACAO] = _getValueOrEmptyString(segundaOpiniao.resposta?.judicializacao?.manifestacao_tecnica);
        dadosResposta[JUDICIAL] = {
            [OUTRAS_TECNOLOGIAS]: _getValueOrEmptyString(segundaOpiniao.resposta?.judicializacao?.outras_tecnologia_disponiveis),
            [TECNOLOGIAS_SOLICITADAS]: [],
            [CUSTOS_TECNOLOGIAS]: [],
            [DIAGNOSTICO]: _getValueOrEmptyString(segundaOpiniao.resposta?.judicializacao?.diagnostico) || _getValueOrEmptyString(dados.judicializacao?.diagnostico),
            [MEIOS_CONFIRMATORIOS]: _getValueOrEmptyString(segundaOpiniao.resposta?.judicializacao?.meio_confirmatorio_diagnostico),
            [RESUMO]: _getValueOrEmptyString(segundaOpiniao.resposta?.judicializacao?.breve_resumo_caso_concreto),
            [EVIDENCIAS]: _getValueOrEmptyString(segundaOpiniao.resposta?.judicializacao?.evidencias),
            [RESULTADO_ESPERADO]: _getValueOrEmptyString(segundaOpiniao.resposta?.judicializacao?.resultado_esperado),
            [RECOMENDACAO_CONITEC]: _getValueOrEmptyString(segundaOpiniao.resposta?.judicializacao?.recomendacao_conitec).trim(),
            [COMPLEMENTACAO_RECOMENDACAO]: _getValueOrEmptyString(segundaOpiniao.resposta?.judicializacao?.complementacao_recomendacao),
            [CONCLUSAO]: _getValueOrEmptyString(segundaOpiniao.resposta?.judicializacao?.conclusao),
            [EVIDENCIAS_CIENTIFICAS]: _getValueOrNull(segundaOpiniao.resposta?.judicializacao?.evidencias_cienticas),
            [APOIO_TUTORIA]: _getValueOrNull(segundaOpiniao.resposta?.judicializacao?.nota_tecnica_tutoriada),
        }

        const { custosTecnologias, tecnologiasSolicitadas } = tratarDadosTecnologiasParaAlteracao(dados);
        dadosResposta[JUDICIAL][TECNOLOGIAS_SOLICITADAS] = tecnologiasSolicitadas;
        dadosResposta[JUDICIAL][CUSTOS_TECNOLOGIAS] = custosTecnologias;
    }

    dadosResposta.referencias = segundaOpiniao.referencias?.map(r => r.referencia) || ['', '', ''];
    const diffRef = 3 - dadosResposta.referencias.length;
    for (let i = 0; i < diffRef; i++) {
        dadosResposta.referencias.push('')
    }

    dadosResposta.anexos = segundaOpiniao.anexos || [];
    dadosResposta.anexosExcluidos = [];

    dadosResposta.sigtap = segundaOpiniao.sigtap || dados.sigtap || [];
    dadosResposta.ciap2 = segundaOpiniao.ciap2 || dados.ciap2 || [];

    dadosResposta.cid10 = segundaOpiniao.cid10 || [];
    dadosResposta.cid10.forEach(cid => {
        cid.codigo = cid.codigo_subcategoria ? cid.codigo_subcategoria : cid.codigo_categoria;
        cid.nome = cid.codigo_subcategoria ? cid.descritor_subcategoria : cid.descritor_categoria;
        cid.nome_abreviado = cid.codigo_subcategoria ? cid.nome_abreviado_subcategoria : cid.nome_abreviado_categoria;
        cid.tipo = cid.codigo_subcategoria ? 'subcategoria' : 'categoria';
    });

    return dadosResposta;
};

const inicializarValoresRespostaSecundario = (dados, user) => {
    const { segundaOpiniao } = dados;

    const respAnterior = segundaOpiniao.resposta.respostasComplementares.find(rc => rc.id_funcionario_respondedor === user.idFuncionario)

    const dadosResposta = {
        idSolicitacao: dados.id,
        idSegundaOpiniao: dados.id_segunda_opiniao,
        idResposta: segundaOpiniao.resposta.id,
        [COMPLEMENTO_RESPOSTA]: _getValueOrEmptyString(respAnterior?.complemento)
    };

    return dadosResposta;
}

const verificarErrosOrdemPrecedenciaJudicial = (errosJudicial, subsecao) => {
    if (errosJudicial[DIAGNOSTICO] || errosJudicial[MEIOS_CONFIRMATORIOS]) {
        return subsecao === DIAGNOSTICO;
    }

    if (errosJudicial[RESUMO]) {
        return subsecao === RESUMO;
    }

    if (errosJudicial[TECNOLOGIAS_SOLICITADAS]) {
        return subsecao === TECNOLOGIAS_SOLICITADAS;
    }

    if (errosJudicial[OUTRAS_TECNOLOGIAS]) {
        return subsecao === OUTRAS_TECNOLOGIAS;
    }

    if (errosJudicial[CUSTOS_TECNOLOGIAS]) {
        return subsecao === CUSTOS_TECNOLOGIAS;
    }

    if (errosJudicial[EVIDENCIAS]) {
        return subsecao === EVIDENCIAS;
    }

    if (errosJudicial[CONCLUSAO] || errosJudicial[EVIDENCIAS_CIENTIFICAS] || errosJudicial[APOIO_TUTORIA]) {
        return subsecao === CONCLUSAO;
    }

    return true;
}

const verificarErrosOrdemPrecedencia = (erros, secao) => {
    const ordemPrecedencia = [
        CABECALHO,
        JUDICIAL,
        REFERENCIAS,
        SIGTAP,
        CID_10,
        ANEXOS,
    ];

    for (const ordem of ordemPrecedencia) {
        if (secao === ordem) {
            return false;
        }

        if (erros[ordem]) {
            return true;
        }
    }
}

const formatarDadosParaSalvar = (data) => {
    const formData = new FormData();
    formData.append('idSolicitacao', data.id);
    formData.append('idSegundaOpiniao', data.idSegundaOpiniao);
    formData.append('idInstituicaoSolicitante', data.idInstituicaoSolicitante);
    formData.append('classificacao', data.classificacao);
    if (data.idResposta) {
        formData.append('idResposta', data.idResposta);
    }

    // ========== Dados gerais ==========
    formData.append('cabecalho', JSON.stringify(data.cabecalho));
    // ========== Dados gerais ==========

    // ========== Dados judicialização ==========
    if (data.judicial) {
        formData.append('judicial', JSON.stringify(data.judicial));
    }
    // ========== Dados judicialização ==========

    // ========== Referencias ==========
    if (data.referencias[0]) {
        formData.append('referencias', JSON.stringify(data.referencias));
    }
    // ========== Referencias ==========

    // ========== SIGTAP ==========
    if (data.sigtap.length > 0) {
        formData.append('sigtap', JSON.stringify(data.sigtap));
    }

    // ========== CID-10 ==========
    if (data.cid10.length > 0) {
        formData.append('cid10', JSON.stringify(data.cid10));
    }
    // ========== CID-10 ==========

    // ========== CIAP-2 ==========
    if (data.ciap2.length > 0) {
        formData.append('ciap2', JSON.stringify(data.ciap2));
    }
    // ========== CIAP-2 ==========

    _adicionarAnexos(formData, data.anexos);
    _adicionarAnexosExcluidos(formData, data.anexosExcluidos);

    return formData;
};

const tratarDadosTecnologiasParaAlteracao = (dados) => {
    const tecnologiasSolicitadas = [];
    const custosTecnologias = [];

    dados.judicializacao.medicamentos.map(m => {
        const tecResposta = dados.segundaOpiniao.resposta?.judicializacao?.tecnologias.find(tec => tec.codigo === m.codigo && tec.tipo === OPCOES_TIPO_TECNOLOGIA.TIPO.MEDICAMENTO);
        tecnologiasSolicitadas.push({
            codigo: m.codigo,
            descricao: m.descricao,
            tipo: OPCOES_TIPO_TECNOLOGIA.TIPO.MEDICAMENTO,
            descricaoTipo: OPCOES_TIPO_TECNOLOGIA.LABEL[OPCOES_TIPO_TECNOLOGIA.TIPO.MEDICAMENTO],
            [VIA_ADMINISTRACAO]: _getValueOrEmptyString(tecResposta?.via_administracao),
            [POSOLOGIA]: _getValueOrEmptyString(tecResposta?.posologia),
            [USO_CONTINUO]: _getValueOrNull(tecResposta?.uso_continuo),
            [TEMPO_TRATAMENTO]: tecResposta?.identificador_cadastro_tempo_tratamento ? {
                identificador: tecResposta.identificador_cadastro_tempo_tratamento,
                descricao: tecResposta.descricao_tempo_tratamento,
                exige_complemento_numerico: tecResposta.exige_complemento_numerico_tempo_tratamento,
            } : null,
            [COMPLEMENTO_TEMPO_TRATAMENTO]: _getValueOrEmptyString(tecResposta?.tempo_tratamento),
            [ORIGEM_PRESCRICAO]: _getValueOrEmptyString(tecResposta?.origem_prescricao_medica),
            [REGISTRO_ANVISA]: _getValueOrNull(tecResposta?.registro_anvisa),
            [REGISTRO_ATIVO]: _getValueOrNull(tecResposta?.registro_ativo),
            [INDICACAO_CONFORMIDADE_REGISTRO]: _getValueOrNull(tecResposta?.indicacao_conformidade_registro),
            [COMPLEMENTO_INDICACAO]: _getValueOrEmptyString(tecResposta?.complemento_indicacao_conformidade_registro),
            [ONCOLOGICO]: _getValueOrNull(tecResposta?.oncologico),
            [COMPLEMENTO_ONCOLOGICO]: _getValueOrEmptyString(tecResposta?.complemento_oncologico),
            [PREVISTO_PROTOCOLO_MS]: _getValueOrNull(tecResposta?.previsto_protocolo_ms),
            [DISPONIVEL_SUS]: _getValueOrNull(tecResposta?.disponivel_sus),
            [EXISTE_GENERICO]: _getValueOrNull(tecResposta?.existe_generico),
            [EXISTE_SIMILAR]: _getValueOrNull(tecResposta?.existe_similar),
        });

        custosTecnologias.push({
            codigo: m.codigo,
            descricao: m.descricao,
            tipo: OPCOES_TIPO_TECNOLOGIA.TIPO.MEDICAMENTO,
            descricaoTipo: OPCOES_TIPO_TECNOLOGIA.LABEL[OPCOES_TIPO_TECNOLOGIA.TIPO.MEDICAMENTO],
            [LABORATORIO]: _getValueOrEmptyString(tecResposta?.laboratorio),
            [MARCA_COMERCIAL]: _getValueOrEmptyString(tecResposta?.marca_comercial),
            [APRESENTACAO]: _getValueOrEmptyString(tecResposta?.apresentacao),
            [PRECO_FABRICA]: _getValueOrEmptyString(tecResposta?.preco_fabrica),
            [PRECO_MAXIMO_GOVERNO]: _getValueOrEmptyString(tecResposta?.preco_maximo_governo),
            [PRECO_MAXIMO_CONSUMIDOR]: _getValueOrEmptyString(tecResposta?.preco_maximo_consumidor),
            [PRECO_TRATAMENTO]: _getValueOrEmptyString(tecResposta?.preco_tratamento),
            [PRECO_TRATAMENTO_ANUAL]: _getValueOrEmptyString(tecResposta?.preco_tratamento_anual),
            [DURACAO_TRATAMENTO]: tecResposta?.identificador_cadastro_tempo_tratamento_medicamento ? {
                identificador: tecResposta.identificador_cadastro_tempo_tratamento_medicamento,
                descricao: tecResposta.descricao_duracao_tratamento,
                exige_complemento_numerico: tecResposta.exige_complemento_numerico_duracao_tratamento,
            } : null,
            [COMPLEMENTO_DURACAO_TRATAMENTO]: _getValueOrEmptyString(tecResposta?.tempo_tratamento_medicamento),
            [OBSERVACAO]: _getValueOrEmptyString(tecResposta?.observacao),
        });
    });

    dados.judicializacao.insumos.map(i => {
        const tecResposta = dados.segundaOpiniao.resposta?.judicializacao?.tecnologias.find(tec => tec.codigo === i.codigo && tec.tipo === OPCOES_TIPO_TECNOLOGIA.TIPO.INSUMO);
        tecnologiasSolicitadas.push({
            codigo: i.codigo,
            descricao: i.descricao,
            tipo: OPCOES_TIPO_TECNOLOGIA.TIPO.INSUMO,
            descricaoTipo: OPCOES_TIPO_TECNOLOGIA.LABEL[OPCOES_TIPO_TECNOLOGIA.TIPO.INSUMO],
            [REGISTRO_ANVISA]: _getValueOrNull(tecResposta?.registro_anvisa),
            [REGISTRO_ATIVO]: _getValueOrNull(tecResposta?.registro_ativo),
            [DISPONIVEL_SUS]: _getValueOrNull(tecResposta?.disponivel_sus),
        });

        custosTecnologias.push({
            codigo: i.codigo,
            descricao: i.descricao,
            tipo: OPCOES_TIPO_TECNOLOGIA.TIPO.INSUMO,
            descricaoTipo: OPCOES_TIPO_TECNOLOGIA.LABEL[OPCOES_TIPO_TECNOLOGIA.TIPO.INSUMO],
            [LABORATORIO]: _getValueOrEmptyString(tecResposta?.laboratorio),
            [MARCA_COMERCIAL]: _getValueOrEmptyString(tecResposta?.marca_comercial),
            [APRESENTACAO]: _getValueOrEmptyString(tecResposta?.apresentacao),
            [PRECO_TRATAMENTO]: _getValueOrEmptyString(tecResposta?.preco_tratamento),
            [DURACAO_TRATAMENTO]: tecResposta?.identificador_cadastro_tempo_tratamento_insumo ? {
                identificador: tecResposta.identificador_cadastro_tempo_tratamento_insumo,
                descricao: tecResposta.descricao_duracao_tratamento,
                exige_complemento_numerico: tecResposta.exige_complemento_numerico_duracao_tratamento,
            } : null,
            [COMPLEMENTO_DURACAO_TRATAMENTO]: _getValueOrEmptyString(tecResposta?.tempo_tratamento),
            [OBSERVACAO]: _getValueOrEmptyString(tecResposta?.observacao),
        });
    });

    dados.judicializacao.nutricao.map(n => {
        const tecResposta = dados.segundaOpiniao.resposta?.judicializacao?.tecnologias.find(tec => tec.codigo === n.codigo && tec.tipo === OPCOES_TIPO_TECNOLOGIA.TIPO.NUTRICAO);
        tecnologiasSolicitadas.push({
            codigo: n.codigo,
            descricao: n.descricao_resumida,
            tipo: OPCOES_TIPO_TECNOLOGIA.TIPO.NUTRICAO,
            descricaoTipo: OPCOES_TIPO_TECNOLOGIA.LABEL[OPCOES_TIPO_TECNOLOGIA.TIPO.NUTRICAO],
            [REGISTRO_ANVISA]: _getValueOrNull(tecResposta?.registro_anvisa),
            [REGISTRO_ATIVO]: _getValueOrNull(tecResposta?.registro_ativo),
            [DISPONIVEL_SUS]: _getValueOrNull(tecResposta?.disponivel_sus),
        });

        custosTecnologias.push({
            codigo: n.codigo,
            descricao: n.descricao_resumida,
            tipo: OPCOES_TIPO_TECNOLOGIA.TIPO.NUTRICAO,
            descricaoTipo: OPCOES_TIPO_TECNOLOGIA.LABEL[OPCOES_TIPO_TECNOLOGIA.TIPO.NUTRICAO],
            [LABORATORIO]: _getValueOrEmptyString(tecResposta?.laboratorio),
            [MARCA_COMERCIAL]: _getValueOrEmptyString(tecResposta?.marca_comercial),
            [APRESENTACAO]: _getValueOrEmptyString(tecResposta?.apresentacao),
            [PRECO_TRATAMENTO]: _getValueOrEmptyString(tecResposta?.preco_tratamento),
            [DURACAO_TRATAMENTO]: tecResposta?.identificador_cadastro_tempo_tratamento_nutricao ? {
                identificador: tecResposta.identificador_cadastro_tempo_tratamento_nutricao,
                descricao: tecResposta.descricao_duracao_tratamento,
                exige_complemento_numerico: tecResposta.exige_complemento_numerico_duracao_tratamento,
            } : null,
            [COMPLEMENTO_DURACAO_TRATAMENTO]: _getValueOrEmptyString(tecResposta?.tempo_tratamento),
            [OBSERVACAO]: _getValueOrEmptyString(tecResposta?.observacao),
        });
    });

    dados.judicializacao.procedimentosCbhpm.map(n => {
        const tecResposta = dados.segundaOpiniao.resposta?.judicializacao?.tecnologias.find(tec => tec.codigo === n.codigo && tec.tipo === OPCOES_TIPO_TECNOLOGIA.TIPO.CBHPM);
        tecnologiasSolicitadas.push({
            codigo: n.codigo,
            descricao: n.descricao,
            anoCompetencia: n.ano_competencia,
            tipo: OPCOES_TIPO_TECNOLOGIA.TIPO.CBHPM,
            descricaoTipo: OPCOES_TIPO_TECNOLOGIA.LABEL[OPCOES_TIPO_TECNOLOGIA.TIPO.CBHPM],
            [REGISTRO_ANVISA]: _getValueOrNull(tecResposta?.registro_anvisa),
            [REGISTRO_ATIVO]: _getValueOrNull(tecResposta?.registro_ativo),
            [DISPONIVEL_SUS]: _getValueOrNull(tecResposta?.disponivel_sus),
        });

        custosTecnologias.push({
            codigo: n.codigo,
            descricao: n.descricao,
            anoCompetencia: n.ano_competencia,
            tipo: OPCOES_TIPO_TECNOLOGIA.TIPO.CBHPM,
            descricaoTipo: OPCOES_TIPO_TECNOLOGIA.LABEL[OPCOES_TIPO_TECNOLOGIA.TIPO.CBHPM],
            [LABORATORIO]: _getValueOrEmptyString(tecResposta?.laboratorio),
            [MARCA_COMERCIAL]: _getValueOrEmptyString(tecResposta?.marca_comercial),
            [APRESENTACAO]: _getValueOrEmptyString(tecResposta?.apresentacao),
            [PRECO_TRATAMENTO]: _getValueOrEmptyString(tecResposta?.preco_tratamento),
            [DURACAO_TRATAMENTO]: tecResposta?.identificador_cadastro_tempo_tratamento_procedimento ? {
                identificador: tecResposta.identificador_cadastro_tempo_tratamento_procedimento,
                descricao: tecResposta.descricao_duracao_tratamento,
                exige_complemento_numerico: tecResposta.exige_complemento_numerico_duracao_tratamento,
            } : null,
            [COMPLEMENTO_DURACAO_TRATAMENTO]: _getValueOrEmptyString(tecResposta?.tempo_tratamento),
            [OBSERVACAO]: _getValueOrEmptyString(tecResposta?.observacao),
        });
    });

    dados.judicializacao.procedimentosSigtap.map(n => {
        const tecResposta = dados.segundaOpiniao.resposta?.judicializacao?.tecnologias.find(tec => tec.codigo === n.codigo && tec.tipo === OPCOES_TIPO_TECNOLOGIA.TIPO.SIGTAP);
        tecnologiasSolicitadas.push({
            codigo: n.codigo,
            descricao: n.nome,
            anoMesCompetencia: n.ano_mes_competencia,
            tipo: OPCOES_TIPO_TECNOLOGIA.TIPO.SIGTAP,
            descricaoTipo: OPCOES_TIPO_TECNOLOGIA.LABEL[OPCOES_TIPO_TECNOLOGIA.TIPO.SIGTAP],
            [REGISTRO_ANVISA]: _getValueOrNull(tecResposta?.registro_anvisa),
            [REGISTRO_ATIVO]: _getValueOrNull(tecResposta?.registro_ativo),
            [DISPONIVEL_SUS]: _getValueOrNull(tecResposta?.disponivel_sus),
        });

        custosTecnologias.push({
            codigo: n.codigo,
            descricao: n.nome,
            anoMesCompetencia: n.ano_mes_competencia,
            tipo: OPCOES_TIPO_TECNOLOGIA.TIPO.SIGTAP,
            descricaoTipo: OPCOES_TIPO_TECNOLOGIA.LABEL[OPCOES_TIPO_TECNOLOGIA.TIPO.SIGTAP],
            [LABORATORIO]: _getValueOrEmptyString(tecResposta?.laboratorio),
            [MARCA_COMERCIAL]: _getValueOrEmptyString(tecResposta?.marca_comercial),
            [APRESENTACAO]: _getValueOrEmptyString(tecResposta?.apresentacao),
            [PRECO_TRATAMENTO]: _getValueOrEmptyString(tecResposta?.preco_tratamento),
            [DURACAO_TRATAMENTO]: tecResposta?.identificador_cadastro_tempo_tratamento_procedimento ? {
                identificador: tecResposta.identificador_cadastro_tempo_tratamento_procedimento,
                descricao: tecResposta.descricao_duracao_tratamento,
                exige_complemento_numerico: tecResposta.exige_complemento_numerico_duracao_tratamento,
            } : null,
            [COMPLEMENTO_DURACAO_TRATAMENTO]: _getValueOrEmptyString(tecResposta?.tempo_tratamento),
            [OBSERVACAO]: _getValueOrEmptyString(tecResposta?.observacao),
        });
    });

    return { custosTecnologias, tecnologiasSolicitadas };
}

const _getValueOrNull = (value) => {
    return value ?? null;
}

const _getValueOrEmptyString = (value) => {
    return value ?? '';
}

/**
 * Função que adicionar anexos do tipo "File" ao formulário que será enviado ao backend.
 * 
 * @param {FormData} formData formulário a ser enviado ao backend
 * @param {*} arrayAnexo array de anexos a ser adicionado ao formulário
 */
const _adicionarAnexos = (formData, arrayAnexo) => {
    if (arrayAnexo.length > 0) {
        let quantAnexos = 0;
        arrayAnexo.forEach((anexo, index) => {
            // Apenas anexos adicionados E que não foram enviados ao backend devem ser adicionados no array.
            if (anexo && (anexo instanceof File) && !anexo.id) {
                formData.append(`anexo.${quantAnexos}`, anexo);
                formData.append(`anexoIndex.${quantAnexos}`, index);
                quantAnexos++;
            }
        });
        if (quantAnexos > 0) {
            formData.append(`numeroAnexo`, quantAnexos);
        }
    }
}

/**
 * Função que adicionar os anexos excluídos pelo usuário ao formulário que será enviado ao backend.
 * 
 * @param {FormData} formData formulário a ser enviado ao backend
 * @param {*} arrayAnexo array de anexos excluídos a ser adicionado ao formulário
 */
const _adicionarAnexosExcluidos = (formData, arrayAnexoExcluido) => {
    if (arrayAnexoExcluido?.length > 0) {
        formData.append(`anexoExcluido`, JSON.stringify(arrayAnexoExcluido));
    }
}

export default {
    inicializarValoresResposta,
    inicializarValoresRespostaSecundario,
    verificarErrosOrdemPrecedencia,
    verificarErrosOrdemPrecedenciaJudicial,
    formatarDadosParaSalvar,
    tratarDadosTecnologiasParaAlteracao
};
