import React, { useRef, memo, Suspense, lazy, useContext } from "react";
import {
    SttExpansionPanel,
    SttDivider,
    SttContainer,
    SttLoading,
    SttHeading,
    SttCircularProgress,
    SttButton,
    SttTranslateHook
} from '@stt-componentes/core';
import HttpStatus from 'http-status-codes';
import { Formik } from 'formik';
import { makeStyles } from "@material-ui/core/styles";
import axios from 'axios';
import { getHeaders } from '../../request';
import validationSchema from './validationSchema';
import Invalidacao from './invalidacao';
import Devolucao from '../devolucao';
import AlteracaoSolicitacao from './alteracaoSolicitacao';
import AlteracaoResposta from './alteracaoResposta';
import FunctionsRegulacao from '../../common/FunctionsRegulacao';
import { CLASSIFICACOES, SITUACAO_TELECONSULTORIA } from "../../common/Constants";
import { useSignalEffect } from "@preact/signals-react";
import alerta from "../../signals/alerta";
import { useSignal, useSignals } from "@preact/signals-react/runtime";

const DadosGerais = lazy(() => import('./geral'));
const DadosEncaminhamento = lazy(() => import('./encaminhamento'));
const SttCid10 = lazy(() => import('@stt-componentes/cid10'));
const SttCiap2 = lazy(() => import('@stt-componentes/ciap2'));

const useStyles = makeStyles(theme => ({
    buttonWrapper: {
        marginTop: theme.spacing(1)
    },
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    button: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    expansionPanel: {
        width: '100%'
    }
}));

const Divider = memo((props) => {
    return (
        <SttDivider {...props} />
    )
});

const FormRegulacao = ({ teleconsultoria, removerAba }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    const schema = validationSchema(strings);
    const formRef = useRef();

    useSignals();

    const initialValues = useSignal(null);
    const invalidar = useSignal(false);
    const devolver = useSignal(false);
    const alterarSolicitacao = useSignal(false);
    const alterarResposta = useSignal(false);

    const TELECONSULTORIAS_API_BASE_URL = global.gConfig.url_base_teleconsultorias;

    useSignalEffect(() => {
        if (teleconsultoria.value) {
            initialValues.value = FunctionsRegulacao.inicializarValoresRegulacao(teleconsultoria.value);
        }
    });

    const aprovarTeleconsultoria = () => {
        alerta.value = {
            ...alerta.value,
            open: true,
            title: strings.atencao,
            type: 'alert',
            message: strings.confirmarAprovacao,
            options: [{
                title: strings.sim,
                onClick: () => {
                    enviarAprovacao();
                    alerta.value = {
                        ...alerta.value,
                        open: false
                    };
                }
            },
            {
                title: strings.nao,
                onClick: () => {
                    alerta.value = {
                        ...alerta.value,
                        open: false
                    };
                }
            }],
            onClose: () => {
                alerta.value = {
                    ...alerta.value,
                    open: false
                };
            }
        };
    }

    const enviarAprovacao = () => {
        const dados = formRef.current.values;
        dados.cid10 = dados.cid10.map(cid => {
            if (cid.tipo === 'categoria') {
                cid.codigo_categoria = cid.codigo;
            } else {
                cid.codigo_subcategoria = cid.codigo
            }
            return cid;
        });
        const formData = new FormData();
        formData.append('dados', JSON.stringify(dados));

        formRef.current.setSubmitting(true);

        let tipoAlerta = '';
        let tituloAlerta = '';
        let mensagemAlerta = '';
        let options = [];
        let onClose = () => { };

        axios.post(`${TELECONSULTORIAS_API_BASE_URL}/regulacao/aprovacao/${teleconsultoria.value.id}`, formData, { headers: getHeaders() })
            .then((response) => {
                const { data } = response;
                tipoAlerta = 'success';
                tituloAlerta = strings.sucesso;
                mensagemAlerta = data?.message ? data.message : strings.teleconsultoriaAprovada;
                options = [{
                    title: strings.ok,
                    onClick: () => {
                        removerAba();
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        }
                    }
                }];
                onClose = () => {
                    removerAba();
                    alerta.value = {
                        ...alerta.value,
                        open: false
                    };
                };
            })
            .catch(err => {
                const { response } = err;
                tipoAlerta = 'error';
                tituloAlerta = strings.erro;
                mensagemAlerta = strings.erroGenerico;

                if (response) {
                    const { data } = response;

                    mensagemAlerta = data.message;
                    if (response.status === HttpStatus.BAD_REQUEST || response.status === HttpStatus.INTERNAL_SERVER_ERROR) {
                        let arrMensagem = [];
                        data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        if (arrMensagem.length > 0) {
                            mensagemAlerta = arrMensagem.join('\n');
                        }
                    }
                }

                options = [{
                    title: strings.ok,
                    onClick: () => {
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        }
                    }
                }];
                onClose = () => {
                    alerta.value = {
                        ...alerta.value,
                        open: false
                    };
                }
            })
            .finally(() => {
                formRef.current.setSubmitting(false);
                alerta.value = {
                    ...alerta.value,
                    type: tipoAlerta,
                    title: tituloAlerta,
                    message: mensagemAlerta,
                    open: true,
                    options: options,
                    onClose: onClose
                }
            });
    }

    const encaminharTeleconsultoresSecundarios = () => {
        alerta.value = {
            ...alerta.value,
            open: true,
            title: strings.atencao,
            type: 'alert',
            message: strings.confirmarEncaminhamentoSecundario,
            options: [{
                title: strings.sim,
                onClick: () => {
                    enviarEncaminhamentoTeleconsultoresSecundarios();
                    alerta.value = {
                        ...alerta.value,
                        open: false
                    };
                }
            },
            {
                title: strings.nao,
                onClick: () => {
                    alerta.value = {
                        ...alerta.value,
                        open: false
                    };
                }
            }],
            onClose: () => {
                alerta.value = {
                    ...alerta.value,
                    open: false
                };
            }
        };
    }

    const enviarEncaminhamentoTeleconsultoresSecundarios = () => {
        const dados = formRef.current.values;

        const dadosSalvar = {
            idSolicitacao: dados.idSolicitacao,
            idSegundaOpiniao: dados.idSegundaOpiniao,
            idResposta: dados.idResposta,
            teleconsultoresSecundarios: dados.teleconsultoresSecundarios
        }

        formRef.current.setSubmitting(true);

        let tipoAlerta = '';
        let tituloAlerta = '';
        let mensagemAlerta = '';
        let options = [];
        let onClose = () => { };

        axios.post(`${TELECONSULTORIAS_API_BASE_URL}/regulacao/secundaria`, dadosSalvar, { headers: getHeaders() })
            .then((response) => {
                const { data } = response;
                tipoAlerta = 'success';
                tituloAlerta = strings.sucesso;
                mensagemAlerta = data?.message ? data.message : strings.teleconsultoriaEncaminhadaSecundarios;
                options = [{
                    title: strings.ok,
                    onClick: () => {
                        removerAba();
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        }
                    }
                }];
                onClose = () => {
                    removerAba();
                    alerta.value = {
                        ...alerta.value,
                        open: false
                    };
                };
            })
            .catch(err => {
                const { response } = err;
                tipoAlerta = 'error';
                tituloAlerta = strings.erro;
                mensagemAlerta = strings.erroGenerico;

                if (response) {
                    const { data } = response;

                    mensagemAlerta = data.message;
                    if (response.status === HttpStatus.BAD_REQUEST || response.status === HttpStatus.INTERNAL_SERVER_ERROR) {
                        let arrMensagem = [];
                        data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        if (arrMensagem.length > 0) {
                            mensagemAlerta = arrMensagem.join('\n');
                        }
                    }
                }

                options = [{
                    title: strings.ok,
                    onClick: () => {
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        }
                    }
                }];
                onClose = () => {
                    alerta.value = {
                        ...alerta.value,
                        open: false
                    };
                }
            })
            .finally(() => {
                formRef.current.setSubmitting(false);
                alerta.value = {
                    ...alerta.value,
                    type: tipoAlerta,
                    title: tituloAlerta,
                    message: mensagemAlerta,
                    open: true,
                    options: options,
                    onClose: onClose
                }
            });
    }

    const enviarForm = (dados, setSubmitting) => {
        dados.cid10 = dados.cid10.map(cid => {
            if (cid.tipo === 'categoria') {
                cid.codigo_categoria = cid.codigo;
            } else {
                cid.codigo_subcategoria = cid.codigo
            }
            return cid;
        });
        dados = JSON.stringify(dados);
        const formData = new FormData();
        formData.append('dados', dados);

        setSubmitting(true);

        let tipoAlerta = '';
        let tituloAlerta = '';
        let mensagemAlerta = '';
        let options = [];
        let onClose = () => { };

        axios.post(`${TELECONSULTORIAS_API_BASE_URL}/regulacao/${teleconsultoria.value.id}`, formData, { headers: getHeaders() })
            .then((response) => {
                const { data } = response;
                tipoAlerta = 'success';
                tituloAlerta = strings.sucesso;
                mensagemAlerta = data?.message ? data.message : strings.teleconsultoriaRegulada;
                options = [{
                    title: strings.ok,
                    onClick: () => {
                        removerAba();
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        }
                    }
                }];
                onClose = () => {
                    removerAba();
                    alerta.value = {
                        ...alerta.value,
                        open: false
                    };
                }
            })
            .catch(err => {
                const { response } = err;
                tipoAlerta = 'error';
                tituloAlerta = strings.erro;
                mensagemAlerta = strings.erroGenerico;

                if (response) {
                    const { data } = response;

                    mensagemAlerta = data.message;
                    if (response.status === HttpStatus.BAD_REQUEST || response.status === HttpStatus.INTERNAL_SERVER_ERROR) {
                        let arrMensagem = [];
                        data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        if (arrMensagem.length > 0) {
                            mensagemAlerta = arrMensagem.join('\n');
                        }
                    }
                }

                options = [{
                    title: strings.ok,
                    onClick: () => {
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        }
                    }
                }];
                onClose = () => {
                    alerta.value = {
                        ...alerta.value,
                        open: false
                    };
                }
            })
            .finally(() => {
                setSubmitting(false);
                alerta.value = {
                    ...alerta.value,
                    type: tipoAlerta,
                    title: tituloAlerta,
                    message: mensagemAlerta,
                    open: true,
                    options: options,
                    onClose: onClose
                }
            });
    }

    return (
        <>
            {
                initialValues.value &&
                <Formik
                    innerRef={formRef}
                    initialValues={initialValues.value}
                    validateOnChange={false}
                    validationSchema={schema}
                    onSubmit={(dados, { setSubmitting }) => {
                        setSubmitting(false);
                        alerta.value = {
                            ...alerta.value,
                            open: true,
                            title: strings.atencao,
                            type: 'alert',
                            message: strings.confirmarRegulacaoManual,
                            options: [{
                                title: strings.sim,
                                onClick: () => {
                                    enviarForm(dados, setSubmitting)
                                    alerta.value = {
                                        ...alerta.value,
                                        open: false
                                    };
                                }
                            },
                            {
                                title: strings.nao,
                                onClick: () => {
                                    alerta.value = {
                                        ...alerta.value,
                                        open: false
                                    };
                                }
                            }],
                            onClose: () => {
                                alerta.value = {
                                    ...alerta.value,
                                    open: false
                                };
                            }
                        };
                    }}
                >
                    {
                        ({
                            isSubmitting,
                            values,
                            errors,
                            submitCount,
                            setFieldValue,
                            handleSubmit
                        }) => (
                            <SttContainer>
                                <form onSubmit={handleSubmit} noValidate>
                                    <SttHeading variant="h1" color="primary" align="center">{strings.regulacaoTele}</SttHeading>
                                    {/* Dados gerais */}
                                    {
                                        !values.ehAprovacao &&
                                        <SttExpansionPanel
                                            classegriditem={classes.expansionPanel}
                                            title={strings.dadosGerais}
                                            children={
                                                <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                    <DadosGerais />
                                                </Suspense>
                                            }
                                        />
                                    }

                                    <Divider />
                                    {/* Encaminhamento */}
                                    <SttExpansionPanel
                                        classegriditem={classes.expansionPanel}
                                        title={strings.encaminhamento}
                                        children={
                                            <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                <DadosEncaminhamento
                                                    exibirPsof={values.geral.classificacao !== CLASSIFICACOES.JUDICIALIZACAO}
                                                    exibirSecundario={values.geral.classificacao === CLASSIFICACOES.JUDICIALIZACAO}
                                                />
                                            </Suspense>
                                        }
                                    />
                                    <Divider />

                                    {/* Dados do CID10 */}
                                    {
                                        !values.ehAprovacao &&
                                        <SttExpansionPanel
                                            classegriditem={classes.expansionPanel}
                                            title={strings.cid10}
                                            children={
                                                <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                    <SttCid10 strings={strings}
                                                        headers={getHeaders()}
                                                        formExterno={{
                                                            cid10: values.cid10,
                                                            isSubmitting,
                                                            errors,
                                                            submitCount,
                                                            setFieldValue
                                                        }}
                                                    />
                                                </Suspense>
                                            }
                                        />
                                    }
                                    <Divider />
                                    {
                                        values.geral.assunto && values.geral.classificacao !== CLASSIFICACOES.JUDICIALIZACAO &&
                                        <>
                                            {/* Dados do CIAP-2 */}
                                            <SttExpansionPanel
                                                classegriditem={classes.expansionPanel}
                                                title={strings.ciap2}
                                                children={
                                                    <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                        <SttCiap2 obrigatorio={true}
                                                            strings={strings}
                                                            capCiap={values.geral.assunto.identificador_ciap2_capitulo}
                                                            headers={getHeaders()}
                                                            formExterno={{
                                                                ciap2: values.ciap2,
                                                                isSubmitting,
                                                                errors,
                                                                submitCount,
                                                                setFieldValue
                                                            }}
                                                        />
                                                    </Suspense>
                                                }
                                            />
                                            <Divider />
                                        </>
                                    }

                                    <div className={classes.buttonWrapper}>
                                        {
                                            (values.situacao === SITUACAO_TELECONSULTORIA.SITUACAO.EM_ABERTO || values.situacao === SITUACAO_TELECONSULTORIA.SITUACAO.ENCAMINHADA || values.geral.classificacao === CLASSIFICACOES.JUDICIALIZACAO) &&
                                            <SttButton
                                                type="submit"
                                                variant="contained"
                                                nomarginleft="true"
                                                color="primary"
                                                className={classes.button}
                                                disabled={isSubmitting}
                                            >
                                                {strings.encaminharTeleconsultor}
                                            </SttButton>
                                        }
                                        {
                                            values.geral.classificacao === CLASSIFICACOES.JUDICIALIZACAO &&
                                            values.situacao === SITUACAO_TELECONSULTORIA.SITUACAO.AGUARDANDO_LIBERACAO &&
                                            <SttButton
                                                type="button"
                                                variant="contained"
                                                color="secondary"

                                                className={classes.button}
                                                disabled={isSubmitting}
                                                onClick={aprovarTeleconsultoria}
                                            >
                                                {strings.aprovar}
                                            </SttButton>
                                        }
                                        {
                                            (values.situacao === SITUACAO_TELECONSULTORIA.SITUACAO.AGUARDANDO_LIBERACAO || values.situacao === SITUACAO_TELECONSULTORIA.SITUACAO.AGUARDANDO_RESPOSTA_SECUNDARIA) &&
                                            <SttButton
                                                type="button"
                                                variant="contained"
                                                color="primary"
                                                className={classes.button}
                                                onClick={encaminharTeleconsultoresSecundarios}
                                            >
                                                {strings.encaminharTeleconsultorSecundario}
                                            </SttButton>
                                        }
                                        {
                                            values.geral.classificacao === CLASSIFICACOES.JUDICIALIZACAO &&
                                            <SttButton
                                                variant="outlined"
                                                color="primary"
                                                className={classes.button}
                                                onClick={() => alterarSolicitacao.value = true}
                                            >
                                                {strings.editarSolictacao}
                                            </SttButton>
                                        }
                                        {
                                            values.geral.classificacao === CLASSIFICACOES.JUDICIALIZACAO && values.ehAprovacao &&
                                            <SttButton
                                                type="button"
                                                variant="outlined"
                                                color="primary"
                                                className={classes.button}
                                                onClick={() => alterarResposta.value = true}
                                            >
                                                {strings.editarResposta}
                                            </SttButton>
                                        }
                                        {
                                            !values.ehAprovacao &&
                                            <>
                                                <SttButton
                                                    variant="contained"
                                                    danger={+true}
                                                    className={classes.button}
                                                    onClick={() => invalidar.value = true}
                                                >
                                                    {strings.invalidar}
                                                </SttButton>
                                                <SttButton
                                                    variant="outlined"
                                                    color="secondary"
                                                    className={classes.button}
                                                    onClick={() => devolver.value = true}
                                                >
                                                    {strings.devolverSolicitante}
                                                </SttButton>
                                            </>
                                        }
                                    </div>
                                </form>
                                <SttLoading
                                    open={isSubmitting}
                                    text={strings.salvandoRegulacao}
                                />
                            </SttContainer>
                        )
                    }
                </Formik>
            }
            {
                invalidar.value &&
                <Invalidacao
                    idSolicitacao={teleconsultoria.value.id}
                    invalidar={invalidar}
                    callback={removerAba}
                />
            }
            {
                devolver.value &&
                <Devolucao
                    idSolicitacao={teleconsultoria.value.id}
                    devolucaoSolicitante={true}
                    devolver={devolver}
                    callback={removerAba}
                />
            }
            {
                alterarSolicitacao.value &&
                <AlteracaoSolicitacao
                    initialValues={formRef.current.values}
                    alterarSolicitacao={alterarSolicitacao}
                    callback={(newValues) => {
                        formRef.current.setValues({
                            ...formRef.current.values,
                            judicial: { ...newValues.judicial },
                            judicialTecnologias: { ...newValues.judicialTecnologias },
                            alterouSolicitacaoJudicial: true
                        }, false);
                    }}
                />
            }
            {
                alterarResposta.value &&
                <AlteracaoResposta
                    initialValues={formRef.current.values.resposta}
                    alterarResposta={alterarResposta}
                    callback={(newValues) => {
                        formRef.current.setValues({
                            ...formRef.current.values,
                            resposta: { ...newValues, alterouResposta: true },
                        }, false);
                    }}
                />
            }
        </>
    );
}

export default FormRegulacao;