const tipoResposta = {
    MANIFESTACAO_TECNICA: 'M',
    NOTA_TECNICA: 'N',
};

export const OPCOES_TIPO_RESPOSTA = {
    TIPO: { ...tipoResposta },
    LABEL: {
        [tipoResposta.MANIFESTACAO_TECNICA]: 'Manifestação técnica',
        [tipoResposta.NOTA_TECNICA]: 'Nota técnica',
    }
}

const tipoTecnologia = {
    MEDICAMENTO: 'M',
    INSUMO: 'I',
    CBHPM: 'C',
    SIGTAP: 'S',
    NUTRICAO: 'N'
};

export const OPCOES_TIPO_TECNOLOGIA = {
    TIPO: { ...tipoTecnologia },
    LABEL: {
        [tipoTecnologia.MEDICAMENTO]: 'Medicamento',
        [tipoTecnologia.INSUMO]: 'Insumo',
        [tipoTecnologia.NUTRICAO]: 'Nutrição',
        [tipoTecnologia.CBHPM]: 'Procedimento CBHPM',
        [tipoTecnologia.SIGTAP]: 'Procedimento SIGTAP',
    }
}

const origemPrescricao = {
    PUBLICA: 'U',
    PRIVADA: 'R',
};

export const OPCOES_ORIGEM_PRESCRICAO = {
    TIPO: { ...origemPrescricao },
    LABEL: {
        [origemPrescricao.PUBLICA]: 'Pública',
        [origemPrescricao.PRIVADA]: 'Privada',
    }
}

const recomendacaoConitec = {
    RECOMENDADO: 'R',
    NAO_RECOMENDADO: 'NR',
    NAO_AVALIADO: 'NA',
};

export const OPCOES_RECOMENDACAO_CONITEC = {
    TIPO: { ...recomendacaoConitec },
    LABEL: {
        [recomendacaoConitec.RECOMENDADO]: 'Recomendado',
        [recomendacaoConitec.NAO_RECOMENDADO]: 'Não recomendado',
        [recomendacaoConitec.NAO_AVALIADO]: 'Não avaliado',
    }
}
