import React, { lazy, Suspense, memo, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import HttpStatus from 'http-status-codes';
import axios from 'axios';
import { getHeaders } from '../../../request';
import {
    SttLoading,
    SttGrid,
    SttButton,
    SttModal,
    SttInput,
    SttCircularProgress,
    SttTranslateHook
} from '@stt-componentes/core';
import * as yup from 'yup';
import {
    Formik,
    FastField,
} from 'formik';
import dadosEncaminhamentoSchema from '../../regulacao/encaminhamento/form/validationSchema';
import { useSignal, useSignals } from '@preact/signals-react/runtime';
import alerta from '../../../signals/alerta';

const DadosEncaminhamento = lazy(() => import('../../regulacao/encaminhamento'));

const useStyles = makeStyles(theme => ({
    form: {
        marginTop: theme.spacing(1.5)
    },
}));

const validationSchema = (strings) => {
    const dadosEncaminhamento = dadosEncaminhamentoSchema(strings);
    const schema = yup.object().shape({
        'motivo': yup
            .string()
            .trim()
            .required(strings.campoObrigatorio)
            .nullable(),
    });

    return schema.concat(dadosEncaminhamento)
}

const AlteracaoTeleconsultor = ({ idSegundaOpiniao, idSolicitacao, callback, exibirAlteracaoTeleconsultor }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    useSignals();

    const schema = validationSchema(strings);

    const initialValues = useSignal({
        idSolicitacao: idSolicitacao,
        idSegundaOpiniao: idSegundaOpiniao,
        motivo: '',
        regiao: null,
        cbo: null,
        cboOcupacao: null,
        teleconsultor: null,
        instituicao: null,
        teleconsultoresSecundarios: [],
    });

    const fecharAlteracao = () => {
        exibirAlteracaoTeleconsultor.value = false;
    }

    const enviarForm = (dados, setSubmitting) => {
        setSubmitting(true);

        dados.motivo = dados.motivo.trim();

        let tipoAlerta = '';
        let tituloAlerta = '';
        let mensagemAlerta = '';
        let options = [];
        let onClose = () => { };

        axios.post(`${global.gConfig.url_base_teleconsultorias}/teleconsultor-solicitacao`, dados, { headers: getHeaders() })
            .then((response) => {
                const { data } = response;

                tipoAlerta = 'success';
                tituloAlerta = strings.sucesso;
                mensagemAlerta = data?.message ? data.message : strings.teleconsultorModificado;
                options = [{
                    title: strings.ok,
                    onClick: () => {
                        callback();
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        }
                    }
                }];
                onClose = () => {
                    callback();
                    alerta.value = {
                        ...alerta.value,
                        open: false
                    };
                }
            })
            .catch(err => {
                const { response } = err;
                tipoAlerta = 'error';
                tituloAlerta = strings.erro;
                mensagemAlerta = strings.erroGenerico;

                if (response) {
                    const { data } = response;

                    mensagemAlerta = data.message;
                    if (response.status === HttpStatus.BAD_REQUEST || response.status === HttpStatus.INTERNAL_SERVER_ERROR) {
                        let arrMensagem = [];
                        data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        if (arrMensagem.length > 0) {
                            mensagemAlerta = arrMensagem.join('\n');
                        }
                    }
                }

                options = [{
                    title: strings.ok,
                    onClick: () => {
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        }
                    }
                }];
                onClose = () => {
                    alerta.value = {
                        ...alerta.value,
                        open: false
                    };
                }
            })
            .finally(() => {
                setSubmitting(false);
                alerta.value = {
                    ...alerta.value,
                    type: tipoAlerta,
                    title: tituloAlerta,
                    message: mensagemAlerta,
                    open: true,
                    options: options,
                    onClose: onClose
                }
            });
    }

    return (
        <SttModal
            title={strings.trocaTeleconsultor}
            open={exibirAlteracaoTeleconsultor.value}
            outModalClose={fecharAlteracao}
            iconClose={fecharAlteracao}
            maxWidth="lg"
            fullWidth={true}
            children={
                <Formik
                    initialValues={initialValues.value}
                    validationSchema={schema}
                    onSubmit={(dados, { setSubmitting }) => {
                        setSubmitting(false);
                        alerta.value = {
                            ...alerta.value,
                            open: true,
                            title: strings.atencao,
                            type: 'alert',
                            message: strings.confirmarRegulacaoManual,
                            options: [{
                                title: strings.sim,
                                onClick: () => {
                                    enviarForm(dados, setSubmitting)
                                    alerta.value = {
                                        ...alerta.value,
                                        open: false
                                    };
                                }
                            },
                            {
                                title: strings.nao,
                                onClick: () => {
                                    alerta.value = {
                                        ...alerta.value,
                                        open: false
                                    };
                                }
                            }],
                            onClose: () => {
                                alerta.value = {
                                    ...alerta.value,
                                    open: false
                                };
                            }
                        };
                    }}
                >
                    {
                        ({
                            isSubmitting,
                            handleSubmit,
                            resetForm,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit} noValidate className={classes.form}>
                                    <Suspense fallback={<SttCircularProgress color="primary" />}>
                                        <DadosEncaminhamento exibirPsof={false} />
                                    </Suspense>
                                    <SttGrid container spacing={2}>
                                        <SttGrid item xs={12}>
                                            <FastField name='motivo'>
                                                {({
                                                    field,
                                                    meta
                                                }) => (
                                                    <SttInput
                                                        {...field}
                                                        multiline
                                                        required={true}
                                                        error={meta.touched && meta.error ? true : false}
                                                        helperText={meta.touched && meta.error ? meta.error : undefined}
                                                        minRows={6}
                                                        label={strings.motivoTrocaTeleconsultor}
                                                    />
                                                )}
                                            </FastField>
                                        </SttGrid>
                                        <SttLoading
                                            open={isSubmitting}
                                            text={strings.salvando}
                                        />
                                    </SttGrid>
                                    <SttGrid container spacing={3}>
                                        <SttGrid item xs={12}>
                                            <SttButton
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                nomarginleft="true"
                                                className={classes.button}
                                                disabled={isSubmitting}
                                            >
                                                {strings.confirmar}
                                            </SttButton>
                                            <SttButton
                                                type="button"
                                                variant="outlined"
                                                color="primary"
                                                disabled={isSubmitting}
                                                className={classes.button}
                                                onClick={() => {
                                                    resetForm({ values: initialValues.value });
                                                    fecharAlteracao();
                                                }}
                                            >
                                                {strings.fechar}
                                            </SttButton>
                                        </SttGrid>
                                    </SttGrid>
                                </form>
                            )
                        }
                    }
                </Formik>
            }
        />
    );
};

export default memo(AlteracaoTeleconsultor);