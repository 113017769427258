import * as yup from 'yup';
import { CID_10 } from '@stt-componentes/cid10/dist/lib/fieldNames';

export default (strings) => {
    return yup.object().shape({
        [CID_10]: yup
            .array()
            .min(1, strings.erroSemCid10)
            .of(
                yup.object()
            )
            .required(strings.campoObrigatorio)
    });
}   