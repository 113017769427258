import React, { memo, Suspense, lazy, useEffect, useRef, useContext } from "react";
import {
    SttExpansionPanel,
    SttDivider,
    SttContainer,
    SttLoading,
    SttHeading,
    SttCircularProgress,
    SttButton,
    SttTranslateHook
} from '@stt-componentes/core';
import HttpStatus from 'http-status-codes';
import { Formik } from 'formik';
import { makeStyles } from "@material-ui/core/styles";
import axios from 'axios';
import { getHeaders } from '../../request';
import validationSchemaSecundario from './validationSchemaSecundario';
import FunctionsResposta from "../../common/FunctionsResposta";
import { NotificationManager } from "react-notifications";
import usuario from '../../signals/usuario'
import { useSignal, useSignalEffect, useSignals } from "@preact/signals-react/runtime";
import alerta from "../../signals/alerta";
import { batch } from "@preact/signals-react";

const useStyles = makeStyles(theme => ({
    buttonWrapper: {
        marginTop: theme.spacing(1)
    },
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    button: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    expansionPanel: {
        width: '100%'
    }
}));

const Divider = memo((props) => {
    return (
        <SttDivider {...props} />
    )
});

const DadosSecundario = lazy(() => import('./secundario'));

const FormResposta = ({ teleconsultoria, removerAba }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    useSignals();

    const formRef = useRef();
    const timer = useRef();

    const initialValues = useSignal(FunctionsResposta.inicializarValoresRespostaSecundario(teleconsultoria, usuario.value));
    const salvarAutomatico = useSignal(false);
    const podeSalvarAutomatico = useSignal(false);

    useSignalEffect(() => {
        clearTimeout(timer.current);
        if (podeSalvarAutomatico.value) {
            timer.current = criarTimeout();
        }
    });

    useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    const criarTimeout = () => {
        return setTimeout(() => {
            salvarAutomatico.value = true;
        }, 60 * 1000);
    }

    useSignalEffect(() => {
        if (salvarAutomatico.value) {
            enviarForm(formRef.current.values, true);
        }
    });

    const enviarForm = (dados, rascunho, setSubmitting = null) => {
        //Desativa o salvamento automático enquanto a requisição não retorna
        podeSalvarAutomatico.value = false;

        dados.tipoSubmit = {
            rascunho,
            salvarAutomatico
        }

        // Faz sentido quando o usuário clica no botão "salvar rascunho" ou "enviar resposta", mas não para o salvamento automático
        if (setSubmitting) {
            setSubmitting(true);
        }

        let tipoAlerta = '';
        let tituloAlerta = '';
        let mensagemAlerta = '';
        let options = [];
        let onClose = () => { };

        axios.post(`${global.gConfig.url_base_teleconsultorias}/resposta/secundaria`, dados, { headers: getHeaders() })
            .then((response) => {
                const { data } = response;

                if (setSubmitting) {
                    tipoAlerta = 'success';
                    tituloAlerta = strings.sucesso;
                    let msgPadrao = rascunho ? strings.rascunhoSalvoSucesso : strings.teleconsultoriaComplementoRespondida;
                    mensagemAlerta = data?.message || msgPadrao;
                    options = [{
                        title: strings.ok,
                        onClick: () => {
                            removerAba();
                            alerta.value = {
                                ...alerta.value,
                                open: false
                            }
                        }
                    }];
                    onClose = () => {
                        removerAba();
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        };
                    }
                    return;
                }

                //Se é a partir de salvamento automático, notifica usuário que o salvamento ocorreu
                NotificationManager.success(strings.rascunhoSalvoSucesso);
                batch(() => {
                    podeSalvarAutomatico.value = true;
                    salvarAutomatico.value = false;
                });
            })
            .catch(err => {
                const { response } = err;
                let mensagem = strings.erro;
                if (response) {
                    const { data } = response;
                    mensagem = data.message;
                    if (response.status === HttpStatus.BAD_REQUEST || response.status === HttpStatus.INTERNAL_SERVER_ERROR) {
                        let arrMensagem = [];
                        data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        if (arrMensagem.length > 0) {
                            mensagem = arrMensagem.join('\n');
                        }
                    }
                }

                //O usuário clicou no botão e o sistema notifica o erro por modal
                if (setSubmitting) {
                    tipoAlerta = 'error';
                    tituloAlerta = strings.erro;
                    mensagemAlerta = mensagem;

                    options = [{
                        title: strings.ok,
                        onClick: () => {
                            alerta.value = {
                                ...alerta.value,
                                open: false
                            }
                        }
                    }];
                    onClose = () => {
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        };
                    }
                } else {
                    NotificationManager.error(strings.erroSalvarAutomaticamente);
                }

                podeSalvarAutomatico.value = true;
                // setSalvarAutomatico(false);
            })
            .finally(() => {
                if (setSubmitting) {
                    setSubmitting(false);
                    alerta.value = {
                        ...alerta.value,
                        type: tipoAlerta,
                        title: tituloAlerta,
                        message: mensagemAlerta,
                        open: true,
                        options: options,
                        onClose: onClose
                    }
                }
            });
    }

    const submitForm = rascunho => (dados, { setSubmitting }) => {
        setSubmitting(false);
        salvarAutomatico.value = false;
        if (formRef.current) {
            batch(() => {
                podeSalvarAutomatico.value = false;
                alerta.value = {
                    ...alerta.value,
                    open: true,
                    title: strings.atencao,
                    type: rascunho ? 'alert' : 'confirmation',
                    message: rascunho ? strings.textoConfirmarRascunhoSecundario : strings.confirmarRespostaSecundaria,
                    options: [{
                        title: strings.confirmar,
                        onClick: () => {
                            enviarForm(dados, rascunho, setSubmitting);
                            alerta.value = {
                                ...alerta.value,
                                open: false
                            };
                        }
                    },
                    {
                        title: strings.cancelar,
                        onClick: () => {
                            podeSalvarAutomatico.value = true;
                            alerta.value = {
                                ...alerta.value,
                                open: false
                            };
                        }
                    }],
                    onClose: () => {
                        podeSalvarAutomatico.value = true;
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        };
                    }
                };
            });
        }
    }

    const onSubmit = submitForm(false);
    const onSubmitRascunho = submitForm(true);

    return (
        initialValues.value &&
        <Formik
            innerRef={formRef}
            initialValues={initialValues.value}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={validationSchemaSecundario(strings)}
            onSubmit={onSubmit}
        >
            {
                ({
                    isSubmitting,
                    values,
                    handleSubmit,
                    setSubmitting
                }) => (
                    <>
                        <SttContainer>
                            <form onSubmit={handleSubmit} noValidate>
                                <SttHeading variant="h1" color="primary" align="center">{strings.complementoRespostaTele}</SttHeading>
                                {/* Dados cabeçalho */}
                                <SttExpansionPanel
                                    classegriditem={classes.expansionPanel}
                                    title={strings.cabecalho}
                                    children={
                                        <Suspense fallback={<SttCircularProgress color="primary" />}>
                                            <DadosSecundario />
                                        </Suspense>
                                    }
                                />
                                <Divider />
                                <div className={classes.buttonWrapper}>
                                    <SttButton
                                        type="submit"
                                        variant="contained"
                                        nomarginleft="true"
                                        className={classes.button}
                                        color="primary"
                                        disabled={isSubmitting}
                                    >
                                        {strings.responderTeleComplemento}
                                    </SttButton>
                                    <SttButton
                                        type="button"
                                        variant="outlined"
                                        color="primary"
                                        className={classes.button}
                                        disabled={isSubmitting}
                                        onClick={() => onSubmitRascunho(values, { setSubmitting })}
                                    >
                                        {strings.salvarRascunho}
                                    </SttButton>
                                </div>
                            </form>
                            <SttLoading
                                open={isSubmitting}
                                text={strings.salvandoResposta}
                            />
                        </SttContainer>

                    </>
                )
            }
        </Formik>
    );
}

export default FormResposta;