import * as yup from 'yup';
import {
    GERAL,
    PACIENTE_ESPECIFICO
} from '../geral/form/fieldNames';
import { PACIENTE } from '@stt-componentes/paciente/dist/lib/form/fieldNames';
import dadosPacienteSchema from '@stt-componentes/paciente/dist/lib/form/validationSchema';

export default (strings, campos) => {
    return yup.object().shape({
        [PACIENTE]: yup.object()
            .when(`${GERAL}.${PACIENTE_ESPECIFICO}`, {
                is: (pacEspec) => pacEspec,
                then: dadosPacienteSchema(strings, campos)
            })
    });
}

