import React, { Suspense, lazy, useContext } from 'react';
import { useFormikContext } from 'formik';
import { GERAL, REGIAO } from '../geral/form/fieldNames';
import { FORMULARIOS_ADENDOS } from '../../../common/ConstantsHanseniase';
import { SttCircularProgress, SttDivider, SttExpansionPanel, SttTranslateHook } from '@stt-componentes/core';
import { makeStyles } from '@material-ui/core';

const WrapperDadosAdendoHanseniase = lazy(() => import('./hanseniase'));

const useStyles = makeStyles(theme => ({
    expansionPanel: {
        width: '100%'
    }
}));

const getTextoPanel = (formulario, strings) => {
    if (parseInt(formulario) === FORMULARIOS_ADENDOS.HANSENIASE) return strings.hanseniase;

    return '';
}

const WrapperAdendos = () => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    const { values } = useFormikContext();

    return (
        <>
            {/* Dados de adendos de formulário */}
            <SttExpansionPanel
                classegriditem={classes.expansionPanel}
                title={getTextoPanel(values[GERAL][REGIAO].adendo_formulario, strings)}
                children={
                    <Suspense fallback={<SttCircularProgress color="primary" />}>
                        {
                            parseInt(values[GERAL][REGIAO].adendo_formulario) === FORMULARIOS_ADENDOS.HANSENIASE &&
                            <WrapperDadosAdendoHanseniase />
                        }
                    </Suspense>
                }
            />
            <SttDivider />
        </>
    )
}

export default WrapperAdendos;