import React, { memo, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
    SttTextItem,
    SttHeading,
    SttTranslateHook
} from '@stt-componentes/core';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1.5)
    }
}));

const RespostaSincronaProcessoTrabalho = ({ teleconsultoria }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();

    return (
        <>
            {
                teleconsultoria.participantes?.length > 0 &&
                <>
                    <SttHeading variant="h4" color="primary" className={classes.root}>{strings.listaPresenca}</SttHeading>
                    {
                        teleconsultoria.participantes.map((par, index) => {
                            let texto = par.nome.trim();

                            if (par.profissao) {
                                texto += ` - ${par.profissao}`;
                            }

                            texto += ` (${par.tipo_participante})`;

                            return (
                                <SttTextItem key={`A_${index}`} title={(index + 1)} content={texto} />
                            )
                        })
                    }
                </>
            }
        </>
    )
}


export default memo(RespostaSincronaProcessoTrabalho);