export const JUDICIAL = 'judicial';
export const NUMERO_PROCESSO = 'numeroProcesso';
export const ESFERA_ORGAO = 'esferaOrgao';
export const VARA_SERVENTIA = 'varaServentia';
export const REUS = 'reus';
export const TIPO_REPRESENTACAO = 'tipoRepresentacao';
export const ASSISTENCIA_JUDICIARIA = 'assistenciaJudiciaria';
export const TIPO_JUIZADO = 'tipoJuizado';
export const NOME_ADVOGADO = 'nomeAdvogado';
export const NUMERO_OAB = 'numeroOab';
export const UF_OAB = 'ufOab';
export const AUTOR_REPRESENTADO = 'autorRepresentado';
export const NOME_REPRESENTANTE = 'nomeRepresentante';
export const DIAGNOSTICO = 'diagnostico';
export const PARENTESCO_REPRESENTANTE = 'parentescoRepresentante';
export const COMPLEMENTO_PARENTESCO_REPRESENTANTE = 'complementoParentescoRepresentante';
