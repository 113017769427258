import * as yup from 'yup';
import { CLASSIFICACOES } from '../../../../common/Constants';
import {
    GERAL,
    EQUIPE,
    CLASSIFICACAO,
    ESPECIFICACAO,
    ASSUNTO,
    COMPLEMENTO_ASSUNTO,
    ASSUNTO_TEXTUAL,
    DUVIDA,
    REGIAO,
    INTENCAO_ENCAMINHAMENTO,
    PACIENTE_ESPECIFICO,
} from './fieldNames';

export default (strings) => {
    return yup.object().shape({
        [GERAL]: yup.object().shape({
            [EQUIPE]: yup
                .object().shape({
                    id: yup.number(),
                    nome_equipe: yup.string(),
                    nome_cidade: yup.string(),
                    nome_instituicao: yup.string(),
                })
                .nullable()
                .required(strings.campoObrigatorio),
            [REGIAO]: yup
                .object().shape({
                    id: yup.number(),
                    descricao: yup.string(),
                    identificador_cadastro_classificacao_assunto: yup.string(),
                    id_especificacao_assunto: yup.number(),
                    vinculo_paciente_solicitacao: yup.boolean().nullable(),
                })
                .nullable()
                .required(strings.campoObrigatorio),
            [CLASSIFICACAO]: yup
                .string()
                .nullable()
                .required(strings.campoObrigatorio),
            [ESPECIFICACAO]: yup
                .number()
                .nullable()
                .required(strings.campoObrigatorio),
            [ASSUNTO]: yup
                .object().shape({
                    id: yup.number(),
                    descricao: yup.string()
                })
                .nullable()
                .required(strings.campoObrigatorio),
            [COMPLEMENTO_ASSUNTO]: yup
                .string()
                .trim()
                .nullable()
                .when(ASSUNTO_TEXTUAL, {
                    is: (assuntoTextual) => assuntoTextual,
                    then: yup
                        .string()
                        .min(10, strings.erroConteudoHtml)
                        .required(strings.campoObrigatorio)
                }),
            [INTENCAO_ENCAMINHAMENTO]: yup
                .boolean()
                .nullable()
                .when(PACIENTE_ESPECIFICO, {
                    is: (pacEsp) => pacEsp,
                    then: yup
                        .boolean()
                        .nullable()
                        .oneOf([true, false], strings.campoObrigatorio)
                }),
            [DUVIDA]: yup
                .string()
                .trim()
                .nullable()
                .test('html-valido', strings.erroConteudoHtml, (html) => {
                    html = (html || '').replace(/<[^>]*>?/gm, '');
                    if (html.length === 0 || html.length >= 10) {
                        return true;
                    }
                    return false;
                })
                .when(CLASSIFICACAO, {
                    is: (classificacao) => classificacao !== CLASSIFICACOES.JUDICIALIZACAO,
                    then: yup
                        .string()
                        .required(strings.campoObrigatorio),
                })
        }).required()
    });
}
