import React, { memo, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import HttpStatus from 'http-status-codes';
import axios from 'axios';
import { getHeaders } from '../../../request';
import {
    SttLoading,
    SttGrid,
    SttButton,
    SttModal,
    SttFormLabel,
    SttFormHelperText,
    SttTranslateHook
} from '@stt-componentes/core';
import { SttCkEditor } from '@stt-componentes/ckeditor';
import * as yup from 'yup';
import {
    Formik,
    FastField,
} from 'formik';
import alerta from '../../../signals/alerta';
import { useSignals } from '@preact/signals-react/runtime';

const useStyles = makeStyles(theme => ({
    form: {
        marginTop: theme.spacing(1.5)
    },
    ckeditorLabel: {
        marginBottom: theme.spacing(1),
    },
}));

const validationSchema = (strings) => {
    return yup.object().shape({
        'replica': yup
            .string()
            .trim()
            .required(strings.campoObrigatorio)
            .test('html-valido', strings.erroConteudoHtml, (html) => {
                html = (html || '').replace(/<[^>]*>?/gm, '');
                if (html.length === 0 || html.length >= 10) {
                    return true;
                }
                return false;
            })
            .nullable(),
    });
}

const initialValues = {
    replica: ''
};

const Replica = ({ idSegundaOpiniao, idResposta, idSolicitacao, idTeleconsultor, callback, exibirReplica }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    useSignals();

    const schema = validationSchema(strings);

    const fecharReplica = () => {
        exibirReplica.value = false;
    }

    const enviarForm = (dados, setSubmitting) => {
        setSubmitting(true);

        dados.idSegundaOpiniao = idSegundaOpiniao;
        dados.idSolicitacao = idSolicitacao;
        dados.idResposta = idResposta;
        dados.idTeleconsultor = idTeleconsultor;
        dados.replica = dados.replica.trim();

        let tipoAlerta = '';
        let tituloAlerta = '';
        let mensagemAlerta = '';
        let options = [];
        let onClose = () => { };

        axios.post(`${global.gConfig.url_base_teleconsultorias}/replica`, dados, { headers: getHeaders() })
            .then((response) => {
                const { data } = response;

                tipoAlerta = 'success';
                tituloAlerta = strings.sucesso;
                mensagemAlerta = data?.message ? data.message : strings.replicaEnviada;
                options = [{
                    title: strings.ok,
                    onClick: () => {
                        callback();
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        }
                    }
                }];
                onClose = () => {
                    callback();
                    alerta.value = {
                        ...alerta.value,
                        open: false
                    };
                }
            })
            .catch(err => {
                const { response } = err;
                tipoAlerta = 'error';
                tituloAlerta = strings.erro;
                mensagemAlerta = strings.erroGenerico;

                if (response) {
                    const { data } = response;

                    mensagemAlerta = data.message;
                    if (response.status === HttpStatus.BAD_REQUEST || response.status === HttpStatus.INTERNAL_SERVER_ERROR) {
                        let arrMensagem = [];
                        data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        if (arrMensagem.length > 0) {
                            mensagemAlerta = arrMensagem.join('\n');
                        }
                    }
                }

                options = [{
                    title: strings.ok,
                    onClick: () => {
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        }
                    }
                }];
                onClose = () => {
                    alerta.value = {
                        ...alerta.value,
                        open: false
                    };
                }
            })
            .finally(() => {
                setSubmitting(false);
                alerta.value = {
                    ...alerta.value,
                    type: tipoAlerta,
                    title: tituloAlerta,
                    message: mensagemAlerta,
                    open: true,
                    options: options,
                    onClose: onClose
                }
            });
    }

    return (
        <SttModal
            title={strings.adicionarReplica}
            open={exibirReplica.value}
            outModalClose={fecharReplica}
            iconClose={fecharReplica}
            maxWidth="lg"
            fullWidth={true}
            children={
                <Formik
                    initialValues={initialValues}
                    validationSchema={schema}
                    onSubmit={(dados, { setSubmitting }) => {
                        setSubmitting(false);
                        alerta.value = {
                            ...alerta.value,
                            open: true,
                            title: strings.atencao,
                            type: 'alert',
                            message: strings.confirmarReplica,
                            options: [{
                                title: strings.sim,
                                onClick: () => {
                                    enviarForm(dados, setSubmitting)
                                    alerta.value = {
                                        ...alerta.value,
                                        open: false
                                    };
                                }
                            },
                            {
                                title: strings.nao,
                                onClick: () => {
                                    alerta.value = {
                                        ...alerta.value,
                                        open: false
                                    };
                                }
                            }],
                            onClose: () => {
                                alerta.value = {
                                    ...alerta.value,
                                    open: false
                                };
                            }
                        };
                    }}
                >
                    {
                        ({
                            isSubmitting,
                            handleSubmit,
                            resetForm,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit} noValidate className={classes.form}>
                                    <SttGrid container spacing={3}>
                                        <SttGrid item xs={12}>
                                            <FastField name='replica'>
                                                {({
                                                    form: { setFieldValue },
                                                    field: { name, value },
                                                    meta
                                                }) => (
                                                    <>
                                                        <SttFormLabel required={true} component="legend" className={classes.ckeditorLabel} error={(meta.touched && meta.error) ? true : false}>{strings.replica}</SttFormLabel>
                                                        <SttCkEditor
                                                            data={value}
                                                            config={{ placeholder: strings.placeholderReplica }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                setFieldValue(name, data);
                                                            }}
                                                        />
                                                        <SttFormHelperText error={true}>{meta.touched && meta.error ? meta.error : undefined}</SttFormHelperText>
                                                    </>
                                                )}
                                            </FastField>
                                        </SttGrid>
                                        <SttLoading
                                            open={isSubmitting}
                                            text={strings.salvando}
                                        />
                                    </SttGrid>
                                    <SttGrid container spacing={3}>
                                        <SttGrid item xs={12}>
                                            <SttButton
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                nomarginleft="true"
                                                className={classes.button}
                                                disabled={isSubmitting}
                                            >
                                                {strings.confirmar}
                                            </SttButton>
                                            <SttButton
                                                type="button"
                                                variant="outlined"
                                                color="primary"
                                                disabled={isSubmitting}
                                                className={classes.button}
                                                onClick={() => {
                                                    resetForm({ values: initialValues });
                                                    fecharReplica();
                                                }}
                                            >
                                                {strings.fechar}
                                            </SttButton>
                                        </SttGrid>
                                    </SttGrid>
                                </form>
                            )
                        }
                    }
                </Formik>
            }
        />
    );
};

export default memo(Replica);