import React, { useContext, useEffect } from 'react';
import { SttTranslateHook } from '@stt-componentes/core';
import { SttCardChart, } from '@stt-componentes/charts';
import { useSignal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';
import axios from 'axios';
import { getHeaders } from '../../request';

const PrazoRespostas = ({ idRede }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    useSignals();

    const totalRespondidasPrazo = useSignal();
    const porcentagemRespondidasPrazo = useSignal();

    useEffect(() => {
        buscarIndicadorRespondidasPrazo();
    }, []);

    const buscarIndicadorRespondidasPrazo = () => {
        axios.get(`${global.gConfig.url_base_teleconsultorias}/solicitacao/indicadores/respondidas-prazo`, { params: { idRede }, headers: getHeaders() })
            .then((response) => {
                const { data } = response.data;

                if (data.length === 0) {
                    return;
                    // TODO: TRATAR TELECONSULTOR SEM RESPOSTA
                }

                totalRespondidasPrazo.value = parseInt(data.dentro_do_prazo);
                if (totalRespondidasPrazo.value === 0 || !totalRespondidasPrazo.value) {
                    porcentagemRespondidasPrazo.value = '0%';
                    totalRespondidasPrazo.value = 0;
                    return;
                }
                porcentagemRespondidasPrazo.value = (parseInt(data.dentro_do_prazo) / (parseInt(data.dentro_do_prazo) + parseInt(data.fora_do_prazo)) * 100).toFixed(2) + '%';
                porcentagemRespondidasPrazo.value = porcentagemRespondidasPrazo.value.replace('.', ',');
            })
            .catch(err => {
                // TODO: BOTÃO PARA TENTAR NOVAMENTE
            });
    };

    return (
        <SttCardChart
            title={strings.prazosResposta}
            subtitle={totalRespondidasPrazo.value}
            description={strings.teleconsultoriaRespondidasPrazo}
            showLoading={true}
            tooltip={strings.ultimos6Meses}
        >
            {porcentagemRespondidasPrazo.value}
        </SttCardChart>
    )
}

export default PrazoRespostas;