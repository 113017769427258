import React, { useEffect, memo, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import axios from 'axios';
import { getHeaders } from '../../request';
import {
    SttExpansionPanel,
    SttTextItem,
    SttHeading,
    SttContainer,
    SttCircularProgress,
    SttDivider,
    SttNotification,
    SttTranslateHook
} from '@stt-componentes/core';
import { Util } from '@stt-utilitarios/core'
import Functions from '../../common/Functions';
import ExibicaoHtml from './exibicaoHtml';
import ExibicaoCid10 from './exibicaoCid10';
import Moment from 'react-moment';
import Historico from '../pesquisa/historico';
import BotoesOperacoes from './botoesOperacoes';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { useSignal, useSignals } from '@preact/signals-react/runtime';
import usuario from '../../signals/usuario';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    link: {
        padding: '5px'
    },
    notificacao: {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5),
        alignItems: 'center',
        cursor: 'pointer'
    },
    buttonWrapper: {
        marginTop: theme.spacing(1.5)
    },
    button: {
        marginBottom: theme.spacing(1),
        marginLeft: 0,
        marginRight: theme.spacing(1.5)
    },
}));

const Divider = memo((props) => {
    return (
        <SttDivider {...props} />
    )
});

const DetalhesCondutaClinica = ({ id, adicionarAba }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    useSignals();

    const teleconsultoria = useSignal(null);

    const baixarTeleconsultoria = () => {
        axios.get(`${global.gConfig.url_base_teleconsultorias}/conduta-clinica/${id}`, { headers: getHeaders() })
            .then((response) => {
                if (response.data) {
                    const { data } = response.data;

                    data.acoes = Functions.acoes(usuario, data);
                    teleconsultoria.value = data;
                }
            })
            .catch(err => console.log(err));
    }

    useEffect(() => {
        if (id) {
            baixarTeleconsultoria();
        }
    }, [id]);

    return (
        <SttContainer>
            {
                !teleconsultoria.value
                    ?
                    <div className={classes.carregando}>
                        <SttCircularProgress color="primary" />
                    </div>
                    :
                    <>
                        <SttHeading variant="h1" color="primary" align="center" >{strings.teleCondutaClinica} - {teleconsultoria.value.id}</SttHeading>

                        <SttExpansionPanel
                            title={strings.dadosGerais}
                            children={
                                <div>
                                    <SttNotification severity="info" className={classes.notificacao}>
                                        {strings.teleconsultoriaAutomatica}
                                    </SttNotification>
                                    <SttTextItem title={strings.tipoTeleconsultoria} content={strings.teleBaseadaCC} />
                                    {
                                        teleconsultoria.value.nome_participante &&
                                        <SttTextItem title={strings.solicitante} content={teleconsultoria.value.nome_participante} />
                                    }
                                    {
                                        teleconsultoria.value.cidade_participante &&
                                        <SttTextItem title={strings.cidadeUf} content={`${teleconsultoria.value.cidade_participante} / ${teleconsultoria.value.estado_participante}`} />
                                    }
                                    {
                                        teleconsultoria.value.cbo_familia &&
                                        <SttTextItem title={strings.cbo} content={teleconsultoria.value.cbo_familia} />
                                    }
                                    <ExibicaoCid10 titulo={strings.cid10} cid={teleconsultoria.value.cid10} />
                                    <ExibicaoHtml titulo={strings.assunto} html={teleconsultoria.value.assunto} />
                                </div>
                            }
                        />
                        <Divider />

                        {
                            teleconsultoria.value.id_paciente &&
                            <>
                                <SttExpansionPanel
                                    title={strings.dadosPaciente}
                                    children={
                                        <>
                                            {
                                                teleconsultoria.value.acoes.podeVisualizarHistorico && teleconsultoria.value.pacienteTemHistorico &&
                                                <SttNotification severity="warning"
                                                    className={classes.notificacao}
                                                    icon={<ReportProblemOutlinedIcon fontSize='large' />}
                                                    onClick={() => {
                                                        adicionarAba({
                                                            titulo: `${strings.historico} - ${teleconsultoria.value.nome_paciente}`,
                                                            conteudo: Historico,
                                                            cpf: teleconsultoria.value.cpf_paciente,
                                                            cns: teleconsultoria.value.cns_paciente,
                                                            adicionarAba,
                                                            idAba: `${teleconsultoria.value.cpf_paciente}_${teleconsultoria.value.cns_paciente}`
                                                        });
                                                    }}
                                                >
                                                    <b>{strings.atencao}!</b>&nbsp;{strings.verificamosTelePacienteRespondida}
                                                </SttNotification>
                                            }
                                            <SttTextItem title={strings.nome} content={teleconsultoria.value.nome_paciente} />
                                            <SttTextItem title={strings.sexo} content={teleconsultoria.value.sexo_paciente} />
                                            {
                                                teleconsultoria.value.cpf_paciente &&
                                                <SttTextItem title={strings.cpf} content={Util.util.adicionarMascara(`000${teleconsultoria.value.cpf_paciente}`, '000.000.000-00', { reverse: true })} />
                                            }
                                            {
                                                teleconsultoria.value.cns_paciente &&
                                                <SttTextItem title={strings.cns} content={teleconsultoria.value.cns_paciente} />
                                            }
                                            {
                                                teleconsultoria.value.data_nascimento_paciente &&
                                                <SttTextItem title={strings.dataNascimento} content={<Moment format="DD/MM/YYYY">{teleconsultoria.value.data_nascimento_paciente}</Moment>} />
                                            }
                                            {
                                                teleconsultoria.value.cidade_paciente &&
                                                <SttTextItem title={strings.cidadeUf} content={`${teleconsultoria.value.cidade_paciente} / ${teleconsultoria.value.estado_paciente}`} />
                                            }
                                            {
                                                teleconsultoria.value.raca_paciente &&
                                                <SttTextItem title={strings.racaEtnia} content={teleconsultoria.value.raca_paciente} />
                                            }
                                        </>
                                    }
                                />
                                <Divider />
                            </>
                        }
                        <SttExpansionPanel
                            title={strings.encaminhamento}
                            children={
                                <ExibicaoHtml titulo={''} html={teleconsultoria.value.encaminhamento} />
                            }
                        />
                        <Divider />
                        <BotoesOperacoes teleconsultoria={teleconsultoria} baixarTeleconsultoria={baixarTeleconsultoria} />
                    </>
            }
        </SttContainer>
    )

}

export default DetalhesCondutaClinica;