import React, { memo, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
    SttExpansionPanel,
    SttTextItem,
    SttDivider,
    SttHeading,
    SttNotification,
    SttAlertTitle,
    SttTranslateHook
} from '@stt-componentes/core';
import moment from 'moment';
import ExibicaoHtml from './exibicaoHtml';
import ExibicaoCid10 from './exibicaoCid10';
import ExibicaoTecnologiasSolicitadas from './respostaAssincronaJudicialTecnologiasSolicitadas';
import ExibicaoCustosTecnologias from './respostaAssincronaJudicialCustosTecnologias';
import { OPCOES_RECOMENDACAO_CONITEC, OPCOES_TIPO_RESPOSTA } from '../../common/ConstantsRespostaJudicial';
import { SITUACAO_RESPOSTA } from '../../common/ConstantsResposta';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1.5)
    },
    notificacao: {
        display: 'flex',
        flexDirection: 'column'
    },
    alert: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

const Divider = memo((props) => {
    return (
        <SttDivider {...props} />
    )
});

const RespostaAssincronaJudicial = ({ segundaOpiniao }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();

    const { resposta } = segundaOpiniao;

    if (!resposta || !resposta.judicializacao) {
        return null;
    }
    const { judicializacao } = resposta;

    if (!segundaOpiniao.resposta) {
        return <></>
    }

    return (
        <>
            <SttExpansionPanel
                title={strings.resposta}
                children={
                    <>
                        {
                            segundaOpiniao.resposta.situacao === SITUACAO_RESPOSTA.RASCUNHO &&
                            <SttNotification severity="warning" className={classes.alert}>
                                <SttAlertTitle><b>{strings.atencao}!</b></SttAlertTitle>
                                <div className={classes.notificacao} dangerouslySetInnerHTML={{ __html: strings.rascunhoResposta }} />
                            </SttNotification>
                        }
                        <SttTextItem title={strings.teleconsultor} content={resposta.nome} />
                        <SttTextItem title={strings.dataResposta} content={moment(resposta.data).format('DD/MM/YYYY HH:mm')} />
                        <SttTextItem title={strings.cidadeUf} content={`${resposta.cidade} / ${resposta.estado}`} />
                        {
                            resposta.cbo_familia &&
                            <SttTextItem title={strings.cbo} content={resposta.cbo_familia} />
                        }
                        {
                            judicializacao.tipo_resposta_descricao &&
                            <SttTextItem title={strings.tipoResposta} content={judicializacao.tipo_resposta_descricao} />
                        }
                        <ExibicaoHtml titulo={strings.manifestacaoTecnica} html={judicializacao.manifestacao_tecnica} resposta={true} />
                        <ExibicaoHtml titulo={strings.diagnostico} html={judicializacao.diagnostico} resposta={true} />
                        <ExibicaoHtml titulo={strings.meiosConfirmatorios} html={judicializacao.meio_confirmatorio_diagnostico} resposta={true} />
                        <ExibicaoHtml titulo={strings.breveResumoCasoConcreto} html={judicializacao.breve_resumo_caso_concreto} resposta={true} />

                        <ExibicaoTecnologiasSolicitadas judicializacao={judicializacao} />

                        <ExibicaoHtml titulo={strings.outrasTecnologias} html={judicializacao.outras_tecnologia_disponiveis} resposta={true} />

                        <ExibicaoCustosTecnologias judicializacao={judicializacao} />

                        <ExibicaoHtml titulo={strings.evidencias} html={judicializacao.evidencias} resposta={true} />
                        <ExibicaoHtml titulo={strings.beneficioResultadoEsperado} html={judicializacao.resultado_esperado} resposta={true} />
                        {
                            judicializacao.recomendacao_conitec && judicializacao.recomendacao_conitec.trim() &&
                            <SttTextItem title={strings.recomendacaoConitec} content={OPCOES_RECOMENDACAO_CONITEC.LABEL[judicializacao.recomendacao_conitec.trim()]} />
                        }
                        <ExibicaoHtml titulo={strings.complementacoesConitec} html={judicializacao.complementacao_recomendacao} resposta={true} />

                        <ExibicaoHtml titulo={strings.conclusao} html={judicializacao.conclusao} resposta={true} />
                        {
                            judicializacao.evidencias_cienticas !== null &&
                            <SttTextItem title={strings.evidenciasCientificas} content={judicializacao.evidencias_cienticas ? strings.sim : strings.nao} />
                        }
                        {
                            judicializacao.nota_tecnica_tutoriada !== null &&
                            <SttTextItem title={strings.apoioTutoria} content={judicializacao.nota_tecnica_tutoriada ? strings.sim : strings.nao} />
                        }
                        {
                            segundaOpiniao.referencias.length > 0 &&
                            <>
                                <SttHeading variant="h4" color="primary" className={classes.root}>{strings.referencias}</SttHeading>
                                {
                                    segundaOpiniao.referencias.map((r, index) => (
                                        <SttTextItem key={`A_${index}`} title={(index + 1)} content={r.referencia} />
                                    ))
                                }
                            </>
                        }
                        {
                            judicializacao.tipo_resposta === OPCOES_TIPO_RESPOSTA.TIPO.NOTA_TECNICA &&
                            <ExibicaoCid10 titulo={strings.cid10} cid={segundaOpiniao.cid10} />
                        }
                    </>
                }
            />
            <Divider />
        </>
    )
}

export default memo(RespostaAssincronaJudicial);