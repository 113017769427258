export const JUDICIAL = 'judicial';
export const CUSTOS_TECNOLOGIAS = 'custosTecnologias';
export const LABORATORIO = 'laboratorio';
export const MARCA_COMERCIAL = 'marcaComercial';
export const APRESENTACAO = 'apresentacao';
export const PRECO_FABRICA = 'precoFabrica';
export const PRECO_MAXIMO_GOVERNO = 'precoMaximoGoverno';
export const PRECO_MAXIMO_CONSUMIDOR = 'precoMaximoConsumidor';
export const PRECO_TRATAMENTO = 'precoTratamento';
export const DURACAO_TRATAMENTO = 'duracaoTratamento';
export const COMPLEMENTO_DURACAO_TRATAMENTO = 'complementoDuracaoTratamento';
export const PRECO_TRATAMENTO_ANUAL = 'precoTratamentoAnual';
export const OBSERVACAO = 'observacao';