import * as yup from 'yup';
import { GERAL, PACIENTE_ESPECIFICO } from '../../componentes/solicitacao/geral/form/fieldNames';
import { CID_10 } from '@stt-componentes/cid10/dist/lib/fieldNames';

export default (strings) => {
    return yup.object().shape({
        [CID_10]: yup
            .array()
            .when([`${GERAL}.${PACIENTE_ESPECIFICO}`], {
                is: (pacEspec) => pacEspec,
                then: yup
                    .array()
                    .min(1, strings.erroSemCid10)
                    .max(1)
                    .of(
                        yup.object()
                    )
                    .required(strings.campoObrigatorio)
            })
    });
}   