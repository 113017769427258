import * as yup from 'yup';
import { COMPLEMENTO_RESPOSTA } from './fieldNames';

export default (strings) => {
    return yup.object().shape({
        [COMPLEMENTO_RESPOSTA]: yup
            .string()
            .trim()
            .nullable()
            .test('html-valido', strings.erroConteudoHtml, (html) => {
                html = (html || '').replace(/<[^>]*>?/gm, '');
                if (html.length === 0 || html.length >= 10) {
                    return true;
                }
                return false;
            })
            .required(strings.campoObrigatorio),
    });
}