import * as yup from 'yup';
import { CLASSIFICACAO, GERAL } from '../../componentes/solicitacao/geral/form/fieldNames';
import { CIAP_2 } from '@stt-componentes/ciap2/dist/lib/fieldNames';
import { CLASSIFICACOES } from '../../common/Constants';

export default (strings) => {
    return yup.object().shape({
        [CIAP_2]: yup
            .array()
            .when([`${GERAL}.${CLASSIFICACAO}`], {
                is: (classificacao) => classificacao !== CLASSIFICACOES.JUDICIALIZACAO,
                then: yup
                    .array()
                    .min(1, strings.erroSemCiap2)
                    .max(1)
                    .of(
                        yup.object()
                    )
                    .required(strings.campoObrigatorio)
            })
    });
}   