import moment from 'moment';
import { temPerfilRBAC, temPermissaoRBAC } from '../security/rbac';
import { CODIGOS_CBO_HISTORICO, SITUACAO_TELECONSULTORIA, CLASSIFICACOES, PERMISSOES, PERFIL } from './Constants';
import { Util } from '@stt-utilitarios/core';
import { ANEXOS, ANEXOS_EXCLUIDOS } from "../componentes/anexos/form/fieldNames";
import { GERAL, EQUIPE, CLASSIFICACAO, ESPECIFICACAO, ASSUNTO, ASSUNTO_TEXTUAL, PACIENTE_ESPECIFICO, DUVIDA, REGIAO, INTENCAO_ENCAMINHAMENTO, FORMA_ORGANIZACAO } from '../componentes/solicitacao/geral/form/fieldNames';
import { TEXTUAL, HISTORIA_ATUAL, COMORBIDADES, TRATAMENTOS_USO, EXAMES_IMAGEM, COMPLEMENTACOES } from '../componentes/solicitacao/textual/form/fieldNames';
import { PACIENTE } from '@stt-componentes/paciente/dist/lib/form/fieldNames';
import { CID_10 } from '@stt-componentes/cid10/dist/lib/fieldNames';
import { CIAP_2 } from '@stt-componentes/ciap2/dist/lib/fieldNames';
import { ASSISTENCIA_JUDICIARIA, AUTOR_REPRESENTADO, DIAGNOSTICO, ESFERA_ORGAO, JUDICIAL, TIPO_JUIZADO, NOME_ADVOGADO, NOME_REPRESENTANTE, NUMERO_OAB, NUMERO_PROCESSO, REUS, TIPO_REPRESENTACAO, VARA_SERVENTIA, PARENTESCO_REPRESENTANTE, COMPLEMENTO_PARENTESCO_REPRESENTANTE, UF_OAB } from '../componentes/solicitacao/judicial/form/fieldNames';
import { INSUMOS, JUDICIAL_TECNOLOGIAS, MEDICAMENTOS, NUTRICOES, PROCEDIMENTOS_CBHPM, PROCEDIMENTOS_SIGTAP } from '../componentes/solicitacao/judicial-tecnologia/fieldNames';
import { OPCOES_TIPO_TECNOLOGIA } from './ConstantsRespostaJudicial';
import { ADENDO_HANSENIASE, ALTERACAO_NERVOS_SENSITIVOS, BACILOSCOPIA, CLASSIFICACAO_OPERACIONAL, DOSE_DIA, EPISODIO_REACIONAL, ESPECIFICACAO_TEMPO_USO, ESQUEMA_TERAPEUTICO, FORMA_CLINICA, GRAU_INCAPACIDADE, INDICE_BACILOSCOPICO, LESOES_CUTANEAS, MOTIVO_ENCAMINHAMENTO, NERVOS_AFETADOS, NUMERO_DOSES, NUMERO_LESOES, OUTRO_MOTIVO_ENCAMINHAMENTO, SINAIS_SINTOMAS, TEMPO_USO, TIPO_EPISODIO_REACIONAL, TRATAMENTO_REACIONAL } from '../componentes/solicitacao/adendo/hanseniase/form/fieldNames';
import { FORMULARIOS_ADENDOS } from './ConstantsHanseniase';

const acoes = (usuario, teleconsultoria = {}) => {
    let { id_teleconsultor, id_funcionario_solicitante } = teleconsultoria;

    let podeVerHistorico = false;
    let podeVisualizarMovimentacoes = false;
    let podeVisualizarHistorico = false;
    let podeVisualizarRegulacaoAutomatica = false;
    let podeVisualizarDevolucao = false;
    let podeVisualizarRespostaTemporaria = false;

    let podeAlterarEncaminhamento = false;
    let podeResponder = false;
    let podeAlterarResposta = false;
    let podeDevolver = false;
    let podeAvaliarAlterar = false;
    let podeEnviarReplica = false;
    let podeResponderSecundario = false;

    if (usuario.value.cbo) {
        usuario.value.cbo.forEach((cbo) => {
            if (CODIGOS_CBO_HISTORICO.includes(parseInt(cbo.codigo_cbo_familia))) {
                podeVerHistorico = true;
            }
        });
    }

    if (temPermissaoRBAC(usuario, PERMISSOES.ADMINISTRATIVO)) {
        podeVisualizarMovimentacoes = true;
        podeVisualizarHistorico = true;
        podeVisualizarRespostaTemporaria = true;
        podeVisualizarDevolucao = true;
        podeAlterarResposta = parseInt(usuario.value.idFuncionario) === parseInt(id_teleconsultor);
    }

    if (temPermissaoRBAC(usuario, PERMISSOES.RESPOSTA)) {
        podeVisualizarRespostaTemporaria = true;
        podeResponder = true;
        podeAlterarResposta = parseInt(usuario.value.idFuncionario) === parseInt(id_teleconsultor);
        podeVisualizarDevolucao = true;
        podeDevolver = podeAlterarResposta;

        if (teleconsultoria.segundaOpiniao?.teleconsultoresSecundarios?.length > 0) {
            podeResponderSecundario = teleconsultoria.segundaOpiniao?.teleconsultoresSecundarios.some(tsec => tsec.id_funcionario_teleconsultor === parseInt(usuario.value.idFuncionario));
        }
    }

    if (temPermissaoRBAC(usuario, PERMISSOES.REGULACAO)) {
        podeVisualizarHistorico = true;
        podeVisualizarRegulacaoAutomatica = true;
        podeVisualizarDevolucao = true;
        podeAlterarEncaminhamento = true;
        podeAlterarResposta = parseInt(usuario.value.idFuncionario) === parseInt(id_teleconsultor);
    }

    if (temPerfilRBAC(usuario, PERFIL.VISUALIZADOR)) {
        podeVisualizarDevolucao = true;
    }

    if (podeVerHistorico) {
        podeVisualizarRegulacaoAutomatica = true;
        podeVisualizarHistorico = true;
    }

    // É o solicitante da teleconsultoria
    podeAvaliarAlterar = parseInt(usuario.value.idFuncionario) === parseInt(id_funcionario_solicitante);

    if (podeAvaliarAlterar && teleconsultoria.segundaOpiniao?.resposta?.data) {
        // o envio da réplica só pode ser feita pelo solicitante.
        // o envio da réplica só pode ser feito para teleconsultorias ASSINCRONAS.
        if (teleconsultoria.regiao?.replica_treplica) {
            let hoje = moment();
            let dataSolMaisPrazoHoras = moment(teleconsultoria.segundaOpiniao.resposta.data).add(teleconsultoria.regiao.prazo_replica_treplica, 'hours');
            podeEnviarReplica = (hoje.toDate() < dataSolMaisPrazoHoras.toDate());
        }
    }

    return {
        podeVisualizarMovimentacoes,
        podeVisualizarRegulacaoAutomatica,
        podeVisualizarRespostaTemporaria,
        podeVisualizarHistorico,
        podeVisualizarDevolucao,

        podeAlterarEncaminhamento,
        podeAlterarResposta,
        podeResponder,
        podeDevolver,
        podeAvaliarAlterar,
        podeEnviarReplica,
        podeResponderSecundario
    }
};

const formatarParaVisualizacao = (teleconsultoria) => {
    if (teleconsultoria.data_nascimento_paciente) {
        let dataMoment = moment(teleconsultoria.data_nascimento_paciente);

        if (dataMoment.isValid()) {
            let now = moment();
            let dataMomentFormatada = moment(dataMoment, 'DD-MM-YYYY');
            let idade = moment.duration(now.diff(dataMomentFormatada));
            let anos = idade.years();
            let meses = idade.months();
            let dias = idade.days();
            teleconsultoria.idade_paciente = `${anos} ano(s), ${meses} mes(es) e ${dias} dia(s)`;
        }
    }

    let textoAssunto = null;
    if (teleconsultoria.complemento_assunto) {
        textoAssunto = teleconsultoria.complemento_assunto;
    }
    if (teleconsultoria.assunto) {
        textoAssunto = `${teleconsultoria.assunto.descricao}${textoAssunto ? (' - ' + textoAssunto) : ''}`;
    }
    if (teleconsultoria.id_equipe_saude) {
        teleconsultoria.equipe = {
            id: teleconsultoria.id_equipe_saude,
            nome_equipe: teleconsultoria.equipe_saude,
            nome_cidade: teleconsultoria.cidade_solicitante,
            nome_instituicao: teleconsultoria.instituicao_solicitante,
            id_instituicao: teleconsultoria.id_instituicao_solicitante,
            id_cidade: teleconsultoria.id_cidade_solicitante
        }
    }

    teleconsultoria.textoAssunto = textoAssunto;

    if (teleconsultoria.segundaOpiniao?.resposta?.judicializacao) {
        const { judicializacao } = teleconsultoria.segundaOpiniao.resposta;

        judicializacao.medicamentos.forEach(m => {
            m.ehMedicamento = true;
            m.tipo = OPCOES_TIPO_TECNOLOGIA.TIPO.MEDICAMENTO;
            m.descricaoTipo = OPCOES_TIPO_TECNOLOGIA.LABEL[OPCOES_TIPO_TECNOLOGIA.TIPO.MEDICAMENTO];
            if (m.tempo_tratamento_medicamento) {
                m.descricaoDuracaoTratamento = `${m.tempo_tratamento_medicamento} ${m.descricao_duracao_tratamento}`
            } else if (m.descricao_duracao_tratamento) {
                m.descricaoDuracaoTratamento = m.descricao_duracao_tratamento;
            }
        });
        judicializacao.insumos.forEach(m => {
            m.ehMedicamento = false;
            m.tipo = OPCOES_TIPO_TECNOLOGIA.TIPO.INSUMO;
            m.descricaoTipo = OPCOES_TIPO_TECNOLOGIA.LABEL[OPCOES_TIPO_TECNOLOGIA.TIPO.INSUMO];
            if (m.tempo_tratamento) {
                m.descricaoDuracaoTratamento = `${m.tempo_tratamento} ${m.descricao_duracao_tratamento}`
            } else if (m.descricao_duracao_tratamento) {
                m.descricaoDuracaoTratamento = m.descricao_duracao_tratamento;
            }
        });
        judicializacao.nutricao.forEach(m => {
            m.descricao = m.descricao_resumida;
            m.tipo = OPCOES_TIPO_TECNOLOGIA.TIPO.NUTRICAO;
            m.ehMedicamento = false;
            m.descricaoTipo = OPCOES_TIPO_TECNOLOGIA.LABEL[OPCOES_TIPO_TECNOLOGIA.TIPO.NUTRICAO];
            if (m.tempo_tratamento) {
                m.descricaoDuracaoTratamento = `${m.tempo_tratamento} ${m.descricao_duracao_tratamento}`
            } else if (m.descricao_duracao_tratamento) {
                m.descricaoDuracaoTratamento = m.descricao_duracao_tratamento;
            }
        });
        judicializacao.procedimentosCbhpm.forEach(m => {
            m.ehMedicamento = false;
            m.tipo = OPCOES_TIPO_TECNOLOGIA.TIPO.CBHPM;
            m.descricaoTipo = OPCOES_TIPO_TECNOLOGIA.LABEL[OPCOES_TIPO_TECNOLOGIA.TIPO.CBHPM];
            if (m.tempo_tratamento) {
                m.descricaoDuracaoTratamento = `${m.tempo_tratamento} ${m.descricao_duracao_tratamento}`
            } else if (m.descricao_duracao_tratamento) {
                m.descricaoDuracaoTratamento = m.descricao_duracao_tratamento;
            }
        });
        judicializacao.procedimentosSigtap.forEach(m => {
            m.descricao = m.nome;
            m.tipo = OPCOES_TIPO_TECNOLOGIA.TIPO.SIGTAP;
            m.ehMedicamento = false;
            m.descricaoTipo = OPCOES_TIPO_TECNOLOGIA.LABEL[OPCOES_TIPO_TECNOLOGIA.TIPO.SIGTAP];
            if (m.tempo_tratamento) {
                m.descricaoDuracaoTratamento = `${m.tempo_tratamento} ${m.descricao_duracao_tratamento}`
            } else if (m.descricao_duracao_tratamento) {
                m.descricaoDuracaoTratamento = m.descricao_duracao_tratamento;
            }
        });

        let tecs = judicializacao.medicamentos.concat(judicializacao.insumos).concat(judicializacao.nutricao);
        tecs = tecs.concat(judicializacao.procedimentosCbhpm).concat(judicializacao.procedimentosSigtap);

        judicializacao.tecnologias = tecs;
        delete judicializacao.medicamentos;
        delete judicializacao.insumos;
        delete judicializacao.nutricao;
        delete judicializacao.procedimentosCbhpm;
        delete judicializacao.procedimentosSigtap;
    }

    return teleconsultoria;
}

const inicializarValoresSolicitacao = (dados) => {
    const dadosSolicitacao = {};

    dadosSolicitacao[GERAL] = {
        id: dados.id || null,
        podeVisualizarHistorico: dados.podeVisualizarHistorico,
        situacao: dados.situacao_solicitacao || SITUACAO_TELECONSULTORIA.SITUACAO.RASCUNHO,
        idTeleconsultorDevolucao: null,
        devolvidaSolicitante: dados.situacao_solicitacao === SITUACAO_TELECONSULTORIA.SITUACAO.DEVOLVIDA_SOLICITANTE,
        [EQUIPE]: _getValueOrNull(dados.equipe),
        [CLASSIFICACAO]: _getValueOrNull(dados.identificador_cadastro_classificacao_assunto),
        [ESPECIFICACAO]: _getValueOrNull(dados.especificacao?.id),
        [REGIAO]: _getValueOrNull(dados.regiao),
        [FORMA_ORGANIZACAO]: _getValueOrNull(dados.formaOrganizacao),
        [INTENCAO_ENCAMINHAMENTO]: _getValueOrNull(dados.intencao_encaminhamento),
        [ASSUNTO]: _getValueOrNull(dados.assunto),
        [DUVIDA]: _getValueOrEmptyString(dados.duvida_original),
    };

    if (dados.devolucao?.length > 0) {
        dadosSolicitacao.geral.idTeleconsultorDevolucao = dados.devolucao[0].id_funcionario_teleconsultor;
    }

    dadosSolicitacao[GERAL][PACIENTE_ESPECIFICO] = false;
    dadosSolicitacao[GERAL][ASSUNTO_TEXTUAL] = false;
    if (dadosSolicitacao[GERAL][ASSUNTO]?.exige_complemento) {
        dadosSolicitacao[GERAL][ASSUNTO_TEXTUAL] = true;
    }

    switch (dadosSolicitacao[GERAL][CLASSIFICACAO]) {
        case CLASSIFICACOES.DUVIDAS_CLINICAS_GERAIS:
            dadosSolicitacao[GERAL][PACIENTE_ESPECIFICO] = (dados.id_paciente != null);
            break;
        case CLASSIFICACOES.ENCAMINHAMENTO:
        case CLASSIFICACOES.CIRURGIA:
        case CLASSIFICACOES.JUDICIALIZACAO:
            dadosSolicitacao[GERAL][PACIENTE_ESPECIFICO] = true;
            break;
        default:
            dadosSolicitacao[GERAL][PACIENTE_ESPECIFICO] = false;
            break;
    }

    dadosSolicitacao[ANEXOS] = dados.anexos || [];
    dadosSolicitacao[ANEXOS_EXCLUIDOS] = [];

    dadosSolicitacao[CIAP_2] = dados.ciap2 || [];

    dadosSolicitacao[CID_10] = dados.cid10 || [];
    dadosSolicitacao[CID_10].forEach(cid => {
        cid.codigo = cid.codigo_subcategoria ? cid.codigo_subcategoria : cid.codigo_categoria;
        cid.nome = cid.codigo_subcategoria ? cid.descritor_subcategoria : cid.descritor_categoria;
        cid.nome_abreviado = cid.codigo_subcategoria ? cid.nome_abreviado_subcategoria : cid.nome_abreviado_categoria;
    });

    dadosSolicitacao[PACIENTE] = {
        id: _getValueOrEmptyString(dados.id_paciente),
        cpf: dados.cpf_paciente ? Util.util.adicionarMascara(`000${dados.cpf_paciente}`, '000.000.000-00', { reverse: true }) : '',
        cns: _getValueOrEmptyString(dados.cns_paciente),
        nome: _getValueOrEmptyString(dados.nome_paciente),
        nomeMae: _getValueOrEmptyString(dados.nome_mae_paciente),
        genero: _getValueOrNull(dados.id_sexo_paciente),
        raca: _getValueOrNull(dados.id_raca_paciente),
        pais: _getValueOrNull(dados.id_pais_paciente),
        uf: _getValueOrNull(dados.id_estado_paciente),
        municipio: _getValueOrNull(dados.id_cidade_paciente),
        dataNascimento: _getDateOrNull(dados.data_nascimento_paciente),
        idade: '',
        peso: _getValueOrEmptyString(dados.peso),
        altura: _getValueOrEmptyString(dados.altura),
    };

    dadosSolicitacao[TEXTUAL] = {
        [HISTORIA_ATUAL]: _getValueOrEmptyString(dados.historia_atual_queixa_principal),
        [COMORBIDADES]: _getValueOrEmptyString(dados.comorbidades),
        [TRATAMENTOS_USO]: _getValueOrEmptyString(dados.medicamentos_plantas_em_uso),
        [EXAMES_IMAGEM]: _getValueOrEmptyString(dados.resultados_exames_imagem),
        [COMPLEMENTACOES]: _getValueOrEmptyString(dados.complementacoes),
    };

    dadosSolicitacao[JUDICIAL] = {
        [NUMERO_PROCESSO]: _getValueOrEmptyString(dados.judicializacao?.numero_processo),
        [ESFERA_ORGAO]: _getValueOrEmptyString(dados.judicializacao?.esfera_orgao),
        [VARA_SERVENTIA]: _getValueOrEmptyString(dados.judicializacao?.vara_serventia),
        [REUS]: _getValueOrEmptyString(dados.judicializacao?.reus),
        [TIPO_REPRESENTACAO]: _getValueOrEmptyString(dados.judicializacao?.tipo_representacao),
        [ASSISTENCIA_JUDICIARIA]: dados.judicializacao?.assistencia_judiciaria_gratuita ?? false,
        [TIPO_JUIZADO]: _getValueOrEmptyString(dados.judicializacao?.tipo_juizado),
        [NOME_ADVOGADO]: _getValueOrEmptyString(dados.judicializacao?.nome_advogado),
        [NUMERO_OAB]: _getValueOrEmptyString(dados.judicializacao?.numero_oab),
        [UF_OAB]: dados.judicializacao?.id_uf_oab ?
            {
                id: dados.judicializacao.id_uf_oab,
                sigla: dados.judicializacao.sigla_uf_oab
            } : null,
        [AUTOR_REPRESENTADO]: dados.judicializacao?.autor_representado_assistido ?? false,
        [NOME_REPRESENTANTE]: _getValueOrEmptyString(dados.judicializacao?.nome_representante),
        [PARENTESCO_REPRESENTANTE]: dados.judicializacao?.id_parentesco_representante ?
            {
                id: dados.judicializacao.id_parentesco_representante,
                descricao: dados.judicializacao.descricao_parentesco,
                exige_complemento: dados.judicializacao.exige_complemento_parentesco
            } : null,
        [COMPLEMENTO_PARENTESCO_REPRESENTANTE]: _getValueOrEmptyString(dados.judicializacao?.complemento_parentesco_representante),
        [DIAGNOSTICO]: _getValueOrEmptyString(dados.judicializacao?.diagnostico),
    }

    dadosSolicitacao[JUDICIAL_TECNOLOGIAS] = {
        [MEDICAMENTOS]: dados.judicializacao?.medicamentos ?? [],
        [INSUMOS]: dados.judicializacao?.insumos ?? [],
        [NUTRICOES]: dados.judicializacao?.nutricao ?? [],
        [PROCEDIMENTOS_CBHPM]: dados.judicializacao?.procedimentosCbhpm ?? [],
        [PROCEDIMENTOS_SIGTAP]: dados.judicializacao?.procedimentosSigtap ?? [],
    }

    dadosSolicitacao[ADENDO_HANSENIASE] = {
        [MOTIVO_ENCAMINHAMENTO]: _getValueOrNull(dados.adendoHanseniase?.motivo_encaminhamento),
        [OUTRO_MOTIVO_ENCAMINHAMENTO]: _getValueOrEmptyString(dados.adendoHanseniase?.outro_motivo_encaminhamento).trim(),
        [FORMA_CLINICA]: _getValueOrEmptyString(dados.adendoHanseniase?.forma_clinica).trim(),
        [CLASSIFICACAO_OPERACIONAL]: _getValueOrNull(dados.adendoHanseniase?.classificacao_operacional),
        [ESQUEMA_TERAPEUTICO]: _getValueOrNull(dados.adendoHanseniase?.esquema_terapeutico),
        [NUMERO_DOSES]: _getValueOrEmptyString(dados.adendoHanseniase?.numero_doses_ingeridas),
        [LESOES_CUTANEAS]: _getValueOrNull(dados.adendoHanseniase?.lesoes_cutaneas),
        [NUMERO_LESOES]: _getValueOrEmptyString(dados.adendoHanseniase?.numero_lesoes),
        [NERVOS_AFETADOS]: _getValueOrNull(dados.adendoHanseniase?.nervos_afetados),
        [BACILOSCOPIA]: _getValueOrEmptyString(dados.adendoHanseniase?.baciloscopia).trim(),
        [INDICE_BACILOSCOPICO]: _getValueOrEmptyString(dados.adendoHanseniase?.indice_baciloscopico),
        [ALTERACAO_NERVOS_SENSITIVOS]: _getValueOrNull(dados.adendoHanseniase?.alteracao_nervos_sensitivos),
        [GRAU_INCAPACIDADE]: _getValueOrEmptyString(dados.adendoHanseniase?.grau_incapacidade).trim(),
        [EPISODIO_REACIONAL]: _getValueOrNull(dados.adendoHanseniase?.episodio_reacional),
        [TIPO_EPISODIO_REACIONAL]: _getValueOrNull(dados.adendoHanseniase?.tipo_episodio_reacional),
        [TRATAMENTO_REACIONAL]: _getValueOrNull(dados.adendoHanseniase?.tratamento_reacional),
        [DOSE_DIA]: _getValueOrEmptyString(dados.adendoHanseniase?.dose_dia),
        [TEMPO_USO]: _getValueOrEmptyString(dados.adendoHanseniase?.tempo_uso),
        [ESPECIFICACAO_TEMPO_USO]: dados.adendoHanseniase?.id_especificacao_tempo_uso ?
            {
                id: dados.adendoHanseniase.id_especificacao_tempo_uso,
                descricao: dados.adendoHanseniase.descricao_especificacao_tempo_uso,
            } : null,
        [SINAIS_SINTOMAS]: dados.adendoHanseniase?.sinaisSintomas?.length > 0 ? dados.adendoHanseniase?.sinaisSintomas : [],
    }

    return dadosSolicitacao;
};

const formatarDadosParaSalvar = (data) => {
    const formData = new FormData();
    formData.append('id', data.geral.id);

    // ========== Dados gerais ==========
    formData.append('geral', JSON.stringify(data.geral));
    // ========== Dados gerais ==========

    if (data.geral.pacienteEspecifico) {
        if (data.geral.classificacao !== CLASSIFICACOES.JUDICIALIZACAO) {
            // ========== Textual ==========
            formData.append('textual', JSON.stringify(data.textual));
            // ========== Textual ==========
        } else {
            // ========== Judicial ==========
            if (data.judicial.numeroProcesso) {
                data.judicial.numeroProcesso = data.judicial.numeroProcesso.replace(/\D+/g, '');
            }
            formData.append('judicial', JSON.stringify(data.judicial));
            formData.append('judicialTecnologias', JSON.stringify(data.judicialTecnologias));
            // ========== Judicial ==========
        }

        // ========== Paciente ==========
        if (data.paciente.cpf) {
            data.paciente.cpf = data.paciente.cpf.replace(/\D+/g, '');
        }
        if (data.paciente.cpf || data.paciente.cns) {
            formData.append('paciente', JSON.stringify(data.paciente));
        }
        // ========== Paciente ==========

        // ========== CID-10 ==========
        if (data.cid10.length > 0) {
            formData.append('cid10', JSON.stringify(data.cid10));
        }
        // ========== CID-10 ==========
    }

    (data.ciap2.length > 0) && formData.append('ciap2', JSON.stringify(data.ciap2));
    _adicionarAnexos(formData, data.anexos);
    _adicionarAnexosExcluidos(formData, data.anexosExcluidos);

    if (data.geral.regiao?.adendo_formulario) {
        if (data.geral.regiao.adendo_formulario === FORMULARIOS_ADENDOS.HANSENIASE) {
            formData.append('adendoHanseniase', JSON.stringify(data.adendoHanseniase));
        }
    }

    return formData;
};

const verificarErrosOrdemPrecedencia = (erros, secao) => {
    const ordemPrecedencia = [
        GERAL,
        PACIENTE,
        CID_10,
        CIAP_2,
        TEXTUAL,
        JUDICIAL,
        JUDICIAL_TECNOLOGIAS,
        ADENDO_HANSENIASE,
        ANEXOS,
    ];

    for (const ordem of ordemPrecedencia) {
        if (secao === ordem) {
            return false;
        }

        if (erros[ordem]) {
            return true;
        }
    }
}

const _getDateOrNull = (value) => {
    if (!value) {
        return null;
    }
    if (moment(value).isValid()) {
        value = moment.utc(value);
        return value.set({ h: 12 }).toISOString();
    }

    return value;
}

const _getValueOrNull = (value) => {
    return value ?? null;
}

const _getValueOrEmptyString = (value) => {
    return value ?? '';
}

/**
 * Função que adicionar anexos do tipo "File" ao formulário que será enviado ao backend.
 * 
 * @param {FormData} formData formulário a ser enviado ao backend
 * @param {*} arrayAnexo array de anexos a ser adicionado ao formulário
 */
const _adicionarAnexos = (formData, arrayAnexo) => {
    if (arrayAnexo.length > 0) {
        let quantAnexos = 0;
        arrayAnexo.forEach((anexo, index) => {
            // Apenas anexos adicionados E que não foram enviados ao backend devem ser adicionados no array.
            if (anexo && (anexo instanceof File) && !anexo.id) {
                formData.append(`anexo.${quantAnexos}`, anexo);
                formData.append(`anexoIndex.${quantAnexos}`, index);
                quantAnexos++;
            }
        });
        if (quantAnexos > 0) {
            formData.append(`numeroAnexo`, quantAnexos);
        }
    }
}

/**
 * Função que adicionar os anexos excluídos pelo usuário ao formulário que será enviado ao backend.
 * 
 * @param {FormData} formData formulário a ser enviado ao backend
 * @param {*} arrayAnexo array de anexos excluídos a ser adicionado ao formulário
 */
const _adicionarAnexosExcluidos = (formData, arrayAnexoExcluido) => {
    if (arrayAnexoExcluido?.length > 0) {
        formData.append(`anexoExcluido`, JSON.stringify(arrayAnexoExcluido));
    }
}

export default {
    acoes,
    formatarParaVisualizacao,
    inicializarValoresSolicitacao,
    formatarDadosParaSalvar,
    verificarErrosOrdemPrecedencia
};
