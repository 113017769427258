import React, { useContext, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
    SttTable,
    SttTableHead,
    SttTableBody,
    SttTableRow,
    SttTableCell,
    SttTableSortLabel,
    SttTablePagination,
    SttCircularProgress,
    SttSeeButton,
    SttHidden,
    SttText,
    SttTranslateHook
} from '@stt-componentes/core';
import axios from 'axios';
import { getHeaders } from '../../request';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import { TIPO_TELECONSULTORIA } from '../../common/Constants';
import Detalhes from '../visualizacao/detalhes';
import DetalhesCondutaClinica from '../visualizacao/detalhesCondutaClinica';
import { batch, signal, useSignalEffect } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';

const useStyles = makeStyles(theme => ({
    tableWrapper: {
        marginTop: theme.spacing(2)
    },
    table: {
        overflow: 'auto'
    },
}));

const ORDENACAO = {
    DIRECAO: {
        ASC: 'asc',
        DESC: 'desc'
    },
    CAMPOS: {
        NUMERO: 'id',
        DATA_RESPOSTA: 'data_resposta',
        CIDADE: 'nome_cidade',
        TELECONSULTOR: 'nome_teleconsultor',
        AREA_TELECONSULTOR: 'cbo_familia_teleconsultor',
        ASSUNTO: 'assunto',
        TIPO: 'tipo_teleconsultoria',
    }
};

const solicitacoes = signal([]);
const totalRegistros = signal(0);
const buscaEmAndamento = signal(false);
const page = signal(null)
const count = signal(null);
const order = signal(ORDENACAO.DIRECAO.DESC);
const orderBy = signal(ORDENACAO.CAMPOS.DATA_RESPOSTA);
const buscar = signal(false);

const Historico = ({ adicionarAba, cpf, cns }) => {
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);

    useSignals();

    useEffect(() => {
        batch(() => {
            page.value = global.gConfig.pagination.start;
            count.value = global.gConfig.pagination.count;
            buscar.value = true;
        });
    }, []);

    const handleClickSort = (campo) => {
        const isAsc = (orderBy.value === campo) && (order.value === ORDENACAO.DIRECAO.ASC);
        const newOrder = isAsc ? ORDENACAO.DIRECAO.DESC : ORDENACAO.DIRECAO.ASC;
        batch(() => {
            order.value = newOrder;
            orderBy.value = campo;
            buscar.value = true;
        });
    }

    const handleChangePage = (event, newPage) => {
        batch(() => {
            page.value = newPage
            buscar.value = true;
        });
    };

    const handleChangeRowsPerPage = event => {
        batch(() => {
            count.value = event.target.value;
            page.value = global.gConfig.pagination.start;
            buscar.value = true;
        });
    };

    const buscarHistorico = () => {
        buscaEmAndamento.value = true;

        let params = {};

        if (cpf) {
            params.cpf = cpf.replace(/\D+/g, '');
        }
        if (cns) {
            params.cns = cns.replace(/\D+/g, '');
        }

        if (orderBy.value && order.value) {
            params.sort = orderBy.value;
            params.direction = order.value;
        }

        const offset = (page.value * count.value);
        params.start = offset;
        params.count = count.value;

        axios.get(`${global.gConfig.url_base_teleconsultorias}/solicitacao/historico`, { params, headers: getHeaders() })
            .then((response) => {
                const { data } = response.data;
                batch(() => {
                    totalRegistros.value = parseInt(data.totalRegistros);
                    solicitacoes.value = data.itens;
                });
            })
            .catch(err => {
                batch(() => {
                    console.log(err)
                    totalRegistros.value = 0;
                    solicitacoes.value = [];
                });
            })
            .finally(() => {
                batch(() => {
                    buscaEmAndamento.value = false;
                    buscar.value = false;
                });
            });
    }

    useSignalEffect(() => {
        if (buscar.value) {
            buscarHistorico();
        }
    });

    return (
        <>
            <TableContainer className={classes.tableWrapper} component={Paper}>
                <SttTable>
                    <SttTableHead>
                        <SttTableRow>
                            <SttHidden xsDown>
                                <SttTableCell width="4%" sortDirection={orderBy.value === ORDENACAO.CAMPOS.NUMERO ? order.value : false}>
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.NUMERO}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.NUMERO ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.NUMERO)}>
                                        {strings.numeroTeleconsultoria}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="7%" sortDirection={orderBy.value === ORDENACAO.CAMPOS.DATA_RESPOSTA ? order.value : false}>
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.DATA_RESPOSTA}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.DATA_RESPOSTA ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.DATA_RESPOSTA)}>
                                        {strings.dataResposta}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="10%" sortDirection={orderBy.value === ORDENACAO.CAMPOS.CIDADE ? order.value : false}>
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.CIDADE}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.CIDADE ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.CIDADE)}>
                                        {strings.cidadeUf}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="10%" sortDirection={orderBy.value === ORDENACAO.CAMPOS.TELECONSULTOR ? order.value : false}>
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.TELECONSULTOR}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.TELECONSULTOR ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.TELECONSULTOR)}>
                                        {strings.nomeTeleconsultor}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="8%" sortDirection={orderBy.value === ORDENACAO.CAMPOS.AREA_TELECONSULTOR ? order.value : false}>
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.AREA_TELECONSULTOR}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.AREA_TELECONSULTOR ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.AREA_TELECONSULTOR)}>
                                        {strings.areaTeleconsultor}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="16%" sortDirection={orderBy.value === ORDENACAO.CAMPOS.ASSUNTO ? order.value : false}>
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.ASSUNTO}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.ASSUNTO ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.ASSUNTO)}>
                                        {strings.assunto}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="6%" sortDirection={orderBy.value === ORDENACAO.CAMPOS.TIPO ? order.value : false}>
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.TIPO}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.TIPO ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.TIPO)}>
                                        {strings.tipoTeleconsultoria}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="5%" align="center">
                                    {strings.visualizar}
                                </SttTableCell>
                            </SttHidden>
                            <SttHidden smUp>
                                <SttTableCell width="100%" colSpan="2">{strings.teleconsultorias}</SttTableCell>
                            </SttHidden>
                        </SttTableRow>
                    </SttTableHead>
                    <SttTableBody>
                        {
                            buscaEmAndamento.value
                                ?
                                <SttTableRow key={-1}>
                                    <SttTableCell colSpan={8}
                                        align="center">
                                        <SttCircularProgress />
                                    </SttTableCell>
                                </SttTableRow>
                                :
                                (
                                    solicitacoes.value.length === 0 ?
                                        <SttTableRow key={-1}>
                                            <SttTableCell colSpan={8} align="center">
                                                {strings.nenhumRegistroEncontrado}
                                            </SttTableCell>
                                        </SttTableRow>
                                        :
                                        solicitacoes.value.map((row, index) => (
                                            <SttTableRow hover key={index}>
                                                <SttHidden xsDown>
                                                    <SttTableCell>{row.id}</SttTableCell>
                                                    <SttTableCell>{moment(row.data_resposta).format('DD/MM/YYYY HH:mm:ss')}</SttTableCell>
                                                    <SttTableCell>{row.nome_cidade} / {row.sigla_estado}</SttTableCell>
                                                    <SttTableCell>{row.nome_teleconsultor}</SttTableCell>
                                                    <SttTableCell>{row.cbo_familia_teleconsultor}</SttTableCell>
                                                    <SttTableCell>{row.assunto}</SttTableCell>
                                                    <SttTableCell>{TIPO_TELECONSULTORIA.LABEL[row.tipo_teleconsultoria]}</SttTableCell>
                                                </SttHidden>
                                                <SttHidden smUp>
                                                    <SttTableCell width="96%">
                                                        <SttText size="small"><b>{strings.numero}:</b> {row.id}</SttText>
                                                        <SttText size="small"><b>{strings.dataResposta}:</b> {moment(row.data_resposta).format('DD/MM/YYYY HH:mm:ss')}</SttText>
                                                        <SttText size="small"><b>{strings.cidadeUf}:</b> {row.nome_cidade} / {row.sigla_estado}</SttText>
                                                        <SttText size="small"><b>{strings.teleconsultor}:</b> {row.nome_teleconsultor}</SttText>
                                                        <SttText size="small"><b>{strings.areaTeleconsultor}:</b> {row.cbo_familia_teleconsultor}</SttText>
                                                        <SttText size="small"><b>{strings.tipo}:</b> {TIPO_TELECONSULTORIA.LABEL[row.tipo_teleconsultoria]}</SttText>
                                                    </SttTableCell>
                                                </SttHidden>
                                                <SttTableCell align="center">
                                                    <SttSeeButton
                                                        id={`btn-ver-solicitacao-${index}`}
                                                        onClick={() => {
                                                            if (row.tipo_teleconsultoria === TIPO_TELECONSULTORIA.TIPO.ASSINCRONA || row.tipo_teleconsultoria === TIPO_TELECONSULTORIA.TIPO.SINCRONA) {
                                                                adicionarAba({
                                                                    titulo: `${strings.solicitacao} - ${row.id}`,
                                                                    conteudo: Detalhes,
                                                                    id: row.id,
                                                                    adicionarAba,
                                                                    idAba: `${row.tipo_teleconsultoria}_${row.id}`,
                                                                });
                                                                return;
                                                            }
                                                            if (row.tipo_teleconsultoria === TIPO_TELECONSULTORIA.TIPO.CC) {
                                                                let idCC = row.id.replace(/\D+/g, '');
                                                                adicionarAba({
                                                                    titulo: `${TIPO_TELECONSULTORIA.LABEL[TIPO_TELECONSULTORIA.TIPO.CC]} - ${idCC}`,
                                                                    conteudo: DetalhesCondutaClinica,
                                                                    id: idCC,
                                                                    adicionarAba,
                                                                    idAba: row.id
                                                                });
                                                            }
                                                        }}
                                                    />
                                                </SttTableCell>
                                            </SttTableRow>
                                        ))
                                )
                        }
                    </SttTableBody>
                </SttTable>
            </TableContainer>
            {
                solicitacoes.value.length > 0 &&
                <SttTablePagination
                    rowsPerPageOptions={[10, 20, 40]}
                    component="div"
                    count={totalRegistros.value}
                    rowsPerPage={count.value}
                    page={page.value}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={strings.linhasPorPagina}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${strings.de} ${count}`}
                />
            }
        </>
    )
}

export default Historico;