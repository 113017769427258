import React, { memo } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
    SttText,
    SttHeading,
} from '@stt-componentes/core';
import { SttCkEditor } from '@stt-componentes/ckeditor';

const useStylesHeading = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1.5)
    },
    textoQuebraLinha: {
        wordBreak: 'break-all',
        whiteSpace: 'pre-line',
    },
}));

const ExibicaoHtml = ({ titulo, html, resposta = false }) => {
    const classesHeading = useStylesHeading();

    return (
        <>
            {
                html?.trim() &&
                <>
                    <SttHeading variant="h4" color="primary" className={classesHeading.root}>{titulo}</SttHeading>
                    {
                        resposta
                            ?
                            <SttCkEditor
                                data={html.trim()}
                                allowImage={true}
                                allowTable={true}
                                useBase64Adapter={true}
                                isReadOnly={true}
                                maxHeight={500}
                            />
                            :
                            <SttText
                                variant="body2"
                                className={classesHeading.textoQuebraLinha}
                                style={{ wordBreak: 'break-word' }}
                                dangerouslySetInnerHTML={{ __html: html.trim() }}
                            />
                    }
                </>
            }
        </>
    )
}

export default memo(ExibicaoHtml);