import React, { useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import axios from 'axios';
import {
    SttButton,
    SttModal,
    SttTable,
    SttTableHead,
    SttTableCell,
    SttTableRow,
    SttTableBody,
    SttLoading,
    SttTranslateHook,
} from '@stt-componentes/core';
import { IconButton, Paper, TableContainer } from '@material-ui/core';
import { MYME_TYPE_ARQUIVOS } from '../../common/Constants';
import { getHeaders } from '../../request';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./custom-carousel.css";
import { Carousel } from 'react-responsive-carousel';
import AnexosRegiaoPreVisualizacao from './anexosRegiaoPreVisualizacao';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useSignal, useSignals } from '@preact/signals-react/runtime';

const useStyles = makeStyles(theme => ({
    button: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    tableWrapper: {
        marginTop: theme.spacing(2)
    },
    table: {
        overflow: 'auto'
    },
    divCentral: {
        height: '100%',
        minHeight: '400px'
    },
    carousel: {
        '& .carousel.carousel-slider .control-arrow': {
            backgroundColor: 'rgba(0, 0, 0, 0.50)'
        },
    }
}));

const openFileUrl = (response, nome) => {
    if (response.data) {
        const blob = response.data;
        const file = new File([blob], nome, { lastModified: new Date().getTime(), type: blob.type });
        const fileURL = URL.createObjectURL(file);

        if (blob.type === MYME_TYPE_ARQUIVOS.DOC || blob.type === MYME_TYPE_ARQUIVOS.DOCX || blob.type === MYME_TYPE_ARQUIVOS.XLS || blob.type === MYME_TYPE_ARQUIVOS.XLSX) {
            let a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = fileURL;
            a.download = nome;
            a.click();
            URL.revokeObjectURL(fileURL);
            a.remove();
            return;
        }
        window.open(fileURL);
    }
}

const AnexosRegiaoVisualizacao = ({ anexos, exibirAnexosRegiao }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    useSignals();

    const baixandoAnexo = useSignal(false);
    const progress = useSignal(0);

    const fecharModal = () => {
        exibirAnexosRegiao.value = false;
    }

    return (
        <SttModal
            title={strings.protocoloClinico}
            open={exibirAnexosRegiao.value}
            outModalClose={fecharModal}
            iconClose={fecharModal}
            maxWidth="xl"
            fullWidth={true}
            children={
                <>
                    <Carousel
                        showThumbs={false}
                        infiniteLoop={true}
                        showStatus={false}
                        showIndicators={anexos.length > 1}
                        className={classes.carousel}
                    >
                        {
                            anexos.map((a, index) => (
                                <div className={classes.divCentral} key={index}>
                                    <AnexosRegiaoPreVisualizacao anexo={a} />
                                </div>
                            ))
                        }
                    </Carousel>
                    <TableContainer className={classes.tableWrapper} component={Paper}>
                        <SttTable>
                            <SttTableHead>
                                <SttTableRow>
                                    <SttTableCell
                                        width="95%">
                                        {strings.nome}
                                    </SttTableCell>
                                    <SttTableCell width="5%" align="center">
                                        {strings.download}
                                    </SttTableCell>
                                </SttTableRow>
                            </SttTableHead>
                            <SttTableBody>
                                {
                                    anexos.map((anexo, index) => (
                                        <SttTableRow hover key={index}>
                                            <SttTableCell>{anexo.nome}</SttTableCell>
                                            <SttTableCell align="center">
                                                <IconButton
                                                    id={`btn-ver-solicitacao-${index}`}
                                                    onClick={() => {
                                                        baixandoAnexo.value = true;
                                                        axios.get(anexo.url, {
                                                            headers: getHeaders(),
                                                            responseType: 'blob',
                                                            onDownloadProgress: (event) => {
                                                                let pg = Math.round(
                                                                    (event.loaded * 100) / event.total
                                                                );
                                                                progress.value = pg;
                                                            },
                                                        })
                                                            .then((response) => openFileUrl(response, anexo.nome))
                                                            .catch(err => { console.log(err) })
                                                            .finally(() => {
                                                                baixandoAnexo.value = false;
                                                                progress.value = 0;
                                                            });
                                                    }}
                                                >
                                                    <GetAppIcon color="primary" />
                                                </IconButton>
                                            </SttTableCell>
                                        </SttTableRow>
                                    ))
                                }
                            </SttTableBody>
                        </SttTable>
                    </TableContainer>
                    <SttLoading
                        open={baixandoAnexo.value}
                        text={strings.carregandoAnexo}
                        progress={progress.value}
                    />
                </>
            }
            footer={
                <div>
                    <SttButton
                        type="button"
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                        onClick={fecharModal}
                    >
                        {strings.fechar}
                    </SttButton>
                </div>
            }
        />
    );
};

export default AnexosRegiaoVisualizacao;