const satisfacao = {
    MUITO_SATISFEITO: 'M',
    SATISFEITO: 'S',
    INSATISFEITO: 'I',
    OUTRO: 'O',
};

export const SATISFACAO = {
    TIPO: { ...satisfacao },
    LABEL_GERAL: {
        [satisfacao.MUITO_SATISFEITO]: 'Muito satisfeito(a)',
        [satisfacao.SATISFEITO]: 'Satisfeito(a)',
        [satisfacao.INSATISFEITO]: 'Insatisfeito(a)',
        [satisfacao.OUTRO]: 'Outro',
    },
    LABEL_ESCLARECIDA: {
        [satisfacao.MUITO_SATISFEITO]: 'Muito satisfatoriamente',
        [satisfacao.SATISFEITO]: 'Satisfatoriamente',
        [satisfacao.INSATISFEITO]: 'Insatisfatoriamente',
        [satisfacao.OUTRO]: 'Outro',
    }
};

const resolubilidade = {
    EVITOU: 'EE',
    NAO_EVITOU: 'NE',
    INDEFINIDO: 'I',
    NAO_APLICA: 'NA',
    OUTRO: 'O',
};

export const RESOLUBILIDADE = {
    TIPO: { ...resolubilidade },
    LABEL: {
        [resolubilidade.EVITOU]: 'Evitou encaminhamento',
        [resolubilidade.NAO_EVITOU]: 'Não evitou encaminhamento',
        [resolubilidade.INDEFINIDO]: 'Indefinido',
        [resolubilidade.NAO_APLICA]: 'Não se aplica ao caso',
        [resolubilidade.OUTRO]: 'Outro',
    },
};

const atendimento = {
    TOTALMENTE: 'AT',
    PARCIALMENTE: 'AP',
    NAO_ATENDEU: 'NA',
};

export const ATENDIMENTO = {
    TIPO: { ...atendimento },
    LABEL: {
        [atendimento.TOTALMENTE]: 'Atendeu totalmente',
        [atendimento.PARCIALMENTE]: 'Atendeu parcialmente',
        [atendimento.NAO_ATENDEU]: 'Não atendeu',
    },
};

const satisfacaoGeral = {
    MUITO_SATISFEITO: 'MS',
    SATISFEITO: 'S',
    INDIFERENTE: 'ID',
    INSATISFEITO: 'IN',
    MUITO_INSATISFEITO: 'MI',
};

export const SATISFACAO_GERAL = {
    TIPO: { ...satisfacaoGeral },
    LABEL: {
        [satisfacaoGeral.MUITO_SATISFEITO]: 'Muito satisfeito(a)',
        [satisfacaoGeral.SATISFEITO]: 'Satisfeito(a)',
        [satisfacaoGeral.INDIFERENTE]: 'Indiferente',
        [satisfacaoGeral.INSATISFEITO]: 'Insatisfeito(a)',
        [satisfacaoGeral.MUITO_INSATISFEITO]: 'Muito insatisfeito(a)',
    },
};

const simNaoNaoAplica = {
    SIM: 'S',
    NAO: 'N',
    NAO_APLICA: 'NA',
};

export const SIM_NAO_NAO_APLICA = {
    TIPO: { ...simNaoNaoAplica },
    LABEL: {
        [simNaoNaoAplica.SIM]: 'Sim',
        [simNaoNaoAplica.NAO]: 'Não',
        [simNaoNaoAplica.NAO_APLICA]: 'Não se aplica',
    },
};