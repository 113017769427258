import { Cache } from '@stt-componentes/cache';
import axios from 'axios';
import {
    temVinculoEstadual,
    pegarEstadosVinculoPerfil
} from "../security/rbac";

export const getHeaders = () => {
    const token = Cache.getAccessToken();
    return { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' };
};

export const getHeadersFormData = () => {
    const token = Cache.getAccessToken();
    return { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' };
};

export const listarClassificacoes = (thenHandler, catchHandler, finallyHandler) => {
    const controller = new AbortController();

    axios.get(`${global.gConfig.url_base_teleconsultorias}/classificacao`, { headers: getHeaders(), signal: controller.signal })
        .then(thenHandler)
        .catch(catchHandler)
        .finally(finallyHandler);

    return controller;
}

export const listarEspecificacoes = (params, thenHandler, catchHandler, finallyHandler) => {
    const controller = new AbortController();

    axios.get(`${global.gConfig.url_base_teleconsultorias}/especificacao/solicitacao`, { params, headers: getHeaders(), signal: controller.signal })
        .then(thenHandler)
        .catch(catchHandler)
        .finally(finallyHandler);

    return controller;
}

export const listarRegioes = (params, thenHandler, catchHandler, finallyHandler) => {
    const controller = new AbortController();

    axios.get(`${global.gConfig.url_base_teleconsultorias}/regiao`, { params, headers: getHeaders(), signal: controller.signal })
        .then(thenHandler)
        .catch(catchHandler)
        .finally(finallyHandler);

    return controller;
}

export const listarEstados = (user, thenHandler, catchHandler, finallyHandler) => {
    let complementoUrlEstado = '';
    if (temVinculoEstadual(user)) {
        let estados = pegarEstadosVinculoPerfil(user);
        complementoUrlEstado = `?ids=${estados.join(',')}`;
    }

    const controller = new AbortController();

    axios.get(`${global.gConfig.url_base_utilitarios}/localizacao/estado${complementoUrlEstado}`, { headers: getHeaders(), signal: controller.signal })
        .then(thenHandler)
        .catch(catchHandler)
        .finally(finallyHandler);

    return controller;
}

export const listarCbosFamilia = (thenHandler, catchHandler, finallyHandler) => {
    const controller = new AbortController();

    axios.get(`${global.gConfig.url_base_utilitarios}/ocupacao/cbo-familia`, { headers: getHeaders(), signal: controller.signal })
        .then(thenHandler)
        .catch(catchHandler)
        .finally(finallyHandler);

    return controller;
}

export const listarCbosOcupacao = (params, thenHandler, catchHandler, finallyHandler) => {
    const controller = new AbortController();

    axios.get(`${global.gConfig.url_base_utilitarios}/ocupacao/cbo-ocupacao`, { params, headers: getHeaders(), signal: controller.signal })
        .then(thenHandler)
        .catch(catchHandler)
        .finally(finallyHandler);

    return controller;
}