import React, { memo } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
    SttTextItem,
    SttHeading,
} from '@stt-componentes/core';

const useStylesHeading = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1.5)
    }
}));

const ExibicaoCiap2 = ({ titulo, ciap2 }) => {
    const classesHeading = useStylesHeading();

    return (
        <>
            {
                ciap2?.length > 0 &&
                <>
                    <SttHeading variant="h4" color="primary" className={classesHeading.root}>{titulo}</SttHeading>
                    {
                        ciap2.map((c, index) => (
                            <SttTextItem key={`A_${index}`}
                                title={`${c.identificador_ciap2_capitulo} - ${c.identificador}`}
                                content={c.titulo}
                            />
                        ))
                    }
                </>
            }
        </>
    )
};

export default memo(ExibicaoCiap2);