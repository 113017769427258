import * as yup from 'yup'

import dadosCabecalhoSchema from './cabecalho/form/validationSchema';
import dadosCabecalhoJudicialSchema from './cabecalho/form/validationSchemaJudicializacao';
import dadosDiagnosticoSchema from './diagnostico-principal/form/validationSchema';
import dadosResumoCasoSchema from './resumo-caso-concreto/form/validationSchema';
import dadosOutrasTecnologiasSchema from './outras-tecnologias/form/validationSchema';
import dadosEvidenciasSchema from './evidencia/form/validationSchema';
import dadosConclusaoSchema from './conclusao/form/validationSchema';
import dadosTecnologiasSchema from './tecnologias-solicitadas/form/validationSchema';
import dadosCustosSchema from './custos-tecnologias/form/validationSchema';
import dadosReferenciasSchema from './referencias/form/validationSchema';
import dadosAnexosSchema from '../anexos/form/validationSchema';
import dadosCid10Schema from './validationSchemaCid10';
import dadosSigtapSchema from './validationSchemaSigtap';
import { CLASSIFICACOES } from '../../common/Constants';

export default (strings, exibirOrientacaoEspecifica, classificacao) => {
    let schema = yup.object().shape({});

    const dadosAnexos = dadosAnexosSchema(strings);
    const dadosReferencias = dadosReferenciasSchema(strings, classificacao === CLASSIFICACOES.JUDICIALIZACAO);
    const dadosCid10 = dadosCid10Schema(strings);

    if (classificacao === CLASSIFICACOES.CIRURGIA) {
        const dadosSigtap = dadosSigtapSchema(strings);
        schema = schema.concat(dadosSigtap);
    }

    if (classificacao === CLASSIFICACOES.JUDICIALIZACAO) {
        const dadosCabecalhoJudicial = dadosCabecalhoJudicialSchema(strings);
        const dadosDiagnostico = dadosDiagnosticoSchema(strings);
        const dadosResumo = dadosResumoCasoSchema(strings);
        const dadosOutrasTecnologias = dadosOutrasTecnologiasSchema(strings);
        const dadosEvidencias = dadosEvidenciasSchema(strings);
        const dadosConclusao = dadosConclusaoSchema(strings);
        const dadosTecnologias = dadosTecnologiasSchema(strings);
        const dadosCustos = dadosCustosSchema(strings);

        return schema
            .concat(dadosCabecalhoJudicial)
            .concat(dadosDiagnostico)
            .concat(dadosResumo)
            .concat(dadosOutrasTecnologias)
            .concat(dadosEvidencias)
            .concat(dadosConclusao)
            .concat(dadosTecnologias)
            .concat(dadosCustos)
            .concat(dadosReferencias)
            .concat(dadosAnexos)
            .concat(dadosCid10);
    }

    const dadosCabecalho = dadosCabecalhoSchema(strings);

    if (exibirOrientacaoEspecifica) {
        schema = schema.concat(dadosCid10);
    }

    return schema
        .concat(dadosCabecalho)
        .concat(dadosReferencias)
        .concat(dadosAnexos);
}