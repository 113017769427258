import React, { useContext, useEffect } from 'react';
import { SttTranslateHook } from '@stt-componentes/core';
import { SttCardChart } from '@stt-componentes/charts';
import { useSignal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';
import axios from 'axios';
import { getHeaders } from '../../request';

const OrientacoesManejo = ({ idRede }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    useSignals();

    const totalManejo = useSignal();
    const porcentagemManejo = useSignal();

    useEffect(() => {
        buscarIndicadorManejo();
    }, []);

    const buscarIndicadorManejo = () => {
        axios.get(`${global.gConfig.url_base_teleconsultorias}/solicitacao/indicadores/manejo`, { params: { idRede }, headers: getHeaders() })
            .then((response) => {
                const { data } = response.data;

                if (data.length === 0) {
                    return;
                    // TODO: TRATAR TELECONSULTOR SEM RESPOSTA
                }

                totalManejo.value = parseInt(data.total_orientacao_especifica_m);

                if (totalManejo.value === 0) {
                    porcentagemManejo.value = '0%';
                    return;
                }
                porcentagemManejo.value = (parseInt(data.total_orientacao_especifica_m) / parseInt(data.total) * 100).toFixed(2) + '%';
                porcentagemManejo.value = porcentagemManejo.value.replace('.', ',')
            })
            .catch(err => {
                // TODO: BOTÃO PARA TENTAR NOVAMENTE
            });
    };


    return (
        <SttCardChart
            title={strings.orientacoesManejo}
            subtitle={totalManejo.value}
            description={strings.teleconsultoriaRecomendacaoManejo}
            showLoading={true}
            tooltip={strings.ultimos6Meses}
        >
            {porcentagemManejo.value}
        </SttCardChart>
    )
}

export default OrientacoesManejo;