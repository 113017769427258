const esferaOrgao = {
    JUSTICA_FEDERAL: 'J',
    OUTRO: 'O',
};

export const ESFERA_ORGAO_JUDICIAL = {
    TIPO: { ...esferaOrgao },
    LABEL: {
        [esferaOrgao.JUSTICA_FEDERAL]: 'Justiça Federal',
        [esferaOrgao.OUTRO]: 'Outro',
    }
}

const reus = {
    UNIAO: 'U',
    UNIAO_ESTADO: 'E',
    TODOS: 'T',
    UNIAO_MUNICIPIO: 'M',
};

export const REUS_JUDICIAL = {
    TIPO: { ...reus },
    LABEL: {
        [reus.UNIAO]: 'União',
        [reus.UNIAO_ESTADO]: 'União e estado',
        [reus.TODOS]: 'União, estado e município',
        [reus.UNIAO_MUNICIPIO]: 'União e município',
    }
}

const tipoRepresentacao = {
    PUBLICA: 'U',
    PRIVADA: 'R',
};

export const TIPO_REPRESENTACAO_JUDICIAL = {
    TIPO: { ...tipoRepresentacao },
    LABEL: {
        [tipoRepresentacao.PUBLICA]: 'Pública',
        [tipoRepresentacao.PRIVADA]: 'Privada',
    }
}

const tipoJuizado = {
    JUIZADO_ESPECIAL: 'J',
    PROCEDIMENTO_COMUM: 'P',
};

export const TIPO_JUIZADO_JUDICIAL = {
    TIPO: { ...tipoJuizado },
    LABEL: {
        [tipoJuizado.JUIZADO_ESPECIAL]: 'Juizado especial (JEF)',
        [tipoJuizado.PROCEDIMENTO_COMUM]: 'Procedimento comum',
    }
}