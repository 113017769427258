import * as yup from 'yup';
import { CLASSIFICACOES } from '../../../common/Constants';
import { JUDICIAL_TECNOLOGIAS } from './fieldNames';

export default (strings) => {
    return yup.object().shape({
        [JUDICIAL_TECNOLOGIAS]: yup
            .object()
            .test('tecnologias-validas', strings.erroSemTecnologia, function () {
                const { from } = this;

                if (!from[1].value || (from[1].value.geral?.classificacao !== CLASSIFICACOES.JUDICIALIZACAO)) {
                    return true;
                }

                const tecnologias = from[0].value;

                if (tecnologias.insumos.length === 0 &&
                    tecnologias.medicamentos.length === 0 &&
                    tecnologias.nutricoes.length === 0 &&
                    tecnologias.procedimentosCbhpm.length === 0 &&
                    tecnologias.procedimentosSigtap.length === 0) {
                    return false;
                }
                return true;
            })
    });
}