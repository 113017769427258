import React, { memo, useContext, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
    SttTextItem,
    SttHeading,
    SttDivider,
    SttTabs,
    SttTranslateHook
} from '@stt-componentes/core';
import ExibicaoHtml from './exibicaoHtml';
import { Util } from '@stt-utilitarios/core';
import { useSignal, useSignals } from '@preact/signals-react/runtime';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1.5)
    },
    divider: {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5),
    },
    tabs: {
        padding: 0
    }
}));

const AbaTecnologia = memo(({ strings, ...tecnologia }) => {
    return (
        <>
            <SttTextItem title={strings.tipoTecnologia} content={tecnologia.descricaoTipo} />
            <SttTextItem title={strings.descricao} content={tecnologia.descricao} />
            {
                tecnologia.laboratorio &&
                <SttTextItem title={strings.laboratorio} content={tecnologia.laboratorio} />
            }
            {
                tecnologia.marca_comercial &&
                <SttTextItem title={strings.marcaComercial} content={tecnologia.marca_comercial} />
            }
            <ExibicaoHtml titulo={strings.apresentacao} html={tecnologia.apresentacao} resposta={true} />
            {
                tecnologia.preco_fabrica &&
                <SttTextItem title={strings.precoFabrica} content={Util.util.adicionarMascara(tecnologia.preco_fabrica.replace(/\D/g, ''), 'R$ #.##0,00', { reverse: true })} />
            }
            {
                tecnologia.preco_maximo_governo &&
                <SttTextItem title={strings.precoMaximoGoverno} content={Util.util.adicionarMascara(tecnologia.preco_maximo_governo.replace(/\D/g, ''), 'R$ #.##0,00', { reverse: true })} />
            }
            {
                tecnologia.preco_maximo_consumidor &&
                <SttTextItem title={strings.precoMaximoConsumidor} content={Util.util.adicionarMascara(tecnologia.preco_maximo_consumidor.replace(/\D/g, ''), 'R$ #.##0,00', { reverse: true })} />
            }
            {
                tecnologia.descricaoDuracaoTratamento &&
                <SttTextItem title={strings.duracaoTratamento} content={tecnologia.descricaoDuracaoTratamento} />
            }
            {
                tecnologia.preco_tratamento &&
                <SttTextItem title={strings.precoTratamento} content={Util.util.adicionarMascara(tecnologia.preco_tratamento.replace(/\D/g, ''), 'R$ #.##0,00', { reverse: true })} />
            }
            {
                tecnologia.preco_tratamento_anual &&
                <SttTextItem title={strings.precoTratamentoAnual} content={Util.util.adicionarMascara(tecnologia.preco_tratamento_anual.replace(/\D/g, ''), 'R$ #.##0,00', { reverse: true })} />
            }
            <ExibicaoHtml titulo={strings.observacao} html={tecnologia.observacao} resposta={true} />
        </>
    )
});

const RespostaAssincronaJudicialCustosTecnologias = ({ judicializacao }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    useSignals();

    const tecnologias = useSignal([]);

    useEffect(() => {
        if (judicializacao) {
            tecnologias.value = judicializacao.tecnologias.map((t, index) => ({
                conteudo: AbaTecnologia,
                titulo: `${strings.tecnologia} ${(index + 1)}`,
                indice: index,
                permanente: true,
                strings,
                ...t
            }));
        }
    }, [judicializacao])

    return (
        tecnologias.value.length > 0 &&
        <>
            <SttDivider className={classes.divider} />
            <SttHeading variant="h4" color="primary" className={classes.root}>{strings.custosTecnologias}</SttHeading>
            <SttTabs abas={tecnologias.value}
                permanente={true}
                className={classes.tabs}
            />
            <SttDivider className={classes.divider} />
        </>
    )
}

export default memo(RespostaAssincronaJudicialCustosTecnologias);