import React, { useRef, useEffect, memo, Suspense, lazy, useContext } from "react";
import {
    SttExpansionPanel,
    SttDivider,
    SttContainer,
    SttLoading,
    SttHeading,
    SttCircularProgress,
    SttButton,
    SttTranslateHook
} from '@stt-componentes/core';
import HttpStatus from 'http-status-codes';
import { Formik } from 'formik';
import Functions from '../../common/Functions';
import { makeStyles } from "@material-ui/core/styles";
import validationSchema from '../../paginas/solicitacao/validationSchema';
import { useHistory, useLocation } from "react-router-dom";
import { CLASSIFICACOES, PERFIL, SITUACAO_TELECONSULTORIA } from '../../common/Constants';
import axios from 'axios';
import { getHeaders, getHeadersFormData } from '../../request';
import { NotificationManager } from 'react-notifications';
import { ANEXOS } from "../anexos/form/fieldNames";
import { PACIENTE } from '@stt-componentes/paciente/dist/lib/form/fieldNames';
import { CAMPOS_PACIENTE } from "./camposPaciente";
import { useSignal, useSignalEffect, useSignals } from "@preact/signals-react/runtime";
import alerta from "../../signals/alerta";
import { batch } from "@preact/signals-react";
import usuario from "../../signals/usuario";
import { temPerfilRBAC } from "../../security/rbac";
import WrapperAdendos from "./adendo";

const { SITUACAO } = SITUACAO_TELECONSULTORIA;

const DadosGerais = lazy(() => import('./geral'));
const Paciente = lazy(() => import('./paciente'));
const Judicial = lazy(() => import('./judicial'));
const JudicialTecnologias = lazy(() => import('./judicial-tecnologia'));
const SttCid10 = lazy(() => import('@stt-componentes/cid10'));
const SttCiap2 = lazy(() => import('@stt-componentes/ciap2'));
const Textual = lazy(() => import('./textual'));
const ResultadoExames = lazy(() => import('./textual/resultadoExame'));
const Anexos = lazy(() => import('../anexos'));

const useStyles = makeStyles(theme => ({
    buttonWrapper: {
        marginTop: theme.spacing(1)
    },
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    header: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    button: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    expansionPanel: {
        width: '100%'
    }
}));

const Divider = memo((props) => {
    return (
        <SttDivider {...props} />
    )
});

const camposPaciente = CAMPOS_PACIENTE;

const Solicitacao = ({ adicionarAba }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    useSignals();

    const TELECONSULTORIAS_API_BASE_URL = global.gConfig.url_base_teleconsultorias;

    const schema = validationSchema(strings, camposPaciente);
    const formRef = useRef();
    const setFieldValueRef = useRef(null);
    const timer = useRef(null);
    const history = useHistory();
    const location = useLocation();

    const idSolicitacao = location.state?.idSolicitacao || null;

    const initialValues = useSignal(null);
    const salvarAutomatico = useSignal(false);
    const podeSalvarAutomatico = useSignal(false);
    const botaoEnviarDesabilitado = useSignal(false);
    const mensagensRestricaoIdade = useSignal([]);

    useSignalEffect(() => {
        clearTimeout(timer.current);
        if (podeSalvarAutomatico.value) {
            timer.current = criarTimeout();
        }
    });

    const criarTimeout = () => {
        return setTimeout(() => {
            salvarAutomatico.value = true;
        }, 60 * 1000);
    }

    useSignalEffect(() => {
        if (salvarAutomatico.value) {
            enviarForm(formRef.current.values, true);
        }
    });

    useEffect(() => {
        if (idSolicitacao) {
            handleIniciarAlteracao(idSolicitacao);
        } else {
            handleIniciarSolicitacao();
        }
    }, [idSolicitacao]);

    const enviarForm = (dados, rascunho, setSubmitting = null) => {
        //Desativa o salvamento automático enquanto a requisição não retorna
        podeSalvarAutomatico.value = false;

        const teleFormatada = Functions.formatarDadosParaSalvar(dados);
        const tipoSubmit = {
            rascunho,
            salvarAutomatico
        }
        teleFormatada.append('tipoSubmit', JSON.stringify(tipoSubmit));

        //Faz sentido quando o usuário clica no botão "salvar rascunho" ou "enviar dúvida", mas não para o salvamento automático
        if (setSubmitting) {
            setSubmitting(true);
        }

        let tipoAlerta = '';
        let tituloAlerta = '';
        let mensagemAlerta = '';
        let options = [];
        let onClose = () => { };

        axios.post(`${TELECONSULTORIAS_API_BASE_URL}/solicitacao/${teleFormatada.get('id')}`, teleFormatada, { headers: getHeadersFormData() })
            .then((response) => {
                const { data } = response;

                //O usuário clicou no botão e o sistema retorna para a tela de pesquisa.
                if (setSubmitting) {
                    tipoAlerta = 'success';
                    tituloAlerta = strings.sucesso;
                    let msgPadrao = rascunho ? strings.rascunhoSalvoSucesso : strings.solicitacaoEnviada;
                    mensagemAlerta = data?.message || msgPadrao;
                    options = [{
                        title: strings.ok,
                        onClick: () => {
                            handleCancelarInicioSolicitacao();
                            alerta.value = {
                                ...alerta.value,
                                open: false
                            }
                        }
                    }];
                    onClose = () => {
                        handleCancelarInicioSolicitacao();
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        };
                    }
                    return;
                }

                // Atualiza os dados dos anexos inseridos
                if (data?.data?.anexosInseridos?.length > 0) {
                    data.data.anexosInseridos.forEach(inserido => {
                        setFieldValueRef.current(`${ANEXOS}[${inserido.index}]`, inserido);
                    });
                }

                // Atualiza o id do paciente (importante quando o paciente veio do CADSUS)
                if (data?.data?.idPaciente) {
                    setFieldValueRef.current(`${PACIENTE}.id`, data.data.idPaciente);
                }

                //Se é a partir de salvamento automático, notifica usuário que o salvamento ocorreu
                NotificationManager.success(strings.rascunhoSalvoSucesso);
                batch(() => {
                    podeSalvarAutomatico.value = true;
                    salvarAutomatico.value = false;
                });
            })
            .catch(err => {
                const { response } = err;
                let mensagem = strings.erro;
                let erroEncaminhadaPreviamente = false;
                if (response) {
                    const { data } = response;

                    erroEncaminhadaPreviamente = data.data?.erro === 'ERRO_TELECONSULTORIA_ENCAMINHADA';

                    mensagem = data.message;
                    if (response.status === HttpStatus.BAD_REQUEST || response.status === HttpStatus.INTERNAL_SERVER_ERROR) {
                        let arrMensagem = [];
                        data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        if (arrMensagem.length > 0) {
                            mensagem = arrMensagem.join('\n');
                        }
                    }
                }

                //O usuário clicou no botão e o sistema notifica o erro por modal
                if (setSubmitting || erroEncaminhadaPreviamente) {
                    tipoAlerta = 'error';
                    tituloAlerta = strings.erro;
                    mensagemAlerta = mensagem;

                    options = [{
                        title: strings.ok,
                        onClick: () => {
                            if (erroEncaminhadaPreviamente) {
                                handleCancelarInicioSolicitacao();
                                return;
                            }
                            alerta.value = {
                                ...alerta.value,
                                open: false
                            }
                        }
                    }];
                    onClose = () => {
                        if (erroEncaminhadaPreviamente) {
                            handleCancelarInicioSolicitacao();
                            return;
                        }
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        };
                    }
                } else {
                    NotificationManager.error(strings.erroSalvarSolicitacaoAutomaticamente);
                }

                podeSalvarAutomatico.value = true;
            })
            .finally(() => {
                salvarAutomatico.value = false;

                if (setSubmitting) {
                    setSubmitting(false);
                    alerta.value = {
                        ...alerta.value,
                        type: tipoAlerta,
                        title: tituloAlerta,
                        message: mensagemAlerta,
                        open: true,
                        options: options,
                        onClose: onClose
                    }
                }

            });
    }

    const submitForm = rascunho => (dados, { setSubmitting }) => {
        setSubmitting(false);
        salvarAutomatico.value = false;

        if (formRef.current) {
            batch(() => {
                podeSalvarAutomatico.value = false;
                alerta.value = {
                    ...alerta.value,
                    open: true,
                    title: strings.atencao,
                    type: rascunho ? 'alert' : 'confirmation',
                    message: rascunho ? strings.textoConfirmarRascunhoSolicitacao : strings.textoConfirmarEnvio,
                    options: [{
                        title: strings.confirmar,
                        onClick: () => {
                            enviarForm(dados, rascunho, setSubmitting);
                            alerta.value = {
                                ...alerta.value,
                                open: false
                            };
                        }
                    },
                    {
                        title: strings.cancelar,
                        onClick: () => {
                            podeSalvarAutomatico.value = true;
                            alerta.value = {
                                ...alerta.value,
                                open: false
                            };
                        }
                    }],
                    onClose: () => {
                        podeSalvarAutomatico.value = true;
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        };
                    }
                };
            });
        }
    }

    const onSubmit = submitForm(false);
    const onSubmitRascunho = submitForm(true);

    // Inicia uma nova solicitação
    const handleIniciarSolicitacao = () => {
        const acoes = Functions.acoes(usuario);
        initialValues.value = Functions.inicializarValoresSolicitacao({ podeVisualizarHistorico: acoes.podeVisualizarHistorico });
    }

    // Cancelou o inicio da solicitação
    const handleCancelarInicioSolicitacao = () => {
        history.replace("/respondidas");
    }

    //Alteração de processo
    const handleIniciarAlteracao = (id) => {
        axios.get(`${TELECONSULTORIAS_API_BASE_URL}/solicitacao/${id}`, { headers: getHeaders() })
            .then((response) => {
                if (response.data) {
                    let dados = response.data.data;

                    const acoes = Functions.acoes(usuario, response.data.data);

                    dados = Functions.formatarParaVisualizacao(dados);
                    dados.podeVisualizarHistorico = acoes.podeVisualizarHistorico;
                    initialValues.value = Functions.inicializarValoresSolicitacao(dados);

                    if (parseInt(dados.situacao_solicitacao) === SITUACAO.RASCUNHO) {
                        podeSalvarAutomatico.value = true;
                    } else {
                        podeSalvarAutomatico.value = false;
                    }
                }
            })
            .catch(err => {
                const { response } = err;
                const { data } = response;
                console.log(err);

                alerta.value = {
                    ...alerta.value,
                    open: true,
                    title: strings.erro,
                    type: 'error',
                    message: data?.message || strings.erroIniciarSolicitacao,
                    options: [{
                        title: strings.ok,
                        onClick: () => {
                            handleCancelarInicioSolicitacao();
                            alerta.value = {
                                ...alerta.value,
                                open: false
                            };
                        }
                    }],
                    onClose: () => {
                        handleCancelarInicioSolicitacao();
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        };
                    }
                };
            });
    }

    return (
        initialValues.value
            ?
            <Formik
                innerRef={formRef}
                initialValues={initialValues.value}
                validationSchema={schema}
                onSubmit={onSubmit}
                validateOnChange={false}
            >
                {
                    ({
                        values,
                        isSubmitting,
                        errors,
                        submitCount,
                        handleSubmit,
                        setSubmitting,
                        setFieldValue
                    }) => {
                        setFieldValueRef.current = setFieldValue;

                        return (
                            <SttContainer>
                                <form onSubmit={handleSubmit} noValidate>
                                    <SttHeading variant="h1" color="primary" align="center" className={classes.header}>{strings.solicitacaoTele}</SttHeading>
                                    {/* Dados gerais */}
                                    <SttExpansionPanel
                                        classegriditem={classes.expansionPanel}
                                        title={strings.dadosGerais}
                                        children={
                                            <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                <DadosGerais
                                                    mensagensRestricaoIdade={mensagensRestricaoIdade}
                                                    botaoEnviarDesabilitado={botaoEnviarDesabilitado}
                                                    podeSalvarAutomatico={podeSalvarAutomatico}
                                                />
                                            </Suspense>
                                        }
                                    />
                                    <Divider />
                                    {
                                        values.geral.pacienteEspecifico
                                            ?
                                            <>
                                                {/* Dados do paciente */}
                                                <SttExpansionPanel
                                                    classegriditem={classes.expansionPanel}
                                                    title={strings.dadosPaciente}
                                                    children={
                                                        <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                            <Paciente campos={camposPaciente}
                                                                mensagensRestricaoIdade={mensagensRestricaoIdade}
                                                                adicionarAba={adicionarAba}
                                                            />
                                                        </Suspense>
                                                    }
                                                />
                                                <Divider />

                                                {
                                                    values.geral.assunto &&
                                                    <>
                                                        {/* Dados do CID-10 */}
                                                        <SttExpansionPanel
                                                            classegriditem={classes.expansionPanel}
                                                            title={strings.cid10}
                                                            children={
                                                                <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                                    <SttCid10 obrigatorio={true}
                                                                        strings={strings}
                                                                        headers={getHeaders()}
                                                                        capCiap={values.geral.assunto.identificador_ciap2_capitulo}
                                                                        verificarErrosOrdemPrecedencia={Functions.verificarErrosOrdemPrecedencia}
                                                                        formExterno={{
                                                                            cid10: values.cid10,
                                                                            isSubmitting,
                                                                            errors,
                                                                            submitCount,
                                                                            setFieldValue
                                                                        }}
                                                                    />
                                                                </Suspense>
                                                            }
                                                        />
                                                        <Divider />

                                                        {
                                                            values.geral.classificacao !== CLASSIFICACOES.JUDICIALIZACAO &&
                                                            <>
                                                                {/* Dados do CIAP-2 */}
                                                                <SttExpansionPanel
                                                                    classegriditem={classes.expansionPanel}
                                                                    title={strings.ciap2}
                                                                    children={
                                                                        <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                                            <SttCiap2 obrigatorio={true}
                                                                                verificarErrosOrdemPrecedencia={Functions.verificarErrosOrdemPrecedencia}
                                                                                strings={strings}
                                                                                capCiap={values.geral.assunto.identificador_ciap2_capitulo}
                                                                                headers={getHeaders()}
                                                                                formExterno={{
                                                                                    ciap2: values.ciap2,
                                                                                    isSubmitting,
                                                                                    errors,
                                                                                    submitCount,
                                                                                    setFieldValue
                                                                                }}
                                                                            />
                                                                        </Suspense>
                                                                    }
                                                                />
                                                                <Divider />
                                                            </>
                                                        }
                                                    </>
                                                }

                                                {
                                                    values.geral.classificacao === CLASSIFICACOES.JUDICIALIZACAO ?
                                                        <>
                                                            {/* Dados judiciais */}
                                                            <SttExpansionPanel
                                                                classegriditem={classes.expansionPanel}
                                                                title={strings.dadosProcesso}
                                                                children={
                                                                    <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                                        <Judicial />
                                                                    </Suspense>
                                                                }
                                                            />
                                                            <Divider />
                                                            {/* Dados judiciais */}
                                                            <SttExpansionPanel
                                                                classegriditem={classes.expansionPanel}
                                                                title={strings.tecnologias}
                                                                children={
                                                                    <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                                        <JudicialTecnologias />
                                                                    </Suspense>
                                                                }
                                                            />
                                                            <Divider />
                                                        </>
                                                        :
                                                        <>
                                                            {/* Dados textuais */}
                                                            <SttExpansionPanel
                                                                classegriditem={classes.expansionPanel}
                                                                title={strings.relatoPaciente}
                                                                children={
                                                                    <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                                        <Textual />
                                                                    </Suspense>
                                                                }
                                                            />

                                                            <Divider />
                                                            {/* Dados resultados exame */}
                                                            <SttExpansionPanel
                                                                classegriditem={classes.expansionPanel}
                                                                title={strings.resultadoExames}
                                                                children={
                                                                    <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                                        <ResultadoExames />
                                                                    </Suspense>
                                                                }
                                                            />
                                                            <Divider />
                                                        </>
                                                }
                                            </>
                                            :
                                            <>
                                                {
                                                    values.geral.assunto &&
                                                    <>
                                                        {/* Dados do CIAP-2 */}
                                                        <SttExpansionPanel
                                                            classegriditem={classes.expansionPanel}
                                                            title={strings.ciap2}
                                                            children={
                                                                <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                                    <SttCiap2 obrigatorio={true}
                                                                        verificarErrosOrdemPrecedencia={Functions.verificarErrosOrdemPrecedencia}
                                                                        strings={strings}
                                                                        capCiap={values.geral.assunto.identificador_ciap2_capitulo}
                                                                        headers={getHeaders()}
                                                                        formExterno={{
                                                                            ciap2: values.ciap2,
                                                                            isSubmitting,
                                                                            errors,
                                                                            submitCount,
                                                                            setFieldValue
                                                                        }}
                                                                    />
                                                                </Suspense>
                                                            }
                                                        />
                                                        <Divider />
                                                    </>
                                                }
                                            </>
                                    }
                                    {
                                        values.geral.regiao &&
                                        <>
                                            {
                                                values.geral.regiao.adendo_formulario &&
                                                <WrapperAdendos />
                                            }

                                            {/* Dados de anexos */}
                                            <SttExpansionPanel
                                                classegriditem={classes.expansionPanel}
                                                title={strings.anexos}
                                                children={
                                                    <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                        <Anexos verificarErrosOrdemPrecedencia={Functions.verificarErrosOrdemPrecedencia} />
                                                    </Suspense>
                                                }
                                            />
                                            <Divider />
                                        </>
                                    }
                                    {
                                        values.geral.id &&
                                        <div className={classes.buttonWrapper}>
                                            {/* Por solicitação do núcleo de Telessaúde (tarefa #5042), adminGeral e adminTelessaude podem preenchar as teleconsultorias, mas não podem confirmar o envio (apenas rascunhos). */}
                                            <SttButton
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                nomarginleft="true"
                                                className={classes.button}
                                                disabled={isSubmitting || botaoEnviarDesabilitado.value || temPerfilRBAC(usuario, [PERFIL.ADMINISTRADOR, PERFIL.ADMINISTRADOR_TELE])}
                                            >
                                                {strings.enviarDuvida}
                                            </SttButton>
                                            {
                                                parseInt(values.geral.situacao) === SITUACAO.RASCUNHO &&
                                                <SttButton
                                                    type="button"
                                                    variant="outlined"
                                                    color="primary"
                                                    className={classes.button}
                                                    disabled={isSubmitting}
                                                    onClick={() => onSubmitRascunho(values, { setSubmitting })}
                                                >
                                                    {strings.salvarRascunho}
                                                </SttButton>
                                            }
                                        </div>
                                    }
                                </form>
                                <SttLoading
                                    open={isSubmitting}
                                    text={strings.salvandoSolicitacao}
                                />
                            </SttContainer>
                        )
                    }
                }
            </Formik>
            :
            <div className={classes.carregando}>
                <SttCircularProgress color="primary" />
            </div>

    );
}

export default memo(Solicitacao);