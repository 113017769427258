import React, { memo, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import HttpStatus from 'http-status-codes';
import axios from 'axios';
import { getHeaders } from '../../../request';
import {
    SttLoading,
    SttGrid,
    SttButton,
    SttModal,
    SttFormControl,
    SttRadioGroup,
    SttFormControlLabel,
    SttRadioButton,
    SttFormLabel,
    SttInput,
    SttFormHelperText,
    SttTranslateHook
} from '@stt-componentes/core';
import * as yup from 'yup';
import {
    Formik,
    FastField,
} from 'formik';
import {
    ATENDIMENTO,
    SATISFACAO_GERAL,
    SIM_NAO_NAO_APLICA
} from '../../../common/ConstantsAvaliacao';
import { CLASSIFICACOES } from '../../../common/Constants';
import alerta from '../../../signals/alerta';
import { useSignals } from '@preact/signals-react/runtime';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    form: {
        marginTop: theme.spacing(1.5)
    }
}));

const validationSchema = (strings) => {
    return yup.object().shape({
        'resolveuDuvida': yup
            .string()
            .required(strings.campoObrigatorio),
        'sugestaoCritica': yup
            .string()
            .trim()
            .nullable(),
    });
}

const initialValues = {
    resolveuDuvida: '',
    satisfacaoGeral: '',
    evitouEncaminhamento: '',
    sugeriuEncaminhamento: '',
    intencaoEncaminhar: '',
    sugestaoCritica: ''
};

const Avaliacao = ({ idSegundaOpiniao, idTeleconsultoria, idSolicitacao, callback, exibirAvaliacao, classificacao }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    useSignals();

    const schema = validationSchema(strings);

    const fecharAvaliacao = () => {
        exibirAvaliacao.value = false;
    }

    const enviarForm = (dados, setSubmitting) => {
        setSubmitting(true);

        dados.idSegundaOpiniao = idSegundaOpiniao;
        dados.idTeleconsultoria = idTeleconsultoria;
        dados.idSolicitacao = idSolicitacao;
        dados.sugestaoCritica = dados.sugestaoCritica.trim();

        let tipoAlerta = '';
        let tituloAlerta = '';
        let mensagemAlerta = '';
        let options = [];
        let onClose = () => { };

        axios.post(`${global.gConfig.url_base_teleconsultorias}/avaliacao`, dados, { headers: getHeaders() })
            .then((response) => {
                const { data } = response;

                tipoAlerta = 'success';
                tituloAlerta = strings.sucesso;
                mensagemAlerta = data?.message ? data.message : strings.teleconsultoriaAvaliada;
                options = [{
                    title: strings.ok,
                    onClick: () => {
                        callback();
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        }
                    }
                }];
                onClose = () => {
                    callback();
                    alerta.value = {
                        ...alerta.value,
                        open: false
                    };
                }

            })
            .catch(err => {
                const { response } = err;
                tipoAlerta = 'error';
                tituloAlerta = strings.erro;
                mensagemAlerta = strings.erroGenerico;

                if (response) {
                    const { data } = response;

                    mensagemAlerta = data.message;
                    if (data.data?.erro === 'ERRO_TELECONSULTORIA_JA_AVALIADA') {
                        mensagemAlerta = data.message;
                    } if (response.status === HttpStatus.BAD_REQUEST || response.status === HttpStatus.INTERNAL_SERVER_ERROR) {
                        let arrMensagem = [];
                        data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        if (arrMensagem.length > 0) {
                            mensagemAlerta = arrMensagem.join('\n');
                        }
                    }
                }

                options = [{
                    title: strings.ok,
                    onClick: () => {
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        }
                    }
                }];
                onClose = () => {
                    alerta.value = {
                        ...alerta.value,
                        open: false
                    };
                }
            })
            .finally(() => {
                setSubmitting(false);
                alerta.value = {
                    ...alerta.value,
                    type: tipoAlerta,
                    title: tituloAlerta,
                    message: mensagemAlerta,
                    open: true,
                    options: options,
                    onClose: onClose
                }
            });
    }

    return (
        <SttModal
            title={strings.avaliar}
            open={exibirAvaliacao.value}
            outModalClose={fecharAvaliacao}
            iconClose={fecharAvaliacao}
            maxWidth="lg"
            fullWidth={true}
            children={
                <Formik
                    initialValues={initialValues}
                    validationSchema={schema}
                    onSubmit={(dados, { setSubmitting }) => {
                        setSubmitting(false);
                        alerta.value = {
                            ...alerta.value,
                            open: true,
                            title: strings.atencao,
                            type: 'alert',
                            message: strings.confirmarAvaliacao,
                            options: [{
                                title: strings.sim,
                                onClick: () => {
                                    enviarForm(dados, setSubmitting)
                                    alerta.value = {
                                        ...alerta.value,
                                        open: false
                                    };
                                }
                            },
                            {
                                title: strings.nao,
                                onClick: () => {
                                    alerta.value = {
                                        ...alerta.value,
                                        open: false
                                    };
                                }
                            }],
                            onClose: () => {
                                alerta.value = {
                                    ...alerta.value,
                                    open: false
                                };
                            }
                        };
                    }}
                >
                    {
                        ({
                            isSubmitting,
                            handleSubmit,
                            resetForm,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit} noValidate className={classes.form}>
                                    <SttGrid container spacing={1}>
                                        <SttGrid item xs={12}>
                                            <FastField name='resolveuDuvida'>
                                                {({
                                                    field,
                                                    meta
                                                }) => (
                                                    <SttFormControl variant="outlined">
                                                        <SttFormLabel required component="legend" error={(meta.touched && meta.error) ? true : false}>{strings.resolveuDuvida}</SttFormLabel>
                                                        <SttRadioGroup row>
                                                            {
                                                                Object.entries(ATENDIMENTO.TIPO).map(([key, value], index) => (
                                                                    <SttFormControlLabel
                                                                        {...field}
                                                                        key={index}
                                                                        control={
                                                                            <SttRadioButton
                                                                                type="radio"
                                                                                value={value}
                                                                                color="primary"
                                                                                checked={field.value === value}
                                                                            />
                                                                        }
                                                                        label={ATENDIMENTO.LABEL[value]}
                                                                    />
                                                                ))
                                                            }
                                                        </SttRadioGroup>
                                                        <SttFormHelperText error={true}>{meta.touched && meta.error ? meta.error : undefined}</SttFormHelperText>
                                                    </SttFormControl>
                                                )}
                                            </FastField>
                                        </SttGrid>
                                        <SttGrid item xs={12}>
                                            <FastField name='satisfacaoGeral'>
                                                {({
                                                    field,
                                                }) => (
                                                    <SttFormControl variant="outlined">
                                                        <SttFormLabel component="legend">{strings.grauSatisfacaoPergunta}</SttFormLabel>
                                                        <SttRadioGroup row>
                                                            {
                                                                Object.entries(SATISFACAO_GERAL.TIPO).map(([key, value], index) => (
                                                                    <SttFormControlLabel
                                                                        {...field}
                                                                        key={index}
                                                                        control={
                                                                            <SttRadioButton
                                                                                type="radio"
                                                                                value={value}
                                                                                color="primary"
                                                                                checked={field.value === value}
                                                                            />
                                                                        }
                                                                        label={SATISFACAO_GERAL.LABEL[value]}
                                                                    />
                                                                ))
                                                            }
                                                        </SttRadioGroup>
                                                    </SttFormControl>
                                                )}
                                            </FastField>
                                        </SttGrid>
                                        {
                                            classificacao !== CLASSIFICACOES.PROCESSO_TRABALHO &&
                                            <>
                                                <SttGrid item xs={12}>
                                                    <FastField name='evitouEncaminhamento'>
                                                        {({
                                                            field,
                                                        }) => (
                                                            <SttFormControl variant="outlined">
                                                                <SttFormLabel component="legend">{strings.evitouEncaminhamentoPergunta}</SttFormLabel>
                                                                <SttRadioGroup row>
                                                                    {
                                                                        Object.entries(SIM_NAO_NAO_APLICA.TIPO).map(([key, value], index) => (
                                                                            <SttFormControlLabel
                                                                                {...field}
                                                                                key={index}
                                                                                control={
                                                                                    <SttRadioButton
                                                                                        type="radio"
                                                                                        value={value}
                                                                                        color="primary"
                                                                                        checked={field.value === value}
                                                                                    />
                                                                                }
                                                                                label={SIM_NAO_NAO_APLICA.LABEL[value]}
                                                                            />
                                                                        ))
                                                                    }
                                                                </SttRadioGroup>
                                                            </SttFormControl>
                                                        )}
                                                    </FastField>
                                                </SttGrid>
                                                <SttGrid item xs={12}>
                                                    <FastField name='sugeriuEncaminhamento'>
                                                        {({
                                                            field,
                                                        }) => (
                                                            <SttFormControl variant="outlined">
                                                                <SttFormLabel component="legend">{strings.sugeriuEncaminhamentoPergunta}</SttFormLabel>
                                                                <SttRadioGroup row>
                                                                    {
                                                                        Object.entries(SIM_NAO_NAO_APLICA.TIPO).map(([key, value], index) => (
                                                                            <SttFormControlLabel
                                                                                {...field}
                                                                                key={index}
                                                                                control={
                                                                                    <SttRadioButton
                                                                                        type="radio"
                                                                                        value={value}
                                                                                        color="primary"
                                                                                        checked={field.value === value}
                                                                                    />
                                                                                }
                                                                                label={SIM_NAO_NAO_APLICA.LABEL[value]}
                                                                            />
                                                                        ))
                                                                    }
                                                                </SttRadioGroup>
                                                            </SttFormControl>
                                                        )}
                                                    </FastField>
                                                </SttGrid>
                                                <SttGrid item xs={12}>
                                                    <FastField name='intencaoEncaminhar'>
                                                        {({
                                                            field,
                                                        }) => (
                                                            <SttFormControl variant="outlined">
                                                                <SttFormLabel component="legend">{strings.intencaoEncaminharPergunta}</SttFormLabel>
                                                                <SttRadioGroup row>
                                                                    {
                                                                        Object.entries(SIM_NAO_NAO_APLICA.TIPO).map(([key, value], index) => (
                                                                            <SttFormControlLabel
                                                                                {...field}
                                                                                key={index}
                                                                                control={
                                                                                    <SttRadioButton
                                                                                        type="radio"
                                                                                        value={value}
                                                                                        color="primary"
                                                                                        checked={field.value === value}
                                                                                    />
                                                                                }
                                                                                label={SIM_NAO_NAO_APLICA.LABEL[value]}
                                                                            />
                                                                        ))
                                                                    }
                                                                </SttRadioGroup>
                                                            </SttFormControl>
                                                        )}
                                                    </FastField>
                                                </SttGrid>
                                            </>
                                        }
                                        <SttGrid item xs={12}>
                                            <FastField name='sugestaoCritica'>
                                                {({
                                                    field
                                                }) => (
                                                    <SttInput
                                                        {...field}
                                                        multiline
                                                        minRows={6}
                                                        label={strings.sugestaoCritica}
                                                    />
                                                )}
                                            </FastField>
                                        </SttGrid>
                                        <SttLoading
                                            open={isSubmitting}
                                            text={strings.salvando}
                                        />
                                    </SttGrid>
                                    <SttGrid container spacing={1}>
                                        <SttGrid item xs={12}>
                                            <SttButton
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                nomarginleft="true"
                                                className={classes.button}
                                                disabled={isSubmitting}
                                            >
                                                {strings.confirmar}
                                            </SttButton>
                                            <SttButton
                                                type="button"
                                                variant="outlined"
                                                color="primary"
                                                disabled={isSubmitting}
                                                className={classes.button}
                                                onClick={() => {
                                                    resetForm({ values: initialValues });
                                                }}
                                            >
                                                {strings.limpar}
                                            </SttButton>
                                            <SttButton
                                                type="button"
                                                variant="outlined"
                                                color="primary"
                                                disabled={isSubmitting}
                                                className={classes.button}
                                                onClick={() => {
                                                    resetForm({ values: initialValues });
                                                    fecharAvaliacao();
                                                }}
                                            >
                                                {strings.fechar}
                                            </SttButton>
                                        </SttGrid>
                                    </SttGrid>
                                </form>
                            )
                        }
                    }
                </Formik>
            }
        />
    );
};

export default memo(Avaliacao);