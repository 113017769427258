import React, { useEffect, useRef, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
    Formik,
    FastField,
    Field,
} from 'formik';
import axios from 'axios';
import { getHeaders, listarCbosFamilia, listarEstados } from '../../request';
import moment from 'moment';
import validationSchema from './validationSchema';
import { estadoInicialForm } from './estadoInicialForm';
import {
    SttGrid,
    SttDatePicker,
    SttInput,
    SttNumberInput,
    SttMaskedInput,
    SttAutocomplete,
    SttButton,
    SttCard,
    SttCardContent,
    SttCardHeader,
    SttTranslateHook
} from '@stt-componentes/core';
import {
    NUMERO,
    DATA_DE_SOLICITACAO,
    DATA_ATE_SOLICITACAO,
    DATA_DE_RESPOSTA,
    DATA_ATE_RESPOSTA,
    NOME_PACIENTE,
    CPF_PACIENTE,
    CNS_PACIENTE,
    NOME_SOLICITANTE,
    CPF_SOLICITANTE,
    UF,
    CIDADE,
    CBO,
    NOME_TELECONSULTOR,
    ASSUNTO,
    SITUACAO,
    TIPO,
    REGIAO
} from './fieldNames';
import {
    SITUACAO_TELECONSULTORIA,
    PERFIL,
    TIPO_TELECONSULTORIA,
    PERMISSOES,
    CODIGOS_CBO_MEDICO
} from '../../common/Constants';
import { temPermissaoRBAC, temPerfilRBAC } from '../../security/rbac';
import { useSignal, useSignalEffect, useSignals } from '@preact/signals-react/runtime';
import { filtros, listar, resetFiltros } from '../../signals/pesquisa';
import { signal } from '@preact/signals-react';
import usuario from '../../signals/usuario';

const { AGUARDANDO_LIBERACAO, EM_CORRECAO } = SITUACAO_TELECONSULTORIA.SITUACAO;

const useStyles = makeStyles(theme => ({
    buttonWrapper: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(0.5)
    },
}));

const ufs = signal([]);
const cidades = signal([]);
const cbos = signal([]);
const regioes = signal([]);

const sit = Object.entries(SITUACAO_TELECONSULTORIA.LABEL).map((e) => ({
    id: parseInt(e[0]),
    descricao: e[1]
}));
let sitFiltrada = sit.filter(s => {
    if (s.id !== AGUARDANDO_LIBERACAO && s.id !== EM_CORRECAO) {
        return true;
    }
    return false;
})
sitFiltrada = sitFiltrada.sort((a, b) => {
    if (a.descricao < b.descricao) return -1;
    if (a.descricao > b.descricao) return 1;
    return;
});
const situacoes = signal(sitFiltrada);

let tiposTele = Object.entries(TIPO_TELECONSULTORIA.LABEL).map((e) => ({
    id: e[0],
    descricao: e[1]
}));
tiposTele = tiposTele.sort((a, b) => {
    if (a.descricao < b.descricao) return -1;
    if (a.descricao > b.descricao) return 1;
    return;
});
const tipos = signal(tiposTele);

const Form = (props) => {
    const {
        buscaEmAndamento,
        callbackBusca,
        count,
        order,
        orderBy,
        page,
        resetPageOrder,
    } = props;

    useSignals();

    const { strings } = useContext(SttTranslateHook.I18nContext);
    const schema = validationSchema(strings);

    const formRef = useRef();
    const classes = useStyles();
    const abortPesquisa = useSignal();
    const pesquisaAbortada = useSignal(false);

    useEffect(() => {
        listarEstados(
            usuario,
            (response) => {
                if (response.data) {
                    const { itens } = response.data.data;
                    ufs.value = itens;
                }
            },
            (err) => {
                console.log(err);
                ufs.value = [];
            },
            () => { }
        );

        axios.get(`${global.gConfig.url_base_teleconsultorias}/regiao/regulacao`, { headers: getHeaders() })
            .then((response) => {
                const { data } = response.data;
                if (data) {
                    regioes.value = data;
                    return;
                }
                regioes.value = [];
            })
            .catch(err => {
                console.log(err)
                regioes.value = [];
            });

        if (temPermissaoRBAC(usuario, PERMISSOES.REGULACAO)) {
            listarCbosFamilia(
                (response) => {
                    if (response.data) {
                        const { itens } = response.data.data;
                        cbos.value = itens;
                        return;
                    }
                    cbos.value = [];
                },
                err => {
                    console.log(err);
                    cbos.value = [];
                },
                () => { }
            );
        }
    }, []);

    useSignalEffect(() => {
        if (listar.value && formRef.current) {
            formRef.current.handleSubmit();
        }
    });

    const handleChangeUf = (uf) => {
        cidades.value = [];
        if (uf) {
            axios.get(`${global.gConfig.url_base_utilitarios}/localizacao/cidade?id-estado=${uf.id}`)
                .then((response) => {
                    if (response.data) {
                        const { itens } = response.data.data;
                        cidades.value = itens;
                    } else {
                        cidades.value = [];
                    }
                })
                .catch(err => {
                    cidades.value = [];
                    console.log(err)
                });
        }
    }

    return (
        <Formik
            innerRef={formRef}
            initialValues={filtros.value}
            validationSchema={schema}
            validateOnChange={false}
            onSubmit={(data, { setSubmitting }) => {
                // Redux dispatcher
                filtros.value = data;

                setSubmitting(false);
                buscaEmAndamento.value = true;

                let params = {};
                if (data.numero.trim()) {
                    params.numero = data.numero.trim();
                }
                if (data.deSolicitacao) {
                    params.deSolicitacao = moment(data.deSolicitacao).format('YYYY-MM-DD 00:00:00');
                }
                if (data.ateSolicitacao) {
                    params.ateSolicitacao = moment(data.ateSolicitacao).format('YYYY-MM-DD 23:59:59');
                }
                if (data.deResposta) {
                    params.deResposta = moment(data.deResposta).format('YYYY-MM-DD 00:00:00');
                }
                if (data.ateResposta) {
                    params.ateResposta = moment(data.ateResposta).format('YYYY-MM-DD 23:59:59');
                }
                if (data.nomePaciente.trim()) {
                    params.nomePaciente = data.nomePaciente.trim();
                }
                if (data.cpfPaciente) {
                    params.cpfPaciente = data.cpfPaciente.replace(/\D+/g, '');
                }
                if (data.cnsPaciente) {
                    params.cnsPaciente = data.cnsPaciente.replace(/\D+/g, '');
                }
                if (data.nomeSolicitante.trim()) {
                    params.nomeSolicitante = data.nomeSolicitante.trim();
                }
                if (data.cpfSolicitante) {
                    params.cpfSolicitante = data.cpfSolicitante.replace(/\D+/g, '');
                }
                if (data.uf) {
                    params.uf = data.uf.id;
                }
                if (data.cidade) {
                    params.cidade = data.cidade.id;
                }
                if (data.cbo) {
                    params.cbo = data.cbo.id;
                }
                if (data.nomeTeleconsultor.trim()) {
                    params.nomeTeleconsultor = data.nomeTeleconsultor.trim();
                }
                if (data.assunto.trim()) {
                    params.assunto = data.assunto.trim();
                }
                if (data.situacao) {
                    params.situacao = data.situacao.id;
                }
                if (data.tipo) {
                    params.tipo = data.tipo.id;
                }
                if (data.regiao) {
                    params.regiao = data.regiao.id_cadastro_regiao_autorregulacao;
                }

                if (orderBy.value && order.value) {
                    params.sort = orderBy.value;
                    params.direction = order.value;
                }

                const offset = (page.value * count.value);
                params.start = offset;
                params.count = count.value;

                if (abortPesquisa.value) {
                    abortPesquisa.value.abort();
                    pesquisaAbortada.value = true;
                }
                abortPesquisa.value = new AbortController();
                axios.get(`${global.gConfig.url_base_teleconsultorias}/solicitacao`, { params, headers: getHeaders(), signal: abortPesquisa.value.signal })
                    .then((response) => {
                        const { data } = response.data;
                        pesquisaAbortada.value = false;
                        callbackBusca(data);
                    })
                    .catch(err => {
                        if (err.code === 'ERR_CANCELED' || pesquisaAbortada.value) return;

                        pesquisaAbortada.value = false;
                        callbackBusca({
                            totalRegistros: 0,
                            itens: []
                        });
                    })
                    .finally(() => {
                        if (pesquisaAbortada.value) return;

                        setSubmitting(false);
                        abortPesquisa.value = null;
                        buscaEmAndamento.value = false;
                    });
            }}
        >
            {
                ({
                    isSubmitting,
                    handleSubmit,
                    resetForm,
                }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate>
                            <SttGrid container spacing={1}>
                                <SttGrid item xs={12} sm={12} md={9}>
                                    <SttCard variant="outlined" >
                                        <SttCardHeader subheader={strings.solicitacao} />
                                        <SttCardContent>
                                            <SttGrid container spacing={1}>
                                                <SttGrid item xs={12} sm={4} md={2} lg={2}>
                                                    <FastField name={NUMERO}>
                                                        {({
                                                            field
                                                        }) => (
                                                            <SttNumberInput
                                                                inputProps={{
                                                                    maxLength: 8,
                                                                }}
                                                                {...field}
                                                                label={strings.numero}
                                                            />
                                                        )}
                                                    </FastField>
                                                </SttGrid>
                                                <SttGrid item xs={12} sm={4} md={2} lg={2}>
                                                    <FastField name={DATA_DE_SOLICITACAO}>
                                                        {({
                                                            field: { name, value, },
                                                            form: { setFieldValue, setFieldError, setFieldTouched },
                                                            meta
                                                        }) => {
                                                            return (
                                                                <SttDatePicker
                                                                    label={strings.dataInicial}
                                                                    inputprops={{
                                                                        name: name
                                                                    }}
                                                                    disableFuture
                                                                    maxDate={new Date()}
                                                                    maxDateMessage={strings.erroDataFutura}
                                                                    error={meta.touched && meta.error ? true : false}
                                                                    onError={error => {
                                                                        setFieldError(DATA_DE_SOLICITACAO, meta.error || error);
                                                                    }}
                                                                    value={value}
                                                                    onBlur={() => {
                                                                        setFieldTouched(DATA_DE_SOLICITACAO, true);
                                                                    }}
                                                                    helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                    onChange={date => setFieldValue(DATA_DE_SOLICITACAO, date, true)}
                                                                    onClose={() => setFieldTouched(DATA_DE_SOLICITACAO, true)}
                                                                />
                                                            );
                                                        }}
                                                    </FastField>
                                                </SttGrid>
                                                <SttGrid item xs={12} sm={4} md={2} lg={2}>
                                                    <FastField name={DATA_ATE_SOLICITACAO}>
                                                        {({
                                                            field: { name, value, },
                                                            form: { setFieldValue, setFieldError, setFieldTouched },
                                                            meta
                                                        }) => {
                                                            return (
                                                                <SttDatePicker
                                                                    label={strings.dataFinal}
                                                                    inputprops={{
                                                                        name: name
                                                                    }}
                                                                    disableFuture
                                                                    maxDate={new Date()}
                                                                    maxDateMessage={strings.erroDataFutura}
                                                                    error={meta.touched && meta.error ? true : false}
                                                                    onError={error => {
                                                                        setFieldError(DATA_ATE_SOLICITACAO, meta.error || error);
                                                                    }}
                                                                    value={value}
                                                                    onBlur={() => {
                                                                        setFieldTouched(DATA_ATE_SOLICITACAO, true);
                                                                    }}
                                                                    helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                    onChange={date => setFieldValue(DATA_ATE_SOLICITACAO, date, true)}
                                                                    onClose={() => setFieldTouched(DATA_ATE_SOLICITACAO, true)}
                                                                />
                                                            );
                                                        }}
                                                    </FastField>
                                                </SttGrid>
                                                <SttGrid item xs={12} sm={4} md={2} lg={2}>
                                                    <Field name={UF}>
                                                        {({
                                                            field: { name, value, onBlur },
                                                            form: { setFieldValue }
                                                        }) => (
                                                            <SttAutocomplete
                                                                inputprops={{
                                                                    name: name,
                                                                    label: strings.uf
                                                                }}
                                                                getOptionLabel={option => (option?.sigla || '')}
                                                                getOptionSelected={(option, val) => option?.id === val?.id}
                                                                options={ufs.value}
                                                                value={value}
                                                                onBlur={onBlur}
                                                                onChange={(e, item) => {
                                                                    const uf = item || null;
                                                                    setFieldValue(UF, uf);
                                                                    setFieldValue(CIDADE, null);
                                                                    handleChangeUf(uf);
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                </SttGrid>
                                                <SttGrid item xs={12} sm={8} md={4} lg={4}>
                                                    <Field name={CIDADE}>
                                                        {({
                                                            field: { name, value, onBlur },
                                                            form: { setFieldValue, values }
                                                        }) => (
                                                            <SttAutocomplete
                                                                inputprops={{
                                                                    name: name,
                                                                    label: strings.cidade
                                                                }}
                                                                disabled={!values.uf}
                                                                getOptionLabel={option => (option?.nome) || ''}
                                                                getOptionSelected={(option, val) => option?.id === val?.id}
                                                                options={cidades.value}
                                                                value={value}
                                                                onBlur={onBlur}
                                                                onChange={(e, item) => {
                                                                    const cidade = item || null;
                                                                    setFieldValue(CIDADE, cidade);
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                </SttGrid>
                                            </SttGrid>
                                        </SttCardContent>
                                    </SttCard>
                                </SttGrid>
                                <SttGrid item xs={12} sm={12} md={3}>
                                    <SttCard variant="outlined">
                                        <SttCardHeader subheader={strings.resposta} />
                                        <SttCardContent>
                                            <SttGrid container spacing={1}>
                                                <SttGrid item xs={12} sm={6} md={6} >
                                                    <FastField name={DATA_DE_RESPOSTA}>
                                                        {({
                                                            field: { name, value, },
                                                            form: { setFieldValue, setFieldError, setFieldTouched },
                                                            meta
                                                        }) => {
                                                            return (
                                                                <SttDatePicker
                                                                    label={strings.dataInicial}
                                                                    inputprops={{
                                                                        name: name
                                                                    }}
                                                                    disableFuture
                                                                    maxDate={new Date()}
                                                                    maxDateMessage={strings.erroDataFutura}
                                                                    error={meta.touched && meta.error ? true : false}
                                                                    onError={error => {
                                                                        setFieldError(DATA_DE_RESPOSTA, meta.error || error);
                                                                    }}
                                                                    value={value}
                                                                    onBlur={() => {
                                                                        setFieldTouched(DATA_DE_RESPOSTA, true);
                                                                    }}
                                                                    helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                    onChange={date => setFieldValue(DATA_DE_RESPOSTA, date, true)}
                                                                    onClose={() => setFieldTouched(DATA_DE_RESPOSTA, true)}
                                                                />
                                                            );
                                                        }}
                                                    </FastField>
                                                </SttGrid>
                                                <SttGrid item xs={12} sm={6} md={6}>
                                                    <FastField name={DATA_ATE_RESPOSTA}>
                                                        {({
                                                            field: { name, value, },
                                                            form: { setFieldValue, setFieldError, setFieldTouched },
                                                            meta
                                                        }) => {
                                                            return (
                                                                <SttDatePicker
                                                                    label={strings.dataFinal}
                                                                    inputprops={{
                                                                        name: name
                                                                    }}
                                                                    disableFuture
                                                                    maxDate={new Date()}
                                                                    maxDateMessage={strings.erroDataFutura}
                                                                    error={meta.touched && meta.error ? true : false}
                                                                    onError={error => {
                                                                        setFieldError(DATA_ATE_RESPOSTA, meta.error || error);
                                                                    }}
                                                                    value={value}
                                                                    onBlur={() => {
                                                                        setFieldTouched(DATA_ATE_RESPOSTA, true);
                                                                    }}
                                                                    helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                    onChange={date => setFieldValue(DATA_ATE_RESPOSTA, date, true)}
                                                                    onClose={() => setFieldTouched(DATA_ATE_RESPOSTA, true)}
                                                                />
                                                            );
                                                        }}
                                                    </FastField>
                                                </SttGrid>
                                            </SttGrid>
                                        </SttCardContent>
                                    </SttCard>
                                </SttGrid>
                                <SttGrid item xs={12} sm={12} md={4}>
                                    <SttCard variant="outlined">
                                        <SttCardHeader subheader={strings.solicitante} />
                                        <SttCardContent>
                                            <SttGrid container spacing={1}>
                                                <SttGrid item xs={12} sm={12} md>
                                                    <FastField name={NOME_SOLICITANTE}>
                                                        {({
                                                            field
                                                        }) => (
                                                            <SttInput
                                                                {...field}
                                                                onInput={(e) => e.target.value = ("" + e.target.value).toUpperCase()}
                                                                label={strings.nome}
                                                            />
                                                        )}
                                                    </FastField>
                                                </SttGrid>
                                                <SttGrid item xs={12} sm md>
                                                    <FastField name={CPF_SOLICITANTE}>
                                                        {({
                                                            field,
                                                            meta
                                                        }) => (
                                                            <SttMaskedInput
                                                                {...field}
                                                                mask="cpf"
                                                                label={strings.cpf}
                                                                error={meta.touched && meta.error ? true : false}
                                                                helperText={meta.touched && meta.error ? meta.error : undefined}
                                                            />
                                                        )}
                                                    </FastField>
                                                </SttGrid>
                                                {
                                                    temPermissaoRBAC(usuario, PERMISSOES.REGULACAO) &&
                                                    <SttGrid item xs={12} sm md>
                                                        <Field name={CBO}>
                                                            {({
                                                                field: { name, value, onBlur },
                                                                form: { setFieldValue }
                                                            }) => (
                                                                <SttAutocomplete
                                                                    inputprops={{
                                                                        name: name,
                                                                        label: strings.cbo
                                                                    }}
                                                                    getOptionLabel={option => (option?.descritor) || ''}
                                                                    getOptionSelected={(option, val) => option?.id === val?.id}
                                                                    options={cbos.value}
                                                                    value={value}
                                                                    onBlur={onBlur}
                                                                    onChange={(e, item) => {
                                                                        const cbo = item || null;
                                                                        setFieldValue(CBO, cbo);
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                    </SttGrid>
                                                }
                                            </SttGrid>
                                        </SttCardContent>
                                    </SttCard>
                                </SttGrid>
                                <SttGrid item xs={12} sm={12} md={8}>
                                    <SttCard variant="outlined">
                                        <SttCardHeader subheader={strings.teleconsultoria} />
                                        <SttCardContent>
                                            <SttGrid container spacing={1}>
                                                <SttGrid item xs={12} sm={12} md={2} lg>
                                                    <FastField name={NOME_TELECONSULTOR}>
                                                        {({
                                                            field
                                                        }) => (
                                                            <SttInput
                                                                {...field}
                                                                onInput={(e) => e.target.value = ("" + e.target.value).toUpperCase()}
                                                                label={strings.nomeTeleconsultor}
                                                            />
                                                        )}
                                                    </FastField>
                                                </SttGrid>
                                                <SttGrid item xs={12} sm={12} md={2} lg={2}>
                                                    <FastField name={ASSUNTO}>
                                                        {({
                                                            field
                                                        }) => (
                                                            <SttInput
                                                                {...field}
                                                                label={strings.assunto}
                                                            />
                                                        )}
                                                    </FastField>
                                                </SttGrid>
                                                <SttGrid item xs={12} sm={6} md={2} lg={2}>
                                                    <Field name={TIPO}>
                                                        {({
                                                            field: { name, value, onBlur },
                                                            form: { setFieldValue }
                                                        }) => (
                                                            <SttAutocomplete
                                                                inputprops={{
                                                                    name: name,
                                                                    label: strings.tipo
                                                                }}
                                                                getOptionLabel={option => (option?.descricao) || ''}
                                                                getOptionSelected={(option, val) => option?.id === val?.id}
                                                                options={tipos.value}
                                                                value={value}
                                                                onBlur={onBlur}
                                                                onChange={(e, item) => {
                                                                    const tipo = item || null;
                                                                    setFieldValue(TIPO, tipo);
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                </SttGrid>
                                                <SttGrid item xs={12} sm={6} md={3} lg={3}>
                                                    <Field name={SITUACAO}>
                                                        {({
                                                            field: { name, value, onBlur },
                                                            form: { setFieldValue }
                                                        }) => (
                                                            <SttAutocomplete
                                                                inputprops={{
                                                                    name: name,
                                                                    label: strings.situacao
                                                                }}
                                                                getOptionLabel={option => (option?.descricao) || ''}
                                                                getOptionSelected={(option, val) => option?.id === val?.id}
                                                                options={situacoes.value}
                                                                value={value}
                                                                onBlur={onBlur}
                                                                onChange={(e, item) => {
                                                                    const sit = item || null;
                                                                    setFieldValue(SITUACAO, sit);
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                </SttGrid>
                                                {
                                                    temPermissaoRBAC(usuario, [PERMISSOES.ADMINISTRATIVO, PERMISSOES.REGULACAO]) &&
                                                    <SttGrid item xs={12} sm={6} md={3} lg>
                                                        <Field name={REGIAO}>
                                                            {({
                                                                field: { name, value, onBlur },
                                                                form: { setFieldValue }
                                                            }) => (
                                                                <SttAutocomplete
                                                                    inputprops={{
                                                                        name: name,
                                                                        label: strings.redeTeleconsultoria
                                                                    }}
                                                                    getOptionLabel={option => (option?.descricao) || ''}
                                                                    getOptionSelected={(option, val) => option?.id === val?.id}
                                                                    options={regioes.value}
                                                                    value={value}
                                                                    onBlur={onBlur}
                                                                    onChange={(e, item) => {
                                                                        const rede = item || null;
                                                                        setFieldValue(REGIAO, rede);
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                    </SttGrid>
                                                }
                                            </SttGrid>
                                        </SttCardContent>
                                    </SttCard>
                                </SttGrid>
                                {
                                    (temPermissaoRBAC(usuario, [PERMISSOES.ADMINISTRATIVO, PERMISSOES.REGULACAO, PERMISSOES.RESPOSTA])
                                        || temPerfilRBAC(usuario, PERFIL.VISUALIZADOR)
                                        || (temPermissaoRBAC(usuario, PERMISSOES.SOLICITACAO) && usuario.value?.cbo.some(cbo => CODIGOS_CBO_MEDICO.includes(parseInt(cbo.codigo_cbo_familia))))
                                    ) &&
                                    <SttGrid item xs={12} sm={12} md={6}>
                                        <SttCard variant="outlined">
                                            <SttCardHeader subheader={strings.paciente} />
                                            <SttCardContent>
                                                <SttGrid container spacing={1}>
                                                    <SttGrid item xs={12} sm={12} md={6}>
                                                        <FastField name={NOME_PACIENTE}>
                                                            {({
                                                                field
                                                            }) => (
                                                                <SttInput
                                                                    {...field}
                                                                    onInput={(e) => e.target.value = ("" + e.target.value).toUpperCase()}
                                                                    label={strings.nome}
                                                                />
                                                            )}
                                                        </FastField>
                                                    </SttGrid>
                                                    <SttGrid item xs={12} sm={6} md>
                                                        <FastField name={CPF_PACIENTE}>
                                                            {({
                                                                field,
                                                                meta
                                                            }) => (
                                                                <SttMaskedInput
                                                                    {...field}
                                                                    mask="cpf"
                                                                    label={strings.cpf}
                                                                    error={meta.touched && meta.error ? true : false}
                                                                    helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                />
                                                            )}
                                                        </FastField>
                                                    </SttGrid>
                                                    <SttGrid item xs={12} sm={6} md>
                                                        <FastField name={CNS_PACIENTE}>
                                                            {({
                                                                field,
                                                                meta,
                                                            }) => (
                                                                <SttMaskedInput
                                                                    {...field}
                                                                    mask="cns"
                                                                    label={strings.cns}
                                                                    error={meta.touched && meta.error ? true : false}
                                                                    helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                />
                                                            )}
                                                        </FastField>
                                                    </SttGrid>
                                                </SttGrid>
                                            </SttCardContent>
                                        </SttCard>
                                    </SttGrid>
                                }
                            </SttGrid>
                            <SttGrid container spacing={1}>
                                <SttGrid item xs={12} className={classes.buttonWrapper}>
                                    <SttButton
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={isSubmitting}
                                        nomarginleft="true"
                                        onClick={() => resetPageOrder()}
                                    >
                                        {strings.pesquisar}
                                    </SttButton>
                                    <SttButton
                                        type="button"
                                        variant="outlined"
                                        color="primary"
                                        disabled={isSubmitting}
                                        onClick={() => {
                                            resetFiltros();
                                            resetForm({
                                                values: estadoInicialForm
                                            });
                                            callbackBusca({
                                                totalRegistros: 0,
                                                itens: []
                                            });
                                        }}
                                    >
                                        {strings.limpar}
                                    </SttButton>
                                </SttGrid>
                            </SttGrid>
                        </form>
                    )
                }
            }
        </Formik>
    );
};

export default Form;