export const CAMPOS_PACIENTE = {
    camposBusca: {
        cpf: { obrigatorio: false },
        cns: { obrigatorio: false }
    },
    camposCadastro: [
        [
            { nome: 'nome', obrigatorio: true, somenteLeitura: false, tamanho: { xs: 12, sm: 6, md: 6 } },
            { nome: 'genero', obrigatorio: true, somenteLeitura: false, tamanho: { xs: 12, sm: 3, md: 3 } },
            { nome: 'raca', obrigatorio: false, somenteLeitura: false, tamanho: { xs: 12, sm: 3, md: 3 } },
        ],
        [
            { nome: 'nomeMae', obrigatorio: false, somenteLeitura: false, tamanho: { xs: 12, sm: 6, md: 6 } },
            { nome: 'cpf', obrigatorio: true, somenteLeitura: false, tamanho: { xs: 12, sm: 3, md: 3 } },
            { nome: 'cns', obrigatorio: true, somenteLeitura: false, tamanho: { xs: 12, sm: 3, md: 3 } },
        ],
        [
            { nome: 'pais', obrigatorio: false, somenteLeitura: false, tamanho: { xs: 12, sm: 4, md: 4 } },
            { nome: 'uf', obrigatorio: false, somenteLeitura: false, tamanho: { xs: 12, sm: 2, md: 2 } },
            { nome: 'municipio', obrigatorio: false, somenteLeitura: false, tamanho: { xs: 12, sm: 6, md: 6 } },
        ],
        [
            { nome: 'peso', obrigatorio: false, somenteLeitura: false, tamanho: { xs: 12, sm: 2, md: 2 } },
            { nome: 'altura', obrigatorio: false, somenteLeitura: false, tamanho: { xs: 12, sm: 2, md: 2 } },
            { nome: 'dataNascimento', obrigatorio: true, somenteLeitura: false, tamanho: { xs: 12, sm: 3, md: 3 } },
            { nome: 'idade', obrigatorio: false, somenteLeitura: true, tamanho: { xs: 12, sm: 5, md: 5 } },
        ]
    ],
};