import { TIPO_TELECONSULTORIA } from "../../common/Constants";

const umDia = 24 * 60 * 60 * 1000;

export const estadoInicialForm = {
    numero: '',
    deSolicitacao: new Date((new Date()).getTime() - 30 * umDia),
    ateSolicitacao: new Date(),
    deResposta: null,
    ateResposta: null,
    nomePaciente: '',
    cpfPaciente: null,
    cnsPaciente: null,
    nomeSolicitante: '',
    cpfSolicitante: null,
    uf: null,
    cidade: null,
    cbo: null,
    nomeTeleconsultor: '',
    assunto: '',
    situacao: null,
    tipo: { id: TIPO_TELECONSULTORIA.TIPO.ASSINCRONA, descricao: TIPO_TELECONSULTORIA.LABEL[TIPO_TELECONSULTORIA.TIPO.ASSINCRONA] },
    regiao: null,
}