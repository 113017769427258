import * as yup from 'yup'

import dadosEncaminhamentoSchema from './encaminhamento/form/validationSchema';
import dadosGeralSchema from './geral/form/validationSchema';
import dadosCiap2Schema from '../../paginas/solicitacao/validationSchemaCiap2';
import dadosJudicialSchema from '../../componentes/solicitacao/judicial/form/validationSchema';
import dadosJudicialTecnologiaSchema from '../../componentes/solicitacao/judicial-tecnologia/validationSchema';

export default (strings) => {
    const schema = yup.object().shape({});
    const dadosGeral = dadosGeralSchema(strings);
    const dadosEncaminhamento = dadosEncaminhamentoSchema(strings);
    const dadosCiap2 = dadosCiap2Schema(strings);
    const dadosJudicial = dadosJudicialSchema(strings);
    const dadosJudicialTecnologia = dadosJudicialTecnologiaSchema(strings);

    return schema
        .concat(dadosGeral)
        .concat(dadosCiap2)
        .concat(dadosEncaminhamento)
        .concat(dadosJudicial)
        .concat(dadosJudicialTecnologia);
}