import React, { useContext, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import HttpStatus from 'http-status-codes';
import axios from 'axios';
import { getHeaders } from '../../request';
import {
    SttLoading,
    SttGrid,
    SttButton,
    SttModal,
    SttInput,
    SttAutocomplete,
    SttNotification,
    SttTranslateHook
} from '@stt-componentes/core';
import * as yup from 'yup';
import {
    Formik,
    Field
} from 'formik';
import { DESTINO_DEVOLUCAO } from '../../common/Constants';
import alerta from '../../signals/alerta';
import { signal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';

const useStyles = makeStyles(theme => ({
    form: {
        marginTop: theme.spacing(1.5)
    },
    notificacao: {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1),
    },
    modalBody: {
        overflow: 'hidden'
    }
}));

const validationSchema = (strings) => {
    return yup.object().shape({
        'motivo': yup
            .object().shape({
                id: yup.number(),
                descricao: yup.string(),
            })
            .nullable()
            .required(strings.campoObrigatorio),
        'observacao': yup
            .string()
            .trim()
            .nullable()
            .when('motivo', {
                is: (val) => val && val.possui_complemento,
                then: yup
                    .string()
                    .required(strings.campoObrigatorio)
            }),
    });
}

const initialValues = {
    observacao: '',
    motivo: null
};

const motivos = signal([]);

const Devolucao = ({ idSolicitacao, callback, devolver, devolucaoSolicitante = false, callbackCancelar = () => { } }) => {
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const schema = validationSchema(strings);
    useSignals();

    const fecharModal = () => {
        callbackCancelar();
        devolver.value = false;
    }

    const enviarForm = (dados, setSubmitting) => {
        setSubmitting(true);

        let tipoAlerta = '';
        let tituloAlerta = '';
        let mensagemAlerta = '';
        let options = [];
        let onClose = () => { };

        const dadosEnviar = {
            idSolicitacao: idSolicitacao,
            devolucaoSolicitante: devolucaoSolicitante,
            observacao: dados.observacao.trim(),
            motivo: dados.motivo.id
        };
        axios.post(`${global.gConfig.url_base_teleconsultorias}/devolucao`, dadosEnviar, { headers: getHeaders() })
            .then((resposta) => {
                const { data } = resposta;
                tipoAlerta = 'success';
                tituloAlerta = strings.sucesso;
                mensagemAlerta = data?.message || strings.teleconsultoriaDevolvidaSucesso;
                options = [{
                    title: strings.ok,
                    onClick: () => {
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        }
                        callback();

                    }
                }];
                onClose = () => {
                    alerta.value = {
                        ...alerta.value,
                        open: false
                    };
                    callback();
                }
            })
            .catch(err => {
                const { response } = err;
                tipoAlerta = 'error';
                tituloAlerta = strings.erro;
                mensagemAlerta = strings.erroGenerico;
                let erroRespondidaPreviamente;

                if (response) {
                    const { data } = response;

                    erroRespondidaPreviamente = data.data?.erro === 'ERRO_TELECONSULTORIA_RESPONDIDA';
                    mensagemAlerta = data.message;
                    if (response.status === HttpStatus.BAD_REQUEST || response.status === HttpStatus.INTERNAL_SERVER_ERROR) {
                        let arrMensagem = [];
                        data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        if (arrMensagem.length > 0) {
                            mensagemAlerta = arrMensagem.join('\n');
                        }
                    }
                }

                options = [{
                    title: strings.ok,
                    onClick: () => {
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        }
                        if (erroRespondidaPreviamente) {
                            callback();
                        }
                    }
                }];
                onClose = () => {
                    alerta.value = {
                        ...alerta.value,
                        open: false
                    };
                    if (erroRespondidaPreviamente) {
                        callback();
                    }
                }
            })
            .finally(() => {
                setSubmitting(false);
                alerta.value = {
                    ...alerta.value,
                    type: tipoAlerta,
                    title: tituloAlerta,
                    message: mensagemAlerta,
                    open: true,
                    options: options,
                    onClose: onClose
                }
            });
    }

    useEffect(() => {
        const params = {
            destino: devolucaoSolicitante ? DESTINO_DEVOLUCAO.SOLICITANTE : DESTINO_DEVOLUCAO.REGULADOR
        };
        axios.get(`${global.gConfig.url_base_teleconsultorias}/motivo-devolucao`, { params, headers: getHeaders() })
            .then(({ data }) => {
                motivos.value = data.data.itens;
            })
            .catch(err => {
                motivos.value = [];
                console.log(err)
            });
    }, []);

    return (
        <SttModal
            title={devolucaoSolicitante ? strings.devolverSolicitante : strings.devolverTelerregulacao}
            open={devolver.value}
            outModalClose={fecharModal}
            iconClose={fecharModal}
            maxWidth="sm"
            fullWidth={true}
            children={
                <div className={classes.modalBody}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={schema}
                        onSubmit={(dados, { setSubmitting }) => {
                            setSubmitting(false);
                            alerta.value = {
                                ...alerta.value,
                                open: true,
                                title: strings.atencao,
                                type: 'alert',
                                message: devolucaoSolicitante ? strings.confirmarDevolverSolicitante : strings.confirmarDevolverTelerregulacao,
                                options: [{
                                    title: strings.sim,
                                    onClick: () => {
                                        enviarForm(dados, setSubmitting)
                                        alerta.value = {
                                            ...alerta.value,
                                            open: false
                                        };
                                    }
                                },
                                {
                                    title: strings.nao,
                                    onClick: () => {
                                        alerta.value = {
                                            ...alerta.value,
                                            open: false
                                        };
                                    }
                                }],
                                onClose: () => {
                                    alerta.value = {
                                        ...alerta.value,
                                        open: false
                                    };
                                }
                            };
                        }}
                    >
                        {
                            ({
                                isSubmitting,
                                values,
                                handleSubmit,
                                resetForm,
                            }) => {
                                return (
                                    <form onSubmit={handleSubmit} noValidate className={classes.form}>
                                        {
                                            devolucaoSolicitante &&
                                            <SttNotification severity="info" className={classes.notificacao}>
                                                <b>{strings.atencao.toUpperCase()}!</b> {strings.avisoDevolucaoSolicitante}
                                            </SttNotification>
                                        }
                                        <SttGrid container spacing={3}>
                                            <SttGrid item xs={12}>
                                                <Field name='motivo'>
                                                    {({
                                                        field: { name, value, onBlur },
                                                        form: { setFieldValue },
                                                        meta
                                                    }) => (
                                                        <SttAutocomplete
                                                            inputprops={{
                                                                name: name,
                                                                label: strings.motivo,
                                                                required: true,
                                                                error: meta.touched && meta.error ? meta.error : undefined
                                                            }}
                                                            getOptionLabel={option => option?.descricao || ''}
                                                            getOptionSelected={(option, val) => option?.id === val?.id}
                                                            options={motivos.value}
                                                            value={value}
                                                            onBlur={onBlur}
                                                            onChange={(e, item) => {
                                                                const mot = item || null;
                                                                setFieldValue('motivo', mot);
                                                            }}
                                                        />
                                                    )}
                                                </Field>
                                            </SttGrid>
                                            <SttGrid item xs={12}>
                                                <Field name='observacao'>
                                                    {({
                                                        field,
                                                        meta
                                                    }) => (
                                                        <SttInput
                                                            {...field}
                                                            required={!!values.motivo?.possui_complemento}
                                                            error={meta.touched && meta.error ? true : false}
                                                            helperText={meta.touched && meta.error ? meta.error : undefined}
                                                            multiline
                                                            minRows={6}
                                                            maxRows={9}
                                                            label={strings.observacao}
                                                        />
                                                    )}
                                                </Field>
                                            </SttGrid>
                                            <SttLoading
                                                open={isSubmitting}
                                                text={strings.devolvendoTele}
                                            />
                                        </SttGrid>
                                        <SttGrid container spacing={3}>
                                            <SttGrid item xs={12}>
                                                <SttButton
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    nomarginleft="true"
                                                    className={classes.button}
                                                    disabled={isSubmitting}
                                                >
                                                    {strings.confirmar}
                                                </SttButton>
                                                <SttButton
                                                    type="button"
                                                    variant="outlined"
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                    className={classes.button}
                                                    onClick={() => {
                                                        resetForm({ values: initialValues });
                                                        fecharModal();
                                                    }}
                                                >
                                                    {strings.cancelar}
                                                </SttButton>
                                            </SttGrid>
                                        </SttGrid>
                                    </form>
                                )
                            }
                        }
                    </Formik>
                </div>
            }
        />
    );
};

export default Devolucao;