import * as yup from 'yup';
import { CLASSIFICACOES } from '../../../../common/Constants';
import { CLASSIFICACAO, GERAL } from '../../geral/form/fieldNames';
import {
    ASSISTENCIA_JUDICIARIA,
    AUTOR_REPRESENTADO,
    COMPLEMENTO_PARENTESCO_REPRESENTANTE,
    DIAGNOSTICO,
    ESFERA_ORGAO,
    JUDICIAL,
    NOME_ADVOGADO,
    NOME_REPRESENTANTE,
    NUMERO_OAB,
    NUMERO_PROCESSO,
    PARENTESCO_REPRESENTANTE,
    REUS,
    TIPO_JUIZADO,
    TIPO_REPRESENTACAO,
    UF_OAB,
    VARA_SERVENTIA
} from './fieldNames';

export default (strings) => {
    return yup.object().shape({
        [JUDICIAL]: yup.object()
            .when(`${GERAL}.${CLASSIFICACAO}`, {
                is: (classificacao) => classificacao == CLASSIFICACOES.JUDICIALIZACAO,
                then: yup
                    .object()
                    .shape({
                        [DIAGNOSTICO]: yup
                            .string()
                            .trim()
                            .nullable()
                            .test('html-valido', strings.erroConteudoHtml, (html) => {
                                html = (html || '').replace(/<[^>]*>?/gm, '');
                                if (html.length === 0 || html.length >= 10) {
                                    return true;
                                }
                                return false;
                            })
                            .required(strings.campoObrigatorio),
                        [NUMERO_PROCESSO]: yup
                            .string()
                            .nullable()
                            .test("tamanho", strings.numeroProcessoVinteCaracteres, (val) => {
                                if (!val) {
                                    return true;
                                }
                                val = val.replace(/\D+/g, '');
                                return val.length === 20;
                            })
                            .required(strings.campoObrigatorio),
                        [VARA_SERVENTIA]: yup
                            .string()
                            .trim()
                            .max(255, strings.erroNome255Caracteres)
                            .nullable()
                            .required(strings.campoObrigatorio),
                        [ESFERA_ORGAO]: yup
                            .string()
                            .trim()
                            .nullable()
                            .required(strings.campoObrigatorio),
                        [REUS]: yup
                            .string()
                            .trim()
                            .nullable()
                            .required(strings.campoObrigatorio),
                        [TIPO_REPRESENTACAO]: yup
                            .string()
                            .trim()
                            .nullable()
                            .required(strings.campoObrigatorio),
                        [ASSISTENCIA_JUDICIARIA]: yup
                            .boolean()
                            .oneOf([true, false], strings.campoObrigatorio),
                        [TIPO_JUIZADO]: yup
                            .string()
                            .trim()
                            .nullable()
                            .required(strings.campoObrigatorio),
                        [NOME_ADVOGADO]: yup
                            .string()
                            .trim()
                            .max(255, strings.erroNome255Caracteres)
                            .test('nomeCompleto', strings.informeNomeCompleto, (val) => {
                                if (!val) {
                                    return true;
                                }
                                const arrNome = val.trim().split(/\s+/);
                                return arrNome.length >= 2;
                            })
                            .nullable()
                            .required(strings.campoObrigatorio),
                        [UF_OAB]: yup
                            .object().shape({
                                id: yup.number(),
                                sigla: yup.string(),
                            })
                            .nullable()
                            .required(strings.campoObrigatorio),
                        [NUMERO_OAB]: yup
                            .string()
                            .trim()
                            .max(6, strings.erroNumeroOab)
                            .nullable()
                            .required(strings.campoObrigatorio),
                        [AUTOR_REPRESENTADO]: yup
                            .boolean()
                            .oneOf([true, false], strings.campoObrigatorio),
                        [NOME_REPRESENTANTE]: yup
                            .string()
                            .trim()
                            .max(255, strings.erroNome255Caracteres)
                            .test('nomeCompleto', strings.informeNomeCompleto, (val) => {
                                if (!val) {
                                    return true;
                                }
                                const arrNome = val.trim().split(/\s+/);
                                return arrNome.length >= 2;
                            })
                            .nullable()
                            .when(AUTOR_REPRESENTADO, {
                                is: (autorRep) => autorRep,
                                then: yup
                                    .string()
                                    .nullable()
                                    .required(strings.campoObrigatorio)
                            }),
                        [PARENTESCO_REPRESENTANTE]: yup
                            .object().shape({
                                id: yup.number(),
                                descricao: yup.string(),
                            })
                            .nullable()
                            .when(AUTOR_REPRESENTADO, {
                                is: (autorRep) => autorRep,
                                then: yup
                                    .object()
                                    .nullable()
                                    .required(strings.campoObrigatorio)
                            }),
                        [COMPLEMENTO_PARENTESCO_REPRESENTANTE]: yup
                            .string()
                            .trim()
                            .max(255, strings.maximo255Caracteres)
                            .nullable()
                            .when(PARENTESCO_REPRESENTANTE, {
                                is: (parentesco) => parentesco && parentesco.exige_complemento,
                                then: yup
                                    .string()
                                    .nullable()
                                    .required(strings.campoObrigatorio)
                            }),

                    })
            })
    });
}
