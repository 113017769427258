import React, { useEffect, memo, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import axios from 'axios';
import { getHeaders } from '../../request';
import {
    SttExpansionPanel,
    SttTextItem,
    SttHeading,
    SttContainer,
    SttCircularProgress,
    SttDivider,
    SttTranslateHook
} from '@stt-componentes/core';
import Functions from '../../common/Functions';
import ExibicaoHtml from './exibicaoHtml';
import Moment from 'react-moment';
import BotoesOperacoes from './botoesOperacoes';
import { useSignal, useSignals } from '@preact/signals-react/runtime';
import usuario from '../../signals/usuario';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    link: {
        padding: '5px'
    },
    notificacao: {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5),
    },
    buttonWrapper: {
        marginTop: theme.spacing(1.5)
    },
    button: {
        marginBottom: theme.spacing(1),
        marginLeft: 0,
        marginRight: theme.spacing(1.5)
    },
}));

const Divider = memo((props) => {
    return (
        <SttDivider {...props} />
    )
});

const Detalhes0800 = ({ id }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    useSignals();

    const teleconsultoria = useSignal(null);

    const baixarTeleconsultoria = () => {
        axios.get(`${global.gConfig.url_base_teleconsultorias}/teleconsultoria-0800/${id}`, { headers: getHeaders() })
            .then((response) => {
                if (response.data) {
                    const { data } = response.data;

                    data.acoes = Functions.acoes(usuario, data);
                    teleconsultoria.value = data;
                }
            })
            .catch(err => console.log(err));
    }

    useEffect(() => {
        if (id) {
            baixarTeleconsultoria();
        }
    }, [id]);

    return (
        <SttContainer>
            {
                !teleconsultoria.value
                    ?
                    <div className={classes.carregando}>
                        <SttCircularProgress color="primary" />
                    </div>
                    :
                    <>
                        <SttHeading variant="h1" color="primary" align="center" >{strings.tele0800} - {teleconsultoria.value.id}</SttHeading>

                        <SttExpansionPanel
                            title={strings.dadosGerais}
                            children={
                                <div>
                                    <SttTextItem title={strings.tipoTeleconsultoria} content={strings.tele0800} />
                                    {
                                        teleconsultoria.value.classificacao?.descricao &&
                                        <SttTextItem title={strings.classificacao} content={teleconsultoria.value.classificacao.descricao} />
                                    }
                                    {
                                        teleconsultoria.value.especificacao?.descricao &&
                                        <SttTextItem title={strings.especificacao} content={teleconsultoria.value.especificacao.descricao} />
                                    }
                                    {
                                        teleconsultoria.value.detalhamento &&
                                        <>
                                            {
                                                teleconsultoria.value.detalhamento.descricao &&
                                                <SttTextItem title={strings.detalhamento} content={teleconsultoria.value.detalhamento.descricao} />
                                            }
                                            {
                                                teleconsultoria.value.detalhamento.ciap2_capitulo &&
                                                <SttTextItem title={strings.detalhamentoCiapCapitulo} content={teleconsultoria.value.detalhamento.ciap2_capitulo} />
                                            }
                                            {
                                                teleconsultoria.value.detalhamento.ciap2_conceito &&
                                                <SttTextItem title={strings.detalhamentoCiapConceito} content={teleconsultoria.value.detalhamento.ciap2_conceito} />
                                            }
                                        </>
                                    }
                                    {
                                        teleconsultoria.value.nome_participante &&
                                        <SttTextItem title={strings.solicitante} content={teleconsultoria.value.nome_participante} />
                                    }
                                    {
                                        teleconsultoria.value.cidade_participante &&
                                        <SttTextItem title={strings.cidadeUf} content={`${teleconsultoria.value.cidade_participante} / ${teleconsultoria.value.estado_participante}`} />
                                    }
                                    {
                                        teleconsultoria.value.cbo_familia &&
                                        <SttTextItem title={strings.cbo} content={teleconsultoria.value.cbo_familia} />
                                    }
                                    {
                                        teleconsultoria.value.data_atendimento &&
                                        <SttTextItem title={strings.dataAtendimento} content={<Moment format="DD/MM/YYYY HH:mm">{teleconsultoria.value.data_atendimento}</Moment>} />
                                    }
                                    {
                                        teleconsultoria.value.data_registro &&
                                        <SttTextItem title={strings.dataInclusao} content={<Moment format="DD/MM/YYYY HH:mm">{teleconsultoria.value.data_registro}</Moment>} />
                                    }
                                    <ExibicaoHtml titulo={strings.assunto} html={teleconsultoria.value.assunto} />
                                    <ExibicaoHtml titulo={strings.categoria} html={teleconsultoria.value.categoria} />
                                    <ExibicaoHtml titulo={strings.encaminhamento} html={teleconsultoria.value.encaminhamento} />
                                </div>
                            }
                        />
                        <Divider />
                        <BotoesOperacoes teleconsultoria={teleconsultoria} baixarTeleconsultoria={baixarTeleconsultoria} />
                    </>
            }
        </SttContainer>
    )
}

export default memo(Detalhes0800);