import React, { memo } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
    SttTextItem,
    SttHeading,
} from '@stt-componentes/core';

const useStylesHeading = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1.5)
    }
}));

const ExibicaoCid10 = ({ titulo, cid }) => {
    const classesHeading = useStylesHeading();

    return (
        <>
            {
                cid?.length > 0 &&
                <>
                    <SttHeading variant="h4" color="primary" className={classesHeading.root}>{titulo}</SttHeading>
                    {
                        cid.map((c, index) => (
                            <SttTextItem key={`A_${index}`}
                                title={c.codigo_subcategoria ? c.codigo_subcategoria : c.codigo_categoria}
                                content={c.descritor_subcategoria ? c.descritor_subcategoria : c.descritor_categoria}
                            />
                        ))
                    }
                </>
            }
        </>
    )
};

export default memo(ExibicaoCid10);