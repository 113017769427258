import * as yup from 'yup';
import { TELECONSULTOR } from './fieldNames';

export default (strings) => {
    return yup.object().shape({
        [TELECONSULTOR]: yup
            .object().shape({
                id: yup.number(),
                nome: yup.string(),
            })
            .nullable()
            .required(strings.campoObrigatorio)
    });
}