import * as yup from 'yup';
import {
    CABECALHO,
    MANIFESTACAO,
    TIPO_RESPOSTA
} from './fieldNames';
import { OPCOES_TIPO_RESPOSTA } from '../../../../common/ConstantsRespostaJudicial';
export default (strings) => {
    let schemaCampo = yup
        .string()
        .trim()
        .nullable()
        .test('html-valido', strings.erroConteudoHtml, (html) => {
            html = (html || '').replace(/<[^>]*>?/gm, '');
            if (html.length === 0 || html.length >= 10) {
                return true;
            }
            return false;
        });

    return yup.object().shape({
        [CABECALHO]: yup.object()
            .required()
            .shape({
                [TIPO_RESPOSTA]: yup
                    .string()
                    .trim()
                    .nullable()
                    .required(strings.campoObrigatorio),
                [MANIFESTACAO]: schemaCampo
                    .when(TIPO_RESPOSTA, {
                        is: OPCOES_TIPO_RESPOSTA.TIPO.MANIFESTACAO_TECNICA,
                        then: yup
                            .string()
                            .required(strings.campoObrigatorio)
                    })
            })
    });
}
