import * as yup from 'yup';
import { OPCOES_TIPO_RESPOSTA } from '../../../../common/ConstantsRespostaJudicial';
import { CABECALHO, TIPO_RESPOSTA } from '../../cabecalho/form/fieldNames';
import {
    REFERENCIAS
} from './fieldNames';

export default (strings, ehJudicializacao) => {
    return yup.object().shape({
        [REFERENCIAS]: yup
            .array()
            .when([`${CABECALHO}.${TIPO_RESPOSTA}`], {
                is: (tipo) => (tipo === OPCOES_TIPO_RESPOSTA.TIPO.NOTA_TECNICA || !ehJudicializacao),
                then: yup
                    .array()
                    .min(1)
                    .required(strings.campoObrigatorio)
                    .of(
                        yup
                            .string()
                            .nullable()
                            .trim()
                            .test('referencia-valida', strings.erroReferenciaInvalida, function (value) {
                                if (this.path !== 'referencias[0]') {
                                    return true;
                                }
                                return !!value;
                            })
                    )
            })

    });
}