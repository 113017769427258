import React, { useContext, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import axios from 'axios';
import {
    SttCircularProgress,
    SttGrid,
    SttContainer,
    SttTable,
    SttTableBody,
    SttTableRow,
    SttTableCell,
    SttTableHead,
    SttHeading,
    SttTranslateHook,
} from '@stt-componentes/core';
import { MYME_TYPE_ARQUIVOS } from '../../common/Constants';
import { getHeaders } from '../../request';
import { useSignals, useSignal } from '@preact/signals-react/runtime';
import { SttCkEditor } from '@stt-componentes/ckeditor';

const useStyles = makeStyles(theme => ({
    gridContainer: {
        justifyContent: 'center',
        alignItems: 'flex-end'
    },
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    tableHead: {
        textAlign: 'center',
        height: theme.spacing(6)
    },
    tableRow: {
        align: 'center',
        textAlign: 'center',
        height: theme.spacing(8),
        textOverflow: 'ellipsis'
    },
    tabela: {
        width: '100%',
        height: '100%'
    },
    imagem: {
        maxHeight: '600px',
        objectFit: 'contain'
    },
    container: {
        padding: theme.spacing(1),
        height: '100%'
    },
    wrapper: {
        height: '100%',
        maxWidth: '99%'
    }
}));

const AnexosRegiaoPreVisualizacao = ({ anexo }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    useSignals();

    const baixandoAnexo = useSignal(true);
    const url = useSignal(null);
    const type = useSignal(null);

    useEffect(() => {
        if (anexo) {
            axios.get(anexo.url, { headers: getHeaders(), responseType: 'blob' })
                .then((response) => {
                    if (response.data) {
                        const blob = response.data;
                        const file = new File([blob], anexo.nome, { lastModified: new Date().getTime(), type: blob.type });
                        const fileURL = URL.createObjectURL(file);

                        if (blob.type === MYME_TYPE_ARQUIVOS.DOC || blob.type === MYME_TYPE_ARQUIVOS.DOCX || blob.type === MYME_TYPE_ARQUIVOS.XLS || blob.type === MYME_TYPE_ARQUIVOS.XLSX) {
                            return;
                        }
                        url.value = fileURL;
                        type.value = blob.type;
                    }
                })
                .catch(err => { console.log(err) })
                .finally(() => {
                    baixandoAnexo.value = false;
                });
        }
    }, [anexo])

    return (
        <SttContainer className={classes.wrapper} >
            {
                baixandoAnexo.value
                    ?
                    <div className={classes.carregando}>
                        <SttCircularProgress color="primary" />
                    </div>
                    :
                    <SttGrid container spacing={1} justifyContent="center" className={classes.container}>
                        <SttGrid item container xs={12} md={anexo.observacao ? 8 : 12} alignItems="center" justifyContent="center" >
                            {
                                url.value
                                    ?
                                    <>
                                        {
                                            type.value === MYME_TYPE_ARQUIVOS.PDF
                                                ?
                                                <iframe width='100%' height='100%' maxHeight='600px' src={url.value} style={{ margin: 0 }} />
                                                :
                                                <img src={url.value} className={classes.imagem} />
                                        }
                                    </>
                                    :
                                    <SttHeading variant="h3" color="primary" align="center">{strings.preVisualizacaoImagem}</SttHeading>
                            }
                        </SttGrid>
                        {
                            anexo.observacao &&
                            <SttGrid item container xs={12} md={4} justifyContent="center" alignItems="center">
                                <SttTable className={classes.tabela} classesSttBox={classes.tabela}>
                                    <SttTableHead>
                                        <SttTableRow hover={false}>
                                            <SttTableCell align="center" className={classes.tableHead}>{strings.observacoes}</SttTableCell>
                                        </SttTableRow>
                                    </SttTableHead>
                                    <SttTableBody>
                                        <SttTableRow hover={false}>
                                            <SttTableCell className={classes.tableRow}>
                                                <SttCkEditor
                                                    data={anexo.observacao.trim()}
                                                    isReadOnly={true}
                                                    maxHeight='570'
                                                />
                                            </SttTableCell>
                                        </SttTableRow>
                                    </SttTableBody>
                                </SttTable>
                            </SttGrid>
                        }
                    </SttGrid>
            }
        </SttContainer>
    );
};

export default AnexosRegiaoPreVisualizacao;