import React from 'react';
import PropTypes from 'prop-types';
import { CLASSIFICACOES, TIPO_TELECONSULTORIA } from '../../common/Constants';
import RespostaAssincrona from './respostaAssincrona';
import RespostaSincrona from './respostaSincrona';
import RespostaAssincronaJudicial from './respostaAssincronaJudicial';

const Resposta = ({ solicitacao }) => {
    if (solicitacao.classificacao.identificador === CLASSIFICACOES.JUDICIALIZACAO) {
        return (
            <RespostaAssincronaJudicial segundaOpiniao={solicitacao.segundaOpiniao} />

        )
    }

    return (
        <>
            {
                solicitacao.tipo === TIPO_TELECONSULTORIA.TIPO.ASSINCRONA
                    ?
                    <RespostaAssincrona segundaOpiniao={solicitacao.segundaOpiniao} />
                    :
                    <RespostaSincrona teleconsultoria={solicitacao.teleconsultoria} />
            }
        </>
    )
}

Resposta.propTypes = {
    solicitacao: PropTypes.object.isRequired,
};

export default Resposta;