export const JUDICIAL = 'judicial';
export const TECNOLOGIAS_SOLICITADAS = 'tecnologiasSolicitadas';
export const VIA_ADMINISTRACAO = 'viaAdministracao';
export const POSOLOGIA = 'posologia';
export const USO_CONTINUO = 'usoContinuo';
export const TEMPO_TRATAMENTO = 'tempoTratamento';
export const COMPLEMENTO_TEMPO_TRATAMENTO = 'complementoTempoTratamento';
export const ORIGEM_PRESCRICAO = 'origemPrescricao';
export const REGISTRO_ANVISA = 'registroAnvisa';
export const REGISTRO_ATIVO = 'registroAtivo';
export const INDICACAO_CONFORMIDADE_REGISTRO = 'indicacaoConformidadeRegistro';
export const COMPLEMENTO_INDICACAO = 'complementoIndicacao';
export const ONCOLOGICO = 'oncologico';
export const COMPLEMENTO_ONCOLOGICO = 'complementoOncologico';
export const PREVISTO_PROTOCOLO_MS = 'previstoProtocoloMs';
export const DISPONIVEL_SUS = 'disponivelSus';
export const EXISTE_GENERICO = 'existeGenerico';
export const EXISTE_SIMILAR = 'existeSimilar';