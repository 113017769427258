import React, { memo, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import axios from 'axios';
import { getHeaders } from '../../request';
import {
    SttTextItem,
    SttHeading,
    SttLink,
    SttLoading,
    SttTranslateHook,
} from '@stt-componentes/core';
import { MYME_TYPE_ARQUIVOS } from '../../common/Constants';
import { useSignal, useSignals } from '@preact/signals-react/runtime';
import { batch } from '@preact/signals-react';

const useStylesHeading = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1.5)
    }
}));

const openFileUrl = (response, nome) => {
    if (response.data) {
        const blob = response.data;
        const file = new File([blob], nome, { lastModified: new Date().getTime(), type: blob.type });
        const fileURL = URL.createObjectURL(file);

        if (blob.type === MYME_TYPE_ARQUIVOS.DOC || blob.type === MYME_TYPE_ARQUIVOS.DOCX || blob.type === MYME_TYPE_ARQUIVOS.XLS || blob.type === MYME_TYPE_ARQUIVOS.XLSX) {
            let a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = fileURL;
            a.download = nome;
            a.click();
            URL.revokeObjectURL(fileURL);
            a.remove();
            return;
        }
        window.open(fileURL);
    }
}

const ExibicaoAnexo = ({ titulo, anexos }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classesHeading = useStylesHeading();
    useSignals();

    const baixandoAnexo = useSignal(false);
    const progress = useSignal(0);

    return (
        <div>
            {
                (anexos?.length) > 0 &&
                <>
                    <SttHeading variant="h4" color="primary" className={classesHeading.root}>{titulo}</SttHeading>
                    {
                        anexos.map((anexo, index) => (
                            <div key={index}>
                                {
                                    anexo.nome &&
                                    <SttTextItem key={`A_${index}`} title={`Anexo ${index + 1}`} content={
                                        <SttLink href={anexo.url} target="_blank"
                                            style={{ cursor: 'pointer' }}
                                            onClick={e => {
                                                e.preventDefault();

                                                baixandoAnexo.value = true;
                                                axios.get(anexo.url, {
                                                    headers: getHeaders(),
                                                    responseType: 'blob',
                                                    onDownloadProgress: (event) => {
                                                        let pg = Math.round(
                                                            (event.loaded * 100) / event.total
                                                        );
                                                        progress.value = pg;
                                                    },
                                                })
                                                    .then((response) => openFileUrl(response, anexo.nome))
                                                    .catch(err => { console.log(err) })
                                                    .finally(() => {
                                                        batch(() => {
                                                            baixandoAnexo.value = false;
                                                            progress.value = 0;
                                                        });
                                                    });
                                            }}>
                                            {anexo.nome}
                                        </SttLink>
                                    } />
                                }
                            </div>
                        ))
                    }
                    <SttLoading
                        open={baixandoAnexo.value}
                        text={strings.carregandoAnexo}
                        progress={progress.value}
                    />
                </>
            }
        </div>
    )
}

export default memo(ExibicaoAnexo);