import * as yup from 'yup';
import { CLASSIFICACOES } from '../../../../common/Constants';
import {
    GERAL,
    CLASSIFICACAO,
    PACIENTE_ESPECIFICO
} from '../../geral/form/fieldNames';
import {
    TEXTUAL,
    COMORBIDADES,
    COMPLEMENTACOES,
    EXAMES_IMAGEM,
    HISTORIA_ATUAL,
    TRATAMENTOS_USO
} from './fieldNames';

export default (strings) => {
    let schemaCampo = yup
        .string()
        .trim()
        .nullable()
        .test('html-valido', strings.erroConteudoHtml, (html) => {
            html = (html || '').replace(/<[^>]*>?/gm, '');
            if (html.length === 0 || html.length >= 10) {
                return true;
            }
            return false;
        });

    let schemaObrigatorio = schemaCampo.required(strings.campoObrigatorio);

    return yup.object().shape({
        [TEXTUAL]: yup.object()
            .when([`${GERAL}.${CLASSIFICACAO}`, `${GERAL}.${PACIENTE_ESPECIFICO}`], {
                is: (classificacao, pacEspec) => classificacao !== CLASSIFICACOES.JUDICIALIZACAO && pacEspec,
                then: yup
                    .object()
                    .shape({
                        [HISTORIA_ATUAL]: schemaObrigatorio,
                        [COMORBIDADES]: schemaObrigatorio,
                        [TRATAMENTOS_USO]: schemaObrigatorio,
                        [COMPLEMENTACOES]: schemaCampo,
                        [EXAMES_IMAGEM]: schemaCampo,
                    })
            })
    });
}
