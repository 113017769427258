import * as yup from 'yup';
import {
    COMPLEMENTO_INDICACAO,
    COMPLEMENTO_ONCOLOGICO,
    COMPLEMENTO_TEMPO_TRATAMENTO,
    DISPONIVEL_SUS,
    EXISTE_GENERICO,
    EXISTE_SIMILAR,
    INDICACAO_CONFORMIDADE_REGISTRO,
    JUDICIAL,
    ONCOLOGICO,
    ORIGEM_PRESCRICAO,
    POSOLOGIA,
    PREVISTO_PROTOCOLO_MS,
    REGISTRO_ANVISA,
    REGISTRO_ATIVO,
    TECNOLOGIAS_SOLICITADAS,
    TEMPO_TRATAMENTO,
    USO_CONTINUO,
    VIA_ADMINISTRACAO
} from './fieldNames';
import { OPCOES_TIPO_RESPOSTA, OPCOES_TIPO_TECNOLOGIA } from '../../../../common/ConstantsRespostaJudicial';
import { CABECALHO, TIPO_RESPOSTA } from '../../cabecalho/form/fieldNames';

export default (strings) => {
    return yup.object().shape({
        [JUDICIAL]: yup.object()
            .when(`${CABECALHO}.${TIPO_RESPOSTA}`, {
                is: (tipo) => tipo == OPCOES_TIPO_RESPOSTA.TIPO.NOTA_TECNICA,
                then: yup
                    .object()
                    .shape({
                        [TECNOLOGIAS_SOLICITADAS]: yup.array().of(
                            yup.object().shape({
                                [VIA_ADMINISTRACAO]: yup
                                    .string()
                                    .trim()
                                    .nullable()
                                    .test('html-valido', strings.erroConteudoHtml, (html) => {
                                        html = (html || '').replace(/<[^>]*>?/gm, '');
                                        if (html.length === 0 || html.length >= 10) {
                                            return true;
                                        }
                                        return false;
                                    })
                                    .when('tipo', {
                                        is: (tipo) => tipo === OPCOES_TIPO_TECNOLOGIA.TIPO.MEDICAMENTO,
                                        then: yup
                                            .string()
                                            .required(strings.campoObrigatorio)
                                    }),
                                [POSOLOGIA]: yup
                                    .string()
                                    .trim()
                                    .nullable()
                                    .test('html-valido', strings.erroConteudoHtml, (html) => {
                                        html = (html || '').replace(/<[^>]*>?/gm, '');
                                        if (html.length === 0 || html.length >= 10) {
                                            return true;
                                        }
                                        return false;
                                    })
                                    .when('tipo', {
                                        is: (tipo) => tipo === OPCOES_TIPO_TECNOLOGIA.TIPO.MEDICAMENTO,
                                        then: yup
                                            .string()
                                            .required(strings.campoObrigatorio)
                                    }),
                                [USO_CONTINUO]: yup
                                    .boolean()
                                    .nullable()
                                    .when('tipo', {
                                        is: (tipo) => tipo === OPCOES_TIPO_TECNOLOGIA.TIPO.MEDICAMENTO,
                                        then: yup
                                            .boolean()
                                            .oneOf([true, false], strings.campoObrigatorio),
                                    }),
                                [TEMPO_TRATAMENTO]: yup
                                    .object().shape({
                                        identificador: yup.string(),
                                        descricao: yup.string(),
                                    })
                                    .nullable()
                                    .when('tipo', {
                                        is: (tipo) => tipo === OPCOES_TIPO_TECNOLOGIA.TIPO.MEDICAMENTO,
                                        then: yup
                                            .object()
                                            .required(strings.campoObrigatorio)
                                    }),
                                [COMPLEMENTO_TEMPO_TRATAMENTO]: yup
                                    .number()
                                    .nullable()
                                    .when(TEMPO_TRATAMENTO, {
                                        is: (duracao) => duracao?.exige_complemento_numerico,
                                        then: yup
                                            .number()
                                            .required(strings.campoObrigatorio)
                                    }),
                                [ORIGEM_PRESCRICAO]: yup
                                    .string()
                                    .trim()
                                    .nullable()
                                    .when('tipo', {
                                        is: (tipo) => tipo === OPCOES_TIPO_TECNOLOGIA.TIPO.MEDICAMENTO,
                                        then: yup
                                            .string()
                                            .required(strings.campoObrigatorio)
                                    }),
                                [REGISTRO_ANVISA]: yup
                                    .boolean()
                                    .nullable()
                                    .oneOf([true, false], strings.campoObrigatorio),
                                [REGISTRO_ATIVO]: yup
                                    .boolean()
                                    .nullable()
                                    .oneOf([true, false], strings.campoObrigatorio),
                                [INDICACAO_CONFORMIDADE_REGISTRO]: yup
                                    .boolean()
                                    .nullable()
                                    .when('tipo', {
                                        is: (tipo) => tipo === OPCOES_TIPO_TECNOLOGIA.TIPO.MEDICAMENTO,
                                        then: yup
                                            .boolean()
                                            .oneOf([true, false], strings.campoObrigatorio),
                                    }),
                                [COMPLEMENTO_INDICACAO]: yup
                                    .string()
                                    .trim()
                                    .nullable()
                                    .test('html-valido', strings.erroConteudoHtml, (html) => {
                                        html = (html || '').replace(/<[^>]*>?/gm, '');
                                        if (html.length === 0 || html.length >= 10) {
                                            return true;
                                        }
                                        return false;
                                    })
                                    .when(INDICACAO_CONFORMIDADE_REGISTRO, {
                                        is: (indicacao) => indicacao === false,
                                        then: yup
                                            .string()
                                            .required(strings.campoObrigatorio)
                                    }),
                                [ONCOLOGICO]: yup
                                    .boolean()
                                    .nullable()
                                    .when('tipo', {
                                        is: (tipo) => tipo === OPCOES_TIPO_TECNOLOGIA.TIPO.MEDICAMENTO,
                                        then: yup
                                            .boolean()
                                            .oneOf([true, false], strings.campoObrigatorio),
                                    }),
                                [COMPLEMENTO_ONCOLOGICO]: yup
                                    .string()
                                    .trim()
                                    .nullable()
                                    .test('html-valido', strings.erroConteudoHtml, (html) => {
                                        html = (html || '').replace(/<[^>]*>?/gm, '');
                                        if (html.length === 0 || html.length >= 10) {
                                            return true;
                                        }
                                        return false;
                                    })
                                    .when(ONCOLOGICO, {
                                        is: (indicacao) => indicacao === true,
                                        then: yup
                                            .string()
                                            .required(strings.campoObrigatorio)
                                    }),
                                [PREVISTO_PROTOCOLO_MS]: yup
                                    .boolean()
                                    .nullable()
                                    .when('tipo', {
                                        is: (tipo) => tipo === OPCOES_TIPO_TECNOLOGIA.TIPO.MEDICAMENTO,
                                        then: yup
                                            .boolean()
                                            .oneOf([true, false], strings.campoObrigatorio),
                                    }),
                                [DISPONIVEL_SUS]: yup
                                    .boolean()
                                    .nullable()
                                    .oneOf([true, false], strings.campoObrigatorio),
                                [EXISTE_GENERICO]: yup
                                    .boolean()
                                    .nullable()
                                    .when('tipo', {
                                        is: (tipo) => tipo === OPCOES_TIPO_TECNOLOGIA.TIPO.MEDICAMENTO,
                                        then: yup
                                            .boolean()
                                            .oneOf([true, false], strings.campoObrigatorio),
                                    }),
                                [EXISTE_SIMILAR]: yup
                                    .boolean()
                                    .nullable()
                                    .when('tipo', {
                                        is: (tipo) => tipo === OPCOES_TIPO_TECNOLOGIA.TIPO.MEDICAMENTO,
                                        then: yup
                                            .boolean()
                                            .oneOf([true, false], strings.campoObrigatorio),
                                    }),
                            })
                        ),
                    }).required()
            })
    });
}