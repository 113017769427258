import React, { memo, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
    Field,
    FieldArray,
    useFormikContext
} from 'formik';
import {
    ANEXOS,
    ANEXOS_EXCLUIDOS,
} from './fieldNames';
import {
    SttGrid,
    SttButton,
    SttFileSelect,
    SttTranslateHook
} from '@stt-componentes/core';
import { getHeaders } from '../../../request';
import { MYME_TYPE_PERMITIDOS } from '../../../common/Constants';

const useStyles = makeStyles(theme => ({
    buttonWrapper: {
        marginTop: theme.spacing(1)
    },
    container: {
        marginBottom: 0
    },
    botaoMais: {
        minWidth: 0,
        marginLeft: 0
    },
    divExterna: {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
        overflowY: 'hidden'
    },
    botaoMenos: {
        minWidth: 0
    },
    divider: {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5),
    },
}));

const FileSelect = memo((props) => {
    return (
        <SttFileSelect {...props} headers={getHeaders()} />
    )
});

const DadosAnexos = () => {
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const { values, setFieldValue } = useFormikContext();

    return (
        <SttGrid container spacing={3} justifyContent="center" className={classes.container}>
            <SttGrid item xs={12}>
                <FieldArray name={ANEXOS} render={
                    ({ remove, push }) => (
                        <>
                            <div className={classes.divExterna}>
                                {
                                    values.anexos.map((anexo, indice) => (
                                        <Field key={indice} name={`${ANEXOS}.${indice}`}>
                                            {({
                                                field: { name, value },
                                                form: { validateField },
                                                meta
                                            }) => (
                                                <>
                                                    <FileSelect
                                                        onFileChange={(event) => {
                                                            //Caso o anexo tenha sido excluído
                                                            if (!event) {
                                                                if (anexo?.id) {
                                                                    setFieldValue(ANEXOS_EXCLUIDOS, [...values.anexosExcluidos, anexo.id]);
                                                                }

                                                                if (values.anexos.length > 1) {
                                                                    remove(indice);
                                                                    return;
                                                                }
                                                                setFieldValue(`${ANEXOS}[${indice}]`, {});
                                                                return;
                                                            }

                                                            if (event.target.files[0]) {
                                                                //Seta o valor da imagem no campo correspondente do formulário
                                                                setFieldValue(`${ANEXOS}[${indice}]`, event.target.files[0]);
                                                                validateField(ANEXOS);
                                                            }
                                                        }}
                                                        file={value}
                                                        urlDownload={anexo.url ? anexo.url : null}
                                                        inputprops={{
                                                            name: name,
                                                            label: `${strings.formatosArquivo} - ${strings.quinhentosMb}`,
                                                            value: value?.name || value?.nome,
                                                            error: meta.touched && meta.error ? meta.error : undefined
                                                        }}
                                                        accept={MYME_TYPE_PERMITIDOS.concat(['.doc', '.docx'])}
                                                    />
                                                </>
                                            )}
                                        </Field>
                                    ))
                                }
                            </div>
                            <SttButton
                                className={classes.botaoMais}
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    let chave = (values.anexos.length + 1);
                                    let obj = {};
                                    obj[chave] = {};
                                    push({});
                                }}
                            >
                                +
                            </SttButton>
                            <SttButton
                                className={classes.botaoMenos}
                                variant="contained"
                                disabled={values.anexos.length < 2}
                                onClick={() => {
                                    const anexoExcluir = values.anexos[values.anexos.length - 1];
                                    if (anexoExcluir?.id) {
                                        setFieldValue(`${ANEXOS_EXCLUIDOS}`, [...values.agendamento.anexosExcluidos, anexoExcluir.id]);
                                    }
                                    if (values.anexos.length > 1) {
                                        let indice = values.anexos.length - 1;
                                        remove(indice);
                                    }
                                }}
                                color="primary">
                                -
                            </SttButton>
                        </>
                    )}
                />
            </SttGrid>
        </SttGrid>

    )
}

export default DadosAnexos;