export const PERFIL = {
    ADMINISTRADOR: 'administrador',
    ADMINISTRADOR_ESTADUAL: 'adminEstadual',
    ADMINISTRADOR_MUNICIPAL: 'adminMunicipal',
    ADMINISTRADOR_REDE_TELE: 'teleAdminRede',
    ADMINISTRADOR_TELE: 'adminTelessaude',
    REGULADOR: 'teleRegulador',
    SOLICITANTE: 'teleSolicitante',
    TELECONSULTOR: 'teleTeleconsultor',
    VISUALIZADOR: 'teleVisualizador'
};

const situacao = {
    INVALIDA: -5,
    RASCUNHO: -2,
    EM_ABERTO: 0,
    DEVOLVIDA_SOLICITANTE: 3,
    ENCAMINHADA: 5,
    AGUARDANDO_RESPOSTA_SECUNDARIA: 6,
    AGUARDANDO_LIBERACAO: 7,
    EM_CORRECAO: 8,
    AGUARDANDO_AVALIACAO: 10,
    AGUARDANDO_TREPLICA: 12,
    TREPLICA_ENVIADA: 13,
    CONCLUIDA: 15,
    FINALIZADA_ADMINISTRATIVAMENTE: 20
}

export const SITUACAO_TELECONSULTORIA = {
    SITUACAO: { ...situacao },
    LABEL: {
        [situacao.INVALIDA]: 'Invalidada',
        [situacao.RASCUNHO]: 'Rascunho',
        [situacao.EM_ABERTO]: 'Aguardando telerregulação',
        [situacao.DEVOLVIDA_SOLICITANTE]: 'Devolvida',
        [situacao.ENCAMINHADA]: 'Aguardando resposta',
        // [situacao.AGUARDANDO_RESPOSTA_SECUNDARIA]: 'Aguardando resposta complementar',
        [situacao.AGUARDANDO_LIBERACAO]: 'Resposta sendo finalizada',
        [situacao.EM_CORRECAO]: 'Resposta em edição',
        [situacao.AGUARDANDO_AVALIACAO]: 'Respondida',
        [situacao.AGUARDANDO_TREPLICA]: 'Aguardando tréplica / Aguardando resposta',
        [situacao.TREPLICA_ENVIADA]: 'Tréplica respondida',
        [situacao.CONCLUIDA]: 'Avaliada',
        [situacao.FINALIZADA_ADMINISTRATIVAMENTE]: 'Finalizada administrativamente',
    },
    GRID: {
        [situacao.INVALIDA]: {
            descricao: 'Invalidada',
            cor: '#f4cbcb'
        },
        [situacao.RASCUNHO]: {
            descricao: 'Rascunho',
            cor: '#cccccc'
        },
        [situacao.EM_ABERTO]: {
            descricao: 'Aguardando resposta',
            cor: '#FFFFFF'
        },
        [situacao.DEVOLVIDA_SOLICITANTE]: {
            descricao: 'Devolvida',
            cor: '#fbd7b1'
        },
        [situacao.ENCAMINHADA]: {
            descricao: 'Aguardando resposta',
            cor: '#FFFFFF'
        },
        [situacao.AGUARDANDO_RESPOSTA_SECUNDARIA]: {
            descricao: 'Aguardando resposta complementar',
            cor: '#FFFFFF'
        },
        [situacao.AGUARDANDO_LIBERACAO]: {
            descricao: 'Resposta sendo finalizada',
            cor: '#F3A46B'
        },
        [situacao.EM_CORRECAO]: {
            descricao: 'Resposta em edição',
            cor: '#E3C5FC'
        },
        [situacao.AGUARDANDO_AVALIACAO]: {
            descricao: 'Respondida',
            cor: '#cee1f4'
        },
        [situacao.AGUARDANDO_TREPLICA]: {
            descricao: 'Aguardando resposta',
            cor: '#FFFFFF'
        },
        [situacao.TREPLICA_ENVIADA]: {
            descricao: 'Respondida',
            cor: '#cee1f4'
        },
        [situacao.CONCLUIDA]: {
            descricao: 'Avaliada',
            cor: '#b0d8b1'
        },
        [situacao.FINALIZADA_ADMINISTRATIVAMENTE]: {
            descricao: 'Finalizada administrativamente',
            cor: '#bdb76b'
        },
    },
}

const tipoTele = {
    ASSINCRONA: 'A',
    SINCRONA: 'S',
    '0800': '0800',
    CC: 'CC'
}

export const TIPO_TELECONSULTORIA = {
    TIPO: { ...tipoTele },
    LABEL: {
        [tipoTele['0800']]: '0800',
        [tipoTele.ASSINCRONA]: 'Assíncrona',
        [tipoTele.CC]: 'Conduta clínica',
        [tipoTele.SINCRONA]: 'Síncrona',
    }
}

export const CODIGOS_CBO_HISTORICO = [2251, 2252, 2253, 2231, 2232, 2235, 3222];
export const CODIGOS_CBO_MEDICO = [2251, 2252, 2253, 2231];

export const CLASSIFICACOES = {
    ENCAMINHAMENTO: 'E',
    DUVIDAS_CLINICAS_GERAIS: 'C',
    PROCESSO_TRABALHO: 'P',
    LITIGIO: 'J',
    JUDICIALIZACAO: 'U',
    CIRURGIA: 'G',
};

const orientacao = {
    MANEJO: 'M',
    ENCAMINHAMENTO: 'E',
    INFORMACOES: 'I',
    SUGESTAO: 'S',
    CIRURGIA: 'C'
}

export const ORIENTACOES_ESPECIFICAS = {
    TIPO: { ...orientacao },
    LABEL: {
        [orientacao.MANEJO]: 'Manejo',
        [orientacao.ENCAMINHAMENTO]: 'Encaminhamento',
        [orientacao.INFORMACOES]: 'Informações',
        [orientacao.SUGESTAO]: 'Segestão',
        [orientacao.CIRURGIA]: 'Cirurgia',
    },
    TEXTO: {
        [orientacao.MANEJO]: 'Manejo na APS:',
        [orientacao.ENCAMINHAMENTO]: 'Encaminhamento ao especialista:',
        [orientacao.INFORMACOES]: 'Solicito mais informações para poder colaborar:',
        [orientacao.SUGESTAO]: 'A partir da sua dúvida, sugiro:',
        [orientacao.CIRURGIA]: 'Encaminhamento para cirurgia:',
    }
}

export const DESTINO_DEVOLUCAO = {
    REGULADOR: 'R',
    SOLICITANTE: 'S',
    AMBOS: 'A'
}

export const PERMISSOES = {
    ADMIN_ESPECIFICACAO: 'TELECONSULTORIAS_ADMIN_ESPECIFICACAO',
    ADMIN_JUDIC_INSUMO: 'TELECONSULTORIAS_ADMIN_JUDIC_INSUMO',
    ADMIN_JUDIC_MEDICAME: 'TELECONSULTORIAS_ADMIN_JUDIC_MEDICAME',
    ADMIN_JUDIC_NUTRICAO: 'TELECONSULTORIAS_ADMIN_JUDIC_NUTRICAO',
    ADMIN_REDE_ACESSO: 'TELECONSULTORIAS_ADMIN_REDE_ACESSO',
    ADMIN_REDE_ALTERACAO: 'TELECONSULTORIAS_ADMIN_REDE_ALTERACAO',
    ADMIN_REDE_CADASTRO: 'TELECONSULTORIAS_ADMIN_REDE_CADASTRO',
    ADMIN_TELERREGULADOR: 'TELECONSULTORIAS_ADMIN_TELERREGULADOR',
    ADMIN_VISUALIZADOR: 'TELECONSULTORIAS_ADMIN_VISUALIZADOR',
    ADMIN_MOTIVO_INVALIDACAO: 'TELECONSULTORIAS_ADMIN_MOT_INVALIDA',
    ADMINISTRATIVO: 'TELECONSULTORIAS_ADMINISTRATIVO',
    FINALIZACAO_ADMIN: 'TELECONSULTORIAS_FINALIZACAO_ADMIN',
    REGULACAO: 'TELECONSULTORIAS_REGULACAO',
    RESPOSTA: 'TELECONSULTORIAS_RESPOSTA',
    SOLICITACAO: 'TELECONSULTORIAS_SOLICITACAO',
    TELECONSULTORIAS: 'TELECONSULTORIAS_TELECONSULTORIAS',
    VISUALIZACAO: 'TELECONSULTORIAS_VISUALIZACAO'
}

export const MYME_TYPE_ARQUIVOS = {
    JPEG: 'image/jpeg',
    PNG: 'image/png',
    PDF: 'application/pdf',
    DOC: 'application/msword',
    DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    XLS: 'application/vnd.ms-excel',
    XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
}

export const MYME_TYPE_PERMITIDOS = Object.values(MYME_TYPE_ARQUIVOS);

export const VINCULO_PERFIL_ESTADUAL = 'E';
export const VINCULO_PERFIL_REDE = 'D';

export const AUTH_MODO_COM_FOCO = 'RACF';
export const AUTH_MODO_SEM_FOCO = 'RASF';

const operadorRestricaoIdade = {
    MENOR: '<',
    MENOR_IGUAL: '<=',
    MAIOR: '>',
    MAIOR_IGUAL: '>=',
}

export const OPERADOR_RESTRICAO_IDADE = {
    TIPO: { ...operadorRestricaoIdade },
    LABEL: {
        [operadorRestricaoIdade.MENOR]: 'Menor',
        [operadorRestricaoIdade.MENOR_IGUAL]: 'Menor ou igual',
        [operadorRestricaoIdade.MAIOR]: 'Maior',
        [operadorRestricaoIdade.MAIOR_IGUAL]: 'Maior ou igual',
    },
    TEXTO: {
        [operadorRestricaoIdade.MENOR]: (idade) => `Menor que ${idade} anos`,
        [operadorRestricaoIdade.MENOR_IGUAL]: (idade) => `Menor ou igual a ${idade} anos`,
        [operadorRestricaoIdade.MAIOR]: (idade) => `Maior que ${idade} anos`,
        [operadorRestricaoIdade.MAIOR_IGUAL]: (idade) => `Maior ou igual a ${idade} anos`,
    }
}

export const TIPO_AGENDA = {
    PERSONALIZADA: 'P',
    INTEGRAL: 'I'
}

export const TIPO_REGULACAO = {
    MUNICIPAL: 'M',
    ESTADUAL: 'E',
    LOCAL: 'L'
}