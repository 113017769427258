import * as yup from 'yup';
import {
    GERAL,
    ASSUNTO,
    ASSUNTO_TEXTUAL,
    COMPLEMENTO_ASSUNTO
} from '../../../solicitacao/geral/form/fieldNames';

export default (strings) => {
    return yup.object().shape({
        [GERAL]: yup.object().shape({
            [ASSUNTO]: yup
                .object().shape({
                    id: yup.number(),
                    descricao: yup.string()
                })
                .nullable()
                .required(strings.campoObrigatorio),
            [COMPLEMENTO_ASSUNTO]: yup
                .string()
                .trim()
                .nullable()
                .when(ASSUNTO_TEXTUAL, {
                    is: (assuntoTextual) => assuntoTextual,
                    then: yup
                        .string()
                        .min(10, strings.erroConteudoHtml)
                        .required(strings.campoObrigatorio)
                }),
        }).required()
    });
}
