import React, { memo, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
    SttText,
    SttHidden,
    SttTable,
    SttTableBody,
    SttTableCell,
    SttTableRow,
    SttTableHead,
    SttDivider,
    SttHeading,
    SttTranslateHook,
} from '@stt-componentes/core';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1.5)
    },
    divider: {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5)
    },
}));

const ExibicaoProcedimentosCbhpm = ({ procedimentos }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();

    return (
        procedimentos?.length > 0 ?
            <>
                <SttHeading variant="h4" color="primary" className={classes.root}>{strings.procedimentosCbhpm}</SttHeading>
                <SttTable>
                    <SttTableHead>
                        <SttTableRow>
                            <SttHidden xsDown>
                                <SttTableCell width="10%">
                                    {strings.codigo}
                                </SttTableCell>
                                <SttTableCell width="68%">
                                    {strings.descricao}
                                </SttTableCell>
                                <SttTableCell width="22%">
                                    {strings.anoCompetencia}
                                </SttTableCell>
                            </SttHidden>
                            <SttHidden smUp>
                                <SttTableCell width="100%" colSpan="2">{strings.procedimentosCbhpm}</SttTableCell>
                            </SttHidden>
                        </SttTableRow>
                    </SttTableHead>
                    <SttTableBody>
                        {
                            procedimentos.map((row, index) => (
                                <SttTableRow key={index}>
                                    <SttHidden xsDown>
                                        <SttTableCell>{row.codigo}</SttTableCell>
                                        <SttTableCell>{row.descricao}</SttTableCell>
                                        <SttTableCell>{row.ano_competencia}</SttTableCell>
                                    </SttHidden>
                                    <SttHidden smUp>
                                        <SttTableCell width="100%">
                                            <SttText size="small"><b>{strings.codigo}:</b> {row.codigo}</SttText>
                                            <SttText size="small"><b>{strings.descricao}:</b> {row.descricao}</SttText>
                                            <SttText size="small"><b>{strings.anoCompetencia}:</b> {row.ano_competencia}</SttText>
                                        </SttTableCell>
                                    </SttHidden>
                                </SttTableRow>
                            ))
                        }
                    </SttTableBody>
                </SttTable>
                <SttDivider className={classes.divider} />
            </>
            : null
    )
};

export default memo(ExibicaoProcedimentosCbhpm);