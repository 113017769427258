import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Switch, Route } from 'react-router-dom';
import Menu from '../componentes/menu';
import {
    SttCircularProgress,
    SttCacheManager
} from '@stt-componentes/core';
import Pesquisa from './pesquisa';
import Solicitacao from './solicitacao';
import Regulacao from './regulacao';
import Resposta from './resposta';
import Respondidas from './respondidas';
import Administrativo from './administrativo';
import Inicio from './inicio';
import Erro401 from './erro/401';
import { Cache } from '@stt-componentes/cache';
import usuario from '../signals/usuario';
import { useSignals } from '@preact/signals-react/runtime';

const useStyles = makeStyles(theme => ({
    wrapper: {
        paddingBottom: theme.spacing(4)
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '300px'
    }
}));

const Interno = () => {
    const classes = useStyles();
    useSignals();

    return (
        usuario.value.autenticado
            ?
            <div className={classes.wrapper}>
                <Menu />
                <Switch>
                    <Route path={['/', '/historico']} exact component={Pesquisa} />
                    <Route path="/inicio" exact component={Inicio} />
                    <Route path="/nao-autorizado" exact component={Erro401} />
                    <Route path="/solicitacao" exact component={Solicitacao} />
                    <Route path="/administrativo" exact component={Administrativo} />
                    <Route path="/regulacao" exact component={Regulacao} />
                    <Route path="/resposta" exact component={Resposta} />
                    <Route path="/respondidas" exact component={Respondidas} />
                </Switch>
            </div>
            :
            <>
                <div className={classes.loading}>
                    <SttCircularProgress />
                </div>
                <SttCacheManager config={global.gConfig} onSync={loaded => {
                    usuario.value = { ...Cache.getUserInfo(), autenticado: loaded };
                }} />
            </>
    );

};

export default Interno;