import * as yup from 'yup';
import {
    ADENDO_HANSENIASE,
    BACILOSCOPIA,
    INDICE_BACILOSCOPICO,
    MOTIVO_ENCAMINHAMENTO,
    OUTRO_MOTIVO_ENCAMINHAMENTO,
} from './fieldNames';
import { GERAL, REGIAO } from '../../../geral/form/fieldNames';
import { FORMULARIOS_ADENDOS } from '../../../../../common/ConstantsHanseniase';

export default (strings) => {
    return yup.object().shape({
        [ADENDO_HANSENIASE]: yup.object()
            .when(`${GERAL}.${REGIAO}`, {
                is: (regiao) => regiao && regiao.adendo_formulario === FORMULARIOS_ADENDOS.HANSENIASE,
                then: yup
                    .object()
                    .shape({
                        [MOTIVO_ENCAMINHAMENTO]: yup
                            .string()
                            .trim()
                            .nullable()
                            .required(strings.campoObrigatorio),
                        [OUTRO_MOTIVO_ENCAMINHAMENTO]: yup
                            .string()
                            .nullable()
                            .when(MOTIVO_ENCAMINHAMENTO, {
                                is: (motivo) => motivo === 'O',
                                then: yup
                                    .string()
                                    .nullable()
                                    .required(strings.campoObrigatorio),
                            }),
                        [BACILOSCOPIA]: yup
                            .string()
                            .trim()
                            .nullable()
                            .required(strings.campoObrigatorio),
                        [INDICE_BACILOSCOPICO]: yup
                            .number()
                            .nullable()
                            .min(0, strings.erroIndiceBaciloscopicoValor)
                            .max(6, strings.erroIndiceBaciloscopicoValor)
                    })
            })
    });
}
