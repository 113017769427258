import * as yup from 'yup';
import {
    CABECALHO,
    RESPOSTA_DIRETA,
    ATRIBUTOS_APS,
    CLASSIFICACAO_RISCO,
    EXAMES_COMPLEMENTARES,
    EXIBIR_EXAMES_COMPLEMENTARES,
    EXIBIR_MANEJO_PREVIO,
    MAIS_INFORMACOES,
    MANEJO_APS,
    MANEJO_PREVIO_APS,
    ORIENTACAO_ESPECIFICA,
    SUGIRO,
    EXIBIR_ORIENTACAO_ESPECIFICA
} from './fieldNames';
import { OPCOES_ORIENTACOES_ESPECIFICAS } from '../../../../common/ConstantsResposta';

export default (strings) => {
    let schemaCampo = yup
        .string()
        .trim()
        .nullable()
        .test('html-valido', strings.erroConteudoHtml, (html) => {
            html = (html || '').replace(/<[^>]*>?/gm, '');
            if (html.length === 0 || html.length >= 10) {
                return true;
            }
            return false;
        });

    let schemaObrigatorio = schemaCampo.required(strings.campoObrigatorio);

    return yup.object().shape({
        [CABECALHO]: yup.object()
            .required()
            .shape({
                [ORIENTACAO_ESPECIFICA]: yup
                    .string()
                    .trim()
                    .nullable()
                    .when(EXIBIR_ORIENTACAO_ESPECIFICA, {
                        is: (exibir) => exibir,
                        then: yup
                            .string()
                            .required(strings.campoObrigatorio)
                    }),
                [RESPOSTA_DIRETA]: schemaObrigatorio,
                [ATRIBUTOS_APS]: schemaCampo,
                [MANEJO_APS]: schemaCampo
                    .when(ORIENTACAO_ESPECIFICA, {
                        is: (orientacao) => orientacao === OPCOES_ORIENTACOES_ESPECIFICAS.MANEJO_APS,
                        then: yup
                            .string()
                            .trim()
                            .required(strings.campoObrigatorio)
                    }),
                [MAIS_INFORMACOES]: schemaCampo
                    .when(ORIENTACAO_ESPECIFICA, {
                        is: (orientacao) => orientacao === OPCOES_ORIENTACOES_ESPECIFICAS.MAIS_INFORMACOES,
                        then: yup
                            .string()
                            .trim()
                            .required(strings.campoObrigatorio)
                    }),
                [SUGIRO]: schemaCampo
                    .when(ORIENTACAO_ESPECIFICA, {
                        is: (orientacao) => orientacao === OPCOES_ORIENTACOES_ESPECIFICAS.SUGIRO,
                        then: yup
                            .string()
                            .trim()
                            .required(strings.campoObrigatorio)
                    }),
                [CLASSIFICACAO_RISCO]: yup
                    .string()
                    .trim()
                    .nullable()
                    .when(ORIENTACAO_ESPECIFICA, {
                        is: (orientacao) => orientacao === OPCOES_ORIENTACOES_ESPECIFICAS.ENCAMINHAMENTO,
                        then: yup
                            .string()
                            .trim()
                            .nullable()
                            .required(strings.campoObrigatorio)
                    }),
                [MANEJO_PREVIO_APS]: schemaCampo
                    .when([ORIENTACAO_ESPECIFICA, EXIBIR_MANEJO_PREVIO], {
                        is: (orientacao, exibir) => (orientacao === OPCOES_ORIENTACOES_ESPECIFICAS.ENCAMINHAMENTO && exibir),
                        then: yup
                            .string()
                            .trim()
                            .required(strings.campoObrigatorio)
                    }),
                [EXAMES_COMPLEMENTARES]: schemaCampo
                    .when([ORIENTACAO_ESPECIFICA, EXIBIR_EXAMES_COMPLEMENTARES], {
                        is: (orientacao, exibir) => (orientacao === OPCOES_ORIENTACOES_ESPECIFICAS.ENCAMINHAMENTO && exibir),
                        then: yup
                            .string()
                            .trim()
                            .required(strings.campoObrigatorio)
                    })
            })
    });
}
