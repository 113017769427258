import React, { memo, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
    SttTextItem,
    SttDivider,
    SttHeading,
    SttNotification,
    SttTranslateHook
} from '@stt-componentes/core';
import ExibicaoHtml from './exibicaoHtml';
import { PLANOS_SUGERIDOS } from '../../common/ConstantsSincrona';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1.5)
    },
    notificacao: {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5),
    },
}));

const Divider = memo((props) => {
    return (
        <SttDivider {...props} />
    )
});

const RespostaSincronaClinicaFamiliar = ({ teleconsultoria }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();

    return (
        <>
            {
                teleconsultoria.numero_integrantes_familia !== null &&
                <SttTextItem title={strings.numeroIntegrantes} content={teleconsultoria.numero_integrantes_familia} />
            }
            {
                teleconsultoria.parentesco?.length > 0 &&
                <>
                    <SttHeading variant="h4" color="primary" className={classes.root}>{strings.conformacaoFamiliar}</SttHeading>
                    {
                        teleconsultoria.parentesco.map((par, index) => {
                            let texto = par.cadastro_descricao.trim();

                            if (par.complemento_descricao) {
                                texto += ` - ${par.descricao}`;
                            } else {
                                texto += ` - ${par.complemento_numero_pessoas}`;
                            }

                            return (
                                <SttTextItem key={`A_${index}`} title={(index + 1)} content={texto} />
                            )
                        })
                    }
                    <Divider />
                </>
            }
            <ExibicaoHtml titulo={strings.redeApoio} html={teleconsultoria.rede_apoio_familia} />
            <ExibicaoHtml titulo={strings.abordagemRealizada} html={teleconsultoria.abordagem_realizada_familia} />
            <ExibicaoHtml titulo={strings.analiseProblema} html={teleconsultoria.analise_problema_familia} />
            <ExibicaoHtml titulo={strings.discussao} html={teleconsultoria.discussao_familia} />
            {
                teleconsultoria.plano_sugerido_familia &&
                <SttTextItem title={strings.planoSugerido} content={PLANOS_SUGERIDOS.LABEL[teleconsultoria.plano_sugerido_familia]} />
            }
            {
                teleconsultoria.id_segunda_opiniao_duvida_familia &&
                <SttNotification severity="info" className={classes.notificacao}>
                    {strings.encaminhadoSegundaOpiniao}
                </SttNotification>
            }
        </>
    )
}

export default memo(RespostaSincronaClinicaFamiliar);