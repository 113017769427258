import * as yup from 'yup';
import { validateBr } from 'js-brasil';
import {
    DATA_DE_SOLICITACAO,
    DATA_ATE_SOLICITACAO,
    DATA_DE_RESPOSTA,
    DATA_ATE_RESPOSTA,
    NOME_PACIENTE,
    CPF_PACIENTE,
    CNS_PACIENTE,
    NOME_SOLICITANTE,
    CPF_SOLICITANTE,
    NOME_TELECONSULTOR,
} from './fieldNames';

export default (strings) => {
    return yup.object().shape({
        [DATA_DE_SOLICITACAO]: yup
            .date()
            .typeError(strings.dataInvalida)
            .max(new Date(), strings.erroDataFutura)
            .nullable(),
        [DATA_ATE_SOLICITACAO]: yup
            .date()
            .typeError(strings.dataInvalida)
            .min(yup.ref(DATA_DE_SOLICITACAO), strings.erroDataMenorDataInicial)
            .max(new Date(), strings.erroDataFutura)
            .nullable(),
        [DATA_DE_RESPOSTA]: yup
            .date()
            .typeError(strings.dataInvalida)
            .max(new Date(), strings.erroDataFutura)
            .nullable(),
        [DATA_ATE_RESPOSTA]: yup
            .date()
            .typeError(strings.dataInvalida)
            .min(yup.ref(DATA_DE_RESPOSTA), strings.erroDataMenorDataInicial)
            .max(new Date(), strings.erroDataFutura)
            .nullable(),
        [NOME_PACIENTE]: yup
            .string()
            .trim()
            .max(100, strings.erroNome100Caracteres)
            .nullable(),
        [CPF_PACIENTE]: yup
            .string()
            .nullable()
            .test('cpf-valido', strings.cpfInvalido, (cpf) => {
                cpf = (cpf || '').replace(/\D+/g, '');
                if (cpf.length === 0) {
                    return true;
                }
                return validateBr.cpf(cpf);
            }),
        [CNS_PACIENTE]: yup
            .string()
            .trim()
            .test('cns-valido', strings.cnsInvalido, (cns) => {
                cns = cns || '';
                if (cns.length === 0) {
                    return true;
                }
                return validateBr.cns(cns);
            })
            .nullable(),
        [NOME_SOLICITANTE]: yup
            .string()
            .trim()
            .max(100, strings.erroNome100Caracteres)
            .nullable(),
        [CPF_SOLICITANTE]: yup
            .string()
            .nullable()
            .test('cpf-valido', strings.cpfInvalido, (cpf) => {
                cpf = (cpf || '').replace(/\D+/g, '');
                if (cpf.length === 0) {
                    return true;
                }
                return validateBr.cpf(cpf);
            }),
        [NOME_TELECONSULTOR]: yup
            .string()
            .trim()
            .max(100, strings.erroNome100Caracteres)
            .nullable(),
    });
}
