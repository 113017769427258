import React, { useEffect, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
    SttTable,
    SttTableHead,
    SttTableBody,
    SttTableRow,
    SttTableCell,
    SttTableSortLabel,
    SttTablePagination,
    SttCircularProgress,
    SttSeeButton,
    SttHidden,
    SttText,
    SttNotification,
    SttAlertTitle,
    SttTranslateHook
} from '@stt-componentes/core';
import axios from 'axios';
import { getHeaders } from '../../request';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Detalhes from '../visualizacao/detalhes';
import { batch, signal } from '@preact/signals-react';
import { useSignalEffect, useSignals } from '@preact/signals-react/runtime';
import { Chip } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    tableWrapper: {
        marginTop: theme.spacing(2)
    },
    table: {
        overflow: 'auto'
    },
    notificacao: {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1),
    },
    alertTitle: {
        marginBottom: 0
    },
    chip: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        color: 'white',
        fontWeight: 'bold',
        height: 'auto',
        textShadow: '0 0 2px black',
        '& span': {
            whiteSpace: 'pre-wrap'
        }
    }
}));

const ORDENACAO = {
    DIRECAO: {
        ASC: 'asc',
        DESC: 'desc'
    },
    CAMPOS: {
        ASSUNTO: 'assunto',
        CIDADE: 'nome_cidade',
        DATA_RESPOSTA: 'data_resposta',
        DATA_SOLICITACAO: 'data',
        AVALIADA: 'avaliada',
        NUMERO: 'id',
        REDE: 'nome_regiao',
        TELECONSULTOR: 'nome_teleconsultor'
    }
};

const solicitacoes = signal([]);
const totalRegistros = signal(0);
const buscaEmAndamento = signal(false);
const page = signal(null)
const count = signal(null);
const order = signal(ORDENACAO.DIRECAO.DESC);
const orderBy = signal(ORDENACAO.CAMPOS.DATA_SOLICITACAO);
const buscar = signal(false);

const PesquisaNaoAvaliadas = ({ adicionarAba, removerAbaId }) => {
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);

    useSignals();

    useEffect(() => {
        batch(() => {
            page.value = global.gConfig.pagination.start;
            count.value = global.gConfig.pagination.count;
            buscar.value = true;
        });
    }, []);

    const handleClickSort = (campo) => {
        const isAsc = (orderBy.value === campo) && (order.value === ORDENACAO.DIRECAO.ASC);
        const newOrder = isAsc ? ORDENACAO.DIRECAO.DESC : ORDENACAO.DIRECAO.ASC;
        batch(() => {
            order.value = newOrder;
            orderBy.value = campo;
            buscar.value = true;
        });
    }

    const handleChangePage = (event, newPage) => {
        batch(() => {
            page.value = newPage
            buscar.value = true;
        });
    };

    const handleChangeRowsPerPage = event => {
        batch(() => {
            count.value = event.target.value;
            page.value = global.gConfig.pagination.start;
            buscar.value = true;
        });
    };

    const removerAba = (idAba) => () => {
        removerAbaId(idAba);
        buscar.value = true;
    }

    useSignalEffect(() => {
        if (buscar.value) {
            buscarTeleconsultorias();
        }
    });

    const buscarTeleconsultorias = () => {
        buscaEmAndamento.value = true;

        let params = { respondidas: true };

        if (orderBy.value && order.value) {
            params.sort = orderBy.value;
            params.direction = order.value;
        }

        const offset = (page.value * count.value);
        params.start = offset;
        params.count = count.value;

        axios.get(`${global.gConfig.url_base_teleconsultorias}/solicitacao/aceleradores`, { params, headers: getHeaders() })
            .then((response) => {
                const { data } = response.data;
                totalRegistros.value = parseInt(data.totalRegistros);
                solicitacoes.value = data.itens;
            })
            .catch(err => {
                totalRegistros.value = 0;
                solicitacoes.value = [];
            })
            .finally(() => {
                buscaEmAndamento.value = false;
                buscar.value = false;
            });
    };

    return (
        <>
            <SttNotification severity="info" className={classes.notificacao}>
                <SttAlertTitle className={classes.alertTitle}>{strings.avisoAvaliacao}</SttAlertTitle>
            </SttNotification>
            <TableContainer className={classes.tableWrapper} component={Paper}>
                <SttTable>
                    <SttTableHead>
                        <SttTableRow>
                            <SttHidden xsDown>
                                <SttTableCell width="10%" sortDirection={orderBy.value === ORDENACAO.CAMPOS.NUMERO ? order.value : false}>
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.NUMERO}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.NUMERO ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.NUMERO)}>
                                        {strings.numeroTeleconsultoria}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="13%" sortDirection={orderBy.value === ORDENACAO.CAMPOS.DATA_SOLICITACAO ? order.value : false}>
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.DATA_SOLICITACAO}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.DATA_SOLICITACAO ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.DATA_SOLICITACAO)}>
                                        {strings.dataSolicitacao}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="13%" sortDirection={orderBy.value === ORDENACAO.CAMPOS.DATA_RESPOSTA ? order.value : false}>
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.DATA_RESPOSTA}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.DATA_RESPOSTA ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.DATA_RESPOSTA)}>
                                        {strings.dataResposta}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="15%" sortDirection={orderBy.value === ORDENACAO.CAMPOS.REDE ? order.value : false}>
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.REDE}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.REDE ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.REDE)}>
                                        {strings.redeTeleconsultoria}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="15%" sortDirection={orderBy.value === ORDENACAO.CAMPOS.TELECONSULTOR ? order.value : false}>
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.TELECONSULTOR}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.TELECONSULTOR ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.TELECONSULTOR)}>
                                        {strings.teleconsultor}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="20%" sortDirection={orderBy.value === ORDENACAO.CAMPOS.ASSUNTO ? order.value : false}>
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.ASSUNTO}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.ASSUNTO ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.ASSUNTO)}>
                                        {strings.assunto}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="8%" sortDirection={orderBy.value === ORDENACAO.CAMPOS.AVALIADA ? order.value : false}>
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.AVALIADA}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.AVALIADA ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.AVALIADA)}>
                                        {strings.avaliada}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="6%" align="center">
                                    {strings.visualizar}
                                </SttTableCell>
                            </SttHidden>
                            <SttHidden smUp>
                                <SttTableCell width="100%" colSpan="2">{strings.teleconsultorias}</SttTableCell>
                            </SttHidden>
                        </SttTableRow>
                    </SttTableHead>
                    <SttTableBody>
                        {
                            buscaEmAndamento.value
                                ?
                                <SttTableRow key={-1}>
                                    <SttTableCell colSpan={7}
                                        align="center">
                                        <SttCircularProgress />
                                    </SttTableCell>
                                </SttTableRow>
                                :
                                (
                                    solicitacoes.value.length === 0 ?
                                        <SttTableRow key={-1}>
                                            <SttTableCell colSpan={7} align="center">
                                                {strings.nenhumRegistroEncontrado}
                                            </SttTableCell>
                                        </SttTableRow>
                                        :
                                        solicitacoes.value.map((row, index) => (
                                            <SttTableRow hover key={index}>
                                                <SttHidden xsDown>
                                                    <SttTableCell>{row.id}</SttTableCell>
                                                    <SttTableCell>{moment(row.data).format('DD/MM/YYYY HH:mm:ss')}</SttTableCell>
                                                    <SttTableCell>{moment(row.data_resposta).format('DD/MM/YYYY HH:mm:ss')}</SttTableCell>
                                                    <SttTableCell>{row.nome_regiao}</SttTableCell>
                                                    <SttTableCell>{row.nome_teleconsultor}</SttTableCell>
                                                    <SttTableCell>{row.assunto}</SttTableCell>
                                                    <SttTableCell>
                                                        <Chip
                                                            className={classes.chip}
                                                            style={{ backgroundColor: row.avaliada ? '#8AC58B' : '#D88080' }}
                                                            label={row.avaliada ? strings.sim : strings.nao}
                                                        />
                                                    </SttTableCell>
                                                </SttHidden>
                                                <SttHidden smUp>
                                                    <SttTableCell width="96%">
                                                        <SttText size="small"><b>{strings.numero}:</b> {row.id}</SttText>
                                                        <SttText size="small"><b>{strings.dataSolicitacao}:</b> {moment(row.data).format('DD/MM/YYYY HH:mm:ss')}</SttText>
                                                        <SttText size="small"><b>{strings.dataResposta}:</b> {moment(row.data_resposta).format('DD/MM/YYYY HH:mm:ss')}</SttText>
                                                        <SttText size="small"><b>{strings.teleconsultor}:</b> {row.nome_teleconsultor}</SttText>
                                                        <SttText size="small"><b>{strings.avaliada}</b> {row.avaliada ? strings.sim : strings.nao}</SttText>
                                                    </SttTableCell>
                                                </SttHidden>
                                                <SttTableCell align="center">
                                                    <SttSeeButton
                                                        id={`btn-ver-solicitacao-${index}`}
                                                        onClick={() => {
                                                            adicionarAba({
                                                                titulo: `${strings.solicitacao} - ${row.id}`,
                                                                conteudo: Detalhes,
                                                                id: row.id,
                                                                adicionarAba,
                                                                removerAba: removerAba(`${row.tipo_teleconsultoria}_${row.id}`),
                                                                idAba: `${row.tipo_teleconsultoria}_${row.id}`,
                                                            });
                                                        }}
                                                    />
                                                </SttTableCell>
                                            </SttTableRow>
                                        ))
                                )
                        }
                    </SttTableBody>
                </SttTable>
            </TableContainer>
            {
                solicitacoes.value.length > 0 &&
                <SttTablePagination
                    rowsPerPageOptions={[10, 20, 40]}
                    component="div"
                    count={totalRegistros.value}
                    rowsPerPage={count.value}
                    page={page.value}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={strings.linhasPorPagina}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${strings.de} ${count}`}
                />
            }
        </>
    )
}

export default PesquisaNaoAvaliadas;