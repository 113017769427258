import React, { useEffect, lazy, Suspense, memo, useContext } from 'react';
import {
    SttTabs,
    SttCircularProgress,
    SttTranslateHook
} from '@stt-componentes/core';
import { makeStyles } from "@material-ui/core/styles";
import { temPermissaoRBAC } from '../../security/rbac';
import { PERMISSOES } from '../../common/Constants';
import { useSignal, useSignals } from '@preact/signals-react/runtime';
import usuario from '../../signals/usuario';

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: theme.spacing(1.5)
    },
}));


const EspecificacoesSuspense = memo((props) => {
    const EspecificacaoTeleconsultoria = lazy(() => import('../../componentes/administrativo/especificacao/pesquisa'));

    return (
        <Suspense fallback={<SttCircularProgress color="primary" />}>
            <EspecificacaoTeleconsultoria {...props} />
        </Suspense>
    )
});

const ReguladoresSuspense = memo((props) => {
    const Reguladores = lazy(() => import('../../componentes/administrativo/regulador'));

    return (
        <Suspense fallback={<SttCircularProgress color="primary" />}>
            <Reguladores {...props} />
        </Suspense>
    )
});

const VisualizadorSuspense = memo((props) => {
    const Visualizador = lazy(() => import('../../componentes/administrativo/visualizador'));

    return (
        <Suspense fallback={<SttCircularProgress color="primary" />}>
            <Visualizador {...props} />
        </Suspense>
    )
});

const RegioesTeleconsultoriaSuspense = memo((props) => {
    const RegioesTeleconsultoria = lazy(() => import('../../componentes/administrativo/regiao-teleconsultoria/pesquisa'));

    return (
        <Suspense fallback={<SttCircularProgress color="primary" />}>
            <RegioesTeleconsultoria {...props} />
        </Suspense>
    )
});

const MejudInsumosSuspense = memo((props) => {
    const MejudInsumos = lazy(() => import('../../componentes/administrativo/insumos/pesquisa'));

    return (
        <Suspense fallback={<SttCircularProgress color="primary" />}>
            <MejudInsumos {...props} />
        </Suspense>
    )
});

const MejudNutricaoSuspense = memo((props) => {
    const MejudNutricao = lazy(() => import('../../componentes/administrativo/nutricao/pesquisa'));

    return (
        <Suspense fallback={<SttCircularProgress color="primary" />}>
            <MejudNutricao {...props} />
        </Suspense>
    )
});

const MedicamentoSuspense = memo((props) => {
    const Medicamento = lazy(() => import('../../componentes/administrativo/medicamento/pesquisa'));

    return (
        <Suspense fallback={<SttCircularProgress color="primary" />}>
            <Medicamento {...props} />
        </Suspense>
    )
});

const motivosInvalidacaoSuspense = memo((props) => {
    const MotivosInvalidacao = lazy(() => import('../../componentes/administrativo/motivos-invalidacao/pesquisa'));

    return (
        <Suspense fallback={<SttCircularProgress color="primary" />}>
            <MotivosInvalidacao {...props} />
        </Suspense>
    )
});

const IndexAdministrativo = () => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    useSignals();

    const abas = useSignal([]);
    const abaAtiva = useSignal('0');

    const adicionarAba = (aba) => {
        let dadosAbas = [...abas.value];

        let { idAba } = aba;
        // Controle para evitar adição de aba repetida
        if (!dadosAbas.some(a => a.idAba === idAba)) {
            dadosAbas.push(aba);
            abas.value = dadosAbas;
        }

        setTimeout(() => {
            abaAtiva.value = dadosAbas.findIndex(a => a.idAba === idAba).toString();
        }, 120);
    };

    const removerAba = (indice) => {
        abaAtiva.value = '0';
        const i = indice[0];

        const dadosAbas = [
            ...abas.value.slice(0, i),
            ...abas.value.slice(i + 1)
        ];

        setTimeout(() => {
            abas.value = dadosAbas;
        }, 120);
    }

    useEffect(() => {
        let dadosAbas = [];

        if (temPermissaoRBAC(usuario, [PERMISSOES.ADMIN_REDE_CADASTRO, PERMISSOES.ADMIN_REDE_ALTERACAO, PERMISSOES.ADMIN_REDE_ACESSO])) {
            dadosAbas.push({
                titulo: strings.cadastroRedes,
                conteudo: RegioesTeleconsultoriaSuspense,
                key: 'RegioesTeleconsultoriaSuspense',
                permanente: true,
                adicionarAba,
            });
        }

        if (temPermissaoRBAC(usuario, PERMISSOES.ADMIN_ESPECIFICACAO)) {
            dadosAbas.push({
                titulo: strings.especificacoes,
                conteudo: EspecificacoesSuspense,
                permanente: true,
                key: 'EspecificacoesSuspense'
            });
        }

        if (temPermissaoRBAC(usuario, PERMISSOES.ADMIN_TELERREGULADOR)) {
            dadosAbas.push({
                titulo: strings.reguladores,
                conteudo: ReguladoresSuspense,
                key: 'ReguladoresSuspense',
                permanente: true,
            });
        }

        if (temPermissaoRBAC(usuario, PERMISSOES.ADMIN_VISUALIZADOR)) {
            dadosAbas.push({
                titulo: strings.visualizadores,
                conteudo: VisualizadorSuspense,
                key: 'VisualizadorSuspense',
                permanente: true,
            });
        }

        if (temPermissaoRBAC(usuario, PERMISSOES.ADMIN_JUDIC_INSUMO)) {
            dadosAbas.push({
                titulo: strings.insumos,
                conteudo: MejudInsumosSuspense,
                permanente: true,
                key: 'MejudSuspense'
            });
        }

        if (temPermissaoRBAC(usuario, PERMISSOES.ADMIN_JUDIC_NUTRICAO)) {
            dadosAbas.push({
                titulo: strings.nutricao,
                conteudo: MejudNutricaoSuspense,
                permanente: true,
                key: 'MejudNutricaoSuspense'
            });
        }

        if (temPermissaoRBAC(usuario, PERMISSOES.ADMIN_JUDIC_MEDICAME)) {
            dadosAbas.push({
                titulo: 'medicamento',
                conteudo: MedicamentoSuspense,
                permanente: true,
                key: 'MedicamentoSuspense'
            });
        }

        if (temPermissaoRBAC(usuario, PERMISSOES.ADMIN_MOTIVO_INVALIDACAO)) {
            dadosAbas.push({
                titulo: strings.motivosInvalidacao,
                conteudo: motivosInvalidacaoSuspense,
                key: 'motivosInvalidacaoSuspense',
                permanente: true,
            });
        }

        abas.value = dadosAbas;
    }, []);

    return (
        <SttTabs abas={abas.value}
            canClose={true}
            className={classes.wrapper}
            handleChangeAbaAtiva={(abaNova) => abaAtiva.value = abaNova}
            abaAtiva={abaAtiva.value}
            handleCloseTab={(indice) => removerAba(indice)}
            permanente={true}
            preload={false}
        />
    );
};

export default memo(IndexAdministrativo);