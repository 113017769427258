import React, { useEffect, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
    SttTable,
    SttTableHead,
    SttTableBody,
    SttTableRow,
    SttTableCell,
    SttTableSortLabel,
    SttTablePagination,
    SttCircularProgress,
    SttEditButton,
    SttHidden,
    SttText,
    SttNotification,
    SttAlertTitle,
    SttTranslateHook
} from '@stt-componentes/core';
import axios from 'axios';
import { getHeaders } from '../../request';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import { SITUACAO_TELECONSULTORIA } from '../../common/Constants';
import ConteinerResposta from './index';
import Chip from '@material-ui/core/Chip';
import Detalhes from '../visualizacao/detalhes';
import { batch, signal } from '@preact/signals-react';
import { useSignalEffect, useSignals } from '@preact/signals-react/runtime';

const useStyles = makeStyles(theme => ({
    tableWrapper: {
        marginTop: theme.spacing(2)
    },
    table: {
        overflow: 'auto'
    },
    chip: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        color: 'white',
        fontWeight: 'bold',
        height: 'auto',
        textShadow: '0 0 2px black',
        '& span': {
            whiteSpace: 'pre-wrap'
        }
    },
    notificacao: {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1),
        '& div': {
            paddingBottom: 0,
        }
    },
}));

const ORDENACAO = {
    DIRECAO: {
        ASC: 'asc',
        DESC: 'desc'
    },
    CAMPOS: {
        NUMERO: 'id',
        DATA_SOLICITACAO: 'data',
        CIDADE: 'nome_cidade',
        SOLICITANTE: 'nome_solicitante',
        REDE: 'descricao_rede'
    }
};

const solicitacoes = signal([]);
const totalRegistros = signal(0);
const buscaEmAndamento = signal(false);
export const page = signal(null)
const count = signal(null);
const order = signal(ORDENACAO.DIRECAO.ASC);
const orderBy = signal(ORDENACAO.CAMPOS.DATA_SOLICITACAO);
export const buscarRespostas = signal(false);
const indicadores = signal(null);

const PesquisaResposta = ({ adicionarAba, removerAbaId }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    useSignals();

    useEffect(() => {
        batch(() => {
            page.value = global.gConfig.pagination.start;
            count.value = global.gConfig.pagination.count;
            buscarRespostas.value = true;
        });
    }, []);

    const handleClickSort = (campo) => {
        const isAsc = (orderBy.value === campo) && (order.value === ORDENACAO.DIRECAO.ASC);
        const newOrder = isAsc ? ORDENACAO.DIRECAO.DESC : ORDENACAO.DIRECAO.ASC;
        batch(() => {
            order.value = newOrder;
            orderBy.value = campo;
            buscarRespostas.value = true;
        });
    }

    const handleChangePage = (event, newPage) => {
        batch(() => {
            page.value = newPage
            buscarRespostas.value = true;
        });
    };

    const handleChangeRowsPerPage = event => {
        batch(() => {
            count.value = event.target.value;
            page.value = global.gConfig.pagination.start;
            buscarRespostas.value = true;
        });
    };

    const removerAba = (idAba) => () => {
        buscarRespostas.value = true;
        removerAbaId(idAba);
    }

    const switchPrazo = (tele) => {
        if (tele.situacao === SITUACAO_TELECONSULTORIA.SITUACAO.AGUARDANDO_TREPLICA) {
            return (
                <Chip
                    className={classes.chip}
                    style={{ backgroundColor: '#0A6FB8' }}
                    label={strings.aguardandoTreplica}
                />
            );
        }
        if (tele.situacao === SITUACAO_TELECONSULTORIA.SITUACAO.AGUARDANDO_RESPOSTA_SECUNDARIA) {
            return (
                <Chip
                    className={classes.chip}
                    style={{ backgroundColor: '#0A6FB8' }}
                    label={strings.aguardandoComplementoResposta}
                />
            );
        }
        let now = moment().startOf('day');
        let dataMomentFormatada = moment(tele.data_inclusao);
        let diff = moment.duration(now.diff(dataMomentFormatada)).asHours();

        const prazoVencimento = tele.prazo_vencimento;
        const prazoQuaseVencimento = Math.floor(prazoVencimento * 2 / 3);

        if (diff > prazoVencimento) {
            return (
                <Chip
                    className={classes.chip}
                    style={{ backgroundColor: '#D88080' }}
                    label={strings.prazoVencido}
                />
            );
        } else if (diff > prazoQuaseVencimento) {
            return (
                <Chip
                    className={classes.chip}
                    style={{ backgroundColor: '#D1D64C' }}
                    label={strings.prazoVencendo}
                />
            );
        }
        return (
            <Chip
                className={classes.chip}
                style={{ backgroundColor: '#8AC58B' }}
                label={strings.prazoNormal}
            />
        );

    }

    const buscarTeleconsultorias = () => {
        buscaEmAndamento.value = true;

        let params = { responder: true };

        if (orderBy.value && order.value) {
            params.sort = orderBy.value;
            params.direction = order.value;
        }

        const offset = (page.value * count.value);
        params.start = offset;
        params.count = count.value;

        axios.get(`${global.gConfig.url_base_teleconsultorias}/solicitacao/aceleradores`, { params, headers: getHeaders() })
            .then((response) => {
                const { data } = response.data;
                batch(() => {
                    totalRegistros.value = parseInt(data.totalRegistros);
                    solicitacoes.value = data.itens;
                });
            })
            .catch(err => {
                batch(() => {
                    console.log(err)
                    totalRegistros.value = 0;
                    solicitacoes.value = [];
                });
            })
            .finally(() => {
                batch(() => {
                    buscaEmAndamento.value = false;
                    buscarRespostas.value = false;
                });
            });
    };

    const buscarIndicadores = () => {
        axios.get(`${global.gConfig.url_base_teleconsultorias}/solicitacao/indicadores/em-aberto`, { headers: getHeaders() })
            .then((response) => {
                const { data } = response.data;

                const prazos = {
                    vencido: 0,
                    vencendo: 0,
                    normal: 0
                }

                data.itens.forEach(tele => {
                    let now = moment().startOf('day');
                    let dataMomentFormatada = moment(tele.data_inclusao);
                    let diff = moment.duration(now.diff(dataMomentFormatada)).asHours();

                    const prazoVencimento = tele.prazo_vencimento;
                    const prazoQuaseVencimento = Math.floor(prazoVencimento * 2 / 3);

                    if (diff > prazoVencimento) {
                        prazos.vencido++;
                    } else if (diff > prazoQuaseVencimento) {
                        prazos.vencendo++;
                    } else {
                        prazos.normal++;
                    }
                });

                indicadores.value = prazos;
            })
            .catch(err => {
                indicadores.value = null;
            });
    };

    useSignalEffect(() => {
        if (buscarRespostas.value) {
            buscarIndicadores();
            buscarTeleconsultorias();
        }
    });

    return (
        <>
            <SttNotification severity="info" className={classes.notificacao}>
                {
                    !indicadores.value
                        ?
                        <SttCircularProgress />
                        :
                        <>
                            <SttAlertTitle>{strings.prazoResponder}</SttAlertTitle>
                            <p><b>{strings.prazoVencido}:</b> {indicadores.value.vencido}. <b>{strings.prazoVencendo}:</b> {indicadores.value.vencendo}. <b>{strings.prazoNormal}:</b> {indicadores.value.normal}</p>
                        </>
                }
            </SttNotification>
            <TableContainer className={classes.tableWrapper} component={Paper}>
                <SttTable>
                    <SttTableHead>
                        <SttTableRow>
                            <SttHidden xsDown>
                                <SttTableCell width="6%" sortDirection={orderBy.value === ORDENACAO.CAMPOS.NUMERO ? order.value : false}>
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.NUMERO}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.NUMERO ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.NUMERO)}>
                                        {strings.numero}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="13%" sortDirection={orderBy.value === ORDENACAO.CAMPOS.DATA_SOLICITACAO ? order.value : false}>
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.DATA_SOLICITACAO}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.DATA_SOLICITACAO ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.DATA_SOLICITACAO)}>
                                        {strings.dataSolicitacao}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="20%" sortDirection={orderBy.value === ORDENACAO.CAMPOS.CIDADE ? order.value : false}>
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.CIDADE}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.CIDADE ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.CIDADE)}>
                                        {strings.cidadeUf}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="20%" sortDirection={orderBy.value === ORDENACAO.CAMPOS.SOLICITANTE ? order.value : false}>
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.SOLICITANTE}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.SOLICITANTE ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.SOLICITANTE)}>
                                        {strings.solicitante}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="26%" sortDirection={orderBy.value === ORDENACAO.CAMPOS.REDE ? order.value : false}>
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.REDE}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.REDE ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.REDE)}>
                                        {strings.rede}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="20%" align="center">
                                    {strings.prazoResposta}
                                </SttTableCell>
                                <SttTableCell width="5%" align="center">
                                    {strings.responder}
                                </SttTableCell>
                            </SttHidden>
                            <SttHidden smUp>
                                <SttTableCell width="100%" colSpan="2">{strings.teleconsultorias}</SttTableCell>
                            </SttHidden>
                        </SttTableRow>
                    </SttTableHead>
                    <SttTableBody>
                        {
                            buscaEmAndamento.value
                                ?
                                <SttTableRow key={-1}>
                                    <SttTableCell colSpan={7}
                                        align="center">
                                        <SttCircularProgress />
                                    </SttTableCell>
                                </SttTableRow>
                                :
                                (
                                    solicitacoes.value.length === 0 ?
                                        <SttTableRow key={-1}>
                                            <SttTableCell colSpan={7} align="center">
                                                {strings.nenhumRegistroEncontrado}
                                            </SttTableCell>
                                        </SttTableRow>
                                        :
                                        solicitacoes.value.map((row, index) => (
                                            <SttTableRow hover key={index}>
                                                <SttHidden xsDown>
                                                    <SttTableCell>{row.id}</SttTableCell>
                                                    <SttTableCell>{moment(row.data).format('DD/MM/YYYY HH:mm:ss')}</SttTableCell>
                                                    <SttTableCell>{row.nome_cidade} / {row.sigla_estado}</SttTableCell>
                                                    <SttTableCell>{row.nome_solicitante}</SttTableCell>
                                                    <SttTableCell>{row.descricao_rede}</SttTableCell>
                                                    <SttTableCell align="center">
                                                        {switchPrazo(row)}
                                                    </SttTableCell>
                                                </SttHidden>
                                                <SttHidden smUp>
                                                    <SttTableCell width="96%">
                                                        <SttText size="small"><b>{strings.numero}:</b> {row.id}</SttText>
                                                        <SttText size="small"><b>{strings.dataSolicitacao}:</b> {moment(row.data).format('DD/MM/YYYY HH:mm:ss')}</SttText>
                                                        <SttText size="small"><b>{strings.cidadeUf}:</b> {row.nome_cidade} / {row.sigla_estado}</SttText>
                                                        <SttText size="small"><b>{strings.solicitante}:</b> {row.nome_solicitante}</SttText>
                                                        <SttText size="small"><b>{strings.rede}:</b> {row.descricao_rede}</SttText>
                                                        <SttText size="small"><b>{strings.prazoResposta}:</b> {switchPrazo(row)}</SttText>
                                                    </SttTableCell>
                                                </SttHidden>
                                                <SttTableCell align="center">
                                                    <SttEditButton
                                                        id={`btn-ver-solicitacao-${index}`}
                                                        onClick={() => {
                                                            if (row.situacao === SITUACAO_TELECONSULTORIA.SITUACAO.AGUARDANDO_TREPLICA) {
                                                                adicionarAba({
                                                                    titulo: `${strings.solicitacao} - ${row.id}`,
                                                                    conteudo: Detalhes,
                                                                    id: parseInt(row.id),
                                                                    removerAba: removerAba(`${row.tipo_teleconsultoria}_${row.id}`),
                                                                    adicionarAba,
                                                                    idAba: `${row.tipo_teleconsultoria}_${row.id}`,
                                                                });
                                                                return;
                                                            }
                                                            adicionarAba({
                                                                titulo: `${strings.resposta} - ${row.id}`,
                                                                conteudo: ConteinerResposta,
                                                                id: parseInt(row.id),
                                                                adicionarAba,
                                                                removerAbaId,
                                                                idAba: `${row.tipo_teleconsultoria}_${row.id}`,
                                                            });
                                                        }}
                                                    />
                                                </SttTableCell>
                                            </SttTableRow>
                                        ))
                                )
                        }
                    </SttTableBody>
                </SttTable>
            </TableContainer>
            {
                solicitacoes.value.length > 0 &&
                <SttTablePagination
                    rowsPerPageOptions={[10, 20, 40]}
                    component="div"
                    count={totalRegistros.value}
                    rowsPerPage={count.value}
                    page={page.value}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={strings.linhasPorPagina}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${strings.de} ${count}`}
                />
            }
        </>
    )
}

export default PesquisaResposta;