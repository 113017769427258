import React, { memo, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
    SttExpansionPanel,
    SttTextItem,
    SttDivider,
    SttHeading,
    SttTranslateHook
} from '@stt-componentes/core';
import { TIPO_DEMANDA, INFORMACAO_COMPLEMENTAR } from '../../common/ConstantsSincrona';
import moment from 'moment';
import ExibicaoAnexo from './exibicaoAnexo';
import ExibicaoHtml from './exibicaoHtml';
import RespostaSincronaClinicaIndiviual from './respostaSincronaClinicaIndiviual';
import RespostaSincronaClinicaFamiliar from './respostaSincronaClinicaFamiliar';
import RespostaSincronaProcessoTrabalho from './respostaSincronaProcessoTrabalho';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1.5)
    },
    notificacao: {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5),
    },
}));

const Divider = memo((props) => {
    return (
        <SttDivider {...props} />
    )
});

const RespostaSincrona = ({ teleconsultoria }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();

    return (
        <>
            <SttExpansionPanel
                title={strings.fichaRegistro}
                children={
                    <>
                        <SttTextItem title={strings.tipoDemanda} content={TIPO_DEMANDA.LABEL[teleconsultoria.tipo_demanda]} />
                        <SttTextItem title={strings.especialidadeArea} content={teleconsultoria.descricao_tema} />
                        {
                            teleconsultoria.data_inicio_atendimento &&
                            <SttTextItem title={strings.inicioAtendimento} content={moment(teleconsultoria.data_inicio_atendimento).format('DD/MM/YYYY HH:mm')} />
                        }
                        {
                            teleconsultoria.data_fim_atendimento &&
                            <SttTextItem title={strings.terminoAtendimento} content={moment(teleconsultoria.data_fim_atendimento).format('DD/MM/YYYY HH:mm')} />
                        }
                        <SttTextItem title={strings.teleconsultor} content={teleconsultoria.nome_teleconsultor} />
                        {
                            teleconsultoria.referencias.length > 0 &&
                            <>
                                <SttHeading variant="h4" color="primary" className={classes.root}>{strings.referenciasEstrategias}</SttHeading>
                                {
                                    teleconsultoria.referencias.map((r, index) => {
                                        let texto = r.descricao_referencia.trim();

                                        if (r.complemento_referencia?.trim()) {
                                            texto += ` - ${r.complemento_referencia.trim()}`;
                                        }

                                        return (
                                            <SttTextItem key={`A_${index}`} title={(index + 1)} content={texto} />
                                        )
                                    })
                                }
                                < Divider />
                            </>
                        }
                        {
                            teleconsultoria.descricao_dificuldade &&
                            <SttTextItem title={strings.dificuldadePercebida} content={teleconsultoria.descricao_dificuldade} />
                        }
                        {
                            teleconsultoria.informacao_complementar &&
                            <SttTextItem title={strings.statusTeleconsultoria} content={INFORMACAO_COMPLEMENTAR.LABEL[teleconsultoria.informacao_complementar]} />
                        }
                        {
                            teleconsultoria.descricao_informacao_complementar &&
                            <SttTextItem title={strings.dificuldadePercebida} content={teleconsultoria.descricao_informacao_complementar} />
                        }
                        <SttTextItem title={strings.potencialOpiniaoFormativa} content={teleconsultoria.descricao_potencial_segunda_opiniao_formativa} />
                        <ExibicaoHtml titulo={strings.sinteseOrientacao} html={teleconsultoria.sintese_orientacao} />
                        <ExibicaoHtml titulo={strings.encaminhamentosCaso} html={teleconsultoria.encaminhamento} />
                        <ExibicaoHtml titulo={strings.orientacoesFinais} html={teleconsultoria.orientacao_final} />
                        <ExibicaoAnexo titulo={strings.anexos} anexos={teleconsultoria.anexos} />
                    </>
                }
            />
            <Divider />
            {
                teleconsultoria.id_individual &&
                <>
                    <SttExpansionPanel
                        title={strings.clinicaIndividual}
                        children={
                            <RespostaSincronaClinicaIndiviual teleconsultoria={teleconsultoria} />
                        }
                    />
                    <Divider />
                </>
            }
            {
                teleconsultoria.id_familia &&
                <>
                    <SttExpansionPanel
                        title={strings.clinicaFamiliar}
                        children={
                            <RespostaSincronaClinicaFamiliar teleconsultoria={teleconsultoria} />
                        }
                    />
                    <Divider />
                </>
            }
            {
                teleconsultoria.id_processo_trabalho &&
                <>
                    <SttExpansionPanel
                        title={strings.processoTrabalho}
                        children={
                            <RespostaSincronaProcessoTrabalho teleconsultoria={teleconsultoria} />
                        }
                    />
                    <Divider />
                </>
            }
        </>
    )
}

export default memo(RespostaSincrona);