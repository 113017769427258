import React, { useEffect, useRef } from 'react';
import DadosAnexos from './form';
import { useFormikContext } from 'formik';
import { ANEXOS } from './form/fieldNames';

const WrapperAnexos = ({ verificarErrosOrdemPrecedencia = null}) => {
    const { errors, isSubmitting } = useFormikContext();

    const secaoAnexos = useRef(null);

    useEffect(() => {
        if (isSubmitting) {
            return;
        }

        if (errors && errors[ANEXOS] && verificarErrosOrdemPrecedencia && !verificarErrosOrdemPrecedencia(errors, ANEXOS)) {
            setTimeout(() => {
                secaoAnexos.current.scrollIntoView(
                    {
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'start'
                    }
                );
            }, 50);
        }
    }, [isSubmitting]);

    return (
        <div ref={secaoAnexos}>
            <DadosAnexos />
        </div>
    )
}

export default WrapperAnexos;