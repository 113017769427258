import React, { memo, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
    SttButton,
    SttLoading,
    SttTranslateHook
} from '@stt-componentes/core';
import { TIPO_TELECONSULTORIA, SITUACAO_TELECONSULTORIA, CLASSIFICACOES, PERMISSOES } from '../../common/Constants';
import { useHistory } from "react-router-dom";
import Avaliar from './operacoes/avaliar';
import Replica from './operacoes/replica';
import Treplica from './operacoes/treplica';
import AlteracaoTeleconsultor from './operacoes/alteracaoTeleconsultor';
import FinalizacaoAdministrativa from './operacoes/finalizacaoAdministrativa';
import axios from 'axios';
import { getHeaders } from '../../request';
import { useSignal, useSignals } from '@preact/signals-react/runtime';
import alerta from '../../signals/alerta';
import usuario from '../../signals/usuario';
import { batch } from '@preact/signals-react';
import { temPermissaoRBAC } from '../../security/rbac';

const { SITUACAO } = SITUACAO_TELECONSULTORIA;
const { TIPO } = TIPO_TELECONSULTORIA;

const useStyles = makeStyles(theme => ({
    buttonWrapper: {
        marginTop: theme.spacing(1.5)
    },
    button: {
        marginBottom: theme.spacing(1),
        marginLeft: 0,
        marginRight: theme.spacing(1.5)
    },
}));

const openFileUrl = (response) => {
    if (response.data) {
        const fileURL = URL.createObjectURL(response.data);
        window.open(fileURL);
    }
}

const BotoesOperacoes = ({ teleconsultoria, baixarTeleconsultoria, removerAba }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    const history = useHistory();
    useSignals();

    const exibirAvaliacao = useSignal(false);
    const exibirReplica = useSignal(false);
    const exibirTreplica = useSignal(false);
    const exibirFinalizacao = useSignal(false);
    const exibirAlteracaoTeleconsultor = useSignal(false);
    const imprimindo = useSignal(false);

    const imprimirTeleconsultoria = () => {
        if (!imprimindo.value) {
            imprimindo.value = true;

            axios.get(`${global.gConfig.url_base_teleconsultorias}/solicitacao/impressao/${teleconsultoria.value.id}`, { headers: getHeaders(), responseType: 'blob' })
                .then((response) => openFileUrl(response))
                .catch(err => { console.log(err) })
                .finally(() => {
                    imprimindo.value = false;
                });
        }
    };

    const regularTeleconsultoria = () => {
        history.push({
            pathname: '/regulacao',
            state: {
                telePadrao: { id: teleconsultoria.value.id, tipo: teleconsultoria.value.tipo },
            },
        });
    }

    const responderTeleconsultoria = () => {
        history.push({
            pathname: '/resposta',
            state: {
                telePadrao: { id: teleconsultoria.value.id, tipo: teleconsultoria.value.tipo },
            },
        });
    }

    const callbackAvaliacao = () => {
        batch(() => {
            alerta.value = {
                ...alerta.value,
                open: false
            }
            exibirAvaliacao.value = false;
        });
        if (removerAba) {
            removerAba();
            return;
        }
        baixarTeleconsultoria();
    }

    const callbackReplica = () => {
        baixarTeleconsultoria();
        batch(() => {
            alerta.value = {
                ...alerta.value,
                open: false
            }
            exibirReplica.value = false;
        });
    }

    const callbackTreplica = () => {
        batch(() => {
            alerta.value = {
                ...alerta.value,
                open: false
            }
            exibirTreplica.value = false;
        });
        if (removerAba) {
            removerAba();
            return;
        }
        baixarTeleconsultoria();
    }

    const callbackFinalizacao = () => {
        batch(() => {
            alerta.value = {
                ...alerta.value,
                open: false
            }
            exibirFinalizacao.value = false;
        });
        if (removerAba) {
            removerAba();
            return;
        }
        baixarTeleconsultoria();
    }

    const callbackAlteracaoTeleconsultor = () => {
        baixarTeleconsultoria();
        batch(() => {
            alerta.value = {
                ...alerta.value,
                open: false
            }
            exibirAlteracaoTeleconsultor.value = false;
        });
    }


    return (
        <>
            <div className={classes.buttonWrapper}>
                {
                    (teleconsultoria.value.acoes.podeAlterarEncaminhamento && teleconsultoria.value.situacao === SITUACAO.ENCAMINHADA) &&
                    <SttButton
                        type="button"
                        variant="contained"
                        className={classes.button}
                        color="primary"
                        onClick={regularTeleconsultoria}
                    >
                        {strings.alterarEncaminhamento}
                    </SttButton>
                }
                {
                    (teleconsultoria.value.acoes.podeAlterarEncaminhamento &&
                        (teleconsultoria.value.situacao === SITUACAO.EM_ABERTO || teleconsultoria.value.situacao_solicitacao === SITUACAO.EM_ABERTO)
                    ) &&
                    <SttButton
                        type="button"
                        variant="contained"
                        className={classes.button}
                        color="primary"
                        onClick={regularTeleconsultoria}
                    >
                        {strings.encaminhar}
                    </SttButton>
                }
                {
                    (teleconsultoria.value.acoes.podeAlterarEncaminhamento &&
                        teleconsultoria.value.situacao === SITUACAO.AGUARDANDO_TREPLICA &&
                        teleconsultoria.value.tipo === TIPO.ASSINCRONA
                    ) &&
                    <>
                        <SttButton
                            type="button"
                            variant="contained"
                            className={classes.button}
                            color="primary"
                            onClick={() => exibirAlteracaoTeleconsultor.value = true}
                        >
                            {strings.alterarTeleconsultor}
                        </SttButton>

                        <AlteracaoTeleconsultor
                            exibirAlteracaoTeleconsultor={exibirAlteracaoTeleconsultor}
                            idSolicitacao={teleconsultoria.value.id}
                            idSegundaOpiniao={teleconsultoria.value.id_segunda_opiniao}
                            callback={callbackAlteracaoTeleconsultor}
                        />
                    </>
                }
                {
                    ((teleconsultoria.value.acoes.podeResponder && teleconsultoria.value.acoes.podeAlterarResposta && teleconsultoria.value.situacao === SITUACAO.ENCAMINHADA && teleconsultoria.value.tipo === TIPO.ASSINCRONA) ||
                        teleconsultoria.value.acoes.podeResponderSecundario && teleconsultoria.value.situacao === SITUACAO.AGUARDANDO_RESPOSTA_SECUNDARIA) &&
                    <SttButton
                        type="button"
                        variant="contained"
                        className={classes.button}
                        color="primary"
                        onClick={responderTeleconsultoria}
                    >
                        {strings.responder}
                    </SttButton>
                }
                {
                    (teleconsultoria.value.acoes.podeAlterarResposta &&
                        (teleconsultoria.value.situacao === SITUACAO.AGUARDANDO_RESPOSTA_SECUNDARIA || teleconsultoria.value.situacao === SITUACAO.AGUARDANDO_LIBERACAO || teleconsultoria.value.situacao === SITUACAO.EM_CORRECAO) &&
                        teleconsultoria.value.tipo == TIPO.ASSINCRONA
                    ) &&
                    <SttButton
                        type="button"
                        variant="contained"
                        className={classes.button}
                        color="primary"
                        onClick={responderTeleconsultoria}
                    >
                        {strings.alterarResposta}
                    </SttButton>
                }
                {
                    teleconsultoria.value.situacao >= SITUACAO.AGUARDANDO_AVALIACAO && teleconsultoria.value.classificacao?.identificador === CLASSIFICACOES.JUDICIALIZACAO &&
                    <SttButton
                        type="button"
                        variant="contained"
                        className={classes.button}
                        color="primary"
                        onClick={imprimirTeleconsultoria}
                    >
                        {strings.imprimir}
                    </SttButton>
                }

                {
                    (teleconsultoria.value.acoes.podeAvaliarAlterar && teleconsultoria.value.classificacao?.identificador !== CLASSIFICACOES.JUDICIALIZACAO &&
                        (teleconsultoria.value.situacao === SITUACAO.AGUARDANDO_AVALIACAO || teleconsultoria.value.situacao === SITUACAO.TREPLICA_ENVIADA)
                        && teleconsultoria.value.tipo === TIPO.ASSINCRONA
                    ) &&
                    <>
                        <SttButton
                            type="button"
                            variant="contained"
                            className={classes.button}
                            color="primary"
                            onClick={() => exibirAvaliacao.value = true}
                        >
                            {strings.avaliar}
                        </SttButton>

                        <Avaliar
                            exibirAvaliacao={exibirAvaliacao}
                            idTeleconsultoria={teleconsultoria.value.id_teleconsultoria}
                            idSolicitacao={teleconsultoria.value.id}
                            idSegundaOpiniao={teleconsultoria.value.id_segunda_opiniao}
                            callback={callbackAvaliacao}
                            classificacao={teleconsultoria.value.classificacao?.identificador}
                        />
                    </>
                }
                {
                    (teleconsultoria.value.acoes.podeEnviarReplica && teleconsultoria.value.situacao === SITUACAO.AGUARDANDO_AVALIACAO &&
                        teleconsultoria.value.tipo === TIPO.ASSINCRONA && teleconsultoria.value.classificacao?.identificador !== CLASSIFICACOES.JUDICIALIZACAO
                    ) &&
                    <>
                        <SttButton
                            type="button"
                            variant="contained"
                            className={classes.button}
                            color="primary"
                            onClick={() => exibirReplica.value = true}
                        >
                            {strings.adicionarReplica}
                        </SttButton>

                        <Replica
                            exibirReplica={exibirReplica}
                            idSolicitacao={teleconsultoria.value.id}
                            idSegundaOpiniao={teleconsultoria.value.id_segunda_opiniao}
                            idResposta={teleconsultoria.value.id_segunda_opiniao_resposta}
                            idTeleconsultor={teleconsultoria.value.id_teleconsultor}
                            callback={callbackReplica}
                        />
                    </>
                }
                {
                    (teleconsultoria.value.acoes.podeAlterarResposta && teleconsultoria.value.situacao === SITUACAO.AGUARDANDO_TREPLICA && teleconsultoria.value.tipo === TIPO.ASSINCRONA) &&
                    <>
                        <SttButton
                            type="button"
                            variant="contained"
                            className={classes.button}
                            color="primary"
                            onClick={() => exibirTreplica.value = true}
                        >
                            {strings.adicionarTreplica}
                        </SttButton>

                        <Treplica
                            exibirTreplica={exibirTreplica}
                            idSolicitacao={teleconsultoria.value.id}
                            idSegundaOpiniao={teleconsultoria.value.id_segunda_opiniao}
                            idResposta={teleconsultoria.value.id_segunda_opiniao_resposta}
                            idSolicitante={teleconsultoria.value.id_funcionario_solicitante}
                            callback={callbackTreplica}
                        />
                    </>
                }
                {
                    (temPermissaoRBAC(usuario, PERMISSOES.FINALIZACAO_ADMIN) &&
                        [
                            SITUACAO.ENCAMINHADA,
                            SITUACAO.AGUARDANDO_RESPOSTA_SECUNDARIA,
                            SITUACAO.AGUARDANDO_TREPLICA,
                            SITUACAO.TREPLICA_ENVIADA
                        ].includes(teleconsultoria.value.situacao) &&
                        teleconsultoria.value.tipo === TIPO.ASSINCRONA
                    ) &&
                    <>
                        <SttButton
                            type="button"
                            variant="contained"
                            className={classes.button}
                            color="primary"
                            onClick={() => exibirFinalizacao.value = true}
                        >
                            {strings.finalizar}
                        </SttButton>

                        <FinalizacaoAdministrativa
                            exibirFinalizacao={exibirFinalizacao}
                            idSolicitacao={teleconsultoria.value.id}
                            idSegundaOpiniao={teleconsultoria.value.id_segunda_opiniao}
                            callback={callbackFinalizacao}
                        />
                    </>
                }
                {
                    (teleconsultoria.value.acoes.podeAvaliarAlterar &&
                        (teleconsultoria.value.situacao_solicitacao === SITUACAO.RASCUNHO || teleconsultoria.value.situacao_solicitacao === SITUACAO.DEVOLVIDA_SOLICITANTE)
                    ) &&
                    <SttButton
                        type="button"
                        variant="contained"
                        className={classes.button}
                        color="primary"
                        onClick={() => {
                            history.push({
                                pathname: '/solicitacao',
                                state: {
                                    idSolicitacao: teleconsultoria.value.id,
                                },
                            });
                        }}
                    >
                        {strings.alterar}
                    </SttButton>
                }
            </div>
            <SttLoading
                open={imprimindo.value}
                text={strings.gerandoDocumento}
            />
        </>
    )

}

export default memo(BotoesOperacoes);