import React, { memo, useContext, useEffect } from 'react';
import TelaInicio from '../../componentes/inicio';
import {
    SttAlertTitle,
    SttContainer,
    SttNotification,
    SttTabs,
    SttTranslateHook
} from '@stt-componentes/core';
import { makeStyles } from "@material-ui/core/styles";
import { useSignal, useSignals } from '@preact/signals-react/runtime';
import axios from 'axios';
import { getHeaders } from '../../request';

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: theme.spacing(1.5)
    },
    notificacao: {
        margin: theme.spacing(5),
        width: '100%'
    },
}));

const Inicio = () => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    useSignals();

    const abas = useSignal([]);
    const abaAtiva = useSignal('0');
    const semRedes = useSignal(false);

    useEffect(() => {
        axios.get(`${global.gConfig.url_base_teleconsultorias}/teleconsultor/regiao`, { headers: getHeaders() })
            .then((response) => {
                const { data } = response.data;

                if (data.length === 0) {
                    semRedes.value = true;
                    return;
                }

                abas.value = data.map(rede => ({
                    titulo: rede.descricao,
                    idRede: rede.id_cadastro_regiao_autorregulacao,
                    permanente: true,
                    conteudo: TelaInicio,
                    key: `${rede.id_cadastro_regiao_autorregulacao}_${rede.descricao}`
                }))
            })
            .catch((err) => {
                console.log(err)
                semRedes.value = true;
            });
    }, []);

    if (semRedes.value) {
        return (
            <SttContainer>
                <SttNotification severity="warning" className={classes.notificacao} >
                    <SttAlertTitle><b>{strings.atencao}!</b></SttAlertTitle>
                    <div dangerouslySetInnerHTML={{ __html: strings.mensagemSemVinculoRede }} />
                </SttNotification>
            </SttContainer>
        )
    }

    return (
        <SttTabs abas={abas.value}
            className={classes.wrapper}
            handleChangeAbaAtiva={(abaNova) => abaAtiva.value = abaNova}
            abaAtiva={abaAtiva.value}
            permanente={true}
            preload={false}
        />
    );
};

export default memo(Inicio);