import React, { memo, useContext } from 'react';
import {
    SttExpansionPanel,
    SttTextItem,
    SttDivider,
    SttTranslateHook
} from '@stt-componentes/core';
import {
    SATISFACAO,
    RESOLUBILIDADE,
    ATENDIMENTO,
    SATISFACAO_GERAL,
    SIM_NAO_NAO_APLICA
} from '../../common/ConstantsAvaliacao';
import ExibicaoHtml from './exibicaoHtml';

const Divider = memo((props) => {
    return (
        <SttDivider {...props} />
    )
});

const Avaliacao = ({ avaliacao }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);

    const getIndicacaoServico = () => {
        if (avaliacao.indicacao_servico) {
            return strings.sim;
        }

        return `${strings.naoPorque}${avaliacao.motivo_nao_indicacao}`;
    }

    return (
        <>
            <SttExpansionPanel
                title={strings.avaliacao}
                children={
                    <>
                        {
                            avaliacao.satisfacao_solicitacao &&
                            <SttTextItem title={strings.satisfacaoSolicitacao} content={SATISFACAO.LABEL_GERAL[avaliacao.satisfacao_solicitacao]} />
                        }
                        {
                            avaliacao.satisfacao_solicitacao === SATISFACAO.TIPO.OUTRO &&
                            <SttTextItem title={strings.complemento} content={avaliacao.satisfacao_solicitacao_outro} />
                        }
                        {
                            avaliacao.duvida_esclarecida &&
                            <SttTextItem title={strings.duvidaEsclarecida} content={SATISFACAO.LABEL_ESCLARECIDA[avaliacao.duvida_esclarecida]} />
                        }
                        {
                            avaliacao.duvida_esclarecida === SATISFACAO.TIPO.OUTRO &&
                            <SttTextItem title={strings.complemento} content={avaliacao.duvida_esclarecida_outro} />
                        }
                        {
                            avaliacao.satisfacao_teleconsultoria &&
                            <SttTextItem title={strings.satisfacaoTeleconsultoria} content={SATISFACAO.LABEL_GERAL[avaliacao.satisfacao_teleconsultoria]} />
                        }
                        {
                            avaliacao.satisfacao_teleconsultoria === SATISFACAO.TIPO.OUTRO &&
                            <SttTextItem title={strings.complemento} content={avaliacao.satisfacao_teleconsultoria_outro} />
                        }
                        {
                            avaliacao.resolubilidade_teleconsultoria &&
                            <SttTextItem title={strings.satisfacaoTeleconsultoria} content={RESOLUBILIDADE.LABEL[avaliacao.resolubilidade_teleconsultoria]} />
                        }
                        {
                            avaliacao.resolubilidade_teleconsultoria === RESOLUBILIDADE.TIPO.OUTRO &&
                            <SttTextItem title={strings.complemento} content={avaliacao.resolubilidade_teleconsultoria_outro} />
                        }
                        {
                            avaliacao.atendimento_paciente_especifico !== null &&
                            <SttTextItem title={strings.atendimentoPaciente} content={avaliacao.atendimento_paciente_especifico ? strings.sim : strings.nao} />
                        }
                        {
                            avaliacao.evitacao_encaminhamento !== null &&
                            <SttTextItem title={strings.evitacaoEncaminhamento} content={avaliacao.evitacao_encaminhamento ? strings.sim : strings.nao} />
                        }
                        {
                            avaliacao.mudanca_conduta !== null &&
                            <SttTextItem title={strings.mudancaConduta} content={avaliacao.mudanca_conduta ? strings.sim : strings.nao} />
                        }
                        {
                            avaliacao.indicacao_servico !== null &&
                            <SttTextItem title={strings.indicacaoServico} content={getIndicacaoServico()} />
                        }
                        {
                            avaliacao.atendimento_duvida_principal &&
                            <SttTextItem title={strings.atendimento} content={ATENDIMENTO.LABEL[avaliacao.atendimento_duvida_principal]} />
                        }
                        {
                            avaliacao.satisfacao_geral &&
                            <SttTextItem title={strings.grauSatisfacao} content={SATISFACAO_GERAL.LABEL[avaliacao.satisfacao_geral]} />
                        }
                        {
                            avaliacao.teleconsultoria_evitou_encaminhamento &&
                            <SttTextItem title={strings.evitouEncaminhamento} content={SIM_NAO_NAO_APLICA.LABEL[avaliacao.teleconsultoria_evitou_encaminhamento]} />
                        }
                        {
                            avaliacao.teleconsultor_sugeriu_encaminhamento &&
                            <SttTextItem title={strings.sugeriuEncaminhamento} content={SIM_NAO_NAO_APLICA.LABEL[avaliacao.teleconsultor_sugeriu_encaminhamento]} />
                        }
                        {
                            avaliacao.intencao_encaminhar_paciente &&
                            <SttTextItem title={strings.intencaoEncaminhar} content={SIM_NAO_NAO_APLICA.LABEL[avaliacao.intencao_encaminhar_paciente]} />
                        }
                        <ExibicaoHtml titulo={strings.criticasSugestoes} html={avaliacao.observacoes} />
                    </>
                }
            />
            <Divider />
        </>
    )
}

export default memo(Avaliacao);