const tipoDemanda = {
    ESPONTANEA: 'E',
    PROGRAMADA: 'P',
    SEM_DEMANDA: null
};

export const TIPO_DEMANDA = {
    TIPO: { ...tipoDemanda },
    LABEL: {
        [tipoDemanda.ESPONTANEA]: 'Espontânea',
        [tipoDemanda.PROGRAMADA]: 'Programada',
        [tipoDemanda.SEM_DEMANDA]: 'Sem demanda',
    }
}

const infoComplementar = {
    SEM_NECESSIDADE: 'S',
    NECESSIDADE: 'N',
};

export const INFORMACAO_COMPLEMENTAR = {
    TIPO: { ...infoComplementar },
    LABEL: {
        [infoComplementar.SEM_NECESSIDADE]: 'Sem necessidade de informações complementares',
        [infoComplementar.NECESSIDADE]: 'Necessidade de informações complementares',
    }
}

const planosSeguridos = {
    REDE_SECUNDARIA: 'S',
    NASF: 'N',
    URGENCIA: 'U',
    SEM_NECESSIDADE: 'C',
    SERVICO_SOCIAL: 'E'
};

export const PLANOS_SUGERIDOS = {
    TIPO: { ...planosSeguridos },
    LABEL: {
        [planosSeguridos.SEM_NECESSIDADE]: 'Não há necessidade de encaminhamento',
        [planosSeguridos.REDE_SECUNDARIA]: 'Encaminhamento para rede secundária',
        [planosSeguridos.NASF]: 'Encaminhamento para NASF',
        [planosSeguridos.URGENCIA]: 'Encaminhamento para urgência/emergência',
        [planosSeguridos.SERVICO_SOCIAL]: 'Encaminhamento para o serviço social',
    }
}