export const NUMERO = 'numero';
export const DATA_DE_SOLICITACAO = 'deSolicitacao';
export const DATA_ATE_SOLICITACAO = 'ateSolicitacao';
export const DATA_DE_RESPOSTA = 'deResposta';
export const DATA_ATE_RESPOSTA = 'ateResposta';
export const NOME_PACIENTE = 'nomePaciente';
export const CPF_PACIENTE = 'cpfPaciente';
export const CNS_PACIENTE = 'cnsPaciente';
export const NOME_SOLICITANTE = 'nomeSolicitante';
export const CPF_SOLICITANTE = 'cpfSolicitante';
export const UF = 'uf';
export const CIDADE = 'cidade';
export const CBO = 'cbo';
export const NOME_TELECONSULTOR = 'nomeTeleconsultor';
export const ASSUNTO = 'assunto';
export const SITUACAO = 'situacao';
export const TIPO = 'tipo';
export const REGIAO = 'regiao';