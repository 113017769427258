import React from 'react';
import {
    SttContainer,
    SttGrid
} from '@stt-componentes/core';
import { makeStyles } from "@material-ui/core/styles";
import EmAberto from './emAberto';
import Respondidas from './respondidas';
import OrientacoesManejo from './manejo';
import PrazoRespostas from './prazoRespostas';
import AvaliacaoRespostas from './avaliacaoRespostas';

const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: theme.spacing(3.5)
    },
    card: {
        padding: '20px !important',
        marginBottom: theme.spacing(3)
    },
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    botao: {
        width: '100%',
        padding: theme.spacing(2),
        textAlign: 'center'
    }

}));

const Inicio = ({ idRede }) => {
    const classes = useStyles();

    return (
        <SttContainer className={classes.container}>
            <SttGrid container spacing={3}>
                <SttGrid item xs={12} md={6}>
                    <EmAberto idRede={idRede} />
                </SttGrid>
                <SttGrid item xs={12} md={6}>
                    <Respondidas idRede={idRede} />
                </SttGrid>
                <SttGrid item xs={12} md={4} className={classes.card}>
                    <OrientacoesManejo idRede={idRede} />
                </SttGrid>
                <SttGrid item xs={12} md={4} className={classes.card}>
                    <PrazoRespostas idRede={idRede} />
                </SttGrid>
                <SttGrid item xs={12} md={4} className={classes.card}>
                    <AvaliacaoRespostas idRede={idRede} />
                </SttGrid>
            </SttGrid>
        </SttContainer>
    )
}

export default Inicio;