import React, { lazy, Suspense, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import HttpStatus from 'http-status-codes';
import axios from 'axios';
import { getHeaders } from '../../request';
import {
    SttLoading,
    SttGrid,
    SttButton,
    SttModal,
    SttCircularProgress,
    SttTranslateHook
} from '@stt-componentes/core';
import * as yup from 'yup';
import { Formik } from 'formik';
import dadosEncaminhamentoSchema from '../regulacao/encaminhamento/form/validationSchema';
import alerta from '../../signals/alerta';
import { useSignalEffect, useSignals, useSignal } from '@preact/signals-react/runtime';

const DadosEncaminhamento = lazy(() => import('../regulacao/encaminhamento'));

const useStyles = makeStyles(theme => ({
    form: {
        marginTop: theme.spacing(1.5)
    },
}));

const validationSchema = (strings) => {
    const dadosEncaminhamento = dadosEncaminhamentoSchema(strings);
    const schema = yup.object().shape({});

    return schema.concat(dadosEncaminhamento)
}


const AlteracaoTeleconsultor = ({ callback, regularMultiplas, telesSelecionadas }) => {
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const schema = validationSchema(strings);

    useSignals();

    const initialValues = useSignal({
        ids: telesSelecionadas.value,
        regiao: null,
        cbo: null,
        cboOcupacao: null,
        teleconsultor: null,
        instituicao: null
    });

    const fecharAlteracao = () => {
        regularMultiplas.value = false;
    }

    const enviarForm = (dados, setSubmitting) => {
        setSubmitting(true);

        let tipoAlerta = '';
        let tituloAlerta = '';
        let mensagemAlerta = '';
        let options = [];
        let onClose = () => { };

        axios.post(`${global.gConfig.url_base_teleconsultorias}/regulacao/multipla`, dados, { headers: getHeaders() })
            .then((response) => {
                const { data } = response;
                tipoAlerta = 'success';
                tituloAlerta = strings.sucesso;
                mensagemAlerta = data?.message ? data.message : strings.teleconsultoriasReguladas;
                options = [{
                    title: strings.ok,
                    onClick: () => {
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        }
                        callback();
                    }
                }];
                onClose = () => {
                    alerta.value = {
                        ...alerta.value,
                        open: false
                    };
                    callback();
                }
            })
            .catch(err => {
                const { response } = err;
                tipoAlerta = 'error';
                tituloAlerta = strings.erro;
                mensagemAlerta = strings.erroGenerico;

                if (response) {
                    const { data } = response;

                    mensagemAlerta = data.message;
                    if (response.status === HttpStatus.BAD_REQUEST || response.status === HttpStatus.INTERNAL_SERVER_ERROR) {
                        let arrMensagem = [];
                        data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        if (arrMensagem.length > 0) {
                            mensagemAlerta = arrMensagem.join('\n');
                        }
                    }
                }

                options = [{
                    title: strings.ok,
                    onClick: () => {
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        }
                    }
                }];
                onClose = () => {
                    alerta.value = {
                        ...alerta.value,
                        open: false
                    };
                }
            })
            .finally(() => {
                setSubmitting(false);
                alerta.value = {
                    ...alerta.value,
                    type: tipoAlerta,
                    title: tituloAlerta,
                    message: mensagemAlerta,
                    open: true,
                    options: options,
                    onClose: onClose
                }
            });
    }

    return (
        <SttModal
            title={strings.regulacaoMultipla}
            open={regularMultiplas.value}
            outModalClose={fecharAlteracao}
            iconClose={fecharAlteracao}
            maxWidth="lg"
            fullWidth={true}
            children={
                initialValues.value
                    ?
                    <Formik
                        initialValues={initialValues.value}
                        validationSchema={schema}
                        onSubmit={(dados, { setSubmitting }) => {
                            setSubmitting(false);
                            alerta.value = {
                                ...alerta.value,
                                open: true,
                                title: strings.atencao,
                                type: 'alert',
                                message: strings.confirmarRegulacaoMultipla,
                                options: [{
                                    title: strings.sim,
                                    onClick: () => {
                                        enviarForm(dados, setSubmitting);
                                        alerta.value = {
                                            ...alerta.value,
                                            open: false
                                        };
                                    }
                                },
                                {
                                    title: strings.nao,
                                    onClick: () => {
                                        alerta.value = {
                                            ...alerta.value,
                                            open: false
                                        };
                                    }
                                }],
                                onClose: () => {
                                    alerta.value = {
                                        ...alerta.value,
                                        open: false
                                    };
                                }
                            };
                        }}
                    >
                        {
                            ({
                                isSubmitting,
                                handleSubmit,
                                resetForm,
                            }) => {
                                return (
                                    <form onSubmit={handleSubmit} noValidate className={classes.form}>
                                        <Suspense fallback={<SttCircularProgress color="primary" />}>
                                            <DadosEncaminhamento exibirPsof={false} exibirSecundario={false} />
                                        </Suspense>
                                        <SttLoading
                                            open={isSubmitting}
                                            text={strings.salvando}
                                        />
                                        <SttGrid container spacing={3}>
                                            <SttGrid item xs={12}>
                                                <SttButton
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    nomarginleft="true"
                                                    className={classes.button}
                                                    disabled={isSubmitting}
                                                >
                                                    {strings.confirmar}
                                                </SttButton>
                                                <SttButton
                                                    type="button"
                                                    variant="outlined"
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                    className={classes.button}
                                                    onClick={() => {
                                                        resetForm({ values: initialValues.value });
                                                        fecharAlteracao();
                                                    }}
                                                >
                                                    {strings.fechar}
                                                </SttButton>
                                            </SttGrid>
                                        </SttGrid>
                                    </form>
                                )
                            }
                        }
                    </Formik>
                    : <></>
            }
        />
    );
};

export default AlteracaoTeleconsultor;