import React, { memo, useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import {
    SttAlertTitle,
    SttExpansionPanel,
    SttHeading,
    SttNotification,
    SttTextItem,
    SttTranslateHook
} from '@stt-componentes/core';
import ExibicaoHtml from './exibicaoHtml';
import { SITUACAO_RESPOSTA } from '../../common/ConstantsResposta';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1.5)
    },
    notificacao: {
        display: 'flex',
        flexDirection: 'column'
    },
    alert: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    heading: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1.5)
    }
}));

const Resposta = ({ segundaOpiniao }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();

    return (
        <>
            {
                segundaOpiniao.resposta?.respostasComplementares?.length > 0 &&
                <SttExpansionPanel
                    title={strings.respostasComplementares}
                    children={
                        segundaOpiniao.resposta.respostasComplementares.map((rc, index) => (
                            <div key={index}>
                                <SttHeading variant="h4" color="primary" className={classes.heading}>{strings.complemento} {(index + 1)}</SttHeading>
                                {
                                    rc.situacao === SITUACAO_RESPOSTA.RASCUNHO &&
                                    <SttNotification severity="warning" className={classes.alert}>
                                        <SttAlertTitle><b>{strings.atencao}!</b></SttAlertTitle>
                                        <div className={classes.notificacao} dangerouslySetInnerHTML={{ __html: strings.rascunhoRespostaVisualizacao }} />
                                    </SttNotification>
                                }
                                <SttTextItem title={strings.teleconsultor} content={rc.nome} />
                                <SttTextItem title={strings.dataResposta} content={moment(rc.data).format('DD/MM/YYYY HH:mm')} />
                                {
                                    rc.cbo_familia &&
                                    <SttTextItem title={strings.cbo} content={rc.cbo_familia} />
                                }
                                <ExibicaoHtml titulo={strings.complemento} html={rc.complemento} resposta={true} />
                            </div>
                        ))
                    }
                />
            }
        </>
    )
}

export default memo(Resposta);