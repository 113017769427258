export const OPCOES_ORIENTACOES_ESPECIFICAS = {
    MANEJO_APS: 'M',
    ENCAMINHAMENTO: 'E',
    MAIS_INFORMACOES: 'I',
    SUGIRO: 'S',
    CIRURGIA: 'C'
};

export const SITUACAO_RESPOSTA = {
    RASCUNHO: -2,
    ENVIADA: 0
}