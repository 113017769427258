import React, { memo, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
    SttExpansionPanel,
    SttTextItem,
    SttDivider,
    SttHeading,
    SttNotification,
    SttAlertTitle,
    SttTranslateHook
} from '@stt-componentes/core';
import moment from 'moment';
import { ORIENTACOES_ESPECIFICAS } from '../../common/Constants';
import ExibicaoHtml from './exibicaoHtml';
import ExibicaoCid10 from './exibicaoCid10';
import ExibicaoAnexo from './exibicaoAnexo';
import ExibicaoCiap2 from './exibicaoCiap2';
import { SITUACAO_RESPOSTA } from '../../common/ConstantsResposta';
import ExibicaoProcedimentosSigtap from './exibicaoProcedimentosSigtap';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1.5)
    },
    notificacao: {
        display: 'flex',
        flexDirection: 'column'
    },
    alert: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

const Divider = memo((props) => {
    return (
        <SttDivider {...props} />
    )
});

const RespostaAssincrona = ({ segundaOpiniao }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();

    return (
        segundaOpiniao.resposta ?
            <>
                <SttExpansionPanel
                    title={strings.resposta}
                    children={
                        <>
                            {
                                segundaOpiniao.resposta.situacao === SITUACAO_RESPOSTA.RASCUNHO &&
                                <SttNotification severity="warning" className={classes.alert}>
                                    <SttAlertTitle><b>{strings.atencao}!</b></SttAlertTitle>
                                    <div className={classes.notificacao} dangerouslySetInnerHTML={{ __html: strings.rascunhoResposta }} />
                                </SttNotification>
                            }
                            <SttTextItem title={strings.teleconsultor} content={segundaOpiniao.resposta.nome} />
                            <SttTextItem title={strings.dataResposta} content={moment(segundaOpiniao.resposta.data).format('DD/MM/YYYY HH:mm')} />
                            <SttTextItem title={strings.cidadeUf} content={`${segundaOpiniao.resposta.cidade} / ${segundaOpiniao.resposta.estado}`} />
                            {
                                segundaOpiniao.resposta.cbo_familia &&
                                <SttTextItem title={strings.cbo} content={segundaOpiniao.resposta.cbo_familia} />
                            }
                            <ExibicaoHtml titulo={strings.respostaDireta} html={segundaOpiniao.resposta.resposta} resposta={true} />
                            {
                                (segundaOpiniao.resposta.orientacao_especifica || '').trim() &&
                                <ExibicaoHtml titulo={strings.orientacaoEspecifica} html={`${ORIENTACOES_ESPECIFICAS.TEXTO[segundaOpiniao.resposta.orientacao_especifica]}${segundaOpiniao.resposta.complemento_orientacao_especifica ? segundaOpiniao.resposta.complemento_orientacao_especifica : ''}`} resposta={true} />
                            }
                            {
                                segundaOpiniao.resposta.conduta_terapeutica &&
                                <ExibicaoHtml titulo={strings.manejoPrevio} html={segundaOpiniao.resposta.complemento_conduta_terapeutica} resposta={true} />
                            }
                            {
                                segundaOpiniao.resposta.solicitacao_exames &&
                                <ExibicaoHtml titulo={strings.solicitacaoExames} html={segundaOpiniao.resposta.complemento_solicitacao_exames} resposta={true} />
                            }
                            {
                                segundaOpiniao.resposta.classificacao_risco &&
                                <ExibicaoHtml titulo={strings.classificacaoRisco} html={segundaOpiniao.resposta.complemento_classificacao_risco} resposta={true} />
                            }
                            {
                                segundaOpiniao.resposta.descricao_longa_classificacao_risco &&
                                <SttTextItem title={strings.classificacaoRisco} content={segundaOpiniao.resposta.descricao_longa_classificacao_risco} />
                            }
                            {
                                segundaOpiniao.resposta.orientacoes_gerais &&
                                <ExibicaoHtml titulo={strings.orientacoesGerais} html={segundaOpiniao.resposta.complemento_orientacoes_gerais} resposta={true} />
                            }
                            <ExibicaoHtml titulo={strings.complementacao} html={segundaOpiniao.resposta.complementacao} resposta={true} />
                            <ExibicaoHtml titulo={strings.atributosAps} html={segundaOpiniao.resposta.atributos_aps} resposta={true} />
                            <ExibicaoHtml titulo={strings.educacaoPermanente} html={segundaOpiniao.resposta.educacao_permanente} resposta={true} />
                            <ExibicaoProcedimentosSigtap titulo={strings.procedimentosSigtap} procedimentos={segundaOpiniao.resposta.sigtap} />
                            {
                                segundaOpiniao.referencias.length > 0 &&
                                <>
                                    <SttHeading variant="h4" color="primary" className={classes.root}>{strings.referencias}</SttHeading>
                                    {
                                        segundaOpiniao.referencias.map((r, index) => (
                                            <SttTextItem key={`A_${index}`} title={(index + 1)} content={r.referencia} />
                                        ))
                                    }
                                </>
                            }
                            <ExibicaoCid10 titulo={strings.cid10} cid={segundaOpiniao.cid10} />
                            <ExibicaoCiap2 titulo={strings.ciap2} ciap2={segundaOpiniao.ciap2} />
                            <ExibicaoHtml titulo={strings.evidencias} html={segundaOpiniao.resposta.evidencia} resposta={true} />
                            <ExibicaoAnexo titulo={strings.anexos} anexos={segundaOpiniao.anexos} />
                            <ExibicaoHtml titulo={strings.replica} html={segundaOpiniao.resposta.replica} resposta={true} />
                            {
                                segundaOpiniao.resposta.nome_teleconsultor_treplica && segundaOpiniao.resposta.nome !== segundaOpiniao.resposta.nome_teleconsultor_treplica &&
                                <div style={{ marginTop: '12px' }}>
                                    <SttTextItem title={strings.teleconsultorTreplica} content={segundaOpiniao.resposta.nome_teleconsultor_treplica} />
                                </div>
                            }
                            <ExibicaoHtml titulo={strings.treplica} html={segundaOpiniao.resposta.treplica} resposta={true} />
                        </>
                    }
                />
                <Divider />
            </> :
            null
    )
}


export default memo(RespostaAssincrona);