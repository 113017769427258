export const ADENDO_HANSENIASE = 'adendoHanseniase';
export const MOTIVO_ENCAMINHAMENTO = 'motivoEncaminhamento';
export const OUTRO_MOTIVO_ENCAMINHAMENTO = 'outroMotivoEncaminhamento';
export const FORMA_CLINICA = 'formaClinica';
export const CLASSIFICACAO_OPERACIONAL = 'classificacaoOperacional';
export const ESQUEMA_TERAPEUTICO = 'esquemaTerapeutico';
export const NUMERO_DOSES = 'numeroDoses';
export const LESOES_CUTANEAS = 'lesoesCutaneas';
export const NUMERO_LESOES = 'numeroLesoes';
export const NERVOS_AFETADOS = 'nervosAfetados';
export const BACILOSCOPIA = 'baciloscopia';
export const INDICE_BACILOSCOPICO = 'indiceBaciloscopico';
export const ALTERACAO_NERVOS_SENSITIVOS = 'alteracaoNervosSensitivos';
export const GRAU_INCAPACIDADE = 'grauIncapacidade';
export const EPISODIO_REACIONAL = 'episodioReacional';
export const TIPO_EPISODIO_REACIONAL = 'tipoEpisodioReacional';
export const TRATAMENTO_REACIONAL = 'tratamentoReacional';
export const TEMPO_USO = 'tempoUso';
export const ESPECIFICACAO_TEMPO_USO = 'especificacaoTempoUso';
export const SINAIS_SINTOMAS = 'sinaisSintomas';
export const DOSE_DIA = 'doseDia';