import React, { memo, Suspense, lazy, useEffect, useRef, useContext } from "react";
import {
    SttExpansionPanel,
    SttDivider,
    SttContainer,
    SttLoading,
    SttHeading,
    SttCircularProgress,
    SttButton,
    SttTranslateHook
} from '@stt-componentes/core';
import SttSigTap from '@stt-componentes/sigtap';
import HttpStatus from 'http-status-codes';
import { Formik } from 'formik';
import { makeStyles } from "@material-ui/core/styles";
import axios from 'axios';
import { getHeaders, getHeadersFormData } from '../../request';
import validationSchema from './validationSchema';
import FunctionsResposta from "../../common/FunctionsResposta";
import Devolucao from '../devolucao';
import { CLASSIFICACOES, SITUACAO_TELECONSULTORIA } from "../../common/Constants";
import { OPCOES_TIPO_RESPOSTA } from "../../common/ConstantsRespostaJudicial";
import { ANEXOS } from "../anexos/form/fieldNames";
import { NotificationManager } from "react-notifications";
import { useSignal, useSignalEffect, useSignals } from "@preact/signals-react/runtime";
import alerta from "../../signals/alerta";
import { batch } from "@preact/signals-react";
import { OPCOES_ORIENTACOES_ESPECIFICAS } from "../../common/ConstantsResposta";

const DadosCabecalho = lazy(() => import('./cabecalho'));
const DadosTecnologiasSolicitadas = lazy(() => import('./tecnologias-solicitadas'));
const DadosOutrasTecnologias = lazy(() => import('./outras-tecnologias'));
const DadosCustosTecnologias = lazy(() => import('./custos-tecnologias'));
const DadosReferencias = lazy(() => import('./referencias'));
const DadosDiagnostico = lazy(() => import('./diagnostico-principal'));
const DadosResumoCasoConcreto = lazy(() => import('./resumo-caso-concreto'));
const DadosEvidencias = lazy(() => import('./evidencia'));
const DadosConclusao = lazy(() => import('./conclusao'));
const SttCid10 = lazy(() => import('@stt-componentes/cid10'));
const SttCiap2 = lazy(() => import('@stt-componentes/ciap2'));
const Anexos = lazy(() => import('../anexos'));

const useStyles = makeStyles(theme => ({
    buttonWrapper: {
        marginTop: theme.spacing(1)
    },
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    button: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    expansionPanel: {
        width: '100%'
    }
}));

const Divider = memo((props) => {
    return (
        <SttDivider {...props} />
    )
});

const FormResposta = ({ teleconsultoria, removerAba }) => {
    useSignals();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();

    const TELECONSULTORIAS_API_BASE_URL = global.gConfig.url_base_teleconsultorias;

    const formRef = useRef();
    const timer = useRef(null);

    const initialValues = useSignal(FunctionsResposta.inicializarValoresResposta(teleconsultoria));
    const salvarAutomatico = useSignal(false);
    const podeSalvarAutomatico = useSignal(false);
    const devolver = useSignal(false);
    const devolverSolicitante = useSignal(false);

    useSignalEffect(() => {
        clearTimeout(timer.current);
        if (podeSalvarAutomatico.value &&
            teleconsultoria.situacao !== SITUACAO_TELECONSULTORIA.SITUACAO.AGUARDANDO_RESPOSTA_SECUNDARIA &&
            teleconsultoria.situacao !== SITUACAO_TELECONSULTORIA.SITUACAO.AGUARDANDO_LIBERACAO &&
            teleconsultoria.situacao !== SITUACAO_TELECONSULTORIA.SITUACAO.EM_CORRECAO) {
            timer.current = criarTimeout();
        }
    });

    useEffect(() => {
        podeSalvarAutomatico.value = true;
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    const criarTimeout = () => {
        return setTimeout(() => {
            salvarAutomatico.value = true;
        }, 60 * 1000);
    }

    useSignalEffect(() => {
        if (salvarAutomatico.value) {
            enviarForm(formRef.current.values, true);
        }
    });

    const enviarForm = (dados, rascunho, setSubmitting = null) => {
        //Desativa o salvamento automático enquanto a requisição não retorna
        podeSalvarAutomatico.value = false;

        const respostaFinal = FunctionsResposta.formatarDadosParaSalvar(dados);
        const tipoSubmit = {
            rascunho,
            salvarAutomatico
        }
        respostaFinal.append('tipoSubmit', JSON.stringify(tipoSubmit));

        //Faz sentido quando o usuário clica no botão "salvar rascunho" ou "enviar resposta", mas não para o salvamento automático
        if (setSubmitting) {
            setSubmitting(true);
        }

        let tipoAlerta = '';
        let tituloAlerta = '';
        let mensagemAlerta = '';
        let options = [];
        let onClose = () => { };

        axios.post(`${TELECONSULTORIAS_API_BASE_URL}/resposta`, respostaFinal, { headers: getHeadersFormData() })
            .then((response) => {
                const { data } = response;

                if (setSubmitting) {
                    tipoAlerta = 'success';
                    tituloAlerta = strings.sucesso;
                    let msgPadrao = rascunho ? strings.rascunhoSalvoSucesso : strings.teleconsultoriaRespondida;
                    mensagemAlerta = data?.message || msgPadrao;
                    options = [{
                        title: strings.ok,
                        onClick: () => {
                            removerAba();
                            alerta.value = {
                                ...alerta.value,
                                open: false
                            }
                        }
                    }];
                    onClose = () => {
                        removerAba();
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        };
                    }
                    return;
                }

                if (data?.data?.idResposta) {
                    formRef.current.setFieldValue('idResposta', data.data.idResposta);
                }

                // Atualiza os dados dos anexos inseridos
                if (data?.data?.anexosInseridos?.length > 0) {
                    data.data.anexosInseridos.forEach(inserido => {
                        formRef.current.setFieldValue(`${ANEXOS}[${inserido.index}]`, inserido);
                    });
                }

                //Se é a partir de salvamento automático, notifica usuário que o salvamento ocorreu
                NotificationManager.success(strings.rascunhoSalvoSucesso);
                batch(() => {
                    podeSalvarAutomatico.value = true;
                    salvarAutomatico.value = false;
                });
            })
            .catch(err => {
                const { response } = err;
                let mensagem = strings.erro;
                let erroRespondidaPreviamente = false;
                if (response) {
                    const { data } = response;

                    erroRespondidaPreviamente = data.data?.erro === 'ERRO_TELECONSULTORIA_RESPONDIDA';
                    mensagem = data.message || '';

                    if (response.status === HttpStatus.BAD_REQUEST || response.status === HttpStatus.INTERNAL_SERVER_ERROR) {
                        let arrMensagem = [];
                        data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        if (arrMensagem.length > 0) {
                            mensagem = arrMensagem.join('\n');
                        }
                    }

                    if (data.data?.message) {
                        mensagem += `\n - ${data.data?.message}`;
                    }
                }

                //O usuário clicou no botão e o sistema notifica o erro por modal
                if (setSubmitting) {
                    tipoAlerta = 'error';
                    tituloAlerta = strings.erro;
                    mensagemAlerta = mensagem;

                    options = [{
                        title: strings.ok,
                        onClick: () => {
                            if (erroRespondidaPreviamente) {
                                removerAba();
                                return;
                            }
                            alerta.value = {
                                ...alerta.value,
                                open: false
                            }
                        }
                    }];
                    onClose = () => {
                        if (erroRespondidaPreviamente) {
                            removerAba();
                            return;
                        }
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        };
                    }
                } else {
                    NotificationManager.error(strings.erroSalvarAutomaticamente);
                }

                podeSalvarAutomatico.value = true;
            })
            .finally(() => {
                if (setSubmitting) {
                    setSubmitting(false);
                    alerta.value = {
                        ...alerta.value,
                        type: tipoAlerta,
                        title: tituloAlerta,
                        message: mensagemAlerta,
                        open: true,
                        options: options,
                        onClose: onClose
                    }
                }
            });
    }

    const submitForm = rascunho => (dados, { setSubmitting }) => {
        setSubmitting(false);
        salvarAutomatico.value = false;
        if (formRef.current) {
            batch(() => {
                podeSalvarAutomatico.value = false;
                alerta.value = {
                    ...alerta.value,
                    open: true,
                    title: strings.atencao,
                    type: rascunho ? 'alert' : 'confirmation',
                    message: rascunho ? strings.textoConfirmarRascunho : strings.confirmarResposta,
                    options: [{
                        title: strings.confirmar,
                        onClick: () => {
                            enviarForm(dados, rascunho, setSubmitting);
                            alerta.value = {
                                ...alerta.value,
                                open: false
                            };
                        }
                    },
                    {
                        title: strings.cancelar,
                        onClick: () => {
                            podeSalvarAutomatico.value = true;
                            alerta.value = {
                                ...alerta.value,
                                open: false
                            };
                        }
                    }],
                    onClose: () => {
                        podeSalvarAutomatico.value = true;
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        };
                    }
                };
            });
        }
    }

    const onSubmit = submitForm(false);
    const onSubmitRascunho = submitForm(true);

    return (
        <>
            {
                initialValues.value &&
                <Formik
                    innerRef={formRef}
                    initialValues={initialValues.value}
                    validateOnChange={false}
                    validateOnBlur={false}
                    validationSchema={validationSchema(strings, initialValues.value.cabecalho.exibirOrientacaoEspecifica, initialValues.value.classificacao)}
                    onSubmit={onSubmit}
                >
                    {
                        ({
                            isSubmitting,
                            values,
                            errors,
                            submitCount,
                            handleSubmit,
                            setFieldValue,
                            setSubmitting
                        }) => (
                            <>
                                <SttContainer>
                                    <form onSubmit={handleSubmit} noValidate>
                                        <SttHeading variant="h1" color="primary" align="center">{strings.respostaTele}</SttHeading>
                                        {/* Dados cabeçalho */}
                                        <SttExpansionPanel
                                            classegriditem={classes.expansionPanel}
                                            title={strings.cabecalho}
                                            children={
                                                <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                    <DadosCabecalho teleconsultoria={teleconsultoria} />
                                                </Suspense>
                                            }
                                        />
                                        <Divider />
                                        {
                                            values.cabecalho.orientacaoEspecifica === OPCOES_ORIENTACOES_ESPECIFICAS.CIRURGIA &&
                                            <>
                                                <SttExpansionPanel
                                                    classegriditem={classes.expansionPanel}
                                                    title={strings.procedimentosSigtap}
                                                    children={
                                                        <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                            <SttSigTap
                                                                obrigatorio={true}
                                                                multiplos={false}
                                                                strings={strings}
                                                                headers={getHeaders()}
                                                                comFormaOrganizacao={true}
                                                                verificarErrosOrdemPrecedencia={FunctionsResposta.verificarErrosOrdemPrecedencia}
                                                                formExterno={{
                                                                    sigtap: values.sigtap,
                                                                    isSubmitting,
                                                                    errors,
                                                                    submitCount,
                                                                    setFieldValue
                                                                }}
                                                            />
                                                        </Suspense>
                                                    }
                                                />
                                                <Divider />
                                            </>
                                        }
                                        {
                                            values.classificacao === CLASSIFICACOES.JUDICIALIZACAO && values.cabecalho.tipoResposta === OPCOES_TIPO_RESPOSTA.TIPO.NOTA_TECNICA &&
                                            <>
                                                {/* Diagnóstico Principal */}
                                                <SttExpansionPanel
                                                    classegriditem={classes.expansionPanel}
                                                    title={strings.diagnosticoPrincipal}
                                                    children={
                                                        <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                            <DadosDiagnostico />
                                                        </Suspense>
                                                    }
                                                />
                                                <Divider />

                                                {/* Resumo do caso concreto */}
                                                <SttExpansionPanel
                                                    classegriditem={classes.expansionPanel}
                                                    title={strings.breveResumoCasoConcreto}
                                                    children={
                                                        <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                            <DadosResumoCasoConcreto />
                                                        </Suspense>
                                                    }
                                                />
                                                <Divider />

                                                {/* Dados tecnologias solicitadas */}
                                                <SttExpansionPanel
                                                    classegriditem={classes.expansionPanel}
                                                    title={strings.tecnologiasSolicitadas}
                                                    children={
                                                        <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                            <DadosTecnologiasSolicitadas />
                                                        </Suspense>
                                                    }
                                                />
                                                <Divider />

                                                {/* Dados outras tecnologias */}
                                                <SttExpansionPanel
                                                    classegriditem={classes.expansionPanel}
                                                    title={strings.outrasTecnologias}
                                                    children={
                                                        <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                            <DadosOutrasTecnologias />
                                                        </Suspense>
                                                    }
                                                />
                                                <Divider />

                                                {/* Dados custos tecnologias */}
                                                <SttExpansionPanel
                                                    classegriditem={classes.expansionPanel}
                                                    title={strings.custosTecnologias}
                                                    children={
                                                        <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                            <DadosCustosTecnologias />
                                                        </Suspense>
                                                    }
                                                />
                                                <Divider />

                                                {/* Dados evidencias */}
                                                <SttExpansionPanel
                                                    classegriditem={classes.expansionPanel}
                                                    title={strings.evidencias}
                                                    children={
                                                        <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                            <DadosEvidencias />
                                                        </Suspense>
                                                    }
                                                />
                                                <Divider />

                                                {/* Dados conclusao */}
                                                <SttExpansionPanel
                                                    classegriditem={classes.expansionPanel}
                                                    title={strings.conclusao}
                                                    children={
                                                        <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                            <DadosConclusao />
                                                        </Suspense>
                                                    }
                                                />
                                                <Divider />
                                            </>
                                        }

                                        {
                                            (values.classificacao !== CLASSIFICACOES.JUDICIALIZACAO || values.cabecalho.tipoResposta === OPCOES_TIPO_RESPOSTA.TIPO.NOTA_TECNICA) &&
                                            <>
                                                {/* Dados de referências */}
                                                <SttExpansionPanel
                                                    classegriditem={classes.expansionPanel}
                                                    title={strings.referencias}
                                                    children={
                                                        <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                            <DadosReferencias />
                                                        </Suspense>
                                                    }
                                                />
                                                <Divider />
                                            </>
                                        }

                                        {/* Dados do CID10 */}
                                        {
                                            (values.cabecalho.exibirOrientacaoEspecifica ||
                                                (values.classificacao === CLASSIFICACOES.JUDICIALIZACAO && values.cabecalho.tipoResposta === OPCOES_TIPO_RESPOSTA.TIPO.NOTA_TECNICA)) &&
                                            <>
                                                <SttExpansionPanel
                                                    classegriditem={classes.expansionPanel}
                                                    title={strings.cid10}
                                                    children={
                                                        <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                            <SttCid10
                                                                obrigatorio={true}
                                                                multiplos={values.cabecalho.tipoResposta === OPCOES_TIPO_RESPOSTA.TIPO.NOTA_TECNICA}
                                                                strings={strings}
                                                                headers={getHeaders()}
                                                                verificarErrosOrdemPrecedencia={FunctionsResposta.verificarErrosOrdemPrecedencia}
                                                                formExterno={{
                                                                    cid10: values.cid10,
                                                                    isSubmitting,
                                                                    errors,
                                                                    submitCount,
                                                                    setFieldValue
                                                                }}
                                                            />
                                                        </Suspense>
                                                    }
                                                />
                                                <Divider />
                                            </>
                                        }

                                        {/* Dados do CIAP2 */}
                                        {
                                            values.classificacao !== CLASSIFICACOES.JUDICIALIZACAO &&
                                            <>
                                                <SttExpansionPanel
                                                    classegriditem={classes.expansionPanel}
                                                    title={strings.ciap2}
                                                    children={
                                                        <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                            <SttCiap2 strings={strings}
                                                                headers={getHeaders()}
                                                                formExterno={{
                                                                    ciap2: values.ciap2,
                                                                    isSubmitting,
                                                                    errors,
                                                                    submitCount,
                                                                    setFieldValue
                                                                }} />
                                                        </Suspense>
                                                    }
                                                />
                                                <Divider />
                                            </>
                                        }

                                        {
                                            (values.classificacao !== CLASSIFICACOES.JUDICIALIZACAO || values.cabecalho.tipoResposta === OPCOES_TIPO_RESPOSTA.TIPO.NOTA_TECNICA) &&
                                            <>
                                                {/* Dados de anexos */}
                                                <SttExpansionPanel
                                                    classegriditem={classes.expansionPanel}
                                                    title={strings.anexos}
                                                    children={
                                                        <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                            <Anexos verificarErrosOrdemPrecedencia={FunctionsResposta.verificarErrosOrdemPrecedencia} />
                                                        </Suspense>
                                                    }
                                                />
                                                <Divider />
                                            </>
                                        }

                                        <div className={classes.buttonWrapper}>
                                            <SttButton
                                                type="submit"
                                                variant="contained"
                                                nomarginleft="true"
                                                className={classes.button}
                                                color="primary"
                                                disabled={isSubmitting}
                                            >
                                                {strings.responderTele}
                                            </SttButton>
                                            <SttButton
                                                type="button"
                                                variant="outlined"
                                                color="primary"
                                                className={classes.button}
                                                disabled={isSubmitting}
                                                onClick={() => onSubmitRascunho(values, { setSubmitting })}
                                            >
                                                {strings.salvarRascunho}
                                            </SttButton>
                                            {
                                                teleconsultoria.situacao === SITUACAO_TELECONSULTORIA.SITUACAO.ENCAMINHADA &&
                                                teleconsultoria.acoes.podeDevolver &&
                                                <SttButton
                                                    type="button"
                                                    variant="contained"
                                                    className={classes.button}
                                                    color="secondary"
                                                    onClick={() => {
                                                        batch(() => {
                                                            devolver.value = true;
                                                            devolverSolicitante.value = false;
                                                            podeSalvarAutomatico.value = false;
                                                        });
                                                    }}
                                                >
                                                    {strings.devolverTelerregulacao}
                                                </SttButton>
                                            }
                                            {
                                                teleconsultoria.situacao === SITUACAO_TELECONSULTORIA.SITUACAO.ENCAMINHADA &&
                                                values.classificacao !== CLASSIFICACOES.JUDICIALIZACAO &&
                                                <SttButton
                                                    type="button"
                                                    variant="contained"
                                                    className={classes.button}
                                                    danger={+true}
                                                    onClick={() => {
                                                        batch(() => {
                                                            devolver.value = true;
                                                            devolverSolicitante.value = true;
                                                            podeSalvarAutomatico.value = false;
                                                        });
                                                    }}
                                                >
                                                    {strings.devolverSolicitante}
                                                </SttButton>
                                            }
                                        </div>
                                    </form>
                                    <SttLoading
                                        open={isSubmitting}
                                        text={strings.salvandoResposta}
                                    />
                                </SttContainer>

                            </>
                        )

                    }
                </Formik>
            }
            {
                devolver.value &&
                <Devolucao
                    idSolicitacao={teleconsultoria.id}
                    devolucaoSolicitante={devolverSolicitante.value}
                    devolver={devolver}
                    callback={removerAba}
                    callbackCancelar={() => podeSalvarAutomatico.value = true}
                />
            }
        </>
    );
}

export default FormResposta;