import * as yup from 'yup';
import {
    ANEXOS
} from './fieldNames';
import { MYME_TYPE_PERMITIDOS } from '../../../common/Constants';

export default (strings) => {
    return yup.object().shape({
        [ANEXOS]: yup
            .array()
            .of(
                yup
                    .mixed()
                    .nullable()
                    .test('tamanhoArquivo', strings.erroTamanhoArquivo500Mb, (value) => {
                        if (!value || !(value instanceof File)) {
                            return true;
                        }
                        return value?.size <= 1048576 * 500;
                    })
                    .test('formatoArquivo', strings.formatoArquivoInvalido, (value) => {
                        if (!value || !(value instanceof File)) {
                            return true;
                        }
                        return value && MYME_TYPE_PERMITIDOS.includes(value.type);
                    })
            )
    });
}