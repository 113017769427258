import React, { memo, useContext, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
    SttTextItem,
    SttHeading,
    SttDivider,
    SttTabs,
    SttTranslateHook
} from '@stt-componentes/core';
import ExibicaoHtml from './exibicaoHtml';
import { OPCOES_ORIGEM_PRESCRICAO } from '../../common/ConstantsRespostaJudicial';
import { useSignal, useSignals } from '@preact/signals-react/runtime';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1.5)
    },
    divider: {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5),
    },
    tabs: {
        padding: 0
    }
}));

const AbaTecnologia = memo(({ strings, ...tecnologia }) => {
    return (
        <>
            <SttTextItem title={strings.tipoTecnologia} content={tecnologia.descricaoTipo} />
            <SttTextItem title={strings.descricao} content={tecnologia.descricao} />
            {
                tecnologia.uso_continuo !== null && tecnologia.uso_continuo !== undefined &&
                <SttTextItem title={strings.usoContinuo} content={tecnologia.uso_continuo ? strings.sim : strings.não} />
            }
            <ExibicaoHtml titulo={strings.viaAdministracao} html={tecnologia.via_administracao} resposta={true} />
            <ExibicaoHtml titulo={strings.posologia} html={tecnologia.posologia} resposta={true} />
            {
                tecnologia.tempo_tratamento && tecnologia.ehMedicamento &&
                <SttTextItem title={strings.duracaoTratamento} content={`${tecnologia.tempo_tratamento} ${tecnologia.descricao_tempo_tratamento}`} />
            }
            {
                tecnologia.descricao_tempo_tratamento && !tecnologia.tempo_tratamento && tecnologia.ehMedicamento &&
                <SttTextItem title={strings.duracaoTratamento} content={tecnologia.descricao_tempo_tratamento} />
            }
            {
                tecnologia.origem_prescricao_medica !== null && tecnologia.origem_prescricao_medica !== undefined &&
                <SttTextItem title={strings.origemPrescricao} content={OPCOES_ORIGEM_PRESCRICAO.LABEL[tecnologia.origem_prescricao_medica]} />
            }
            {
                tecnologia.registro_anvisa !== null && tecnologia.registro_anvisa !== undefined &&
                <SttTextItem title={strings.registroAnvisa} content={tecnologia.registro_anvisa ? strings.sim : strings.nao} />
            }
            {
                tecnologia.registro_ativo !== null && tecnologia.registro_ativo !== undefined &&
                <SttTextItem title={strings.situacaoRegistro} content={tecnologia.registro_ativo ? strings.ativo : strings.cancelado} />
            }
            {
                tecnologia.indicacao_conformidade_registro !== null && tecnologia.indicacao_conformidade_registro !== undefined &&
                <SttTextItem title={strings.indicacaoConformidade} content={tecnologia.indicacao_conformidade_registro ? strings.sim : strings.nao} />
            }
            <ExibicaoHtml titulo={strings.complementoIndicacao} html={tecnologia.complemento_indicacao_conformidade_registro} resposta={true} />
            {
                tecnologia.oncologico !== null && tecnologia.oncologico !== undefined &&
                <SttTextItem title={strings.oncologico} content={tecnologia.oncologico ? strings.sim : strings.nao} />
            }
            <ExibicaoHtml titulo={strings.complementoOncologico} html={tecnologia.complemento_oncologico} resposta={true} />
            {
                tecnologia.previsto_protocolo_ms !== null && tecnologia.previsto_protocolo_ms !== undefined &&
                <SttTextItem title={strings.previstoProtocolo} content={tecnologia.previsto_protocolo_ms ? strings.sim : strings.nao} />
            }
            {
                tecnologia.disponivel_sus !== null && tecnologia.disponivel_sus !== undefined &&
                <SttTextItem title={tecnologia.ehMedicamento ? strings.disponivelSusMedicamento : strings.disponivelSus} content={tecnologia.disponivel_sus ? strings.sim : strings.nao} />
            }
            {
                tecnologia.existe_generico !== null && tecnologia.existe_generico !== undefined &&
                <SttTextItem title={strings.existeGenerico} content={tecnologia.existe_generico ? strings.sim : strings.nao} />
            }
            {
                tecnologia.existe_similar !== null && tecnologia.existe_similar !== undefined &&
                <SttTextItem title={strings.existeSimilar} content={tecnologia.existe_similar ? strings.sim : strings.nao} />
            }
        </>
    )
});

const RespostaAssincronaJudicialTecnologiasSolicitadas = ({ judicializacao }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    useSignals();

    const tecnologias = useSignal([]);

    useEffect(() => {
        if (judicializacao) {
            tecnologias.value = judicializacao.tecnologias.map((t, index) => ({
                conteudo: AbaTecnologia,
                titulo: `${strings.tecnologia} ${(index + 1)}`,
                indice: index,
                permanente: true,
                strings,
                ...t
            }));
        }
    }, [judicializacao])

    return (
        tecnologias.value.length > 0 &&
        <>
            <SttDivider className={classes.divider} />
            <SttHeading variant="h4" color="primary" className={classes.root}>{strings.tecnologiasSolicitadas}</SttHeading>
            <SttTabs abas={tecnologias.value}
                permanente={true}
                className={classes.tabs}
            />
            <SttDivider className={classes.divider} />
        </>
    )
}

export default memo(RespostaAssincronaJudicialTecnologiasSolicitadas);