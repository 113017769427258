import React from 'react';
import PropTypes from 'prop-types';
import { FORMULARIOS_ADENDOS } from '../../../common/ConstantsHanseniase';
import AdendoFormularioHanseniase from './hanseniase';

const AdendoFormulario = ({ solicitacao }) => {
    if (!solicitacao.regiao?.adendo_formulario)
        return <></>;

    if (solicitacao.regiao.adendo_formulario === FORMULARIOS_ADENDOS.HANSENIASE)
        return <AdendoFormularioHanseniase adendoHanseniase={solicitacao.adendoHanseniase} />


    return <></>
}

AdendoFormulario.propTypes = {
    solicitacao: PropTypes.object.isRequired,
};

export default AdendoFormulario;