import * as yup from 'yup';
import {
    APRESENTACAO,
    COMPLEMENTO_DURACAO_TRATAMENTO,
    CUSTOS_TECNOLOGIAS,
    DURACAO_TRATAMENTO,
    JUDICIAL,
    LABORATORIO,
    MARCA_COMERCIAL,
    OBSERVACAO,
    PRECO_FABRICA,
    PRECO_MAXIMO_CONSUMIDOR,
    PRECO_MAXIMO_GOVERNO,
    PRECO_TRATAMENTO,
    PRECO_TRATAMENTO_ANUAL,
} from './fieldNames';
import { OPCOES_TIPO_RESPOSTA, OPCOES_TIPO_TECNOLOGIA } from '../../../../common/ConstantsRespostaJudicial';
import { CABECALHO, TIPO_RESPOSTA } from '../../cabecalho/form/fieldNames';

export default (strings) => {
    return yup.object().shape({
        [JUDICIAL]: yup.object()
            .when(`${CABECALHO}.${TIPO_RESPOSTA}`, {
                is: (tipo) => tipo == OPCOES_TIPO_RESPOSTA.TIPO.NOTA_TECNICA,
                then: yup
                    .object()
                    .shape({
                        [CUSTOS_TECNOLOGIAS]: yup.array().of(
                            yup.object().shape({
                                [LABORATORIO]: yup
                                    .string()
                                    .trim()
                                    .nullable()
                                    .min(10, strings.erroConteudoHtml)
                                    .max(255, strings.maximo255Caracteres)
                                    .required(strings.campoObrigatorio),
                                [MARCA_COMERCIAL]: yup
                                    .string()
                                    .trim()
                                    .nullable()
                                    .min(10, strings.erroConteudoHtml)
                                    .max(255, strings.maximo255Caracteres)
                                    .required(strings.campoObrigatorio),
                                [APRESENTACAO]: yup
                                    .string()
                                    .trim()
                                    .nullable()
                                    .test('html-valido', strings.erroConteudoHtml, (html) => {
                                        html = (html || '').replace(/<[^>]*>?/gm, '');
                                        if (html.length === 0 || html.length >= 10) {
                                            return true;
                                        }
                                        return false;
                                    })
                                    .required(strings.campoObrigatorio),
                                [PRECO_FABRICA]: yup
                                    .number()
                                    .min(0)
                                    .max(99999999.99, strings.precoMaximo)
                                    .nullable()
                                    .when('tipo', {
                                        is: (tipo) => tipo === OPCOES_TIPO_TECNOLOGIA.TIPO.MEDICAMENTO,
                                        then: yup
                                            .number()
                                            .required(strings.campoObrigatorio),
                                    }),
                                [PRECO_MAXIMO_GOVERNO]: yup
                                    .number()
                                    .min(0)
                                    .max(99999999.99, strings.precoMaximo)
                                    .nullable()
                                    .when('tipo', {
                                        is: (tipo) => tipo === OPCOES_TIPO_TECNOLOGIA.TIPO.MEDICAMENTO,
                                        then: yup
                                            .number()
                                            .required(strings.campoObrigatorio),
                                    }),
                                [PRECO_MAXIMO_CONSUMIDOR]: yup
                                    .number()
                                    .min(0)
                                    .max(99999999.99, strings.precoMaximo)
                                    .nullable()
                                    .when('tipo', {
                                        is: (tipo) => tipo === OPCOES_TIPO_TECNOLOGIA.TIPO.MEDICAMENTO,
                                        then: yup
                                            .number()
                                            .required(strings.campoObrigatorio),
                                    }),
                                [DURACAO_TRATAMENTO]: yup
                                    .object().shape({
                                        identificador: yup.string(),
                                        descricao: yup.string(),
                                    })
                                    .nullable()
                                    .required(strings.campoObrigatorio),
                                [COMPLEMENTO_DURACAO_TRATAMENTO]: yup
                                    .number()
                                    .nullable()
                                    .when(DURACAO_TRATAMENTO, {
                                        is: (duracao) => duracao?.exige_complemento_numerico,
                                        then: yup
                                            .number()
                                            .required(strings.campoObrigatorio)
                                    }),
                                [PRECO_TRATAMENTO]: yup
                                    .number()
                                    .min(0)
                                    .max(99999999.99, strings.precoMaximo)
                                    .nullable()
                                    .required(strings.campoObrigatorio),
                                [PRECO_TRATAMENTO_ANUAL]: yup
                                    .number()
                                    .min(0)
                                    .max(99999999.99, strings.precoMaximo)
                                    .nullable()
                                    .when('tipo', {
                                        is: (tipo) => tipo === OPCOES_TIPO_TECNOLOGIA.TIPO.MEDICAMENTO,
                                        then: yup
                                            .number()
                                            .required(strings.campoObrigatorio),
                                    }),
                                [OBSERVACAO]: yup
                                    .string()
                                    .trim()
                                    .nullable()
                                    .test('html-valido', strings.erroConteudoHtml, (html) => {
                                        html = (html || '').replace(/<[^>]*>?/gm, '');
                                        if (html.length === 0 || html.length >= 10) {
                                            return true;
                                        }
                                        return false;
                                    })
                                    .required(strings.campoObrigatorio),
                            })
                        ),
                    }).required()
            })
    });
}