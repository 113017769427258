import { CABECALHO, MANIFESTACAO, TIPO_RESPOSTA } from "../componentes/resposta/cabecalho/form/fieldNames";
import { APOIO_TUTORIA, CONCLUSAO, EVIDENCIAS_CIENTIFICAS } from "../componentes/resposta/conclusao/form/fieldNames";
import { CUSTOS_TECNOLOGIAS } from "../componentes/resposta/custos-tecnologias/form/fieldNames";
import { MEIOS_CONFIRMATORIOS } from "../componentes/resposta/diagnostico-principal/form/fieldNames";
import { EVIDENCIAS } from "../componentes/resposta/evidencia/form/fieldNames";
import { OUTRAS_TECNOLOGIAS } from "../componentes/resposta/outras-tecnologias/form/fieldNames";
import { RESUMO } from "../componentes/resposta/resumo-caso-concreto/form/fieldNames";
import { TECNOLOGIAS_SOLICITADAS } from "../componentes/resposta/tecnologias-solicitadas/form/fieldNames";
import { INSUMOS, JUDICIAL_TECNOLOGIAS, MEDICAMENTOS, NUTRICOES, PROCEDIMENTOS_CBHPM, PROCEDIMENTOS_SIGTAP } from "../componentes/solicitacao/judicial-tecnologia/fieldNames";
import { ASSISTENCIA_JUDICIARIA, AUTOR_REPRESENTADO, COMPLEMENTO_PARENTESCO_REPRESENTANTE, DIAGNOSTICO, ESFERA_ORGAO, JUDICIAL, NOME_ADVOGADO, NOME_REPRESENTANTE, NUMERO_OAB, NUMERO_PROCESSO, PARENTESCO_REPRESENTANTE, REUS, TIPO_JUIZADO, TIPO_REPRESENTACAO, UF_OAB, VARA_SERVENTIA } from "../componentes/solicitacao/judicial/form/fieldNames";
import { CLASSIFICACOES, SITUACAO_TELECONSULTORIA } from "./Constants";
import FunctionsResposta from "./FunctionsResposta";

const inicializarValoresRegulacao = (dados) => {
    const dadosRegulacao = {
        idSolicitacao: dados.id,
        idSegundaOpiniao: dados.id_segunda_opiniao,
        idResposta: dados.id_segunda_opiniao_resposta,
        ehAprovacao: dados.situacao === SITUACAO_TELECONSULTORIA.SITUACAO.AGUARDANDO_LIBERACAO || dados.situacao === SITUACAO_TELECONSULTORIA.SITUACAO.AGUARDANDO_RESPOSTA_SECUNDARIA,
        situacao: dados.situacao ?? dados.situacao_solicitacao,
        geral: {
            equipe: dados.id_equipe_saude,
            classificacao: dados.identificador_cadastro_classificacao_assunto,
            especificacao: _getValueOrNull(dados.especificacao.id),
            assunto: _getValueOrNull(dados.assunto),
            complementoAssunto: _getValueOrEmptyString(dados.complemento_assunto),
            assuntoTextual: false
        },
        regiao: null,
        teleconsultoresSecundarios: [],
        psof: dados.potencial_segunda_opiniao_formativa || false,
        cbo: dados.id_cbo_familia_teleconsultor ? { id: dados.id_cbo_familia_teleconsultor, descritor: dados.cbo_familia_teleconsultor } : null,
        cboOcupacao: null,
        teleconsultor: dados.id_teleconsultor ? { id: dados.id_teleconsultor, nome: dados.nome_teleconsultor } : null,
        instituicao: null,
        alterouSolicitacaoJudicial: false,
    };

    if (dados.segundaOpiniao?.teleconsultoresSecundarios?.length > 0) {
        dadosRegulacao.teleconsultoresSecundarios = dados.segundaOpiniao.teleconsultoresSecundarios.map(sec => ({
            id: sec.id_funcionario_teleconsultor,
            nome: sec.nome_teleconsultor,
            disabled: false
        }));

        if (dados.segundaOpiniao.resposta?.respostasComplementares?.length > 0) {
            dadosRegulacao.teleconsultoresSecundarios.forEach(ts => {
                const jaRespondeu = dados.segundaOpiniao.resposta.respostasComplementares.some(rc => rc.id_funcionario_respondedor === ts.id);
                ts.disabled = jaRespondeu;
            })

        }
    }

    dadosRegulacao.ciap2 = dados.ciap2 || [];

    if (dadosRegulacao.geral.assunto?.exige_complemento) {
        dadosRegulacao.geral.assuntoTextual = true;
    }

    dadosRegulacao.cid10 = dados.cid10 || [];
    dadosRegulacao.cid10.forEach(cid => {
        cid.codigo = cid.codigo_subcategoria ? cid.codigo_subcategoria : cid.codigo_categoria;
        cid.nome = cid.codigo_subcategoria ? cid.descritor_subcategoria : cid.descritor_categoria;
        cid.nome_abreviado = cid.codigo_subcategoria ? cid.nome_abreviado_subcategoria : cid.nome_abreviado_categoria;
        cid.tipo = cid.codigo_subcategoria ? 'subcategoria' : 'categoria'
    });

    if (dados.identificador_cadastro_classificacao_assunto === CLASSIFICACOES.JUDICIALIZACAO) {
        dadosRegulacao[JUDICIAL] = {
            [NUMERO_PROCESSO]: _getValueOrEmptyString(dados.judicializacao?.numero_processo),
            [ESFERA_ORGAO]: _getValueOrEmptyString(dados.judicializacao?.esfera_orgao),
            [VARA_SERVENTIA]: _getValueOrEmptyString(dados.judicializacao?.vara_serventia),
            [REUS]: _getValueOrEmptyString(dados.judicializacao?.reus),
            [TIPO_REPRESENTACAO]: _getValueOrEmptyString(dados.judicializacao?.tipo_representacao),
            [ASSISTENCIA_JUDICIARIA]: dados.judicializacao?.assistencia_judiciaria_gratuita ?? false,
            [TIPO_JUIZADO]: _getValueOrEmptyString(dados.judicializacao?.tipo_juizado),
            [NOME_ADVOGADO]: _getValueOrEmptyString(dados.judicializacao?.nome_advogado),
            [NUMERO_OAB]: _getValueOrEmptyString(dados.judicializacao?.numero_oab),
            [UF_OAB]: dados.judicializacao?.id_uf_oab ?
                {
                    id: dados.judicializacao.id_uf_oab,
                    sigla: dados.judicializacao.sigla_uf_oab
                } : null,
            [AUTOR_REPRESENTADO]: dados.judicializacao?.autor_representado_assistido ?? false,
            [NOME_REPRESENTANTE]: _getValueOrEmptyString(dados.judicializacao?.nome_representante),
            [PARENTESCO_REPRESENTANTE]: dados.judicializacao?.id_parentesco_representante ?
                {
                    id: dados.judicializacao.id_parentesco_representante,
                    descricao: dados.judicializacao.descricao_parentesco,
                    exige_complemento: dados.judicializacao.exige_complemento_parentesco
                } : null,
            [COMPLEMENTO_PARENTESCO_REPRESENTANTE]: _getValueOrEmptyString(dados.judicializacao?.complemento_parentesco_representante),
            [DIAGNOSTICO]: _getValueOrEmptyString(dados.judicializacao?.diagnostico),
        }

        dadosRegulacao[JUDICIAL_TECNOLOGIAS] = {
            [MEDICAMENTOS]: dados.judicializacao?.medicamentos ?? [],
            [INSUMOS]: dados.judicializacao?.insumos ?? [],
            [NUTRICOES]: dados.judicializacao?.nutricao ?? [],
            [PROCEDIMENTOS_CBHPM]: dados.judicializacao?.procedimentosCbhpm ?? [],
            [PROCEDIMENTOS_SIGTAP]: dados.judicializacao?.procedimentosSigtap ?? [],
        }

        if (dadosRegulacao.ehAprovacao) {
            const { segundaOpiniao } = dados;
            dadosRegulacao.resposta = {
                alterouResposta: false,
                idSegundaOpiniao: dados.id_segunda_opiniao,
                idResposta: dados.id_segunda_opiniao_resposta,
                classificacao: dados.identificador_cadastro_classificacao_assunto,
                [CABECALHO]: {
                    [TIPO_RESPOSTA]: _getValueOrEmptyString(segundaOpiniao.resposta.judicializacao.tipo_resposta),
                    [MANIFESTACAO]: _getValueOrEmptyString(segundaOpiniao.resposta.judicializacao.manifestacao_tecnica)
                },
                [JUDICIAL]: {
                    [OUTRAS_TECNOLOGIAS]: _getValueOrEmptyString(segundaOpiniao.resposta.judicializacao.outras_tecnologia_disponiveis),
                    [TECNOLOGIAS_SOLICITADAS]: [],
                    [CUSTOS_TECNOLOGIAS]: [],
                    [DIAGNOSTICO]: _getValueOrEmptyString(segundaOpiniao.resposta.judicializacao.diagnostico),
                    [MEIOS_CONFIRMATORIOS]: _getValueOrEmptyString(segundaOpiniao.resposta.judicializacao.meio_confirmatorio_diagnostico),
                    [RESUMO]: _getValueOrEmptyString(segundaOpiniao.resposta.judicializacao.breve_resumo_caso_concreto),
                    [EVIDENCIAS]: _getValueOrEmptyString(segundaOpiniao.resposta.judicializacao.evidencias),
                    [CONCLUSAO]: _getValueOrEmptyString(segundaOpiniao.resposta.judicializacao.conclusao),
                    [EVIDENCIAS_CIENTIFICAS]: _getValueOrNull(segundaOpiniao.resposta.judicializacao.evidencias_cienticas),
                    [APOIO_TUTORIA]: _getValueOrNull(segundaOpiniao.resposta.judicializacao.nota_tecnica_tutoriada),
                }
            };

            const { custosTecnologias, tecnologiasSolicitadas } = FunctionsResposta.tratarDadosTecnologiasParaAlteracao(dados);
            dadosRegulacao.resposta[JUDICIAL][TECNOLOGIAS_SOLICITADAS] = tecnologiasSolicitadas;
            dadosRegulacao.resposta[JUDICIAL][CUSTOS_TECNOLOGIAS] = custosTecnologias;
        }

    }

    return dadosRegulacao;
};

const _getValueOrNull = (value) => {
    return value ?? null;
}

const _getValueOrEmptyString = (value) => {
    return value ?? '';
}

export default {
    inicializarValoresRegulacao
};