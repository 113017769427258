import React, { memo, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
    SttExpansionPanel,
    SttTextItem,
    SttDivider,
    SttTranslateHook
} from '@stt-componentes/core';
import { ALTERACOES_NERVOS, BACILOSCOPIAS, CLASSIFICACOES_OPERACIONAIS, ESQUEMAS_TERAPEUTICOS, FORMAS_CLINICAS, GRAUS_INCAPACIDADE, MOTIVOS_ENCAMINHAMENTO, OPCOES_LESOES_CUTANEAS, OPCOES_NERVOS_AFETADOS, TIPOS_EPISODIOS_REACIONAIS, TRATAMENTOS_REACIONAIS } from '../../../common/ConstantsHanseniase';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1.5)
    },
    notificacao: {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5),
    },
}));

const Divider = memo((props) => {
    return (
        <SttDivider {...props} />
    )
});

const AdendoFormularioHanseniase = ({ adendoHanseniase }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();

    return (
        <>
            <SttExpansionPanel
                title={strings.hanseniase}
                children={
                    <>
                        {
                            adendoHanseniase.motivo_encaminhamento &&
                            <SttTextItem title={strings.motivoEncaminhamento} content={MOTIVOS_ENCAMINHAMENTO.find(me => me.id === adendoHanseniase.motivo_encaminhamento).descricao} />
                        }
                        {
                            adendoHanseniase.outro_motivo_encaminhamento &&
                            <SttTextItem title={strings.outroMotivoEncaminhamento} content={adendoHanseniase.outro_motivo_encaminhamento} />
                        }
                        {
                            adendoHanseniase.forma_clinica &&
                            <SttTextItem title={strings.formaClinica} content={FORMAS_CLINICAS.LABEL[adendoHanseniase.forma_clinica.trim()]} />
                        }
                        {
                            adendoHanseniase.classificacao_operacional &&
                            <SttTextItem title={strings.classificacaoOperacional} content={CLASSIFICACOES_OPERACIONAIS.LABEL[adendoHanseniase.classificacao_operacional]} />
                        }
                        {
                            adendoHanseniase.esquema_terapeutico &&
                            <SttTextItem title={strings.esquemaTerapeutico} content={ESQUEMAS_TERAPEUTICOS.LABEL[adendoHanseniase.esquema_terapeutico]} />
                        }
                        {
                            adendoHanseniase.numero_doses_ingeridas &&
                            <SttTextItem title={strings.numeroDosesIngeridas} content={adendoHanseniase.numero_doses_ingeridas} />
                        }
                        {
                            adendoHanseniase.lesoes_cutaneas &&
                            <SttTextItem title={strings.lesoesCutaneas} content={OPCOES_LESOES_CUTANEAS.LABEL[adendoHanseniase.lesoes_cutaneas]} />
                        }
                        {
                            adendoHanseniase.numero_lesoes &&
                            <SttTextItem title={strings.numeroLesoes} content={adendoHanseniase.numero_lesoes} />
                        }
                        {
                            adendoHanseniase.nervos_afetados &&
                            <SttTextItem title={strings.nervosAfetados} content={OPCOES_NERVOS_AFETADOS.LABEL[adendoHanseniase.nervos_afetados]} />
                        }
                        {
                            adendoHanseniase.baciloscopia &&
                            <SttTextItem title={strings.baciloscopia} content={BACILOSCOPIAS.LABEL[adendoHanseniase.baciloscopia.trim()]} />
                        }
                        {
                            adendoHanseniase.indice_baciloscopico &&
                            <SttTextItem title={strings.indiceBaciloscopico} content={adendoHanseniase.indice_baciloscopico} />
                        }
                        {
                            adendoHanseniase.alteracao_nervos_sensitivos &&
                            <SttTextItem title={strings.alteracaoNervos} content={ALTERACOES_NERVOS.LABEL[adendoHanseniase.alteracao_nervos_sensitivos]} />
                        }
                        {
                            adendoHanseniase.grau_incapacidade &&
                            <SttTextItem title={strings.grauIncapacidade} content={GRAUS_INCAPACIDADE.LABEL[adendoHanseniase.grau_incapacidade.trim()]} />
                        }
                        {
                            adendoHanseniase.episodio_reacional != null &&
                            <SttTextItem title={strings.episodioReacional} content={adendoHanseniase.episodio_reacional ? strings.sim : strings.nao} />
                        }
                        {
                            adendoHanseniase.tipo_episodio_reacional &&
                            <SttTextItem title={strings.tipoEpisodioReacional} content={TIPOS_EPISODIOS_REACIONAIS.LABEL[adendoHanseniase.tipo_episodio_reacional]} />
                        }
                        {
                            adendoHanseniase.tratamento_reacional &&
                            <SttTextItem title={strings.tratamentoReacional} content={TRATAMENTOS_REACIONAIS.LABEL[adendoHanseniase.tratamento_reacional]} />
                        }
                        {
                            adendoHanseniase.dose_dia &&
                            <SttTextItem title={strings.doseDia} content={adendoHanseniase.dose_dia} />
                        }
                        {
                            adendoHanseniase.tempo_uso &&
                            <SttTextItem title={strings.tempoUso} content={`${adendoHanseniase.tempo_uso} ${adendoHanseniase.descricao_especificacao_tempo_uso || ''}`} />
                        }
                        {
                            adendoHanseniase.sinaisSintomas?.length > 0 &&
                            <SttTextItem title={strings.sinaisSintomas} content={adendoHanseniase.sinaisSintomas.map(ss => ss.descricao).join(', ')} />
                        }
                    </>
                }
            />
            <Divider />
        </>
    )
}

export default memo(AdendoFormularioHanseniase);