import * as yup from 'yup';
import { SIGTAP } from '@stt-componentes/sigtap/dist/lib/fieldNames';

export default (strings) => {
    return yup.object().shape({
        [SIGTAP]: yup
            .array()
            .min(1, strings.erroSemSigtap)
            .max(1, strings.erroSemSigtap)
            .of(
                yup.object()
            )
            .required(strings.campoObrigatorio)
    });
}   