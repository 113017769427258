import React, { useContext, useEffect } from 'react';
import { SttTranslateHook } from '@stt-componentes/core';
import { SttCardChart, } from '@stt-componentes/charts';
import { useSignal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';
import axios from 'axios';
import { getHeaders } from '../../request';

const AvaliacaoRespostas = ({ idRede }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    useSignals();

    const totalSatisfeito = useSignal();
    const porcentagemSatisfeito = useSignal();

    useEffect(() => {
        buscarIndicadorRespondidasAvaliacao()
    }, []);

    const buscarIndicadorRespondidasAvaliacao = () => {
        axios.get(`${global.gConfig.url_base_teleconsultorias}/solicitacao/indicadores/avaliacao`, { params: { idRede }, headers: getHeaders() })
            .then((response) => {
                const { data } = response.data;

                if (data.length === 0) {
                    return;
                    // TODO: TRATAR TELECONSULTOR SEM RESPOSTA
                }

                totalSatisfeito.value = parseInt(data.satisfeito_total);
                if (totalSatisfeito.value === 0) {
                    porcentagemSatisfeito.value = '0%';
                    return;
                }
                porcentagemSatisfeito.value = (parseInt(data.satisfeito_total) / parseInt(data.total_teleconsultorias_avaliadas) * 100).toFixed(2) + '%';
                porcentagemSatisfeito.value = porcentagemSatisfeito.value.replace('.', ',');
            })
            .catch(err => {
                // TODO: BOTÃO PARA TENTAR NOVAMENTE
            });
    };

    return (
        <SttCardChart
            title={strings.avaliacaoRespostas}
            subtitle={totalSatisfeito.value}
            description={strings.teleconsultoriaAvaliadasBoa}
            showLoading={true}
            tooltip={strings.ultimos6Meses}
        >
            {porcentagemSatisfeito.value}
        </SttCardChart>
    )
}

export default AvaliacaoRespostas;