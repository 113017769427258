import React, { lazy, Suspense, useContext, useRef } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
    SttButton,
    SttModal,
    SttDivider,
    SttCircularProgress,
    SttExpansionPanel,
    SttGrid,
    SttFormLabel,
    SttFormHelperText,
    SttTranslateHook
} from '@stt-componentes/core';
import * as yup from 'yup';
import {
    Field,
    Formik,
} from 'formik';
import dadosJudicialSchema from '../../componentes/solicitacao/judicial/form/validationSchema';
import dadosJudicialTecnologiaSchema from '../../componentes/solicitacao/judicial-tecnologia/validationSchema';
import { DIAGNOSTICO, JUDICIAL } from '../solicitacao/judicial/form/fieldNames';
import { SttCkEditor } from '@stt-componentes/ckeditor';
import alerta from '../../signals/alerta';
import { useSignals } from '@preact/signals-react/runtime';

const Judicial = lazy(() => import('../solicitacao/judicial'));
const JudicialTecnologias = lazy(() => import('../solicitacao/judicial-tecnologia'));

const useStyles = makeStyles(theme => ({
    form: {
        marginTop: theme.spacing(1.5)
    },
    modalBody: {
        overflow: 'hidden'
    },
    buttonWrapper: {
        marginTop: theme.spacing(1)
    },
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    header: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    button: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    expansionPanel: {
        width: '100%'
    }
}));

const AlteracaoSolicitacaoJudicial = ({ initialValues, callback, alterarSolicitacao }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    const formRef = useRef();

    useSignals();

    const dadosJudicial = dadosJudicialSchema(strings);
    const dadosJudicialTecnologia = dadosJudicialTecnologiaSchema(strings);

    const schema = yup.object().shape({})
        .concat(dadosJudicial)
        .concat(dadosJudicialTecnologia);

    const fecharModal = () => {
        alterarSolicitacao.value = false;
    }

    return (
        <SttModal
            title={strings.alterarSolicitacao}
            open={alterarSolicitacao.value}
            outModalClose={fecharModal}
            iconClose={fecharModal}
            maxWidth="lg"
            fullWidth={true}
            children={
                <div className={classes.modalBody}>
                    <Formik
                        innerRef={formRef}
                        initialValues={initialValues}
                        validationSchema={schema}
                        validateOnChange={false}
                        onSubmit={(dados, { setSubmitting }) => {
                            setSubmitting(false);
                            alerta.value = {
                                ...alerta.value,
                                open: true,
                                title: strings.atencao,
                                type: 'alert',
                                message: strings.confirmarAlteracaoSolicitacaoJudicial,
                                options: [
                                    {
                                        title: strings.sim,
                                        onClick: () => {
                                            fecharModal();
                                            callback(dados);
                                            alerta.value = {
                                                ...alerta.value,
                                                open: false
                                            };
                                        }
                                    },
                                    {
                                        title: strings.nao,
                                        onClick: () => {
                                            alerta.value = {
                                                ...alerta.value,
                                                open: false
                                            };
                                        }
                                    }
                                ],
                                onClose: () => {
                                    alerta.value = {
                                        ...alerta.value,
                                        open: false
                                    };
                                }
                            };
                        }}
                    >
                        {
                            ({
                                handleSubmit,
                            }) => {
                                return (
                                    <form onSubmit={handleSubmit} noValidate className={classes.form}>
                                        <SttExpansionPanel
                                            classegriditem={classes.expansionPanel}
                                            title={strings.dadosPaciente}
                                            children={
                                                <SttGrid container spacing={2}>
                                                    <SttGrid item xs={12}>
                                                        <Field name={`${JUDICIAL}.${DIAGNOSTICO}`}>
                                                            {({
                                                                field: { name, value },
                                                                form: { setFieldValue },
                                                                meta
                                                            }) => (
                                                                <>
                                                                    <SttFormLabel required component="legend" className={classes.ckeditorLabel} error={(meta.touched && meta.error) ? true : false}>{strings.diagnostico}</SttFormLabel>
                                                                    <SttCkEditor
                                                                        data={value}
                                                                        config={{ placeholder: strings.placeholderDiagnostico }}
                                                                        onChange={(event, editor) => {
                                                                            const data = editor.getData();
                                                                            setFieldValue(name, data);
                                                                        }}
                                                                    />
                                                                    <SttFormHelperText error={true}>{meta.touched && meta.error ? meta.error : undefined}</SttFormHelperText>
                                                                </>
                                                            )}
                                                        </Field>
                                                    </SttGrid>
                                                </SttGrid>
                                            }
                                        />
                                        <SttExpansionPanel
                                            classegriditem={classes.expansionPanel}
                                            title={strings.dadosProcesso}
                                            children={
                                                <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                    <Judicial />
                                                </Suspense>
                                            }
                                        />
                                        <SttDivider />
                                        <SttExpansionPanel
                                            classegriditem={classes.expansionPanel}
                                            title={strings.tecnologias}
                                            children={
                                                <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                    <JudicialTecnologias />
                                                </Suspense>
                                            }
                                        />
                                    </form>
                                )
                            }
                        }
                    </Formik>
                </div>
            }
            footer={
                <div>
                    <SttButton
                        type="button"
                        variant="contained"
                        color="primary"
                        nomarginleft="true"
                        className={classes.button}
                        onClick={() => {
                            formRef.current.submitForm();
                        }}
                    >
                        {strings.confirmar}
                    </SttButton>
                    <SttButton
                        type="button"
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                        onClick={() => {
                            formRef.current.resetForm({ values: initialValues });
                        }}
                    >
                        {strings.limpar}
                    </SttButton>
                    <SttButton
                        type="button"
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                        onClick={() => {
                            formRef.current.resetForm({ values: initialValues });
                            fecharModal();
                        }}
                    >
                        {strings.fechar}
                    </SttButton>
                </div>
            }
        />
    );
};

export default AlteracaoSolicitacaoJudicial;