import * as yup from 'yup'

import dadosGeraisSchema from '../../componentes/solicitacao/geral/form/validationSchema';
import dadosPacienteSchema from '../../componentes/solicitacao/paciente/validationSchema';
import dadosTextuaisSchema from '../../componentes/solicitacao/textual/form/validationSchema';
import dadosAnexosSchema from '../../componentes/anexos/form/validationSchema';
import dadosJudicialSchema from '../../componentes/solicitacao/judicial/form/validationSchema';
import dadosJudicialTecnologiaSchema from '../../componentes/solicitacao/judicial-tecnologia/validationSchema';
import dadosAdendoHanseniaseSchema from '../../componentes/solicitacao/adendo/hanseniase/form/validationSchema';
import dadosCid10Schema from './validationSchemaCid10';
import dadosCiap2Schema from './validationSchemaCiap2';

export default (strings, camposPaciente) => {
    const schema = yup.object().shape({});
    const dadosGerais = dadosGeraisSchema(strings);
    const dadosPaciente = dadosPacienteSchema(strings, camposPaciente);
    const dadosTextuais = dadosTextuaisSchema(strings);
    const dadosAnexos = dadosAnexosSchema(strings);
    const dadosCid10 = dadosCid10Schema(strings);
    const dadosCiap2 = dadosCiap2Schema(strings);
    const dadosJudicial = dadosJudicialSchema(strings);
    const dadosAdendoHanseniase = dadosAdendoHanseniaseSchema(strings);
    const dadosJudicialTecnologia = dadosJudicialTecnologiaSchema(strings);

    return schema
        .concat(dadosGerais)
        .concat(dadosPaciente)
        .concat(dadosTextuais)
        .concat(dadosCid10)
        .concat(dadosCiap2)
        .concat(dadosAnexos)
        .concat(dadosJudicial)
        .concat(dadosJudicialTecnologia)
        .concat(dadosAdendoHanseniase)
}