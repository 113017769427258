import * as yup from 'yup';
import {
    JUDICIAL,
    CONCLUSAO,
    APOIO_TUTORIA,
    EVIDENCIAS_CIENTIFICAS
} from './fieldNames';
import { OPCOES_TIPO_RESPOSTA } from '../../../../common/ConstantsRespostaJudicial';
import { CABECALHO, TIPO_RESPOSTA } from '../../cabecalho/form/fieldNames';

export default (strings) => {
    return yup.object().shape({
        [JUDICIAL]: yup.object()
            .when(`${CABECALHO}.${TIPO_RESPOSTA}`, {
                is: (tipo) => tipo == OPCOES_TIPO_RESPOSTA.TIPO.NOTA_TECNICA,
                then: yup
                    .object()
                    .shape({
                        [CONCLUSAO]: yup
                            .string()
                            .trim()
                            .nullable()
                            .test('html-valido', strings.erroConteudoHtml, (html) => {
                                html = (html || '').replace(/<[^>]*>?/gm, '');
                                if (html.length === 0 || html.length >= 10) {
                                    return true;
                                }
                                return false;
                            })
                            .required(strings.campoObrigatorio),
                        [APOIO_TUTORIA]: yup
                            .boolean()
                            .nullable()
                            .oneOf([true, false], strings.campoObrigatorio),
                        [EVIDENCIAS_CIENTIFICAS]: yup
                            .boolean()
                            .nullable()
                            .oneOf([true, false], strings.campoObrigatorio),
                    })
            })
    });
}