import React, { useContext, useEffect } from 'react';
import { SttTranslateHook } from '@stt-componentes/core';
import { SttPieChart } from '@stt-componentes/charts';
import { useSignal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';
import axios from 'axios';
import { getHeaders } from '../../request';
import { useMoment } from '../../hooks';


const EmAberto = ({ idRede }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    useSignals();

    const moment = useMoment();
    const datasetEmAberto = useSignal([]);
    const totalEmAberto = useSignal(0);
    const semDados = useSignal(undefined);

    useEffect(() => {
        buscarIndicadorEmAberto();
    }, [])

    const buscarIndicadorEmAberto = () => {
        axios.get(`${global.gConfig.url_base_teleconsultorias}/solicitacao/indicadores/em-aberto`, { params: { idRede }, headers: getHeaders() })
            .then((response) => {
                const { data } = response.data;

                if (data.itens.length === 0) {
                    semDados.value = strings.semTeleconsultoriasAguardandoResposta;
                    datasetEmAberto.value = [
                        [],
                        []
                    ]
                    return;
                }

                let vencido = 0;
                let normal = 0;
                data.itens.forEach(tele => {
                    let now = moment().startOf('day');
                    let dataMomentFormatada = moment(tele.data_inclusao);
                    let diff = moment.duration(now.diff(dataMomentFormatada)).asHours();

                    const prazoVencimento = tele.prazo_vencimento;
                    if (diff > prazoVencimento) {
                        vencido++;
                    } else {
                        normal++;
                    }
                });

                totalEmAberto.value = vencido + normal;
                datasetEmAberto.value = [
                    ['Atrasado', 'No prazo'],
                    [vencido, normal],
                    [function (value) {
                        if (value.seriesIndex === 0) return '#f9cb9c';
                        return '#cbe3f9';
                    }]
                ];
            });
    };

    return (
        <SttPieChart
            dataset={datasetEmAberto.value}
            uiConfig={{
                height: 400,
                legend: { horizontalAlign: 'center', position: 'bottom', offsetX: 0 },
                dataLabels: {
                    style: {
                        fontSize: '14px',
                        fontWeight: '500',
                        colors: ['#000']
                    }
                }

            }}
            option={{ title: strings.emAbertoNumeroTotal(totalEmAberto.value), description: strings.totalAguardandoResposta }}
            showLoading={true}
            noData={semDados.value}
        />
    )
}

export default EmAberto;