export const GERAL = 'geral';
export const EQUIPE = 'equipe';
export const CLASSIFICACAO = 'classificacao';
export const ESPECIFICACAO = 'especificacao';
export const REGIAO = 'regiao';
export const INTENCAO_ENCAMINHAMENTO = 'intencaoEncaminhamento';
export const ASSUNTO = 'assunto';
export const COMPLEMENTO_ASSUNTO = 'complementoAssunto';
export const ASSUNTO_TEXTUAL = 'assuntoTextual';
export const PACIENTE_ESPECIFICO = 'pacienteEspecifico';
export const DUVIDA = 'duvida';
export const FORMA_ORGANIZACAO = 'formaOrganizacao';