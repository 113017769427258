import React, { memo, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
    SttTextItem,
    SttDivider,
    SttHeading,
    SttNotification,
    SttTranslateHook
} from '@stt-componentes/core';
import moment from 'moment';
import Moment from 'react-moment';
import ExibicaoHtml from './exibicaoHtml';
import ExibicaoCid10 from './exibicaoCid10';
import { PLANOS_SUGERIDOS } from '../../common/ConstantsSincrona';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1.5)
    },
    notificacao: {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5),
    },
}));

const Divider = memo((props) => {
    return (
        <SttDivider {...props} />
    )
});

const RespostaSincronaClinicaIndiviual = ({ teleconsultoria }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();

    const getIdadeIndividual = () => {
        let dataMoment = moment(teleconsultoria.data_nascimento_individual);

        if (dataMoment.isValid()) {
            let now = moment();
            let dataMomentFormatada = moment(dataMoment, 'DD-MM-YYYY');
            let idade = moment.duration(now.diff(dataMomentFormatada));
            let anos = idade.years();
            let meses = idade.months();
            let dias = idade.days();
            return `${anos} ano(s), ${meses} mes(es) e ${dias} dia(s)`;
        }

        return null;
    }

    return (
        <>
            {
                teleconsultoria.data_nascimento_individual &&
                <SttTextItem title={strings.dataNascimento} content={<Moment format="DD/MM/YYYY">{teleconsultoria.data_nascimento_individual}</Moment>} />
            }
            {
                teleconsultoria.data_nascimento_individual &&
                <SttTextItem title={strings.idade} content={getIdadeIndividual()} />
            }
            {
                (!teleconsultoria.data_nascimento_individual && teleconsultoria.idade_individual) &&
                <SttTextItem title={strings.idade} content={`${teleconsultoria.idade_individual} ano(s)`} />
            }
            {
                teleconsultoria.sexo_individual &&
                <SttTextItem title={strings.sexo} content={teleconsultoria.sexo_individual} />
            }
            <ExibicaoCid10 titulo={strings.cid10} cid={teleconsultoria.cid10} />
            {
                teleconsultoria.medicamentos?.length > 0 &&
                <>
                    <SttHeading variant="h4" color="primary" className={classes.root}>{strings.medicamentosRecursos}</SttHeading>
                    {
                        teleconsultoria.medicamentos.map((med, index) => {
                            let texto = med.descricao_medicamento.trim();

                            if (med.complemento_medicamento?.trim()) {
                                texto += ` - ${med.complemento_medicamento.trim()}`;
                            }

                            return (
                                <SttTextItem key={`A_${index}`} title={(index + 1)} content={texto} />
                            )
                        })
                    }
                    <Divider />
                </>
            }
            <ExibicaoHtml titulo={strings.outrosProblemas} html={teleconsultoria.outros_problemas_comorbidades_individual} />
            <ExibicaoHtml titulo={strings.outrasInformacoes} html={teleconsultoria.outras_informacoes_individual} />
            <ExibicaoHtml titulo={strings.analiseProblema} html={teleconsultoria.analise_problema_individual} />
            <ExibicaoHtml titulo={strings.discussao} html={teleconsultoria.discussao_individual} />
            {
                teleconsultoria.plano_sugerido_individual &&
                <SttTextItem title={strings.planoSugerido} content={PLANOS_SUGERIDOS.LABEL[teleconsultoria.plano_sugerido_individual]} />
            }
            {
                teleconsultoria.id_segunda_opiniao_individual &&
                <SttNotification severity="info" className={classes.notificacao}>
                    {strings.encaminhadoSegundaOpiniao}
                </SttNotification>
            }
        </>
    )
}

export default memo(RespostaSincronaClinicaIndiviual);