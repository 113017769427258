import React, { lazy, Suspense, useContext, useRef } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
    SttButton,
    SttModal,
    SttDivider,
    SttCircularProgress,
    SttExpansionPanel,
    SttTranslateHook,
} from '@stt-componentes/core';
import * as yup from 'yup';
import { Formik } from 'formik';
import dadosCabecalhoJudicialSchema from '../resposta/cabecalho/form/validationSchemaJudicializacao';
import dadosDiagnosticoSchema from '../resposta/cabecalho/form/validationSchemaJudicializacao';
import dadosResumoCasoSchema from '../resposta/cabecalho/form/validationSchemaJudicializacao';
import dadosOutrasTecnologiasSchema from '../resposta/cabecalho/form/validationSchemaJudicializacao';
import dadosEvidenciasSchema from '../resposta/cabecalho/form/validationSchemaJudicializacao';
import dadosConclusaoSchema from '../resposta/cabecalho/form/validationSchemaJudicializacao';
import dadosTecnologiasSchema from '../resposta/cabecalho/form/validationSchemaJudicializacao';
import dadosCustosSchema from '../resposta/cabecalho/form/validationSchemaJudicializacao';
import { OPCOES_TIPO_RESPOSTA } from '../../common/ConstantsRespostaJudicial';
import { useSignals } from '@preact/signals-react/runtime';
import alerta from '../../signals/alerta';

const DadosCabecalho = lazy(() => import('../resposta/cabecalho'));
const DadosDiagnostico = lazy(() => import('../resposta/diagnostico-principal'));
const DadosResumoCasoConcreto = lazy(() => import('../resposta/resumo-caso-concreto'));
const DadosTecnologiasSolicitadas = lazy(() => import('../resposta/tecnologias-solicitadas'));
const DadosOutrasTecnologias = lazy(() => import('../resposta/outras-tecnologias'));
const DadosCustosTecnologias = lazy(() => import('../resposta/custos-tecnologias'));
const DadosEvidencias = lazy(() => import('../resposta/evidencia'));
const DadosConclusao = lazy(() => import('../resposta/conclusao'));

const useStyles = makeStyles(theme => ({
    form: {
        marginTop: theme.spacing(1.5)
    },
    modalBody: {
        overflow: 'hidden'
    },
    buttonWrapper: {
        marginTop: theme.spacing(1)
    },
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    header: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    button: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    expansionPanel: {
        width: '100%'
    }
}));

const AlteracaoRespostaJudicial = ({ initialValues, callback, alterarResposta }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    const formRef = useRef();

    useSignals();

    const schema = yup.object().shape({})
        .concat(dadosCabecalhoJudicialSchema(strings))
        .concat(dadosDiagnosticoSchema(strings))
        .concat(dadosResumoCasoSchema(strings))
        .concat(dadosOutrasTecnologiasSchema(strings))
        .concat(dadosEvidenciasSchema(strings))
        .concat(dadosConclusaoSchema(strings))
        .concat(dadosTecnologiasSchema(strings))
        .concat(dadosCustosSchema(strings));

    const fecharModal = () => {
        alterarResposta.value = false;
    }

    return (
        <SttModal
            title={strings.alteracaoResposta}
            open={alterarResposta.value}
            outModalClose={fecharModal}
            iconClose={fecharModal}
            maxWidth="lg"
            fullWidth={true}
            children={
                <div className={classes.modalBody}>
                    <Formik
                        innerRef={formRef}
                        initialValues={initialValues}
                        validationSchema={schema}
                        validateOnChange={false}
                        onSubmit={(dados, { setSubmitting }) => {
                            setSubmitting(false);
                            alerta.value = {
                                ...alerta.value,
                                open: true,
                                title: strings.atencao,
                                type: 'alert',
                                message: strings.confirmarAlteracaoRespostaJudicial,
                                options: [
                                    {
                                        title: strings.sim,
                                        onClick: () => {
                                            fecharModal();
                                            callback(dados);
                                            alerta.value = {
                                                ...alerta.value,
                                                open: false
                                            };
                                        }
                                    },
                                    {
                                        title: strings.nao,
                                        onClick: () => {
                                            alerta.value = {
                                                ...alerta.value,
                                                open: false
                                            };
                                        }
                                    }
                                ],
                                onClose: () => {
                                    alerta.value = {
                                        ...alerta.value,
                                        open: false
                                    };
                                }
                            };
                        }}
                    >
                        {
                            ({
                                handleSubmit,
                                values
                            }) => {
                                return (
                                    <form onSubmit={handleSubmit} noValidate className={classes.form}>
                                        <SttExpansionPanel
                                            classegriditem={classes.expansionPanel}
                                            title={strings.cabecalho}
                                            children={
                                                <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                    <DadosCabecalho alteracaoRespostaRegulador={true} />
                                                </Suspense>
                                            }
                                        />
                                        <SttDivider />
                                        {
                                            values.cabecalho.tipoResposta === OPCOES_TIPO_RESPOSTA.TIPO.NOTA_TECNICA &&
                                            <>
                                                {/* Diagnóstico Principal */}
                                                <SttExpansionPanel
                                                    classegriditem={classes.expansionPanel}
                                                    title={strings.diagnosticoPrincipal}
                                                    children={
                                                        <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                            <DadosDiagnostico />
                                                        </Suspense>
                                                    }
                                                />
                                                <SttDivider />
                                                {/* Resumo do caso concreto */}
                                                <SttExpansionPanel
                                                    classegriditem={classes.expansionPanel}
                                                    title={strings.breveResumoCasoConcreto}
                                                    children={
                                                        <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                            <DadosResumoCasoConcreto />
                                                        </Suspense>
                                                    }
                                                />
                                                <SttDivider />
                                                {/* Dados tecnologias solicitadas */}
                                                <SttExpansionPanel
                                                    classegriditem={classes.expansionPanel}
                                                    title={strings.tecnologiasSolicitadas}
                                                    children={
                                                        <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                            <DadosTecnologiasSolicitadas />
                                                        </Suspense>
                                                    }
                                                />
                                                <SttDivider />
                                                {/* Dados outras tecnologias */}
                                                <SttExpansionPanel
                                                    classegriditem={classes.expansionPanel}
                                                    title={strings.outrasTecnologias}
                                                    children={
                                                        <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                            <DadosOutrasTecnologias />
                                                        </Suspense>
                                                    }
                                                />
                                                <SttDivider />
                                                {/* Dados custos tecnologias */}
                                                <SttExpansionPanel
                                                    classegriditem={classes.expansionPanel}
                                                    title={strings.custosTecnologias}
                                                    children={
                                                        <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                            <DadosCustosTecnologias />
                                                        </Suspense>
                                                    }
                                                />
                                                <SttDivider />
                                                {/* Dados evidencias */}
                                                <SttExpansionPanel
                                                    classegriditem={classes.expansionPanel}
                                                    title={strings.evidencias}
                                                    children={
                                                        <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                            <DadosEvidencias />
                                                        </Suspense>
                                                    }
                                                />
                                                <SttDivider />
                                                {/* Dados conclusao */}
                                                <SttExpansionPanel
                                                    classegriditem={classes.expansionPanel}
                                                    title={strings.conclusao}
                                                    children={
                                                        <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                            <DadosConclusao />
                                                        </Suspense>
                                                    }
                                                />

                                            </>
                                        }
                                    </form>
                                )
                            }
                        }
                    </Formik>
                </div>
            }
            footer={
                <div>
                    <SttButton
                        type="button"
                        variant="contained"
                        color="primary"
                        nomarginleft="true"
                        className={classes.button}
                        onClick={() => {
                            formRef.current.submitForm();
                        }}
                    >
                        {strings.confirmar}
                    </SttButton>
                    <SttButton
                        type="button"
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                        onClick={() => {
                            formRef.current.resetForm({ values: initialValues });
                        }}
                    >
                        {strings.limpar}
                    </SttButton>
                    <SttButton
                        type="button"
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                        onClick={() => {
                            formRef.current.resetForm({ values: initialValues });
                            fecharModal();
                        }}
                    >
                        {strings.fechar}
                    </SttButton>
                </div>
            }
        />
    );
};

export default AlteracaoRespostaJudicial;