export const MOTIVOS_ENCAMINHAMENTO = [
    {
        id: 'C',
        descricao: 'Confirmação diagnóstica',
        complemento: false
    },
    {
        id: 'R',
        descricao: 'Recidiva',
        complemento: false
    },
    {
        id: 'E',
        descricao: 'Reação',
        complemento: false
    },
    {
        id: 'I',
        descricao: 'Intelerância medicamentosa',
        complemento: false
    },
    {
        id: 'O',
        descricao: 'Outro',
        complemento: true
    }
]

export const FORMULARIOS_ADENDOS = {
    HANSENIASE: 1
}

const formasClinicas = {
    INDETERMINADA: 'I',
    TUBERCULOIDE: 'T',
    DIMORFA: 'D',
    VIRCHOWIANA: 'V',
    NEURAL: 'N',
    NAO_SE_APLICA: 'NA'
}

export const FORMAS_CLINICAS = {
    TIPO: { ...formasClinicas },
    LABEL: {
        [formasClinicas.INDETERMINADA]: 'Indeterminada',
        [formasClinicas.TUBERCULOIDE]: 'Tuberculóide',
        [formasClinicas.DIMORFA]: 'Dimorfa',
        [formasClinicas.VIRCHOWIANA]: 'Virchowiana',
        [formasClinicas.NEURAL]: 'Neural',
        [formasClinicas.NAO_SE_APLICA]: 'Não se aplica',
    }
}

const classificacoesOperacionais = {
    PB: 'PB',
    MB: 'MB',
    NAO_SE_APLICA: 'NA'
}

export const CLASSIFICACOES_OPERACIONAIS = {
    TIPO: { ...classificacoesOperacionais },
    LABEL: {
        [classificacoesOperacionais.PB]: 'PB',
        [classificacoesOperacionais.MB]: 'MB',
        [classificacoesOperacionais.NAO_SE_APLICA]: 'Não se aplica',
    }
}

const esquemasTerapeuticos = {
    PB: 'PB',
    MB: 'MB',
    ESQUEMA_SUBSTITUTIVO: 'ES',
    NAO_SE_APLICA: 'NA'
}

export const ESQUEMAS_TERAPEUTICOS = {
    TIPO: { ...esquemasTerapeuticos },
    LABEL: {
        [esquemasTerapeuticos.PB]: 'PQT-PB',
        [esquemasTerapeuticos.MB]: 'PQT-MB',
        [esquemasTerapeuticos.ESQUEMA_SUBSTITUTIVO]: 'Esquema substitutivo',
        [esquemasTerapeuticos.NAO_SE_APLICA]: 'Não se aplica',
    }
}

const lesoesCutaneas = {
    MANCHAS: 'M',
    PLACAS: 'P',
    INFILTRACOES: 'I',
    OUTRAS: 'O'
}

export const OPCOES_LESOES_CUTANEAS = {
    TIPO: { ...lesoesCutaneas },
    LABEL: {
        [lesoesCutaneas.MANCHAS]: 'Manchas',
        [lesoesCutaneas.PLACAS]: 'Placas',
        [lesoesCutaneas.INFILTRACOES]: 'Infiltrações',
        [lesoesCutaneas.OUTRAS]: 'Outras',
    }
}

const nervosAfetados = {
    AURICULAR: 'A',
    ULNAR: 'U',
    MEDIANO: 'M',
    RADIAL: 'R',
    FIBULAR: 'F',
    TIBIAL: 'T',
}

export const OPCOES_NERVOS_AFETADOS = {
    TIPO: { ...nervosAfetados },
    LABEL: {
        [nervosAfetados.AURICULAR]: 'Auricular',
        [nervosAfetados.ULNAR]: 'Ulnar',
        [nervosAfetados.MEDIANO]: 'Mediano',
        [nervosAfetados.RADIAL]: 'Radial',
        [nervosAfetados.FIBULAR]: 'Fibular',
        [nervosAfetados.TIBIAL]: 'Tibial',
    }
}

const baciloscopias = {
    POSITIVA: 'P',
    NEGATIVA: 'N',
    NAO_REALIZADA: 'NR',
    AGUARDANDO_RESULTADO: 'AR',
}

export const BACILOSCOPIAS = {
    TIPO: { ...baciloscopias },
    LABEL: {
        [baciloscopias.POSITIVA]: 'Positiva',
        [baciloscopias.NEGATIVA]: 'Negativa',
        [baciloscopias.NAO_REALIZADA]: 'Não realizada',
        [baciloscopias.AGUARDANDO_RESULTADO]: 'Aguardando resultado',
    }
}

const alteracoesNervos = {
    SENSIBILIDADE: 'S',
    FORCA: 'F',
    DEFORMIDADE: 'D',
}

export const ALTERACOES_NERVOS = {
    TIPO: { ...alteracoesNervos },
    LABEL: {
        [alteracoesNervos.SENSIBILIDADE]: 'Alteração de sensibilidade',
        [alteracoesNervos.FORCA]: 'Alteração de força',
        [alteracoesNervos.DEFORMIDADE]: 'Deformidade (garra, reabsorção, pé caido, outras...)',
    }
}

const grausIncapacidade = {
    '0': '0',
    '1': '1',
    '2': '2',
    NAO_AVALIADO: 'NA',
}

export const GRAUS_INCAPACIDADE = {
    TIPO: { ...grausIncapacidade },
    LABEL: {
        [grausIncapacidade['0']]: '0',
        [grausIncapacidade['1']]: '1',
        [grausIncapacidade['2']]: '2',
        [grausIncapacidade.NAO_AVALIADO]: 'Não avaliado/informado',
    }
}

const tiposEpisodiosReacionais = {
    I: '1',
    II: '2',
    MISTA: 'M',
    NEURITES: 'N',
}

export const TIPOS_EPISODIOS_REACIONAIS = {
    TIPO: { ...tiposEpisodiosReacionais },
    LABEL: {
        [tiposEpisodiosReacionais.I]: 'Tipo I',
        [tiposEpisodiosReacionais.II]: 'Tipo II',
        [tiposEpisodiosReacionais.MISTA]: 'Mista',
        [tiposEpisodiosReacionais.NEURITES]: 'Neurites',
    }
}

const tratamentosReacionais = {
    TALIDOMIDA: 'T',
    PREDINISONA: 'P',
}

export const TRATAMENTOS_REACIONAIS = {
    TIPO: { ...tratamentosReacionais },
    LABEL: {
        [tratamentosReacionais.TALIDOMIDA]: 'Talidomida',
        [tratamentosReacionais.PREDINISONA]: 'Predinisona',
    }
}